define("inboxfront/components/users/dialogs/reset-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      copyLinkToClipboard: function copyLinkToClipboard() {
        var _this = this;

        if (navigator.clipboard) {
          navigator.clipboard.writeText(this.get('link')).then(function () {
            _this.get('paperToaster').show(_this.intl.t('link_copied'));
          }, function () {
            _this.get('paperToaster').show(_this.intl.t('link_not_copied'));
          });
        } else {
          this.get('paperToaster').show(this.intl.t('link_not_copied'));
        }
      }
    }
  });

  _exports.default = _default;
});