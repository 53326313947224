define("inboxfront/components/dashboard/pages/chats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cards: Ember.computed('pageName', 'listDateMin', 'realData', 'dataGroups', 'prevDataGroups', function () {
      var dataGroups = this.get('dataGroups');
      var prevDataGroups = Array.isArray(this.get('prevDataGroups')) ? this.get('prevDataGroups') : [];
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime'];
      var data = {};
      var prevData = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
        prevData[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      prevDataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          prevData[k] += d[k];
        });
      });
      var diffTotalChats = prevData.totalChats === 0 ? 100 : (100 * (data.totalChats - prevData.totalChats) / prevData.totalChats).toFixed(2);
      var diff2 = prevData.totalQueueAbandonment === 0 ? 100 : (100 * (data.totalQueueAbandonment - prevData.totalQueueAbandonment) / prevData.totalQueueAbandonment).toFixed(2);
      var diff3 = prevData.avgChatResolutionTime === 0 ? 100 : (100 * (data.avgChatResolutionTime - prevData.avgChatResolutionTime) / prevData.avgChatResolutionTime).toFixed(2);
      var diff4 = prevData.totalOpenChats === 0 ? 100 : (100 * (data.totalOpenChats - prevData.totalOpenChats) / prevData.totalOpenChats).toFixed(2);
      var diff5 = prevData.totalChats === 0 ? 100 : (100 * (data.totalChats - prevData.totalChats) / prevData.totalChats).toFixed(2);
      var realData = this.get('realData');
      return [{
        title: 'total_chats',
        unit: '',
        icon: 'chat-bubble2',
        colorIcon: '#1D3461',
        colorWrapIcon: '#EDF2FB',
        count: realData ? data.totalChats : Math.ceil(Math.random() * 200),
        dir: (realData ? diffTotalChats < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalChats) : 10.25
      }, {
        title: 'queue_abandonment',
        unit: '',
        icon: 'refuse',
        colorIcon: '#1D3461',
        colorWrapIcon: '#E2EAFC',
        count: realData ? data.totalQueueAbandonment : Math.ceil(Math.random() * 20),
        dir: (realData ? diff2 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff2) : 5.25
      }, {
        title: 'avg_resolution_time',
        unit: 'mins',
        icon: 'clock-checked',
        colorIcon: '#1D3461',
        colorWrapIcon: '#D7E3FC',
        count: realData ? data.avgChatResolutionTime.toFixed(2) : Math.ceil(Math.random() * 20),
        dir: (realData ? diff3 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff3) : 5.25
      }, {
        title: 'avg_new_inbound',
        unit: '/day',
        icon: 'ask-question',
        colorIcon: '#1D3461',
        colorWrapIcon: '#CCDBFD',
        count: realData ? data.totalOpenChats : Math.ceil(Math.random() * 30),
        dir: (realData ? diff4 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff4) : 5.25
      }, {
        title: 'avg_chat_per_agent',
        unit: '',
        icon: 'customer-support',
        colorIcon: '#1D3461',
        colorWrapIcon: '#C1D3FE',
        count: realData ? Math.ceil(data.totalChats / 2) : Math.ceil(Math.random() * 10),
        //TODO get agents count
        dir: (realData ? diff5 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff5) : 5.25
      }];
    })
  });

  _exports.default = _default;
});