define("inboxfront/controllers/signup/index", ["exports", "inboxfront/gql/mutation", "inboxfront/utils/jwtdecode"], function (_exports, _mutation, _jwtdecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    inputType: 'password',
    inputConfirmedType: 'password',
    isValidForm: Ember.computed('categoryName', 'firstName', 'lastName', 'email', 'password', 'confirmed', function () {
      return !(this.get('validation').nameValidation[0].validate(this.get('categoryName')) && this.get('firstName') && this.get('lastName') && this.get('email') && this.get('password') && this.get('confirmed') === this.get('password'));
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var session = this.get('session');

      window.googleSignUpCallback = function (_ref) {
        var credential = _ref.credential;

        _this.ajax.query(_mutation.default.GOOGLE_AUTH, 'googleJwtAuthentication', {
          token: credential
        }).then(function (_ref2) {
          var okay = _ref2.okay;

          if (okay === 'true') {
            var responsePayload = (0, _jwtdecode.default)(credential);
            var email = responsePayload.email;
            session.authenticate('authenticator:oauth2', email, credential).catch(function (_ref3) {
              var responseJSON = _ref3.responseJSON;

              if (responseJSON.error_description) {
                _this.get('paperToaster').show(_this.intl.t('forms.login_failed'));
              }
            });
          }
        });
      };
    },
    actions: {
      togglePassword: function togglePassword() {
        if (this.inputType === 'password') {
          this.set('inputType', 'text');
        } else {
          this.set('inputType', 'password');
        }
      },
      toggleConfirmedPassword: function toggleConfirmedPassword() {
        if (this.inputConfirmedType === 'password') {
          this.set('inputConfirmedType', 'text');
        } else {
          this.set('inputConfirmedType', 'password');
        }
      },
      checkEmail: function checkEmail() {
        var _this2 = this;

        this.ajax.query(_mutation.default.CHECK_NEW_EMAIL, 'checkEmail', {
          email: this.get('email')
        }).then(function (data) {
          if (data.status === 200) {
            _this2.registerUser();
          } else {
            _this2.get('paperToaster').show(_this2.intl.t('forms.invalid_email'));
          }
        }).catch(function () {
          return _this2.get('paperToaster').show(_this2.intl.t('network_error'));
        });
      }
    },
    registerUser: function registerUser() {
      var _this3 = this;

      var hash = {
        email: this.get('email').trim(),
        password: this.get('password').trim(),
        firstName: this.get('firstName').trim(),
        lastName: this.get('lastName').trim(),
        category: this.get('categoryName') ? this.get('categoryName').trim() : ''
      };
      this.ajax.query(_mutation.default.REGISTER_USER, 'registerUser', hash).then(function () {
        _this3.transitionToRoute('signup.active');
      }).catch(function () {
        return _this3.get('paperToaster').show(_this3.intl.t('network_error'));
      });
    }
  });

  _exports.default = _default;
});