define("inboxfront/components/kb/magic-styling", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    kb: Ember.inject.service(),
    tagName: '',
    showBanner: true,
    fonts: ['', '', '', '', '', '', '', '', '', '', ''],
    selectedFont: null,
    googleAPIKey: 'AIzaSyAN3UzOIZL08KtPhUALO3aQdzQeJ8onVMg',
    uploadSuccessLogo: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        console.log('LOGO RESP', response, result);

        if (result.data.file) {
          controller.set('base.styles.logoAttachmentId', result.data.file.attachment.id);
          controller.set('base.styles.logoUrl', result.data.file.attachment.datafile);
          controller.get('kb').saveStyles(controller.get('base'));
        }
      };
    }),
    uploadSuccessBG: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        console.log('BG RESP', response, result);

        if (result.data.file) {
          controller.set('base.styles.bgAttachmentId', result.data.file.attachment.id);
          controller.set('base.styles.bgUrl', result.data.file.attachment.datafile);
          controller.get('kb').saveStyles(controller.get('base'));
        }
      };
    }),
    optionsLogo: Ember.computed('base.id', function () {
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        dictDefaultMessage: this.intl.t('upload_image'),
        clickable: '.avatar-upload-logo',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "kb", "model": "knowledgebase", "objectId": "' + this.get('base.id') + '"}'
        }
      };
    }),
    optionsBG: Ember.computed('base.id', function () {
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        dictDefaultMessage: this.intl.t('upload_image'),
        clickable: '.avatar-upload-bg',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "kb", "model": "knowledgebase", "objectId": "' + this.get('base.id') + '"}'
        }
      };
    }),
    logoUrl: Ember.computed('base.styles.logoUrl', function () {
      if (this.get('base.styles.logoUrl')) {
        return _environment.default.mediaUrl + this.get('base.styles.logoUrl');
      }

      return '';
    }),
    backgroundUrl: Ember.computed('base.styles.bgUrl', function () {
      if (this.get('base.styles.bgUrl')) {
        return _environment.default.mediaUrl + this.get('base.styles.bgUrl');
      }

      return '';
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      saveStyles: function saveStyles() {
        this.get('kb').saveStyles(this.get('base'));
      },
      deleteLogo: function deleteLogo() {
        this.get('kb').deleteLogo(this.get('base.styles'));
      },
      deleteBackground: function deleteBackground() {
        this.get('kb').deleteBackground(this.get('base.styles'));
      },
      updateField: function updateField(name, value) {
        var styles = this.get('base.styles');
        Ember.set(styles, name, value);
        styles.validate();
      },
      discardStyleChanges: function discardStyleChanges(goBack) {
        this.get('base.styles').restore();
        goBack && this.onBack();
      },
      goBack: function goBack() {
        if (this.get('base.styles.isUpdated')) {
          this.set('openDiscardChangesDialog', true);
        } else {
          this.onBack();
        }
      },
      onChangeFont: function onChangeFont(value) {
        this.set('selectedFont', value);
      }
    }
  });

  _exports.default = _default;
});