define("inboxfront/controllers/organizations/edit", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query", "inboxfront/config/environment", "inboxfront/helpers/countries-list"], function (_exports, _mutation, _query, _environment, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    contacts: Ember.inject.service(),
    queryParams: ['id'],
    countriesList: _countriesList.COUNTRIES_LIST,
    isAddPreferenceMode: false,
    isEditNoteStatus: false,
    selectedOrganizationTab: 'timeline',
    organizationContacts: Ember.computed('organization', function () {
      var channels = this.get('channels');
      var guestcontactSet = this.get('organization.guestcontactSet');
      var arr = [];

      if (this.get('organization')) {
        guestcontactSet.forEach(function (el) {
          var contact = channels.find(function (item) {
            return item.type === el.contactType;
          });

          if (contact) {
            arr.push({
              type: contact.type,
              contactValue: el.contactValue,
              id: el.id,
              icon: contact.icon
            });
          }
        });
        return arr;
      }

      return [];
    }),
    timeline: Ember.computed('organization', function () {
      var _this = this;

      return this.get('organization.activity').map(function (item) {
        var randomColor = _this.getRandomColor();

        return {
          title: item.title,
          obj: JSON.parse(item.text),
          created: item.createdAt,
          color: randomColor === '#ffffff' ? '#b0dcf7' : randomColor
        };
      });
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        controller.set('attachmentId', result.data.file.attachment.id);
        controller.set('urlAttachment', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
        controller.updateOrganizationQuery();
      };
    }),
    options: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token') || undefined;
      var guestId = this.get('organization.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '#avatar-upload',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "guest", "model": "organization", "objectId": "' + guestId + '"}'
        }
      };
    }),
    openOpportunities: null,
    closedOpportunities: null,
    orgIsFromCrm: Ember.computed('organization.source', function () {
      return !!this.get('organization.source');
    }),
    loadOpportunities: function loadOpportunities() {
      var _this2 = this;

      this.set('openOpportunities', []);
      this.set('closedOpportunities', []);
      var organization = this.get('organization');

      if (!!organization.source) {
        //if orgIsFromCrm
        var data = {
          crm: organization.source.toUpperCase(),
          objectId: organization.id,
          systemObject: 'organization'
        };
        this.ajax.query(_query.default.GET_CRM_OPPORTUNITIES, 'opportunities', data).then(function (res) {
          if (!Array.isArray(res)) {
            res = [];
          }

          _this2.set('openOpportunities', res.filter(function (opp) {
            return !opp.closedDate;
          }));

          _this2.set('closedOpportunities', res.filter(function (opp) {
            return opp.closedDate;
          }));
        });
      }
    },
    actions: {
      onRemovePreference: function onRemovePreference(preference) {
        var _this3 = this;

        this.ajax.query(_mutation.default.DELETE_PREFERENCE, 'deleteGuestPreference', {
          preferenceId: preference.id
        }).then(function () {
          return _this3.updateOrganizationQuery();
        });
      },
      onCancelNote: function onCancelNote() {
        this.set('note', '');
        this.set('noteTitle', '');
        this.set('showAddNote', false);
        this.set('isEditNoteStatus', false);
      },
      onShowEditNote: function onShowEditNote(note) {
        this.set('note', note.text);
        this.set('noteTitle', note.title);
        this.set('showAddNote', true);
        this.set('isEditNoteStatus', true);
        this.set('noteId', note.id);
      },
      deleteNote: function deleteNote(noteId) {
        var _this4 = this;

        this.ajax.query(_mutation.default.DELETE_NOTE, 'deleteNote', {
          noteId: noteId
        }).then(function () {
          _this4.get('paperToaster').show(_this4.intl.t('note_deleted'));

          _this4.updateOrganizationQuery();
        });
      },
      addNote: function addNote(note) {
        var _this5 = this;

        var hash = {
          appLabel: 'guest',
          model: 'organization',
          objectId: this.get('organization.id'),
          text: note,
          title: this.get('noteTitle')
        };

        if (this.get('isEditNoteStatus')) {
          hash = {
            appLabel: 'guest',
            model: 'organization',
            noteId: this.get('noteId'),
            text: note,
            title: this.get('noteTitle')
          };
        }

        this.ajax.query(_mutation.default.ADD_CONTACT_NOTE, 'addContactNote', hash).then(function () {
          _this5.set('note', '');

          _this5.set('noteTitle', '');

          _this5.set('showAddNote', false);

          _this5.set('isEditNoteStatus', false);

          _this5.set('noteId', null);

          _this5.updateOrganizationQuery();
        });
      },
      onAddNoteClick: function onAddNoteClick() {
        this.set('showAddNote', true);
      },
      closeDialog: function closeDialog() {
        this.set('showDialog', false);
      },
      editAddresses: function editAddresses(key, val) {
        var _hash,
            _this6 = this;

        var hash = (_hash = {
          addressId: this.get('organization.address.id')
        }, _defineProperty(_hash, key, val), _defineProperty(_hash, "isOffice", true), _defineProperty(_hash, "appLabel", 'guest'), _defineProperty(_hash, "model", 'organization'), _hash);
        if (this.get('cloneOrganizationAddress')[key] === val) return;
        this.ajax.query(_mutation.default.CREATE_UPDATE_ADDRESS, 'createUpdateAddress', hash).then(function () {
          _this6.updateOrganizationQuery();

          _this6.get('paperToaster').show(_this6.intl.t('org_address_updated'));
        });
      },
      deleteAttachment: function deleteAttachment() {
        var _this7 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: this.get('attachmentId')
        }).then(function () {
          _this7.set('urlAttachment', null);

          _this7.set('attachmentId', null);

          _this7.updateOrganizationQuery();
        });
      },
      onSelectParentCompany: function onSelectParentCompany(val) {
        var _this8 = this;

        var hash = {
          organizationId: this.get('organization.id'),
          parentId: val.id
        };
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash).then(function () {
          _this8.updateOrganizationQuery(true);

          _this8.get('paperToaster').show(_this8.intl.t('org_updated'));
        });
      },
      onSelectChildCompany: function onSelectChildCompany(val) {
        var _this9 = this;

        var hash = {
          organizationId: val.id,
          parentId: this.get('organization.id')
        };
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash).then(function () {
          _this9.updateOrganizationQuery(true);

          _this9.get('paperToaster').show(_this9.intl.t('org_updated'));
        });
      },
      addChannel: function addChannel(channel, value, org) {
        var _this10 = this;

        var hash = {
          contactType: channel.type,
          contactValue: value,
          organizationId: org.id
        };
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST_CONTACT, 'createOrUpdateGuestContact', hash).then(function () {
          _this10.set('selectedChannel', {
            app: 'Facebook'
          });

          _this10.set('selectedContactValue', '');

          _this10.closeFormChannel();

          _this10.updateOrganizationQuery();
        });
      },
      deleteChannel: function deleteChannel(channel) {
        var _this11 = this;

        this.ajax.query(_mutation.default.DELETE_GUEST_CONTACT, 'deleteGuestContact', {
          contactId: channel.id
        }).then(function () {
          _this11.closeFormChannel();

          _this11.updateOrganizationQuery();
        });
      },
      cancelAddChannel: function cancelAddChannel() {
        this.closeFormChannel();
      },
      chooseGuestContact: function chooseGuestContact(val) {
        var _this12 = this;

        var hash = {
          organizationId: this.get('organization.id'),
          guestId: val.id
        };
        this.set('selectedGuest', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this12.updateOrganizationQuery();

          _this12.get('paperToaster').show(_this12.intl.t('org_updated'));
        });
      },
      onSelectContactOwner: function onSelectContactOwner(val) {
        var _this13 = this;

        var hash = {
          organizationId: this.get('organization.id'),
          contactOwnerId: val.id
        };
        this.set('selectedContactOwner', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash).then(function () {
          _this13.updateOrganizationQuery();

          _this13.get('paperToaster').show(_this13.intl.t('org_updated'));
        });
      },
      onSelectAddressCountry: function onSelectAddressCountry(val) {
        var _this14 = this;

        var hash = {
          addressId: this.get('organization.address.id'),
          country: val.iso2,
          isOffice: false,
          appLabel: 'guest',
          model: 'organization'
        };
        this.set('addressOrganization', val);
        this.ajax.query(_mutation.default.CREATE_UPDATE_ADDRESS, 'createUpdateAddress', hash).then(function () {
          _this14.updateOrganizationQuery();

          _this14.get('paperToaster').show(_this14.intl.t('address_updated'));
        });
      },
      onSelectProperty: function onSelectProperty(val) {
        var _this15 = this;

        var hash = {
          organizationId: this.get('organization.id'),
          propertyId: val.id
        };
        this.set('selectedProperty', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash).then(function () {
          _this15.updateOrganizationQuery();

          _this15.get('paperToaster').show(_this15.intl.t('org_updated'));
        });
      },
      saveEdit: function saveEdit(key, val) {
        var _this16 = this;

        var hash = _defineProperty({
          organizationId: this.get('organization.id')
        }, key, val);

        if (this.get('cloneOrganization')[key] === val) return;
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash).then(function () {
          _this16.updateOrganizationQuery();

          _this16.get('paperToaster').show(_this16.intl.t('org_updated'));
        });
      },
      deleteChildCompany: function deleteChildCompany(child) {
        var _this17 = this;

        var hash = {
          organizationId: child.id,
          deleteParent: true
        };
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash).then(function () {
          _this17.updateOrganizationQuery();

          _this17.get('paperToaster').show(_this17.intl.t('child_company_deleted'));
        });
      },
      saveCustomFields: function saveCustomFields() {
        var _this18 = this;

        var timeout = this.get('saveTimeout');
        timeout && clearTimeout(timeout);
        this.set('saveTimeout', setTimeout(function () {
          _this18.get('contacts').saveOrganization(_this18.get('customFieldsData'), function () {
            _this18.updateOrganizationQuery();

            _this18.get('paperToaster').show(_this18.intl.t('org_updated'));
          });

          _this18.set('saveTimeout', null);
        }, 2000));
      }
    },
    getRandomColor: function getRandomColor() {
      var color = '#';

      for (var i = 0; i < 6; i++) {
        color += '0123456789abcdef'[Math.floor(Math.random() * 16)];
      }

      return color;
    },
    onFilterOrganizations: function onFilterOrganizations() {
      var arr = [];
      var children = this.get('organization.children');
      var parent = this.get('organization.parent');
      var original = this.get('model.organizations');
      arr.push(parent);
      arr.push.apply(arr, _toConsumableArray(children));
      this.set('filterOrganizations', original.filter(function (obj) {
        return !arr.find(function (g) {
          return g.id === obj.id;
        });
      }));
    },
    closeFormChannel: function closeFormChannel() {
      this.set('selectedChannel', {
        app: 'Facebook'
      });
      this.set('selectedContactValue', '');
      this.set('showAddChannel', false);
    },
    updateOrganizationQuery: function updateOrganizationQuery() {
      var _this19 = this;

      var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.ajax.query(_query.default.ORGANIZATION, 'organization', {
        organizationId: this.get('organization.id')
      }).then(function (data) {
        _this19.set('organization', data);

        _this19.set('cloneOrganization', _objectSpread({}, data));

        _this19.set('cloneOrganizationAddress', _objectSpread({}, data.address));

        status && _this19.onFilterOrganizations();

        _this19.set('customFieldsData', _this19.get('contacts').deserialize(_this19.get('model.orgFields'), data || {}));
      });
    }
  });

  _exports.default = _default;
});