define("inboxfront/components/dashboard/panels/ticket-category", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#tickets_by_category')).highcharts({
        chart: {
          type: 'bar',
          height: 315
        },
        title: null,
        xAxis: {
          categories: [],
          title: null
        },
        yAxis: {
          min: 0,
          title: null
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        series: [{
          name: this.intl.t('general_enquiry'),
          data: [],
          color: '#A3AF9C',
          pointWidth: 14
        }, {
          name: this.intl.t('incident'),
          data: [],
          color: '#3F524C',
          pointWidth: 14
        }, {
          name: this.intl.t('cancellation'),
          data: [],
          color: '#FDEDD8',
          pointWidth: 14
        }, {
          name: this.intl.t('complaints'),
          data: [],
          color: '#A3AF9C',
          pointWidth: 14
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var general_enquiry = [];
      var incident = [];
      var cancellation = [];
      var complaints = [];
      var series = [general_enquiry, incident, cancellation, complaints];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function () {
        if (realData) {
          var data = dataGroups[i];
          general_enquiry.pushObject(data === null || data === void 0 ? void 0 : data.enquiryTickets);
          incident.pushObject(data === null || data === void 0 ? void 0 : data.incidentTickets);
          cancellation.pushObject(data === null || data === void 0 ? void 0 : data.cancellationTickets);
          complaints.pushObject(data === null || data === void 0 ? void 0 : data.complaintTickets);
        } else {
          general_enquiry.pushObject(Math.ceil(Math.random() * 50 + 20));
          incident.pushObject(Math.ceil(Math.random() * 50 + 20));
          cancellation.pushObject(Math.ceil(Math.random() * 50 + 20));
          complaints.pushObject(Math.ceil(Math.random() * 50 + 20));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.series[2].setData(series[2]);
      chart.series[3].setData(series[3]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});