define("inboxfront/templates/components/field-emoji", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zo7isEwT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"click-outside\",null,[[\"action\",\"tagName\"],[[28,\"action\",[[23,0,[]],\"onClickOutsidePopup\"],null],\"\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"msg wrap-editor-emoji\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"txt-input \",[28,\"if\",[[24,[\"isFocus\"]],\"focus\",\"\"],null],\" \",[22,\"class\"]]]],[11,\"id\",[22,\"id\"]],[10,\"onkeypress\",\"return (this.innerText.length <= 256)\"],[11,\"placeholder\",[28,\"t\",[\"message\"],null]],[10,\"contenteditable\",\"true\"],[11,\"onkeyup\",[28,\"action\",[[23,0,[]],\"onKeyUp\"],null]],[11,\"onmouseup\",[28,\"action\",[[23,0,[]],\"onMouseUp\"],null]],[11,\"onBlur\",[28,\"action\",[[23,0,[]],\"onBlurInputMessage\",[24,[\"extraData\"]],\"\",true],null]],[8],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isFocus\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"popups/emoji-picker\",null,[[\"onSelectEmoji\"],[[28,\"action\",[[23,0,[]],\"onSelectEmoji\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/field-emoji.hbs"
    }
  });

  _exports.default = _default;
});