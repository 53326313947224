define("inboxfront/components/dashboard/pages/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cards: Ember.computed('pageName', 'listDateMin', 'realData', 'dataGroups', 'prevDataGroups', function () {
      var dataGroups = this.get('dataGroups');
      var prevDataGroups = Array.isArray(this.get('prevDataGroups')) ? this.get('prevDataGroups') : [];
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime'];
      var data = {};
      var prevData = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
        prevData[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      prevDataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          prevData[k] += d[k];
        });
      });
      var diff = prevData.totalTickets === 0 ? 100 : (100 * (data.totalTickets - prevData.totalTickets) / prevData.totalTickets).toFixed(2);
      var diff2 = prevData.openTickets === 0 ? 100 : (100 * (data.openTickets - prevData.openTickets) / prevData.openTickets).toFixed(2);
      var diff3 = prevData.avgTicketResolutionTime === 0 ? 100 : (100 * (data.avgTicketResolutionTime - prevData.avgTicketResolutionTime) / prevData.avgTicketResolutionTime).toFixed(2);
      var diff4 = prevData.openTickets === 0 ? 100 : (100 * (data.openTickets - prevData.openTickets) / prevData.openTickets).toFixed(2);
      var diff5 = prevData.totalTickets === 0 ? 100 : (100 * (data.totalTickets - prevData.totalTickets) / prevData.totalTickets).toFixed(2);
      var realData = this.get('realData');
      return [{
        title: 'total_tickets',
        unit: '',
        icon: 'two-tickets',
        colorIcon: '#3F524C',
        colorWrapIcon: '#F0FAF6',
        count: realData ? data.totalTickets : Math.ceil(Math.random() * 200),
        dir: (realData ? diff < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff) : 5.25
      }, {
        title: 'total_open_tickets',
        unit: '',
        icon: 'unlock2',
        colorIcon: '#3F524C',
        colorWrapIcon: '#E3F0EF',
        count: realData ? data.openTickets : Math.ceil(Math.random() * 20),
        dir: (realData ? diff2 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff2) : 5.25
      }, {
        title: 'avg_resolution_time',
        unit: '',
        icon: 'task-completed',
        colorIcon: '#3F524C',
        colorWrapIcon: '#DBECE9',
        count: realData ? data.avgTicketResolutionTime.toFixed(0) + 'mins' : '2d 00hrs 05mins',
        dir: (realData ? diff3 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff3) : 5.25
      }, {
        title: 'avg_new_ticket',
        unit: '/day',
        icon: 'new-ticket',
        colorIcon: '#3F524C',
        colorWrapIcon: '#CCE3DE',
        count: realData ? data.openTickets : Math.ceil(Math.random() * 30),
        dir: (realData ? diff4 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff4) : 5.25
      }, {
        title: 'avg_ticket_per_agent',
        unit: '',
        icon: 'customer-insights',
        colorIcon: '#3F524C',
        colorWrapIcon: '#B8D3C8',
        count: realData ? data.totalTickets : Math.ceil(Math.random() * 20),
        dir: (realData ? diff5 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff5) : 5.25
      }];
    })
  });

  _exports.default = _default;
});