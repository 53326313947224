define("inboxfront/controllers/contacts/edit", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query", "inboxfront/config/environment", "inboxfront/helpers/countries-list"], function (_exports, _mutation, _query, _environment, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    contacts: Ember.inject.service(),
    validation: Ember.inject.service(),
    countriesList: _countriesList.COUNTRIES_LIST,
    queryParams: ['id'],
    isEditNoteStatus: false,
    isShowAddOneMoreEmail: true,
    isCreateDialogInserted: false,
    showCreateDialog: false,
    selectedContactTab: 'timeline',
    timeline: Ember.computed('guest', function () {
      var _this = this;

      return this.get('guest.activity').map(function (item) {
        var randomColor = _this.getRandomColor();

        var obj;

        try {
          obj = JSON.parse(item.text);
        } catch (e) {
          obj = {
            title: item.text
          };
        }

        return {
          title: item.title,
          obj: obj,
          created: item.createdAt,
          color: randomColor === '#ffffff' ? '#b0dcf7' : randomColor
        };
      });
    }),
    openTasks: Ember.computed('guest.ticketSet.edges.[]', function () {
      return this.get('guest.ticketSet.edges').filter(function (ticket) {
        return ticket.node.status === 'OPEN';
      });
    }),
    closedTasks: Ember.computed('guest.ticketSet.edges.[]', function () {
      return this.get('guest.ticketSet.edges').filter(function (ticket) {
        return ticket.node.status === 'CLOSED';
      });
    }),
    guestContacts: Ember.computed('guest', function () {
      var _this$get;

      var channels = this.get('channels');
      var collection = [];
      (_this$get = this.get('guest.guestcontactSet')) === null || _this$get === void 0 ? void 0 : _this$get.forEach(function (el) {
        var contact = channels.find(function (item) {
          return item.type === el.contactType;
        });

        if (contact) {
          collection.push({
            type: contact.type,
            contactValue: el.contactValue,
            id: el.id,
            icon: contact.icon
          });
        }
      });
      return collection;
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        controller.set('attachmentId', result.data.file.attachment.id);
        controller.set('urlAttachment', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
        controller.updateGuestQuery();
      };
    }),
    options: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token') || undefined;
      var guestId = this.get('guest.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '#avatar-upload',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "guest", "model": "guest", "objectId": "' + guestId + '"}'
        }
      };
    }),
    openOpportunities: null,
    closedOpportunities: null,
    guestIsFromCrm: Ember.computed('guest', function () {
      var _this$get2, _this$get2$find;

      var listCRM = ['hubspot', 'pipedrive', 'salesforce']; //TODO check this data duplication

      var guestContactType = (_this$get2 = this.get('guest.guestcontactSet')) === null || _this$get2 === void 0 ? void 0 : (_this$get2$find = _this$get2.find(function (c) {
        return listCRM.indexOf(c.contactType) !== -1;
      })) === null || _this$get2$find === void 0 ? void 0 : _this$get2$find.contactType;
      return !!guestContactType;
    }),
    loadOpportunities: function loadOpportunities() {
      var _guest$guestcontactSe,
          _guest$guestcontactSe2,
          _this2 = this;

      this.set('openOpportunities', []);
      this.set('closedOpportunities', []);
      var guest = this.get('guest');
      var listCRM = ['hubspot', 'pipedrive', 'salesforce']; //TODO check this data duplication

      var guestContactType = (_guest$guestcontactSe = guest.guestcontactSet) === null || _guest$guestcontactSe === void 0 ? void 0 : (_guest$guestcontactSe2 = _guest$guestcontactSe.find(function (c) {
        return listCRM.indexOf(c.contactType) !== -1;
      })) === null || _guest$guestcontactSe2 === void 0 ? void 0 : _guest$guestcontactSe2.contactType;

      if (!!guestContactType) {
        //if guestIsFromCrm
        var data = {
          crm: guestContactType.toUpperCase(),
          objectId: guest.id,
          systemObject: 'contact'
        };
        this.ajax.query(_query.default.GET_CRM_OPPORTUNITIES, 'opportunities', data).then(function (res) {
          if (!Array.isArray(res)) {
            res = [];
          }

          _this2.set('openOpportunities', res.filter(function (opp) {
            return !_this2._opportunityIsClosed(opp);
          }));

          _this2.set('closedOpportunities', res.filter(function (opp) {
            return _this2._opportunityIsClosed(opp);
          }));
        });
      }
    },
    _opportunityIsClosed: function _opportunityIsClosed(opp) {
      if (opp.stage === 'closedwon' || opp.stage === 'closedlost') {
        return true;
      }

      if (opp.closeDate && !opp.targetCloseDate) {
        //check if opened deal is expired
        opp.isExpired = this._isExpired(opp.closeDate);
        opp.targetCloseDate = opp.closeDate;
        opp.closeDate = null;
      }

      return false;
    },
    _isExpired: function _isExpired(date) {
      var today = new Date();
      var closeDate = new Date(date);
      return closeDate < today;
    },
    actions: {
      onRemovePreference: function onRemovePreference(_ref) {
        var _this3 = this;

        var id = _ref.id;
        this.ajax.query(_mutation.default.DELETE_PREFERENCE, 'deleteGuestPreference', {
          preferenceId: id
        }).then(function () {
          return _this3.updateGuestQuery();
        });
      },
      onTaskCreate: function onTaskCreate() {
        this.get('paperToaster').show(this.intl.t('task_created'));
        this.set('showCreateDialog', false);
        this.set('isCreateDialogInserted', false);
        this.updateGuestQuery();
      },
      createTaskShowDialog: function createTaskShowDialog() {
        var _this4 = this;

        var guest = this.get('guest');
        var additionalData = {
          category: 'TICKET',
          guest: guest,
          companyProperty: guest.companyProperty
        };
        var readOnlyFields = {
          category: true,
          property: true,
          guest: true,
          firstName: guest.firstName,
          lastName: guest.lastName,
          email: guest.primaryEmail,
          phone: guest.personalPhone
        };
        this.set('additionalData', additionalData);
        this.set('readOnlyFields', readOnlyFields);
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this4.set('showCreateDialog', true);
        }, 300);
      },
      onCancelNote: function onCancelNote() {
        this.set('note', '');
        this.set('noteTitle', '');
        this.set('showAddNote', false);
        this.set('isEditNoteStatus', false);
      },
      onSelectContactOwner: function onSelectContactOwner(val) {
        var _this5 = this;

        var hash = {
          guestId: this.get('guest.id'),
          contactOwner: val.id
        };
        this.set('selectedContactOwner', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this5.updateGuestQuery();

          _this5.get('paperToaster').show(_this5.intl.t('contact_updated'));
        });
      },
      onSelectOrganization: function onSelectOrganization(val) {
        var _this6 = this;

        var hash = {
          guestId: this.get('guest.id'),
          organizationId: val.id
        };
        this.set('selectedOrganization', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this6.updateGuestQuery();

          _this6.get('paperToaster').show(_this6.intl.t('contact_updated'));
        });
      },
      onSelectProperty: function onSelectProperty(val) {
        var _this7 = this;

        var hash = {
          guestId: this.get('guest.id'),
          propertyId: val.id
        };
        this.set('selectedProperty', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this7.updateGuestQuery();

          _this7.get('paperToaster').show(_this7.intl.t('contact_company_updated'));
        });
      },
      deleteEmail: function deleteEmail() {
        var _this8 = this;

        var hash = {
          guestId: this.get('guest.id'),
          email: ''
        };
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this8.set('isShowAnotherEmail', false);

          _this8.updateGuestQuery();
        });
      },
      showMoreEmailField: function showMoreEmailField() {
        this.set('isShowAnotherEmail', true);
      },
      actionOptEmail: function actionOptEmail(guest) {
        var _this9 = this;

        var status = !guest.optOutEmail;
        var hash = {
          guestId: guest.id,
          optOutEmail: status
        };
        Ember.set(guest, 'optOutEmail', status);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          return _this9.updateGuestQuery();
        });
      },
      onShowEditNote: function onShowEditNote(note) {
        this.set('note', note.text);
        this.set('noteTitle', note.title);
        this.set('showAddNote', true);
        this.set('isEditNoteStatus', true);
        this.set('noteId', note.id);
      },
      deleteNote: function deleteNote(noteId) {
        var _this10 = this;

        this.ajax.query(_mutation.default.DELETE_NOTE, 'deleteNote', {
          noteId: noteId
        }).then(function () {
          _this10.get('paperToaster').show(_this10.intl.t('note_deleted'));

          _this10.updateGuestQuery();
        });
      },
      onAddNote: function onAddNote(note) {
        var _this11 = this;

        var hash = {
          appLabel: 'guest',
          model: 'guest',
          objectId: this.get('guest.id'),
          text: note,
          title: this.get('noteTitle')
        };

        if (this.get('isEditNoteStatus')) {
          hash = {
            appLabel: 'guest',
            model: 'guest',
            noteId: this.get('noteId'),
            text: note,
            title: this.get('noteTitle')
          };
        }

        this.ajax.query(_mutation.default.ADD_CONTACT_NOTE, 'addContactNote', hash).then(function () {
          _this11.set('note', '');

          _this11.set('noteTitle', '');

          _this11.set('showAddNote', false);

          _this11.set('isEditNoteStatus', false);

          _this11.set('noteId', null);

          _this11.updateGuestQuery();
        });
      },
      onAddNoteClick: function onAddNoteClick() {
        this.set('showAddNote', true);
      },
      closeDialog: function closeDialog() {
        this.set('showDialog', false);
      },
      deleteAttachment: function deleteAttachment() {
        var _this12 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: this.get('attachmentId')
        }).then(function () {
          _this12.set('urlAttachment', null);

          _this12.set('attachmentId', null);

          _this12.updateGuestQuery();
        });
      },
      addChannel: function addChannel(_ref2, value, _ref3) {
        var _this13 = this;

        var type = _ref2.type;
        var id = _ref3.id;
        var hash = {
          contactType: type,
          contactValue: value,
          guestId: id
        };
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST_CONTACT, 'createOrUpdateGuestContact', hash).then(function () {
          _this13.get('paperToaster').show(_this13.intl.t('channel_added', {
            type: type
          }));

          _this13.closeFormChannel();

          _this13.updateGuestQuery();
        });
      },
      deleteChannel: function deleteChannel(_ref4) {
        var _this14 = this;

        var id = _ref4.id,
            type = _ref4.type;
        this.ajax.query(_mutation.default.DELETE_GUEST_CONTACT, 'deleteGuestContact', {
          contactId: id
        }).then(function () {
          _this14.get('paperToaster').show(_this14.intl.t('channel_removed', {
            type: type
          }));

          _this14.closeFormChannel();

          _this14.updateGuestQuery();
        });
      },
      cancelAddChannel: function cancelAddChannel() {
        this.closeFormChannel();
      },
      saveEdit: function saveEdit(key, val) {
        var _this15 = this;

        var hash = _defineProperty({
          guestId: this.get('guest.id')
        }, key, val);

        if (this.get('cloneGuest')[key] === val) return;
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this15.updateGuestQuery();

          _this15.get('paperToaster').show(_this15.intl.t('contact_updated'));
        });
      },
      onSelectHomeAddressCountry: function onSelectHomeAddressCountry(val) {
        var _this16 = this;

        var hash = {
          addressId: this.get('guest.homeAddress.id'),
          country: val.iso2,
          isOffice: false,
          appLabel: 'guest',
          model: 'guest'
        };
        this.set('homeAddressContact', val);
        this.ajax.query(_mutation.default.CREATE_UPDATE_ADDRESS, 'createUpdateAddress', hash).then(function () {
          _this16.updateGuestQuery();

          _this16.get('paperToaster').show(_this16.intl.t('address_updated'));
        });
      },
      onSelectOfficeHomeAddressCountry: function onSelectOfficeHomeAddressCountry(val) {
        var _this17 = this;

        var hash = {
          addressId: this.get('guest.officeAddress.id'),
          country: val.iso2,
          isOffice: true,
          appLabel: 'guest',
          model: 'guest'
        };
        this.set('homeAddressContact', val);
        this.ajax.query(_mutation.default.CREATE_UPDATE_ADDRESS, 'createUpdateAddress', hash).then(function () {
          _this17.updateGuestQuery();

          _this17.get('paperToaster').show(_this17.intl.t('address_updated'));
        });
      },
      onKeyDown: function onKeyDown(e) {
        var val = e.target.value;
        var code = e.which ? e.which : e.keyCode;

        if (code !== 46 && code > 31 && (code < 48 || code > 57) || code === 46 && val.indexOf('.') > -1) {
          return false;
        }

        this.set('guest.mobilePhone', val);
      },
      onKeyUp: function onKeyUp(e) {
        var val = e.target.value;

        if (val.length > 14) {
          val = val.substr(0, 14);
        }

        this.set('guest.mobilePhone', val);
      },
      editAddressesHome: function editAddressesHome(key, val) {
        var _hash2,
            _this18 = this;

        var hash = (_hash2 = {
          addressId: this.get('guest.homeAddress.id')
        }, _defineProperty(_hash2, key, val), _defineProperty(_hash2, "isOffice", false), _defineProperty(_hash2, "appLabel", 'guest'), _defineProperty(_hash2, "model", 'guest'), _hash2);
        if (this.get('cloneGuestAddressHome')[key] === val) return;
        this.ajax.query(_mutation.default.CREATE_UPDATE_ADDRESS, 'createUpdateAddress', hash).then(function () {
          _this18.updateGuestQuery();

          _this18.get('paperToaster').show(_this18.intl.t('address_updated'));
        });
      },
      editAddressesOffice: function editAddressesOffice(key, val) {
        var _hash3,
            _this19 = this;

        var hash = (_hash3 = {
          addressId: this.get('guest.officeAddress.id')
        }, _defineProperty(_hash3, key, val), _defineProperty(_hash3, "isOffice", true), _defineProperty(_hash3, "appLabel", 'guest'), _defineProperty(_hash3, "model", 'guest'), _hash3);
        if (this.get('cloneGuestAddressOffice')[key] === val) return;
        this.ajax.query(_mutation.default.CREATE_UPDATE_ADDRESS, 'createUpdateAddress', hash).then(function () {
          _this19.updateGuestQuery();

          _this19.get('paperToaster').show(_this19.intl.t('address_updated'));
        });
      },
      onSelectLanguage: function onSelectLanguage(val) {
        var _this20 = this;

        var hash = {
          guestId: this.get('guest.id'),
          language: val.code
        };
        this.set('selectedProperty', val);
        this.ajax.query(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash).then(function () {
          _this20.updateGuestQuery();

          _this20.get('paperToaster').show(_this20.intl.t('contact_lang_updated'));
        });
      },
      saveCustomFields: function saveCustomFields() {
        var _this21 = this;

        var timeout = this.get('saveTimeout');
        timeout && clearTimeout(timeout);
        this.set('saveTimeout', setTimeout(function () {
          _this21.get('contacts').saveGuest(_this21.get('customFieldsData'), function () {
            _this21.updateGuestQuery();

            _this21.get('paperToaster').show(_this21.intl.t('contact_updated'));
          });

          _this21.set('saveTimeout', null);
        }, 2000));
      }
    },
    getRandomColor: function getRandomColor() {
      var color = '#';

      for (var i = 0; i < 6; i++) {
        color += '0123456789abcdef'[Math.floor(Math.random() * 16)];
      }

      return color;
    },
    closeFormChannel: function closeFormChannel() {
      this.set('selectedChannel', {
        app: 'Facebook'
      });
      this.set('selectedContactValue', '');
      this.set('showAddChannel', false);
    },
    updateGuestQuery: function updateGuestQuery() {
      var _this22 = this;

      this.ajax.query(_query.default.GUEST, 'guest', {
        guestId: this.get('guest').id
      }).then(function (data) {
        _this22.set('guest', data);

        _this22.set('cloneGuest', _objectSpread({}, data));

        _this22.set('cloneGuestAddressHome', _objectSpread({}, data.homeAddress));

        _this22.set('cloneGuestAddressOffice', _objectSpread({}, data.officeAddress));

        _this22.set('customFieldsData', _this22.get('contacts').deserialize(_this22.get('model.contactFields'), data || {}));
      });
    }
  });

  _exports.default = _default;
});