define("inboxfront/routes/contacts/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    customfields: Ember.inject.service(),
    contacts: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    users: Ember.inject.service(),
    textLoading: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('textLoading', this.get('intl').t('status.loading_contacts'));
    },
    model: function model() {
      var _this = this;

      var guests = this.ajax.query(_query.default.GUEST_CONTACTS); // const users = this.ajax.query(Query.PROPERTY_USERS);

      var guestSettings = this.ajax.query(_query.default.GUEST_SETTINGS);
      var properties = this.ajax.query(_query.default.PROPERTY_LIST);
      return Ember.RSVP.all([guests, this.users.getPropertyUsers(), guestSettings, properties]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 4),
            guests = _ref2[0],
            users = _ref2[1],
            guestSettings = _ref2[2],
            properties = _ref2[3];

        guests = !guests.errors ? guests : [];
        users = users.filter(function (_ref3) {
          var _staffprofile$chatAge;

          var staffprofile = _ref3.staffprofile;
          return (staffprofile === null || staffprofile === void 0 ? void 0 : (_staffprofile$chatAge = staffprofile.chatAgent[0]) === null || _staffprofile$chatAge === void 0 ? void 0 : _staffprofile$chatAge.status) === 'ONLINE';
        });

        var fields = _this.get('customfields').prepare([], guestSettings);

        return {
          guests: guests,
          users: users,
          guestSettings: guestSettings,
          properties: properties,
          fields: fields
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('guests', this.get('sliceData').getSliceData(model.guests, 1));
      controller.set('rangeNum', this.get('sliceData').calculateRange(model.guests)); //TODO move calculations to pagination component?

      controller.set('pageNum', this.get('sliceData').getPageNum());
      controller.set('cloneGuests', model.guests);
      var columns = [{
        id: 'phone',
        title: 'phone'
      }, {
        id: 'country',
        title: 'country'
      }, {
        id: 'company',
        title: 'company'
      }, {
        id: 'organization',
        title: 'organization'
      }, {
        id: 'contactOwner',
        title: 'contact_owner'
      }, {
        id: 'created',
        title: 'created',
        fieldType: 'DATE'
      }]; //TODO move column manipulations to component "column-select"

      var fieldsSettings = model.guestSettings.fields;
      model.fields.forEach(function (cf) {
        if (!cf.isSystem) {
          columns.pushObject({
            id: cf.id,
            title: cf.label,
            fieldType: cf.fieldType,
            isCustomField: true
          });
        }
      });
      var columnSettings;

      try {
        columnSettings = JSON.parse(fieldsSettings);
      } catch (e) {
        columnSettings = [];
      }

      if (!Array.isArray(columnSettings)) {
        columnSettings = [];
      }

      columns.forEach(function (c) {
        var data = columnSettings.findBy('id', c.id) || {
          pos: 0,
          vis: true
        };
        Ember.set(c, 'position', +data.pos);
        Ember.set(c, 'visible', !!data.vis);
      });
      columns = columns.sortBy('position');
      /**/

      controller.set('model.columns', columns);
      controller.set('customFieldsData', this.get('contacts').deserialize(model.fields, {}));
      controller.set('listSelectedGuests', []);
      controller.set('properties', []);
      controller.set('organizations', []);
      controller.set('titleMergeGuests', [{
        title: 'Primary Email',
        key: 'primaryEmail'
      }, {
        title: 'Email',
        key: 'email'
      }, {
        title: 'First Name',
        key: 'firstName'
      }, {
        title: 'Last Name',
        key: 'lastName'
      }, {
        title: 'Phone Number',
        key: 'personalPhone'
      }, {
        title: 'Mobile Number',
        key: 'mobilePhone'
      }, {
        title: 'Home Address Line 1',
        key: 'homeAddress.line1'
      }, {
        title: 'Home Address Line 2',
        key: 'homeAddress.line2'
      }, {
        title: 'Home Postal Code',
        key: 'homeAddress.city'
      }, {
        title: 'Home State',
        key: 'homeAddress.state'
      }, {
        title: 'Home Country',
        key: 'homeAddress.country'
      }, {
        title: 'Office Address Line 1',
        key: 'officeAddress.line1'
      }, {
        title: 'Office Address Line 2',
        key: 'officeAddress.line2'
      }, {
        title: 'Office Postal Code',
        key: 'officeAddress.city'
      }, {
        title: 'Office State',
        key: 'officeAddress.state'
      }, {
        title: 'Office Country',
        key: 'officeAddress.country'
      }, {
        title: 'WhatsApp',
        key: 'whatsapp',
        isSocial: true
      }, {
        title: 'Line',
        key: 'line',
        isSocial: true
      }, {
        title: 'Telegram',
        key: 'telegram',
        isSocial: true
      }, {
        title: 'Facebook',
        key: 'facebook',
        isSocial: true
      }, {
        title: 'Twitter',
        key: 'twitter',
        isSocial: true
      }, {
        title: 'WeChat',
        key: 'wechat',
        isSocial: true
      }]);
      controller.set('listFilterFields', [{
        name: this.intl.t('firstname'),
        param: 'first_name'
      }, {
        name: this.intl.t('lastname'),
        param: 'last_name'
      }, {
        name: this.intl.t('email'),
        param: 'email'
      }, {
        name: this.intl.t('phone'),
        param: 'phone'
      }, {
        name: this.intl.t('tag'),
        param: 'tag'
      }, {
        name: this.intl.t('dob'),
        param: 'dob'
      }, {
        name: this.intl.t('country'),
        param: 'country'
      }, //{name: this.intl.t('company'), param: 'company'},
      {
        name: this.intl.t('organization'),
        param: 'organization'
      }]);
      controller.set('listFilterLookup', [{
        name: this.intl.t('filters.startswith'),
        param: 'startswith'
      }, {
        name: this.intl.t('filters.contains'),
        param: 'contains'
      }, {
        name: this.intl.t('filters.exact'),
        param: 'exact'
      }, {
        name: this.intl.t('filters.endswith'),
        param: 'endswith'
      }, {
        name: this.intl.t('filters.exclude'),
        param: 'exclude'
      }]);
      controller.set('selectedFilterField', controller.get('listFilterFields')[0]);
      controller.set('selectedFilterLookup', controller.get('listFilterLookup')[0]);
      controller.set('chosenProperty', {
        value: 'Full Name'
      });
      controller.set('chosenProperties', []);
    }
  });

  _exports.default = _default;
});