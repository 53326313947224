define("inboxfront/templates/components/dashboard/pages/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9B+jVYwi",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row-panels\"],[8],[0,\"\\n    \"],[1,[28,\"dashboard/panels/customer-support-perf\",null,[[\"pageName\",\"cards\",\"realData\",\"dataGroups\",\"isLoading\",\"prevStartDate\",\"prevEndDate\"],[[24,[\"pageName\"]],[24,[\"cards\"]],[24,[\"realData\"]],[24,[\"dataGroups\"]],[24,[\"isLoading\"]],[24,[\"prevStartDate\"]],[24,[\"prevEndDate\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"dashboard/panels/support-trends\",null,[[\"pageName\",\"realData\",\"dataGroups\",\"kbStats\",\"isLoading\"],[[24,[\"pageName\"]],[24,[\"realData\"]],[24,[\"dataGroups\"]],[24,[\"kbStats\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row-panels\"],[8],[0,\"\\n    \"],[1,[28,\"dashboard/panels/kb-performance\",null,[[\"pageName\",\"realData\",\"dataGroups\",\"kbStats\",\"isLoading\"],[[24,[\"pageName\"]],[24,[\"realData\"]],[24,[\"dataGroups\"]],[24,[\"kbStats\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"dashboard/panels/overview-others\",null,[[\"pageName\",\"cards\",\"realData\",\"dataGroups\",\"isLoading\",\"prevStartDate\",\"prevEndDate\"],[[24,[\"pageName\"]],[24,[\"cards2\"]],[24,[\"realData\"]],[24,[\"dataGroups\"]],[24,[\"isLoading\"]],[24,[\"prevStartDate\"]],[24,[\"prevEndDate\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row-panels\"],[8],[0,\"\\n    \"],[1,[28,\"dashboard/panels/performance-matrix\",null,[[\"isAllProperty\",\"props\",\"pageName\",\"realData\",\"dataGroups\",\"isLoading\"],[[24,[\"isAllProperty\"]],[24,[\"props\"]],[24,[\"pageName\"]],[24,[\"realData\"]],[24,[\"dataGroups\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/pages/overview.hbs"
    }
  });

  _exports.default = _default;
});