define("inboxfront/controllers/contacts/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query", "inboxfront/helpers/countries-list"], function (_exports, _mutation, _query, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    contacts: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    countriesList: _countriesList.COUNTRIES_LIST,
    chosenDetail: 'Contains',
    optionDate: Date,
    startDate: Date,
    endDate: Date,
    showDeleteDialog: false,
    showMergeConfirmDialog: false,
    selectedCountry: null,
    selectedOrganization: null,
    selectedContactOwner: null,
    selectedFilterOptions: null,
    selectedProperty: null,
    listMergeSelectedGuests: null,
    rowsPerPage: 10,
    allCheckGuests: Ember.computed('guests.{@each.checked}', function () {
      return this.get('guests').every(function (guest) {
        return guest.checked;
      });
    }),
    isVisibleActions: Ember.computed('guests.{@each.checked}', function () {
      return this.get('guests').some(function (guest) {
        return guest.checked;
      });
    }),
    isVisibleMergeButton: Ember.computed('guests.{@each.checked}', function () {
      var _this$get$filter;

      return ((_this$get$filter = this.get('guests').filter(function (guest) {
        return guest.checked;
      })) === null || _this$get$filter === void 0 ? void 0 : _this$get$filter.length) === 2;
    }),
    observerPage: Ember.observer('pageNum', 'rowsPerPage', function () {
      if (this.get('cloneGuests')) {
        this.set('guests', this.get('sliceData').getSliceData(this.get('cloneGuests'), this.get('pageNum'), this.get('rowsPerPage')));
      }
    }),
    actions: {
      setRowsPerPage: function setRowsPerPage(rowsPerPage) {
        this.set('rowsPerPage', rowsPerPage);
        this.set('guests', this.get('sliceData').getSliceData(this.get('cloneGuests'), 1, this.get('rowsPerPage')));
        this.set('rangeNum', this.get('sliceData').calculateRange(this.get('cloneGuests'), this.get('rowsPerPage'))); //TODO move calculations to pagination component?

        this.set('pageNum', this.get('sliceData').getPageNum());
      },
      updateColumns: function updateColumns(columnSettings) {
        try {
          var hash = {
            id: this.get('model.guestSettings.id'),
            fields: JSON.stringify(columnSettings)
          };
          this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
        } catch (e) {
          console.error('ON UPDATE GUEST COLUMNS', e);
        }
      },
      allCheckGuests: function allCheckGuests(val) {
        this.set('allCheck', val);
        this.get('guests').map(function (el) {
          return Ember.set(el, 'checked', val);
        });
      },
      checkContact: function checkContact(val) {
        Ember.set(val, 'checked', !val.checked);
      },
      showDeleteContactsModal: function showDeleteContactsModal() {
        this.set('showDeleteDialog', true);
      },
      showAssignContactSidebar: function showAssignContactSidebar() {
        this.set('showPopupAssign', true);
      },
      mergeContactsConfirm: function mergeContactsConfirm() {
        var _this = this;

        var _this$get$map = this.get('selectedGuests').map(function (item) {
          return item.id;
        }),
            _this$get$map2 = _slicedToArray(_this$get$map, 2),
            secondaryGuestId = _this$get$map2[0],
            primaryGuestId = _this$get$map2[1];

        var _this$get = this.get('mergeHeaderGuest'),
            primaryEmail = _this$get.primaryEmail,
            email = _this$get.email,
            firstName = _this$get.firstName,
            lastName = _this$get.lastName,
            personalPhone = _this$get.personalPhone,
            mobilePhone = _this$get.mobilePhone,
            homeAddress = _this$get.homeAddress,
            officeAddress = _this$get.officeAddress;

        var hash = {
          secondaryGuestId: secondaryGuestId,
          primaryGuestId: primaryGuestId,
          primaryEmail: primaryEmail,
          email: email,
          firstName: firstName,
          lastName: lastName,
          personalPhone: personalPhone,
          mobilePhone: mobilePhone,
          homeAddress1: homeAddress.line1,
          homeAddress2: homeAddress.line2,
          homeCity: homeAddress.city,
          homeState: homeAddress.state,
          homeCountry: homeAddress.country,
          officeAddress1: officeAddress.line1,
          officeAddress2: officeAddress.line2,
          officeCity: officeAddress.city,
          officeState: officeAddress.state,
          officeCountry: officeAddress.country
        };
        Object.keys(hash).forEach(function (key) {
          return (hash[key] === null || hash[key] === undefined) && delete hash[key];
        });
        this.ajax.query(_mutation.default.MERGE_GUESTS, 'mergeGuests', hash).then(function () {
          _this.set('showPopupMerge', false);

          _this.set('selectedGuests', []);

          _this.set('mergeHeaderGuest', null);

          _this.set('showMergeConfirmDialog', false);

          _this.updateQueryGuests();
        });
      },
      showMergeContactSidebar: function showMergeContactSidebar() {
        var titleMergeGuests = this.get('titleMergeGuests');
        var selectedGuests = this.get('guests').filter(function (el) {
          return el.checked;
        });
        var matrixArrayGuests = [];
        titleMergeGuests.forEach(function (item, i) {
          matrixArrayGuests.push([]);

          for (var j = 0; j < 4; j++) {
            if (j !== 0) {
              var index = j === 1 || j === 3 ? 0 : 1;

              if (item.isSocial) {
                var _selectedGuests$index;

                var findContact = (_selectedGuests$index = selectedGuests[index]['guestcontactSet'].findBy('contactType', item.key)) === null || _selectedGuests$index === void 0 ? void 0 : _selectedGuests$index.contactValue;
                matrixArrayGuests[i].push(findContact || '-');
              } else {
                var result = selectedGuests[index];
                var keys = item.key.split('.');

                var _iterator = _createForOfIteratorHelper(keys),
                    _step;

                try {
                  for (_iterator.s(); !(_step = _iterator.n()).done;) {
                    var key = _step.value;
                    result = result[key];
                  }
                } catch (err) {
                  _iterator.e(err);
                } finally {
                  _iterator.f();
                }

                matrixArrayGuests[i].push(result || '-');
              }
            } else {
              matrixArrayGuests[i].push(item.title);
            }
          }
        });
        this.set('matrixArrayGuests', matrixArrayGuests);
        this.set('selectedGuests', selectedGuests);
        selectedGuests.forEach(function (guest) {
          var avatar = {
            firstName: guest.firstName,
            lastName: guest.lastName
          };
          Ember.set(guest, 'avatar', avatar);
        });
        this.set('mergeHeaderGuest', Ember.copy(selectedGuests[0], true));
        this.set('showPopupMerge', true);
        setTimeout(function () {
          document.querySelectorAll('.merge-guest-popup tbody tr td:nth-child(2) div').forEach(function (el) {
            el.classList.add('active');
          });
        }, 0);
      },
      assignContacts: function assignContacts() {
        var _this2 = this;

        var filterContacts = this.get('guests').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var hash = {
          guestIds: filterContacts,
          contactOwner: this.get('selectedUser.id')
        };
        this.ajax.query(_mutation.default.ASSIGN_GUEST_USER, 'assignGuestUser', hash).then(function () {
          _this2.set('showPopupAssign', false);

          _this2.set('selectedUser', undefined);

          _this2.updateQueryGuests();
        });
      },
      mergeContacts: function mergeContacts() {
        this.set('showMergeConfirmDialog', true);
      },
      deleteContacts: function deleteContacts() {
        var _this3 = this;

        var guestIds = this.get('guests').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var hash = {
          guestIds: guestIds
        };
        this.ajax.query(_mutation.default.DELETE_GUESTS, 'deleteGuest', hash).then(function (res) {
          if (res.status === 400) {
            _this3.get('paperToaster').show(_this3.intl.t('contacts_not_found'));

            return;
          }

          _this3.get('paperToaster').show(_this3.intl.t('contacts_deleted'));

          _this3.updateQueryGuests();
        }).finally(function () {
          return _this3.set('showDeleteDialog', false);
        });
      },
      chooseGuest: function chooseGuest(val, indexProp, rowIndex) {
        var mappingGuests = this.get('matrixArrayGuests').map(function (row, i) {
          if (i === rowIndex) {
            return row.map(function (item, i) {
              return i === 3 ? val : item;
            });
          } else {
            return row;
          }
        });

        if (indexProp === 1 || indexProp === 2) {
          this.set('mergeHeaderGuest.avatar.firstName', mappingGuests[2][3]);
          this.set('mergeHeaderGuest.avatar.lastName', mappingGuests[3][3]);
        }

        var previousIndex = indexProp === 2 ? 1 : 2;
        this.set('matrixArrayGuests', mappingGuests);
        setTimeout(function () {
          var els = document.querySelectorAll(".merge-guest-popup tbody tr:nth-child(".concat(rowIndex + 1, ") td div"));
          els[indexProp].classList.add('active');
          els[previousIndex].classList.remove('active');
        }, 0);
      },
      closeAssignDialog: function closeAssignDialog() {
        this.set('selectedUser', undefined);
        this.set('showPopupAssign', false);
      },
      closeMergeDialog: function closeMergeDialog() {
        this.set('selectedGuests', []);
        this.set('mergeHeaderGuest', null);
        this.set('showPopupMerge', false);
      },
      clearFilter: function clearFilter() {
        this.set('searchContacts', null);
        this.set('isShowClearFilterBtn', false);
        this.updateQueryGuests();
      },
      filterContacts: function filterContacts() {
        var _this4 = this;

        var search = this.get('searchContacts');

        if (search.length > 1) {
          var hash = {
            search: [{
              field: this.get('selectedFilterField.param'),
              lookup: this.get('selectedFilterLookup.param'),
              value: search
            }]
          };
          this.set('isLoader', true);
          this.set('isShowClearFilterBtn', true);
          this.ajax.query(_query.default.GUEST_CONTACTS, 'guests', hash).then(function (data) {
            _this4.set('guests', data);

            _this4.set('isLoader', false);
          });
        }
      },
      createGuest: function createGuest() {
        var _this5 = this;

        var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.get('contacts').saveGuest(this.get('guest'), function () {
          _this5.clearForm();

          _this5.get('paperToaster').show(_this5.intl.t('contact_created'));

          _this5.updateQueryGuests(isOpen);
        });
      },
      showAddContact: function showAddContact() {
        var _this6 = this;

        var guest = this.get('contacts').create(this.get('model.fields'), {});
        this.set('guest', guest);
        this.set('showAddContact', true);
        this.ajax.query(_query.default.ORGANIZATIONS_SELECT, 'organizations').then(function (data) {
          _this6.set('organizations', data);
        });
      },
      showFilterContact: function showFilterContact() {
        this.set('filterPopup', true);
        this.set('isFilterInserted', true);
        this.set('showFilterDialog', true);
      },
      closeContactDialog: function closeContactDialog() {
        this.set('filterPopup', false);
        this.clearForm();
      }
    },
    updateQueryGuests: function updateQueryGuests() {
      var _this7 = this;

      var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.set('isLoader', true);
      this.ajax.query(_query.default.GUEST_CONTACTS).then(function (data) {
        _this7.set('cloneGuests', data);

        _this7.observerPage();

        _this7.set('rangeNum', _this7.sliceData.calculateRange(data)); //TODO move calculations to pagination component?


        _this7.set('pageNum', _this7.sliceData.getPageNum());

        isOpen && _this7.set('showAddContact', true);

        _this7.set('isLoader', false);
      });
    },
    clearForm: function clearForm() {
      this.set('showAddContact', false);
    }
  });

  _exports.default = _default;
});