define("inboxfront/controllers/settings", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    languages: Ember.inject.service(),
    selectedTimezone: null,
    actions: {
      saveSettings: function saveSettings() {
        var _this = this;

        var hash = {
          timezone: this.get('selectedTimezone').name,
          timeformat: this.get('selectedTimeFormat').name,
          dateformat: this.get('selectedDateFormat').name,
          locale: this.get('selectedLocale').name
        };

        if (this.get('selectedEmployeeCount')) {
          hash.employeeCount = this.get('selectedEmployeeCount').id;
        }

        this.ajax.query(_mutation.default.UPDATE_COMPANY_SETTINGS, 'updateCompanySettings', hash).then(function () {
          _this.languages.update();

          _this.paperToaster.show(_this.intl.t('settings_saved'));
        });
      }
    }
  });

  _exports.default = _default;
});