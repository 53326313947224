define("inboxfront/templates/components/dashboard/dates-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v76zhiAt",
    "block": "{\"symbols\":[\"prop\"],\"statements\":[[4,\"power-select\",null,[[\"placeholder\",\"options\",\"selected\",\"onChange\"],[\" \",[24,[\"datesSelector\"]],[24,[\"selectedDate\"]],[28,\"action\",[[23,0,[]],\"onChangeSelect\"],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"title\"]],true],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"click-outside\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],\"onClickOutside\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"dates-custom-picker\"],[8],[0,\"\\n            \"],[1,[28,\"ember-flatpickr\",null,[[\"inline\",\"mode\",\"onChange\"],[true,\"range\",[28,\"action\",[[23,0,[]],\"updateDateRange\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/dates-selector.hbs"
    }
  });

  _exports.default = _default;
});