define("inboxfront/routes/contacts/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query", "inboxfront/config/environment"], function (_exports, _common, _query, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    contacts: Ember.inject.service(),
    users: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var id = _ref.id;

      if (!id) {
        this.transitionTo('contacts');
      } else {
        var guest = this.ajax.query(_query.default.GUEST, 'guest', {
          guestId: id
        }); // const users = this.ajax.query(Query.PROPERTY_USERS);

        var locales = this.ajax.query(_query.default.LOCALES);
        var organizations = this.ajax.query(_query.default.ORGANIZATIONS_SELECT);
        var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
        var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
        var properties = this.ajax.query(_query.default.PROPERTY_LIST);
        var guestSettings = this.ajax.query(_query.default.GUEST_SETTINGS);
        return Ember.RSVP.all([guest, this.users.getPropertyUsers(), locales, organizations, settings, issueTypes, properties, guestSettings]).then(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 8),
              guest = _ref3[0],
              users = _ref3[1],
              locales = _ref3[2],
              organizations = _ref3[3],
              settings = _ref3[4],
              issueTypes = _ref3[5],
              properties = _ref3[6],
              guestSettings = _ref3[7];

          issueTypes = issueTypes.filter(function (t) {
            return t.isActive;
          });

          var taskFields = _this.get('customfields').prepare(_this.get('tasks').systemFields, settings);

          var contactFields = _this.get('customfields').prepare([], guestSettings);

          return {
            guest: guest,
            users: users,
            locales: locales,
            organizations: organizations,
            issueTypes: issueTypes,
            properties: properties,
            taskFields: taskFields,
            contactFields: contactFields
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _guest$homeAddress, _guest$officeAddress;

      this._super(controller, model);

      var guest = model.guest;
      controller.set('locales', JSON.parse(model.locales));
      controller.set('channels', [{
        icon: 'source-FACEBOOK',
        type: 'facebook'
      }, {
        icon: 'source-WHATSAPP',
        type: 'whatsapp'
      }, {
        icon: 'source-LINE',
        type: 'line'
      }, {
        icon: 'source-WECHAT',
        type: 'wechat'
      }, {
        icon: 'source-TWITTER',
        type: 'twitter'
      }, {
        icon: 'source-TELEGRAM',
        type: 'telegram'
      }]);
      controller.set('guest', guest);
      controller.set('selectedOrganization', model.organizations.find(function (o) {
        var _guest$organization;

        return o.id === ((_guest$organization = guest.organization) === null || _guest$organization === void 0 ? void 0 : _guest$organization.id);
      }));
      controller.set('selectedProperty', model.properties.find(function (p) {
        var _guest$companyPropert;

        return p.id === ((_guest$companyPropert = guest.companyProperty) === null || _guest$companyPropert === void 0 ? void 0 : _guest$companyPropert.id);
      }));
      controller.set('selectedLocale', controller.get('locales').find(function (l) {
        return l.code === guest.language;
      }));
      controller.set('selectedContactOwner', model.users.find(function (u) {
        var _guest$contactOwner;

        return u.id === ((_guest$contactOwner = guest.contactOwner) === null || _guest$contactOwner === void 0 ? void 0 : _guest$contactOwner.id);
      }));
      controller.set('homeAddressContact', {
        id: 0,
        name: (_guest$homeAddress = guest.homeAddress) === null || _guest$homeAddress === void 0 ? void 0 : _guest$homeAddress.country
      });
      controller.set('officeAddressContact', {
        id: 0,
        name: (_guest$officeAddress = guest.officeAddress) === null || _guest$officeAddress === void 0 ? void 0 : _guest$officeAddress.country
      });
      controller.set('cloneGuest', _objectSpread({}, guest));
      controller.set('cloneGuestAddressHome', _objectSpread({}, guest.homeAddress));
      controller.set('cloneGuestAddressOffice', _objectSpread({}, guest.officeAddress));
      controller.set('customFieldsData', this.get('contacts').deserialize(model.contactFields, guest || {}));
      controller.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      });
      var url = guest.logo !== null ? "".concat(_environment.default.mediaUrl).concat(guest.logo.datafile) : null;
      controller.set('urlAttachment', url);
      controller.set('attachmentId', guest.logo !== null ? guest.logo.id : null);
      controller.loadOpportunities();
    }
  });

  _exports.default = _default;
});