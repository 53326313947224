define("inboxfront/routes/channels", ["exports", "inboxfront/routes/common", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _common, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    fb: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      code: {
        refreshModel: true,
        replace: false
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.fb.FBInit();
    },
    model: function model(params) {
      var user = this.ajax.query(_query.default.CURRENT_USER);

      if (params.code && window.opener) {
        window.opener.onInstagramAuth && window.opener.onInstagramAuth(params.code);
      }

      return Ember.RSVP.all([user]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            user = _ref2[0];

        return {
          user: user
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var modelUser = model.user;
      var user = {
        firstName: modelUser.firstName,
        lastName: modelUser.lastName,
        username: modelUser.username,
        attachment: modelUser.attachment
      };
      var companies = modelUser.staffprofile.estates;
      companies.map(function (company) {
        return _objectSpread(_objectSpread({}, company), {}, {
          flows: company.flows.unshift({
            id: null,
            title: 'Choose flow...'
          })
        });
      });
      controller.set('companies', companies);
      controller.set('selectedProperty', companies[0]);
      controller.set('user', user);
      controller.changeSelectedChannels(companies[0].id, companies[0].widgetSettings); // controller.set('queryParams', ['page', 'code']);

      controller.set('channels', []);
      controller.set('accounts', [{
        application: 'LINE',
        key: 'lineDisplay'
      }, {
        application: 'TELEGRAM',
        key: 'telegramDisplay'
      }, {
        application: 'WECHAT',
        key: 'wechatDisplay'
      }, {
        application: 'TWITTER',
        key: 'twitterDisplay'
      }, {
        application: 'FACEBOOK',
        key: 'facebookDisplay'
      }, {
        application: 'WHATSAPP',
        key: 'whatsappDisplay'
      }, {
        application: 'INSTAGRAM',
        key: 'instagramDisplay'
      }]);
      controller.set('arrQuery', [{
        mutation: _mutation.default.CREATE_OR_UPDATE_WHATS_APP,
        name: 'WHATSAPP',
        fields: [{
          whatsappNumber: 'whatsapp_number'
        }, {
          whatsappAccessToken: 'whatsapp_access_token'
        }, {
          whatsappPhoneNumberId: 'whatsapp_phone_number_id'
        }, {
          whatsappVerifyToken: 'whatsapp_verify_token'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_TELEGRAM,
        name: 'TELEGRAM',
        fields: [{
          telegramAuthToken: 'telegram_auth_token'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_EMAIL,
        name: 'EMAIL',
        fields: [{
          emailProvider: 'selectedEmailProvider.value'
        }, {
          department: 'selectedDepartment.id'
        }, {
          emailAddress: 'email_address'
        }, {
          emailPassword: 'email_password'
        }, {
          emailImapServer: 'email_imap_server'
        }, {
          emailSmtpServer: 'email_smtp_server'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_FACEBOOK,
        name: 'FACEBOOK',
        fields: [{
          facebookPageId: 'selectedFacebookPage.id'
        }, {
          facebookPageName: 'selectedFacebookPage.name'
        }, {
          facebookShortLivedPageToken: 'selectedFacebookPage.access_token'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_TWITTER,
        name: 'TWITTER',
        fields: [{
          twitterConsumerKey: 'twitter_consumer_key'
        }, {
          twitterConsumerSecret: 'twitter_consumer_secret'
        }, {
          twitterAccessToken: 'twitter_access_token'
        }, {
          twitterAccessTokenSecret: 'twitter_access_token_secret'
        }, {
          twitterEnvironment: 'twitter_environment'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_WECHAT,
        name: 'WECHAT',
        fields: [{
          wechatId: 'wechat_id'
        }, {
          wechatSecret: 'wechat_secret'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_LINE,
        name: 'LINE',
        fields: [{
          lineSecret: 'line_secret'
        }, {
          lineChannelId: 'line_channel_id'
        }, {
          lineContactUrl: 'line_contact_url'
        }]
      }, {
        mutation: _mutation.default.CREATE_OR_UPDATE_INSTAGRAM,
        name: 'INSTAGRAM',
        fields: [{
          code: 'code'
        }]
      }]);
    }
  });

  _exports.default = _default;
});