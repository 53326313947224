define("inboxfront/components/assistants/flows/single-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component.id', function () {
      return this.get('blocks').findBy('id', this.get('component.id'));
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      },
      deleteComponent: function deleteComponent(component, event) {
        this.deleteComponent(component, event);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        this.deleteComponentAll(component, event);
      },
      duplicatePath: function duplicatePath(component, event) {
        this.duplicatePath(component, event);
      }
    }
  });

  _exports.default = _default;
});