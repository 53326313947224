define("inboxfront/components/kb/panels/related-articles-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    tagName: '',
    RELATED_ARTICLES_MAX_COUNT: 3,
    filteredArticles: Ember.computed('article', 'article.relatedArticles.[]', 'selectedBase.articles.[]', function () {
      var articles = [].concat(_toConsumableArray(this.get('article.relatedArticles')), [this.get('article')]);
      return _toConsumableArray(this.get('selectedBase.articles')).removeObjects(articles);
    }),
    actions: {
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;
        var art = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, art);
        this.get('article').validate();
      },
      removeFromRelatedArticles: function removeFromRelatedArticles(art) {
        var article = this.get('article');
        article.relatedArticles.removeObject(art);
        article.validate();
      },
      onSelectChange: function onSelectChange(arts) {
        var article = this.get('article');
        article.relatedArticles.addObjects(arts);
        article.validate();
      }
    }
  });

  _exports.default = _default;
});