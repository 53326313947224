define("inboxfront/controllers/ticketfields", ["exports", "inboxfront/controllers/customfields"], function (_exports, _customfields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _customfields.default.extend({
    tasks: Ember.inject.service(),
    companyFields: Ember.computed('model.fields', function () {
      var fields = this.get('model.fields');
      var company = fields.findBy('id', 'company');
      var department = fields.findBy('id', 'department');
      var assignedTo = fields.findBy('id', 'assignedTo');
      return [company, department, assignedTo];
    }),
    anotherFields: Ember.computed('model.fields', 'companyFields', function () {
      var group = this.get('companyFields');

      var ret = _toConsumableArray(this.get('model.fields'));

      ret.removeObjects(group);
      return ret;
    }),
    updateOrders: function updateOrders() {
      var fields = _toConsumableArray(this.get('companyFields'));

      fields.addObjects(this.get('anotherFields'));
      this.get('customfields').updateOrders(fields, this.get('model.settings.id'));
    },
    actions: {
      showCreateTaskDialog: function showCreateTaskDialog(status) {
        var _this = this;

        this.set('additionalData', status ? {
          status: status
        } : {});
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this.set('showCreateDialog', true);
        }, 300);
      },
      onTaskCreate: function onTaskCreate(task) {
        this.set('showCreateDialog', false);
      }
    }
  });

  _exports.default = _default;
});