define("inboxfront/templates/components/common/panel-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/SIIb1Ue",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"panel\",\"toggle\"]],\"expected `panel.toggle` to be a contextual component but found a string. Did you mean `(component panel.toggle)`? ('inboxfront/templates/components/common/panel-toggle.hbs' @ L1:C3) \"],null]],[[\"class\"],[[28,\"concat\",[\"common-panel-toggle \",[24,[\"class\"]]],null]]],{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],[[\"class\"],[\"title-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,1],[0,\"\\n    \"],[1,[28,\"svg-icon\",[\"arrow\"],[[\"class\"],[\"arrow-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/panel-toggle.hbs"
    }
  });

  _exports.default = _default;
});