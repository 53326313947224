define("inboxfront/templates/components/dashboard/panels/quick-responses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cwd6+AC+",
    "block": "{\"symbols\":[\"response\",\"index\"],\"statements\":[[1,[28,\"dashboard/panel-loader\",null,[[\"isLoading\"],[[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"quick-responses-wrap\"],[8],[0,\"\\n    \"],[7,\"table\",true],[8],[0,\"\\n        \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"quick_responses\"],null],false],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"#\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"%\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"▲\"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"responses\"]]],null,{\"statements\":[[4,\"if\",[[28,\"lt\",[[23,2,[]],[24,[\"countLimitShow\"]]],null]],null,{\"statements\":[[0,\"                \"],[7,\"tr\",true],[8],[0,\"\\n                    \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n                    \"],[7,\"td\",true],[8],[1,[23,1,[\"sent\"]],false],[9],[0,\"\\n                    \"],[7,\"td\",true],[8],[1,[23,1,[\"total\"]],false],[9],[0,\"\\n                    \"],[7,\"td\",true],[8],[1,[23,1,[\"percentage\"]],false],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"show-all\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showFull\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"leftCount\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"if\",[[24,[\"isShow\"]],\"Hide\",\"Show\"],null],false],[0,\" all \"],[4,\"unless\",[[24,[\"isShow\"]]],null,{\"statements\":[[1,[22,\"leftCount\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panels/quick-responses.hbs"
    }
  });

  _exports.default = _default;
});