define("inboxfront/services/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super();

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
      this.trimValidationField = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(value, field) {
          var valid = true;

          if (field.required === true) {
            valid = typeof value === 'string' ? value.trim() !== '' : value;
          }

          return valid;
        }
      }];
      this.nameValidation = [{
        message: this.intl.t('forms.provide_name'),
        validate: function validate(inputValue) {
          var namePattern = /^[a-zA-Z0-9]/;
          return namePattern.test(inputValue);
        }
      }];
      this.emailValidation = [{
        message: this.intl.t('forms.provide_email'),
        validate: function validate(inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return inputValue && emailPattern.test(inputValue);
        }
      }];
      this.emailValidationNotRequired = [{
        message: this.intl.t('forms.provide_email'),
        validate: function validate(inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return !inputValue || emailPattern.test(inputValue);
        }
      }];
      this.passwordValidation = [{
        message: this.intl.t('forms.minimum_password_length'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() && inputValue.length > 7;
        }
      }];
      this.websiteValidation = [{
        message: this.intl.t('forms.provide_website'),
        validate: function validate(inputValue) {
          if (inputValue && !inputValue.length) {
            return true;
          } else {
            var websitePatter = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; //eslint-disable-line

            return websitePatter.test(inputValue);
          }
        }
      }, this.trimValidation[0]];
    }
  });

  _exports.default = _default;
});