define("inboxfront/components/helpdesk/status-selector", ["exports", "inboxfront/components/tasks/id-selector"], function (_exports, _idSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _idSelector.default.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.options = [{
        id: "OPEN",
        title: 'status.open'
      }, {
        id: "RE_OPENED",
        title: 'status.re_opened'
      }, {
        id: "IN_PROGRESS",
        title: 'status.in_progress'
      }, {
        id: "RESOLVED",
        title: 'status.resolved'
      }, {
        id: "CLOSED",
        title: 'status.closed'
      }, // {id: "ARCHIVED", title: 'status.archived', disabled: true},
      {
        id: "DELETED",
        title: 'status.deleted',
        disabled: true
      }];
    }
  });

  _exports.default = _default;
});