define("inboxfront/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    userFullName: Ember.computed('user.{firstName,lastName}', function () {
      var user = this.get('user');

      if (!user) {
        return '';
      }

      var regex = /[^\u0000-\u00ff]/;

      var containsNonLatinCodepoints = function containsNonLatinCodepoints(s) {
        return regex.test(s);
      };

      var fullName = '';

      if (!containsNonLatinCodepoints(user.firstName)) {
        fullName = user.firstName + ' ';
      }

      if (!containsNonLatinCodepoints(user.lastName)) {
        fullName += user.lastName;
      }

      return fullName;
    })
  });

  _exports.default = _default;
});