define("inboxfront/routes/chats", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    chats: Ember.inject.service(),
    emoji: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    kb: Ember.inject.service(),
    users: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var threads = this.ajax.query(_query.default.THREADS);
      var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
      var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
      this.chats.load();
      this.emoji.initEmojis();
      this.get('kb').getBases();
      return Ember.RSVP.all([threads, this.users.getPropertyUsers(), settings, issueTypes, this.chats.loadReminders()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 5),
            threads = _ref2[0],
            propertyUsers = _ref2[1],
            settings = _ref2[2],
            issueTypes = _ref2[3],
            responseReminders = _ref2[4];

        var fields = _this.get('customfields').prepare(_this.get('tasks').systemFields, settings);

        var remindersById = responseReminders.remindersById;
        var reminders = responseReminders.remindersByRoom;
        issueTypes = issueTypes.filter(function (t) {
          return t.isActive;
        });
        threads = !threads.errors ? threads : {
          edges: []
        };
        threads.edges.forEach(function (e) {
          if (!e.node) {
            return;
          }

          if (e.node.lastMessage && e.node.lastMessage.subject === 'shopify_product_card') {
            //TODO remove duplication
            var cards;

            try {
              cards = JSON.parse(e.node.lastMessage.body);
            } catch (e) {
              cards = [];
            }

            if (!Array.isArray(cards)) {
              cards = [];
            }

            e.node.lastMessage.body = cards[0] && cards[0].title || e.node.lastMessage.body;
          }

          if (e.node.lastMessage && e.node.lastMessage.subject === 'kb_article') {
            var data;

            try {
              data = JSON.parse(e.node.lastMessage.body);
            } catch (e) {
              data = {};
            }

            e.node.lastMessage.body = data.title;
          }
        });
        return {
          threads: threads,
          propertyUsers: propertyUsers,
          fields: fields,
          issueTypes: issueTypes,
          reminders: reminders,
          remindersById: remindersById
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var currentUser = this.get('sessionAccount.loggeduser.username');
      var filterUsers = model.propertyUsers.filter(function (el) {
        return el.username !== currentUser;
      });
      controller.set('filterUsers', filterUsers);
      controller.set('threads', model.threads.edges);
      controller.set('mentioned', []);
      controller.set('starredList', []);
      controller.set('guestOrders', []);
      controller.set('kbSearchBase', {
        id: '*',
        name: 'All Knowledge Bases'
      });
      Ember.run.later(function () {
        return controller.updateThreadQueryTrigger();
      }, 2000);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.controllerFor('chats').stopUpdateECommerceStoreInfo();
      }
    }
  });

  _exports.default = _default;
});