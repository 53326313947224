define("inboxfront/services/languages", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    isRTL: false,
    update: function update() {
      var _this = this;

      var locales = this.ajax.query(_query.default.LOCALES);
      var companySettings = this.ajax.query(_query.default.COMPANY_SETTINGS);
      return Ember.RSVP.all([locales, companySettings]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            locales = _ref2[0],
            companySettings = _ref2[1];

        var _ref3 = !companySettings.errors ? companySettings : {},
            locale = _ref3.locale;

        try {
          locales = JSON.parse(locales);
        } finally {
          locales = Array.isArray(locales) ? locales : [];
        }

        switch ((locales.findBy('name', locale) || {}).code) {
          case 'ar':
          case 'ar-ar':
            _this.set('isRTL', true);

            document.body.classList.add('right-to-left');

            _this.intl.set('locale', 'ar-ar');

            break;

          case 'en':
          case 'en-us':
          default:
            _this.set('isRTL', false);

            document.body.classList.remove('right-to-left');

            _this.intl.set('locale', 'en-us');

        }
      });
    }
  });

  _exports.default = _default;
});