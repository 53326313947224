define("inboxfront/components/dashboard/panels/peak-time-heatmap", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['dashboard-panel'],
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _component = this;

      var dataGroups = this.get('dataGroups');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var heatmapMatrix = this.calcData2(dates);

      _highcharts.default.chart({
        chart: {
          renderTo: 'peak-time-heatmap',
          height: 315
        },
        title: null,
        yAxis: {
          categories: dates,
          title: null
        },
        xAxis: {
          opposite: true,
          categories: ['12-03am', '03-06am', '06-09am', '09-12pm', '12-03pm', '03-06pm', '06-09pm', '09-12am'],
          title: null
        },
        legend: {
          align: 'right',
          borderColor: '#ffffff',
          verticalAlign: 'top',
          margin: 0,
          title: {
            style: {
              'fontWeight': 'normal'
            }
          }
        },
        colorAxis: {
          minColor: '#e5f3ff',
          maxColor: '#043E6D',
          labels: {
            enabled: false
          }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          formatter: function formatter() {
            return this.point.value + "</b> " + _component.intl.t('messages_on_something') + " <br><b>" + this.series.xAxis.categories[this.point.x] + " - " + this.series.yAxis.categories[this.point.y] + "</b>";
          }
        },
        plotOptions: {
          series: {
            borderRadius: 10
          }
        },
        series: [{
          type: 'heatmap',
          borderWidth: 2,
          borderColor: '#ffffff',
          borderRadius: 10,
          showInLegend: false,
          data: heatmapMatrix
        }]
      });
      /**/

    },
    calcData2: function calcData2(dates) {
      var arr = [];
      var count = 0;

      for (var row = 0; row < dates.length; row++) {
        for (var col = 0; col < 8; col++, count += 3) {
          arr.pushObject([col, row, 0]);
        }
      }

      return arr;
    },
    calcData: function calcData(dates) {
      var pickGraph = this.get('pickGraph');
      var listDate = this.get('listDate');
      var arr = [];

      var _loop = function _loop(row) {
        var filterDate = pickGraph.filter(function (el) {
          return el.node.date === listDate[row];
        });

        if (filterDate.length) {
          var newArr = [];
          filterDate.forEach(function (el) {
            var parseObj = el.node.inboundMessagesPeak ? Object.entries(JSON.parse(el.node.inboundMessagesPeak)) : null;

            if (parseObj) {
              if (newArr.length) {
                for (var col = 0; col < 8; col++) {
                  newArr[col][2] += parseObj[col][1];
                }
              } else {
                for (var _col = 0; _col < 8; _col++) {
                  newArr.push([row, _col, parseObj[_col][1]]);
                }
              }
            }
          });
          arr.push.apply(arr, newArr);
        } else {
          var count = 0;

          for (var col = 0; col < 8; col++, count++) {
            arr.push([row, col, count]);
          }
        }
      };

      for (var row = 0; row < dates.length; row++) {
        _loop(row);
      }

      return arr;
    }
  });

  _exports.default = _default;
});