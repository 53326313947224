define("inboxfront/components/dashboard/panels/kb-views-visitors", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#kb_views_visitors')).highcharts({
        chart: {
          height: 315
        },
        title: null,
        xAxis: {
          categories: [],
          title: null
        },
        yAxis: [{
          title: null
        }, {
          title: null,
          opposite: true
        }],
        series: [{
          type: 'column',
          name: 'Total Views',
          color: '#9195C5',
          data: []
        }, {
          type: 'spline',
          name: 'Total Visitors',
          yAxis: 1,
          color: '#CBCDEE',
          data: []
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('kbStats');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var views = [];
      var visitors = [];
      var series = [views, visitors];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function (d) {
        if (realData) {
          var data = dataGroups[i];
          views.pushObject(data === null || data === void 0 ? void 0 : data.totalViews);
          visitors.pushObject(data === null || data === void 0 ? void 0 : data.totalVisitors);
        } else {
          views.pushObject(Math.ceil(Math.random() * 50 + 20));
          visitors.pushObject(Math.ceil(Math.random() * 50 + 20));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});