define("inboxfront/routes/tasks/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    users: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var ticketSettings = this.ajax.query(_query.default.TICKET_SETTINGS);
      var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES); // const propertyUsers = this.ajax.query(Query.PROPERTY_USERS);

      return Ember.RSVP.all([ticketSettings, issueTypes, this.users.getPropertyUsers()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            ticketSettings = _ref2[0],
            issueTypes = _ref2[1],
            propertyUsers = _ref2[2];

        propertyUsers.forEach(function (u) {
          return Ember.set(u, 'fullName', "".concat(u.firstName, " ").concat(u.lastName));
        });
        return {
          ticketSettings: ticketSettings,
          issueTypes: issueTypes.filter(function (t) {
            return t.isActive;
          }),
          propertyUsers: propertyUsers,
          fields: _this.get('customfields').prepare(_this.get('tasks').systemFields, ticketSettings)
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var columns = [{
        id: 'department',
        title: 'department'
      }, {
        id: 'source',
        title: 'source'
      }, {
        id: 'priority',
        title: 'priority'
      }, {
        id: 'status',
        title: 'Status'
      }, {
        id: 'assignedTo',
        title: 'assigned_to'
      }, {
        id: 'dueDate',
        title: 'time.due_date',
        fieldType: 'DATE'
      }]; //TODO move column manipulations to component "column-select"

      var fieldsSettings = model.ticketSettings.fields;
      model.fields.forEach(function (cf) {
        if (!cf.isSystem) {
          columns.pushObject({
            id: cf.id,
            title: cf.label,
            fieldType: cf.fieldType,
            isCustomField: true
          });
        }
      });
      var columnSettings;

      try {
        columnSettings = JSON.parse(fieldsSettings);
      } catch (e) {
        columnSettings = [];
      }

      if (!Array.isArray(columnSettings)) {
        columnSettings = [];
      }

      columns.forEach(function (c) {
        var data = columnSettings.findBy('id', c.id) || {
          pos: 0,
          vis: true
        };
        Ember.set(c, 'position', +data.pos);
        Ember.set(c, 'visible', !!data.vis);
      });
      columns = columns.sortBy('position');
      /**/

      controller.set('model.columns', columns);
      controller.set('filterTasks', [{
        id: 'all',
        title: 'filters.all_tasks'
      }, {
        id: 'personal',
        title: 'filters.personal_tasks'
      }, {
        id: 'opened',
        title: 'filters.opened_tasks'
      }, {
        id: 'closed',
        title: 'filters.closed_tasks'
      }, {
        id: 'resolved',
        title: 'filters.resolved_tasks'
      }, {
        id: 'deleted',
        title: 'filters.deleted_tasks'
      }]);
      /*
      if (this.get('sessionAccount.loggeduser.isAdmin')) {
          controller.get('filterTasks').pushObjects([
              { id: 'archived', title: 'filters.archived_tasks' }
          ]);
      }
       */

      controller.set('taskList', []);
      controller.set('cloneTaskList', []);
      controller.set('isPrevDisabled', true);
      controller.set('isNextDisabled', true);
      controller.set('selectedFilter', controller.get('filterTasks')[0]);
      controller.set('selectedFilterOptions', {
        page: 0,
        after: "",
        before: "",
        searchString: "",
        status: "",
        creation: {
          startDate: "",
          endDate: ""
        },
        closed: {
          startDate: "",
          endDate: ""
        },
        updated: {
          startDate: "",
          endDate: ""
        },
        priority: "",
        departments: "",
        reporter: "",
        assignee: "",
        active: true
      });
      controller.set('selectedTasks', []);
      controller.updateTasks();
    }
  });

  _exports.default = _default;
});