define("inboxfront/templates/components/dashboard/panels/kb-views-visitors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YEuw/Lf",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dashboard/panel-loader\",null,[[\"isLoading\"],[[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"kb_views_visitors\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panels/kb-views-visitors.hbs"
    }
  });

  _exports.default = _default;
});