define("inboxfront/components/kb/section-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    kb: Ember.inject.service(),
    tagName: '',
    sectionType: Ember.computed('section.entityType', function () {
      return this.get('entityTypes').findBy('id', this.get('section.entityType'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('entityTypes', [{
        id: 'PUBLIC',
        title: 'Public (visible on public site)'
      }, {
        id: 'PRIVATE',
        title: 'Private (visible to Raincheck users)',
        disabled: true
      }]);
    },
    actions: {
      saveSection: function saveSection() {
        var _this = this;

        this.get('kb').checkSlug(this.get('section'), 'section', this.get('selectedBase')).then(function () {
          _this.get('kb').saveSection(_this.get('section'));
        }).catch(function () {
          _this.set('isSlugError', true);
        });
      },
      checkSlug: function checkSlug() {
        var _this2 = this;

        this.get('kb').checkSlug(this.get('section'), 'section', this.get('selectedBase')).then(function () {
          _this2.set('isSlugError', false);
        }).catch(function () {
          _this2.set('isSlugError', true);
        });
      },
      changeEntityType: function changeEntityType(type) {
        this.set('section.entityType', type.id);
        this.get('section').validate();
      },
      updateField: function updateField(name, value) {
        var section = this.get('section');

        if (name === 'title') {
          Ember.set(section, 'slug', this.get('kb').formatSlug(value));
        } else if (name === 'slug') {
          value = this.get('kb').formatSlug(value);
          this.set('isSlugError', false);
        }

        Ember.set(section, name, value);
        section.validate();
      },
      discardSectionChanges: function discardSectionChanges(goBack) {
        this.get('section').restore();
        this.set('isSlugError', false);
        goBack && this.onBack();
      },
      goBack: function goBack() {
        if (this.get('section.isUpdated')) {
          this.set('openDiscardChangesDialog', true);
        } else {
          this.onBack();
        }
      }
    }
  });

  _exports.default = _default;
});