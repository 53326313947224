define("inboxfront/templates/components/helpdesk/status-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X4Sh2LsM",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"isEditMode\"]]],null,{\"statements\":[[4,\"power-select\",null,[[\"options\",\"placeholder\",\"selected\",\"disabled\",\"onChange\"],[[24,[\"options\"]],[24,[\"placeholder\"]],[24,[\"selected\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"changeValue\"],null]]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[[23,1,[\"id\"]]]]],[8],[1,[28,\"t\",[[23,1,[\"title\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[[24,[\"selected\",\"id\"]]]]],[8],[1,[28,\"t\",[[24,[\"selected\",\"title\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/helpdesk/status-selector.hbs"
    }
  });

  _exports.default = _default;
});