define("inboxfront/templates/all_icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ovHYFA/y",
    "block": "{\"symbols\":[\"symbol\"],\"statements\":[[7,\"style\",true],[8],[0,\"\\n    .all-icons-wrap {\\n        display: flex;\\n        flex-direction: column;\\n        flex: 1;\\n        margin: 20px;\\n    }\\n\\n    .all-icons-wrap > div {\\n        margin-bottom: 10px;\\n    }\\n\\n    .all-icons-wrap .header {\\n        display: flex;\\n        align-items: center;\\n    }\\n\\n    .all-icons {\\n        flex: 1;\\n    }\\n\\n    .all-icons .svg-icon {\\n        width: 130px;\\n        height: 30px;\\n        margin-right: 10px;\\n    }\\n\\n    .all-icons.apply-fill .svg-icon {\\n        fill: #ff55ff;\\n    }\\n\\n    .all-icons span {\\n        display: inline-block;\\n    }\\n\\n    .all-icons .item {\\n        display: flex;\\n        align-items: center;\\n        line-height: 30px;\\n        margin-bottom: 5px;\\n    }\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"all-icons-wrap\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[24,[\"symbols\",\"length\"]],false],[0,\" icons  \\n\"],[4,\"paper-switch\",null,[[\"value\",\"onChange\"],[[24,[\"applyFillToSvg\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"applyFillToSvg\"]]],null]],null]]],{\"statements\":[[0,\"            Apply color to icons\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"all-icons\",[28,\"if\",[[24,[\"applyFillToSvg\"]],\" apply-fill\"],null]]]],[8],[0,\"\\n\"],[4,\"ember-scrollable\",null,null,{\"statements\":[[4,\"each\",[[24,[\"symbols\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"svg-icon\",[[23,1,[\"id\"]]],null],false],[7,\"span\",true],[8],[1,[23,1,[\"id\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/all_icons.hbs"
    }
  });

  _exports.default = _default;
});