define("inboxfront/components/assistants/flows/templates-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    // templateFlows: computed('flows.[]', function () {
    // return [(this.get('flows') || []).findBy('id', '2071')];
    // console.log('CALC SYS', (this.get('flows') || []).filter(f => f.isSystem));
    // return (this.get('flows') || []).filter(f => f.isSystem);
    // }),
    actions: {
      selectTemplate: function selectTemplate(flow) {
        console.log('SELECT FLOW', flow);
        this.set('selectedFlow', flow);
      },
      hidePreview: function hidePreview() {
        this.set('selectedFlow', null);
      },
      useTemplate: function useTemplate() {
        // this.router.transitionTo('dashboard');
        // this.transitionToRoute('assistants.edit', {queryParams: {id: this.get('selectedFlow').id}});
        this.router.transitionTo('assistants.edit', {
          queryParams: {
            id: this.get('selectedFlow').id
          }
        });
        this.set('selectedFlow', null);
      }
    }
  });

  _exports.default = _default;
});