define("inboxfront/components/chat/room-info-pinned-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pinnedMessages: Ember.computed('room', 'room.allMessages.[]', function () {
      return this.get('room') && this.get('room.allMessages').filter(function (msg) {
        return msg.pinned;
      }) || [];
    }),
    actions: {}
  });

  _exports.default = _default;
});