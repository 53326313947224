define("inboxfront/components/chat/message-list", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    actions: {
      pickThread: function pickThread(threadId) {
        this.pickThread(threadId);
      },
      triggerStarredThread: function triggerStarredThread(thread) {
        var hash = {
          threadId: thread.node.id,
          starred: !thread.node.starred
        };
        this.ajax.mutation(_mutation.default.STARRED_THREAD, 'starredThread', hash);
        this.updateThreadQuery();
      }
    }
  });

  _exports.default = _default;
});