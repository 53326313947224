define("inboxfront/components/assistants/flows/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component.id', function () {
      return this.get('blocks').findBy('id', this.get('component.id'));
    })
  });

  _exports.default = _default;
});