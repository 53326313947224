define("inboxfront/templates/components/settings-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OoZjLzDq",
    "block": "{\"symbols\":[\"menuItem\",\"p\",\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"administration-menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"menu-title\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"Administration\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"menuItems\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"link\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"settings-menu-item\",null,[[\"menuItem\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"cp-panel\",null,[[\"open\"],[true]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"toggle\"]],\"expected `p.toggle` to be a contextual component but found a string. Did you mean `(component p.toggle)`? ('inboxfront/templates/components/settings-menu.hbs' @ L10:C19) \"],null]],null,{\"statements\":[[0,\"                    \"],[7,\"div\",true],[10,\"class\",\"alignCenter\"],[8],[0,\"\\n                        \"],[1,[28,\"svg-icon\",[[23,1,[\"icon\"]]],null],false],[0,\"\\n                        \"],[7,\"span\",true],[8],[1,[28,\"t\",[[23,1,[\"title\"]]],null],false],[9],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[1,[28,\"svg-icon\",[\"icon-expand-collapse\"],[[\"class\"],[\"arrow\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"body\"]],\"expected `p.body` to be a contextual component but found a string. Did you mean `(component p.body)`? ('inboxfront/templates/components/settings-menu.hbs' @ L17:C19) \"],null]],null,{\"statements\":[[4,\"each\",[[23,1,[\"subMenu\"]]],null,{\"statements\":[[0,\"                        \"],[1,[28,\"settings-menu-item\",null,[[\"menuItem\"],[[23,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/settings-menu.hbs"
    }
  });

  _exports.default = _default;
});