define("inboxfront/templates/components/ecommerce/dialogs/confirm-disconnect-bc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GZ6P2WCm",
    "block": "{\"symbols\":[\"dialog\"],\"statements\":[[4,\"common/dialogs/warning\",null,[[\"open\",\"onClose\",\"onConfirm\"],[[24,[\"open\"]],[24,[\"onCancel\"]],[24,[\"onConfirm\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `dialog.title` to be a contextual component but found a string. Did you mean `(component dialog.title)`? ('inboxfront/templates/components/ecommerce/dialogs/confirm-disconnect-bc.hbs' @ L7:C7) \"],null]],null,{\"statements\":[[0,\"        Disconnect BigCommerce Store\"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[1,[24,[\"data\",\"clientName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `dialog.body` to be a contextual component but found a string. Did you mean `(component dialog.body)`? ('inboxfront/templates/components/ecommerce/dialogs/confirm-disconnect-bc.hbs' @ L12:C7) \"],null]],[[\"icon\"],[\"trash2\"]],{\"statements\":[[0,\"        What happens.\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"btn-cancel\"]],false],[0,\"\\n    \"],[1,[23,1,[\"btn-confirm\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/ecommerce/dialogs/confirm-disconnect-bc.hbs"
    }
  });

  _exports.default = _default;
});