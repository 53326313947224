define("inboxfront/routes/onboarding", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "inboxfront/gql/query", "inboxfront/config/environment"], function (_exports, _authenticatedRouteMixin, _query, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model() {
      var locales = this.ajax.query(_query.default.LOCALES);
      var roles = this.ajax.query(_query.default.CUSTOM_ROLES);
      var progress = this.ajax.query(_query.default.ONBOARDING_PROGRESS);
      return Ember.RSVP.all([locales, roles, progress]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            locales = _ref2[0],
            roles = _ref2[1],
            progress = _ref2[2];

        return {
          locales: locales,
          roles: roles,
          progress: progress
        };
      });
    },
    setupController: function setupController(c, m) {
      var _this$sessionAccount$, _user$staffprofile, _user$staffprofile$co, _user$staffprofile2;

      this._super(c, m);

      var companies = (_this$sessionAccount$ = this.sessionAccount.loggeduser.staffprofile) === null || _this$sessionAccount$ === void 0 ? void 0 : _this$sessionAccount$.estates;
      var user = this.sessionAccount.loggeduser;
      var domain = (_user$staffprofile = user.staffprofile) === null || _user$staffprofile === void 0 ? void 0 : (_user$staffprofile$co = _user$staffprofile.company) === null || _user$staffprofile$co === void 0 ? void 0 : _user$staffprofile$co.domain;
      var progressValue = Number(m.progress[0]);
      var steps = m.progress[1] ? JSON.parse(m.progress[1]) : [];

      if (progressValue >= 80) {
        this.router.transitionTo('dashboard');
      }

      c.set('systemRoles', [{
        id: 'admin',
        name: this.intl.t('user_role_admin')
      }, {
        id: 'owner',
        name: this.intl.t('user_role_owner')
      }, {
        id: 'member',
        name: this.intl.t('user_role_member')
      }]);
      c.set('stepsProgress', [{
        title: this.intl.t('actions_update_profile'),
        icon: 'profile-green',
        value: 'profile',
        isComplete: false
      }, {
        title: this.intl.t('personalise_helpdesk'),
        icon: 'profile-yellow',
        value: 'personalization',
        isComplete: false
      }, {
        title: this.intl.t('actions_setup_email'),
        icon: 'email-config',
        value: 'mailbox',
        isComplete: false
      }, {
        title: this.intl.t('actions_invite_team'),
        icon: 'users-red',
        value: 'invite',
        isComplete: false
      }]);
      c.set('steps', steps);
      c.get('stepsProgress').forEach(function (step) {
        Ember.set(step, 'isComplete', steps.includes(step.value));
      });
      c.set('progressValue', progressValue);
      c.set('companies', companies);
      c.set('locales', JSON.parse(m.locales));
      c.set('customRoles', m.roles);
      c.set('selectedProperty', companies[0]);
      c.set('selectedDepartment', (_user$staffprofile2 = user.staffprofile) === null || _user$staffprofile2 === void 0 ? void 0 : _user$staffprofile2.department);
      c.set('profile', user);
      c.set('invite', {
        isEmail: true
      });
      c.set('domain', domain.replace(/[@.]raincheck.co/, ''));
      c.set('urlAttachment', user.attachment ? _environment.default.mediaUrl + user.attachment.datafile : '');
      this.set('controller', c);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (!this.sessionAccount.loggeduser.staffprofile.department) {
          this.controller.set('showDepartmentWarning', true);
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});