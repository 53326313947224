define("inboxfront/templates/components/assistants/flows/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u3QazjLO",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fields-data\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"nextComponent\",\"dataCollectorsSelect\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"single-option\"],[8],[0,\"\\n            \"],[1,[23,1,[\"text\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[28,\"not\",[[24,[\"disabled\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[\"add-component-flow\",[28,\"if\",[[24,[\"isCopyState\"]],\" copied\"],null]]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"openAddComponent\",[24,[\"component\"]]],null]],[8],[0,\"\\n            \"],[1,[28,\"svg-icon\",[\"plus-square\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"nextComponent\",\"children\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"assistants/flows/message\",null,[[\"component\",\"blocks\",\"disabled\",\"openAddComponent\",\"openConfigure\",\"deleteComponent\",\"deleteComponentAll\",\"duplicatePath\"],[[24,[\"nextComponent\",\"children\",\"firstObject\"]],[24,[\"blocks\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"openAddComponent\"],null],[28,\"action\",[[23,0,[]],\"openConfigure\"],null],[28,\"action\",[[23,0,[]],\"deleteComponent\"],null],[28,\"action\",[[23,0,[]],\"deleteComponentAll\"],null],[28,\"action\",[[23,0,[]],\"duplicatePath\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/flows/form.hbs"
    }
  });

  _exports.default = _default;
});