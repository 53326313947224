define("inboxfront/controllers/onboarding", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    currentStep: 0,
    stepActive: 'profile',
    selectedLocale: Ember.computed('profile.staffprofile.language', function () {
      var _this = this;

      if (this.get('locales')) {
        if (!this.get('profile.staffprofile.language')) {
          return null;
        } else {
          return this.get('locales').find(function (obj) {
            return obj.code === _this.get('profile.staffprofile.language');
          });
        }
      }
    }),
    userDepartment: Ember.computed('profile.staffprofile.department', function () {
      return this.get('profile.staffprofile.department');
    }),
    mailboxAddress: Ember.computed('mailboxName', 'domain', function () {
      var mailboxName = this.get('mailboxName') || '';
      var domain = this.get('domain') || '';
      return mailboxName + '@' + domain + '.raincheck.co';
    }),
    isValidForm: Ember.computed('invite.{firstName,lastName,selectedRole,selectedDepartment,email}', function () {
      var _this$get = this.get('invite'),
          firstName = _this$get.firstName,
          lastName = _this$get.lastName,
          selectedRole = _this$get.selectedRole,
          selectedDepartment = _this$get.selectedDepartment,
          email = _this$get.email;

      return this.validation.nameValidation[0].validate(firstName) && this.validation.nameValidation[0].validate(lastName) && this.validation.emailValidationNotRequired[0].validate(email) && (selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.id) && (selectedDepartment === null || selectedDepartment === void 0 ? void 0 : selectedDepartment.id);
    }),
    onAddedFile: Ember.computed(function () {
      var controller = this;
      return function (_ref) {
        var status = _ref.status;
        setTimeout(function () {
          if (status !== 'error') {
            controller.set('isLoading', true);
          }
        }, 0);
      };
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var _JSON$parse = JSON.parse(response.xhr.response),
            data = _JSON$parse.data,
            errors = _JSON$parse.errors;

        this.removeAllFiles();

        if (data.file) {
          controller.set('urlAttachment', _environment.default.mediaUrl + data.file.attachment.datafile);
          controller.set('attachmentId', data.file.attachment.id);
        } else {
          controller.paperToaster.show(errors[0].message);
        }

        controller.get('sessionAccount').update(false, function () {
          controller.set('isLoading', false);
        });
      };
    }),
    options: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token');
      var userId = this.get('profile.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '.edit-icon',
        dictDefaultMessage: this.intl.t('upload_image'),
        acceptedFiles: '.png,.jpg,.jpeg',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "auth", "model": "user", "objectId": "' + userId + '"}'
        }
      };
    }),
    actions: {
      deleteAvatar: function deleteAvatar() {
        var _this2 = this;

        var hash = {
          attachmentId: this.get('attachmentId')
        };
        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', hash).then(function () {
          _this2.set('urlAttachment', '');

          _this2.set('attachmentId', null);

          _this2.get('sessionAccount').update();
        });
      },
      confirmDomain: function confirmDomain() {
        var hash = {
          objId: this.get('profile.staffprofile.company.id'),
          domain: this.get('domain')
        };
        this.ajax.query(_mutation.default.UPDATE_COMPANY, 'updateCompany', hash);
      },
      copyMailbox: function copyMailbox(text) {
        var _this3 = this;

        navigator.clipboard.writeText(text).then(function () {
          _this3.paperToaster.show(_this3.intl.t('copying_success'));
        }, function (err) {
          _this3.paperToaster.show(_this3.intl.t('copying_failed'), err);
        });
      },
      updateConfirmation: function updateConfirmation() {
        var _this4 = this;

        var hash = {
          userId: this.get('profile').id,
          language: this.get('selectedLocale.code')
        };
        this.set('stepActive', 'mailbox');
        this.ajax.query(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash).then(function () {
          if (!_this4.get('steps').includes('personalization')) {
            _this4.updateOnBoardingProcess('personalization');
          }
        });
      },
      nextStep: function nextStep() {
        if (this.get('currentStep') === 0) {
          var hash = {
            name: this.get('mailboxName'),
            emailAddress: this.get('mailboxAddress'),
            application: 'EMAIL',
            emailProvider: 'SENDGRID',
            propertyId: this.get('selectedProperty').id,
            department: this.get('selectedDepartment').id
          };
          this.ajax.query(_mutation.default.CREATE_OR_UPDATE_EMAIL, 'createOrUpdateAccount', hash);
        }

        this.set('currentStep', this.get('currentStep') + 1);
      },
      prevStep: function prevStep() {
        this.set('currentStep', this.get('currentStep') - 1);
      },
      updateMailbox: function updateMailbox() {
        this.set('stepActive', 'invite');

        if (!this.get('steps').includes('mailbox')) {
          this.updateOnBoardingProcess('mailbox');
        }
      },
      saveInvite: function saveInvite() {
        var _this5 = this;

        var _this$get2 = this.get('invite'),
            isAgent = _this$get2.isAgent,
            isHasInboxAccess = _this$get2.isHasInboxAccess,
            isHasHelpdeskAccess = _this$get2.isHasHelpdeskAccess,
            defaultPassword = _this$get2.defaultPassword,
            email = _this$get2.email,
            firstName = _this$get2.firstName,
            lastName = _this$get2.lastName,
            selectedRole = _this$get2.selectedRole,
            selectedProperty = _this$get2.selectedProperty,
            selectedDepartment = _this$get2.selectedDepartment;

        var hash = {
          firstName: firstName,
          lastName: lastName,
          defaultPassword: defaultPassword,
          isAgent: !!isAgent,
          hasInboxAccess: !!isHasInboxAccess,
          hasHelpdeskAccess: !!isHasHelpdeskAccess,
          systemGroup: selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.id,
          propertiesId: (selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.id) && [selectedProperty.id],
          department: selectedDepartment === null || selectedDepartment === void 0 ? void 0 : selectedDepartment.id
        };
        if (this.get('isEmail')) hash.email = email;
        hash = Object.fromEntries(Object.entries(hash).filter(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
              val = _ref3[1];

          return typeof val !== 'undefined';
        }));
        this.set('isLoading', true);
        this.ajax.query(_mutation.default.CREATE_USER, 'createUser', hash).then(function (_ref4) {
          var profile = _ref4.profile;

          _this5.paperToaster.show(_this5.intl.t('user_created'));

          _this5.set('invite', {
            isEmail: true
          });

          _this5.set('openUserPasswordResetDialog', true);

          _this5.set('resetHeader', _this5.intl.t('user_created'));

          _this5.set('resetUser', profile.user);

          _this5.set('resetLink', profile.user.resetPasswordLink);

          _this5.set('resetPasswordText', _this5.intl.t('reset_user_password', {
            link: profile.user.resetPasswordLink,
            user: "".concat(profile.user.firstName, " ").concat(profile.user.lastName)
          }));

          if (!_this5.get('steps').includes('invite')) {
            _this5.updateOnBoardingProcess('invite');
          }
        }).finally(function () {
          return _this5.set('isLoading', false);
        });
      },
      onChangeInputDomain: function onChangeInputDomain(value) {
        var trimValue = value.replace(/\s/g, '');
        this.set('domain', trimValue);
      },
      onDepartmentSelectChange: function onDepartmentSelectChange(department) {
        this.set('selectedDepartment', department);
      },
      updateProfile: function updateProfile() {
        var _this6 = this;

        var _this$get3 = this.get('profile'),
            id = _this$get3.id,
            firstName = _this$get3.firstName,
            lastName = _this$get3.lastName,
            _this$get3$staffprofi = _this$get3.staffprofile,
            phone = _this$get3$staffprofi.phone,
            position = _this$get3$staffprofi.position,
            email = _this$get3.email;

        var hash = {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          position: position,
          userId: id,
          department: this.get('selectedDepartment.id'),
          language: this.get('selectedLocale.code')
        };
        this.ajax.query(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash).then(function () {
          _this6.set('stepActive', 'personalization');

          if (_this6.get('selectedDepartment.id')) {
            Ember.set(_this6.profile.department, _this6.get('selectedDepartment'));
          }

          if (!_this6.get('steps').includes('profile')) {
            _this6.updateOnBoardingProcess('profile');
          }
        });
      },
      onStep: function onStep(step) {
        this.set('stepActive', step);
      }
    },
    updateOnBoardingProcess: function updateOnBoardingProcess(step) {
      this.get('steps').pushObject(step);
      this.get('stepsProgress').forEach(function (item) {
        if (item.value === step) {
          Ember.set(item, 'isComplete', true);
        }
      });
      this.set('progressValue', this.get('progressValue') + 20);
      this.ajax.query(_mutation.default.UPDATE_ONBOARDING_PROCESS, 'updateOnboardingProcess', {
        progress: String(this.get('progressValue')),
        steps: JSON.stringify(this.get('steps'))
      });
    }
  });

  _exports.default = _default;
});