define("inboxfront/templates/components/integrations/install-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7DKtItHT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ecommerce-settings\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"logos\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/assets/images/main-logo.svg\"],[10,\"alt\",\"main\"],[8],[9],[0,\"\\n            \"],[1,[28,\"svg-icon\",[\"sorting\"],[[\"class\"],[\"arrows\"]]],false],[0,\"\\n            \"],[1,[28,\"svg-icon\",[[24,[\"logo\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            Connect \"],[1,[22,\"title\"],false],[0,\" to your Raincheck account\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"category\"]],\"E-Commerce\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"isConnected\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n                        \"],[1,[22,\"label\"],false],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"                            \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"category\"]],\"CRM\"],null]],null,{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"                \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"        \"],[1,[28,\"integrations/details\",null,[[\"category\"],[[24,[\"category\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/integrations/install-form.hbs"
    }
  });

  _exports.default = _default;
});