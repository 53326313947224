define("inboxfront/components/channels/facebook-controls", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    fb: Ember.inject.service(),
    tagName: '',
    disableFacebookPages: true,
    instagramEnabled: false,
    instagramIsEnabled: Ember.computed('instagramChannel.instagram_username', 'instagramEnabled', function () {
      return this.get('instagramChannel.instagram_username') || this.get('instagramEnabled');
    }),
    instagramNotConnectedToFB: Ember.computed('instagramChannel', 'instagramChannel.{}', 'channel.my_instagram_account_id', function () {
      return this.get('instagramChannel.pk') !== +this.get('channel.my_instagram_account_id') || this.get('instagramChannel.instagram_username');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.facebookPages = [];
    },
    actions: {
      enableInstagram: function enableInstagram() {
        this.set('instagramEnabled', !this.get('instagramEnabled'));
      },
      connectFacebook: function connectFacebook() {
        var control = this;
        this.get('fb').login('pages_show_list read_page_mailboxes pages_messaging instagram_basic instagram_manage_insights instagram_manage_messages pages_read_engagement pages_manage_metadata').then(function (response) {
          var shortLiveAccessToken = response.authResponse.accessToken;
          fetch('https://graph.facebook.com/me/accounts?access_token=' + shortLiveAccessToken).then(function (response) {
            return response.json();
          }).then(function (pages) {
            control.set('facebookPages', pages.data);
            control.set('disableFacebookPages', false);

            if (control.get('instagramIsEnabled')) {
              control.showLogin();
            }
          });
        });
      },
      authenticateToInstagram: function authenticateToInstagram() {
        var _this = this;

        this.showLogin(function () {
          var filterChannel = {
            mutation: _mutation.default.CREATE_OR_UPDATE_INSTAGRAM,
            name: 'INSTAGRAM',
            fields: [{
              code: 'code'
            }]
          };
          var hash = {
            accountId: _this.get('instagramChannel.accountId'),
            name: _this.get('instagramChannel.name'),
            application: filterChannel.name,
            propertyId: _this.get('selectedProperty').id,
            code: _this.get('instagramChannel.code')
          };

          _this.ajax.query(filterChannel.mutation, 'createOrUpdateAccount', hash).then(function (result) {
            if (!result.errors) {
              var account = _this.get('selectedProperty.accounts.edges').findBy('node.id', result.account.id);

              Ember.set(account, 'node.name', result.account.name);
              Ember.set(account, 'node.accountsettingSet', result.account.accountsettingSet);

              _this.set('instagramChannel.instagram_access_token', 'fake token');
            }
          });
        });
      }
    },
    showLogin: function showLogin(callback) {
      var width = 500,
          height = 700,
          left = (window.screen.width - width) / 2,
          top = (window.screen.height - height) / 2;
      var clientId = '1518204778523488';
      var redirectUrl = 'https://dev.raincheck.co/channels/';
      var control = this;
      var popup = window.open("https://www.instagram.com/oauth/authorize?client_id=".concat(clientId, "&redirect_uri=").concat(redirectUrl, "&scope=user_profile,user_media&response_type=code"), '', "width=".concat(width, ",height=").concat(height, ",left=").concat(left, ",top=").concat(top));

      window.onInstagramAuth = function (code) {
        control.set('instagramChannel.code', code);
        popup.close();
        callback && callback();
      };
    }
  });

  _exports.default = _default;
});