define("inboxfront/templates/components/dashboard/panel-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tuc0Xk8N",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"application-loading\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panel-loader.hbs"
    }
  });

  _exports.default = _default;
});