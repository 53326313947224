define("inboxfront/templates/chatslimit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NS5IKH9",
    "block": "{\"symbols\":[\"page\",\"property\"],\"statements\":[[4,\"page-admin\",null,null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `page.title` to be a contextual component but found a string. Did you mean `(component page.title)`? ('inboxfront/templates/chatslimit.hbs' @ L2:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"h2\",true],[8],[0,\"Update chat limit per agent\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `page.content` to be a contextual component but found a string. Did you mean `(component page.content)`? ('inboxfront/templates/chatslimit.hbs' @ L6:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"channels-select\"],[8],[0,\"\\n            \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"company\"],null],false],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"onChange\"],[[24,[\"companies\"]],[24,[\"selectedProperty\"]],[28,\"action\",[[23,0,[]],\"onChangeSelectCompany\"],null]]],{\"statements\":[[0,\"                \"],[1,[23,2,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/chatslimit.hbs"
    }
  });

  _exports.default = _default;
});