define("inboxfront/components/tasks/task-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    fieldValue: Ember.computed('task', 'field.id', function () {
      return this.get('task')[this.get('field.id')];
    })
  });

  _exports.default = _default;
});