define("inboxfront/routes/tasks/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    users: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        this._super(transition);

        this.controller.set('isEditMode', false);
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('tasks.index');
      } else {
        // const propertyUsers = this.ajax.query(Query.PROPERTY_USERS);
        var settings = this.ajax.query(_query.default.COMPANY_SETTINGS);
        var ticketSettings = this.ajax.query(_query.default.TICKET_SETTINGS);
        var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
        return Ember.RSVP.all([this.users.getPropertyUsers(), settings, ticketSettings, issueTypes]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 4),
              propertyUsers = _ref2[0],
              settings = _ref2[1],
              ticketSettings = _ref2[2],
              issueTypes = _ref2[3];

          propertyUsers.forEach(function (u) {
            return Ember.set(u, 'fullName', "".concat(u.firstName, " ").concat(u.lastName));
          });
          return {
            properties: _this.get('sessionAccount.loggeduser.properties'),
            propertyUsers: propertyUsers,
            settings: !settings.errors ? settings : {},
            id: params.id,
            fields: _this.get('customfields').prepare(_this.get('tasks').systemFields, ticketSettings),
            issueTypes: issueTypes.filter(function (t) {
              return t.isActive;
            })
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this._super(controller, model);

      var filterOption = {
        id: model.id
      };
      var session = this.get('session');
      var currentUser = this.get('sessionAccount.loggeduser.username');
      var filterUsers = model.propertyUsers.filter(function (el) {
        return el.username !== currentUser;
      });
      controller.set('filterUsers', filterUsers);

      if (!session.get('data.userSettings')) {
        session.set('data.userSettings', model.settings);
      }

      this.tasks.filter(filterOption, function (result) {
        controller.set('task', _this2.get('tasks').deserialize(model.fields, result.edges[0].node || {}, model.propertyUsers));
        var formFields = ['issueType', 'category', 'assignedTo', 'department', 'priority', 'status', 'dueDate'];

        var fields = _toConsumableArray(model.fields);

        fields.forEach(function (f) {
          if (f.isSystem) {
            f.visible = formFields.indexOf(f.id) >= 0;
          } else {
            f.visible = true;
          }
        });
        controller.set('fields', fields);
        var hash = {
          departments: [controller.get('task.department.id')],
          propertyIds: [controller.get('task.propertyId')]
        };

        _this2.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
          result.unshift({
            id: 0,
            firstName: 'Unassigned',
            lastName: ''
          });
          controller.set('departmentUsers', result);
        });

        controller.updatePopupUsers();
      });
    }
  });

  _exports.default = _default;
});