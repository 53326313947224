define("inboxfront/validators/required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.required = required;
  _exports.default = void 0;

  function required(value, required) {
    return required === true && !Ember.isEmpty(value) || required !== true;
  }

  var _default = {
    param: 'required',
    message: 'This is required.',
    validate: required
  };
  _exports.default = _default;
});