define("inboxfront/components/dashboard/panels/support-performance-2", ["exports", "inboxfront/components/dashboard/panel"], function (_exports, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({});

  _exports.default = _default;
});