define("inboxfront/controllers/permissions", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    customTabs: Ember.computed('grid', function () {
      return this.get('grid').map(function (el) {
        return el.name;
      });
    }),
    actions: {
      onChangeAllCheckbox: function onChangeAllCheckbox(el) {
        Ember.set(el, 'enabled', !el.enabled);
        el.subArr.forEach(function (item, i) {
          Ember.set(el.subArr[i], 'enabled', el.enabled);
        });

        if (this.get('selectedTab') === 'Conversation') {
          var filterContactsView = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr;
          filterContactsView.forEach(function (item, i) {
            Ember.set(filterContactsView[i], 'enabled', el.enabled);
          });
        }
      },
      onChangeTabs: function onChangeTabs(val) {
        var selectedRole = this.get('selectedRole');
        if (val === this.get('selectedTab')) return;
        var currentGrid = selectedRole.readyGrid.filter(function (el) {
          return el.name === val;
        })[0];
        var arr = [];
        currentGrid.arr.forEach(function (item) {
          var a = _objectSpread({}, item);

          a.subArr = _toConsumableArray(item.subArr);
          arr.pushObject(a);
        });

        if (currentGrid.name === 'Conversation') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.delegate_assign', 'Permissions.respond_reply']);
        } else if (currentGrid.name === 'Administration') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.publish']);
          arr.forEach(function (i) {
            var a = i.subArr.slice(0, 4);
            i.subArr = a.concat(i.subArr.slice(i.subArr.length - 1));
          });
        } else {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete']);
          arr.forEach(function (i) {
            i.subArr = i.subArr.slice(0, -2);
          });
        }

        Ember.set(this, 'currentGrid', arr);
        Ember.set(this, 'selectedTab', currentGrid.name);
      },
      onSelectRole: function onSelectRole(val) {
        if (val === this.get('selectedRole')) return;
        Ember.set(this, 'currentGrid', val.readyGrid[0].arr);
        Ember.set(this, 'selectedTab', val.readyGrid[0].name);
        Ember.set(this, 'selectedRole', val);
        var currentGrid = val.readyGrid[0];
        var arr = [];
        currentGrid.arr.forEach(function (item) {
          var a = _objectSpread({}, item);

          a.subArr = _toConsumableArray(item.subArr);
          arr.pushObject(a);
        });

        if (currentGrid.name === 'Conversation') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.delegate_assign', 'Permissions.respond_reply']);
        } else if (currentGrid.name === 'Administration') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.publish']);
          arr.forEach(function (i) {
            var a = i.subArr.slice(0, 4);
            i.subArr = a.concat(i.subArr.slice(i.subArr.length - 1));
          });
        } else {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete']);
          arr.forEach(function (i) {
            i.subArr = i.subArr.slice(0, -2);
          });
        }

        Ember.set(this, 'currentGrid', arr);
        Ember.set(this, 'selectedTab', currentGrid.name);
      },
      checkPermission: function checkPermission(val) {
        var currentGrid = this.get('currentGrid');
        var enabled = !val.enabled;

        if (!val.enabled) {
          var filterSubTypeView = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'view';
          })[0];

          if (val.name === 'add') {
            var filterSubTypeChange = currentGrid.filter(function (el) {
              return el.name === val.type;
            })[0].subArr.filter(function (el) {
              return el.name === 'change';
            })[0];
            var filterSubTypeDelete = currentGrid.filter(function (el) {
              return el.name === val.type;
            })[0].subArr.filter(function (el) {
              return el.name === 'delete';
            })[0];
            Ember.set(filterSubTypeView, 'enabled', true);
            Ember.set(filterSubTypeChange, 'enabled', true);
            Ember.set(filterSubTypeDelete, 'enabled', true);

            if (this.get('selectedTab') === 'Conversation') {
              var filterContacts = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr;
              filterContacts.forEach(function (item, i) {
                Ember.set(filterContacts[i], 'enabled', true);
              });
            }
          } else if (val.name === 'delete' || val.name === 'change' || val.name === 'assign') {
            Ember.set(filterSubTypeView, 'enabled', true);

            if (this.get('selectedTab') === 'Conversation') {
              var _filterContacts = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr.findBy('name', val.name);

              var filterContactsView = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr[0];
              Ember.set(_filterContacts, 'enabled', true);
              Ember.set(filterContactsView, 'enabled', true);
            }
          } else if (this.get('selectedTab') === 'Conversation' && val.name === 'reply') {
            var filterContactsEdit = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr[2];
            var filterTemplateRead = this.get('selectedRole.readyGrid').findBy('name', 'Templates').arr[0].subArr[0];
            Ember.set(filterSubTypeView, 'enabled', true);
            Ember.set(filterTemplateRead, 'enabled', true);
            Ember.set(filterContactsEdit, 'enabled', true);
          }
        }

        if (val.name === 'add' || val.name === 'change') {
          var _filterSubTypeChange = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'change';
          })[0];
          var filterSubTypeAdd = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'add';
          })[0];
          Ember.set(_filterSubTypeChange, 'enabled', enabled);
          Ember.set(filterSubTypeAdd, 'enabled', enabled);
        } else {
          Ember.set(val, 'enabled', !val.enabled);
        }
      },
      updatePermissions: function updatePermissions() {
        var _this = this;

        var hash = {
          roleId: this.get('selectedRole.id'),
          permissions: this.sortCheckBox(),
          readyGrid: JSON.stringify(this.get('selectedRole.readyGrid'))
        };
        this.ajax.mutation(_mutation.default.ADD_REMOVE_PERMISSIONS, 'addRemovePermissions', hash).then(function () {
          _this.paperToaster.show(_this.intl.t('permissions_updated'));
        });
      }
    },
    sortCheckBox: function sortCheckBox() {
      var arr = [];
      this.get('selectedRole.readyGrid').forEach(function (el) {
        el.arr.forEach(function (el) {
          el.subArr.forEach(function (el) {
            el.enabled && arr.push(el.id);
          });
        });
      });
      return arr;
    }
  });

  _exports.default = _default;
});