define("inboxfront/templates/components/dashboard/panels/overview-others", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ybHxzesQ",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[1,[28,\"dashboard/panel-loader\",null,[[\"isLoading\"],[[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"chat_bot_performance\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"cards-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"cards\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"wrap-card\"],[11,\"style\",[29,[\"background-color: \",[23,1,[\"colorWrapIcon\"]]]]],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"top\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[1,[28,\"t\",[[23,1,[\"title\"]]],null],false],[9],[0,\"\\n\"],[0,\"                \"],[7,\"div\",true],[10,\"class\",\"wrap-icon\"],[8],[0,\"\\n                    \"],[1,[28,\"svg-icon\",[[23,1,[\"icon\"]]],[[\"color\"],[[23,1,[\"colorIcon\"]]]]],false],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"middle\"],[8],[0,\"\\n                \"],[1,[23,1,[\"count\"]],false],[0,\"\\n                \"],[1,[23,1,[\"unit\"]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[11,\"class\",[29,[\"bottom \",[28,\"if\",[[28,\"eq\",[[23,1,[\"dir\"]],\"down\"],null],\"down\"],null]]]],[8],[0,\"\\n                \"],[1,[28,\"svg-icon\",[\"arrow-up\"],null],false],[0,\"\\n                \"],[1,[23,1,[\"prevCount\"]],false],[0,\"%\\n                \"],[4,\"paper-tooltip\",null,[[\"position\"],[\"bottom\"]],{\"statements\":[[0,\"In comparison with period from \"],[1,[22,\"prevStartDate\"],false],[0,\" to \"],[1,[22,\"prevEndDate\"],false]],\"parameters\":[]},null],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panels/overview-others.hbs"
    }
  });

  _exports.default = _default;
});