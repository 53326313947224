define("inboxfront/components/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    color: '',
    icon: Ember.computed('params.[]', function () {
      return this.params[0] || 'configure';
    }),
    styles: Ember.computed('color', function () {
      if (this.get('color')) {
        return 'fill:' + this.get('color');
      }

      return '';
    })
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});