define("inboxfront/templates/components/kb/bases-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HlT5VskQ",
    "block": "{\"symbols\":[\"base\"],\"statements\":[[4,\"if\",[[24,[\"bases\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"sections-list bases-list short\"],[8],[0,\"\\n\"],[4,\"ember-scrollable\",null,null,{\"statements\":[[4,\"each\",[[24,[\"bases\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[11,\"class\",[29,[\"item\",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"selectedBase\"]]],null],\" selected\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onClick\"]],[23,1,[]]],null]],[11,\"ondrop\",[28,\"action\",[[23,0,[]],\"onDrop\",[23,1,[]]],null]],[11,\"ondragover\",[28,\"action\",[[23,0,[]],\"onDragOver\",[23,1,[]]],null]],[8],[0,\"\\n                    \"],[7,\"div\",true],[11,\"class\",[29,[\"indicator \",[28,\"if\",[[23,1,[\"visible\"]],\"PUBLISHED\",\"DRAFT\"],null]]]],[8],[0,\" \"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"selectedBase\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"sections-list bases-list short single\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"item selected\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onClick\"]],[24,[\"selectedBase\"]]],null]],[8],[0,\"\\n            \"],[7,\"div\",true],[11,\"class\",[29,[\"indicator \",[28,\"if\",[[24,[\"selectedBase\",\"visible\"]],\"PUBLISHED\",\"DRAFT\"],null]]]],[8],[0,\" \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[24,[\"selectedBase\",\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/kb/bases-list.hbs"
    }
  });

  _exports.default = _default;
});