define("inboxfront/components/helpdesk/department-selector", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    departments: Ember.inject.service(),
    activeDepartments: null,
    isEditMode: true,
    actions: {
      onSelectChange: function onSelectChange(value) {
        this.set('value', value);

        if (this.get('getusers')) {
          this.setDepartmentUsers();
        } else {
          this.onChange(value);
        }
      },
      selectDepartment: function selectDepartment() {
        if (this.get('multiple')) {
          var selected = [];

          for (var i = this.get('activeDepartments').length - 1; i >= 0; i--) {
            if (this.get('activeDepartments')[i].selected) {
              selected.push(this.get('departments')[i]);
            }
          }

          this.set('value', selected);
        }

        this.onChange();
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.departments.load().then(function (deps) {
        _this.set('activeDepartments', deps);

        if (_this.get('getusers')) {
          _this.setDepartmentUsers();
        }
      });
    },
    setDepartmentUsers: function setDepartmentUsers() {
      var _this2 = this;

      if (this.get('value') && this.get('activeDepartments')) {
        var department = this.get('activeDepartments').find(function (dep) {
          return dep.id === _this2.get('value').id;
        });

        if (department) {
          this.onChange(department.staffProfiles.filter(function (obj) {
            return obj.user.isActive === true;
          }));
        }
      }
    }
  });

  _exports.default = _default;
});