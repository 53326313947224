define("inboxfront/components/dashboard/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['dashboard-panel'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.renderChart(this.element);
    },
    renderChart: function renderChart(element) {},
    willDestroyElement: function willDestroyElement() {
      var charts = this.get('$highcharts');

      if (Array.isArray(charts)) {
        charts.forEach(function (c) {
          return c.destroy();
        });
      } else {
        charts === null || charts === void 0 ? void 0 : charts.destroy();
      }
    }
  });

  _exports.default = _default;
});