define("inboxfront/routes/ticketfields", ["exports", "inboxfront/routes/customfields", "inboxfront/gql/query"], function (_exports, _customfields, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _customfields.default.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
      var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
      return Ember.RSVP.all([settings, issueTypes]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            settings = _ref2[0],
            issueTypes = _ref2[1];

        return {
          settings: settings,
          fields: _this.get('customfields').prepare(_this.get('tasks').systemFields, settings),
          types: _this.get('customfields').types,
          issueTypes: issueTypes.filter(function (t) {
            return t.isActive;
          })
        };
      });
    }
  });

  _exports.default = _default;
});