define("inboxfront/components/chat/room-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    tagName: '',
    participantInfo: Ember.computed('room', function () {
      var room = this.get('room');
      var username = this.get('sessionAccount.loggeduser.username');
      return room && room.participants.filter(function (user) {
        return user.username !== username;
      })[0] || null;
    }),
    actions: {}
  });

  _exports.default = _default;
});