define("inboxfront/controllers/ecommerce/crm-integrations", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    paperToaster: Ember.inject.service(),
    ajax: Ember.inject.service(),
    importContacts: true,
    importCompanies: true,
    importDeals: true,
    contactsProcessed: true,
    companiesProcessed: true,
    dealsProcessed: true,
    hubspotIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount;

      var ret = false;
      (_this$_findAccount = this._findAccount('HUBSPOT')) === null || _this$_findAccount === void 0 ? void 0 : _this$_findAccount.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'hubspot_refresh_token') {
          ret = true;
        }
      });

      this._updateMapping(ret);

      return ret;
    }),
    pipedriveIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount2;

      var ret = false;
      (_this$_findAccount2 = this._findAccount('PIPEDRIVE')) === null || _this$_findAccount2 === void 0 ? void 0 : _this$_findAccount2.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'pipedrive_refresh_token') {
          ret = true;
        }
      });

      this._updateMapping(ret);

      return ret;
    }),
    salesforceIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount3;

      var ret = false;
      (_this$_findAccount3 = this._findAccount('SALESFORCE')) === null || _this$_findAccount3 === void 0 ? void 0 : _this$_findAccount3.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'salesforce_refresh_token') {
          ret = true;
        }
      });

      this._resetSFSettings();

      this._updateMapping(ret);

      return ret;
    }),
    consumerKey: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', 'isShowSFFullSettings', function () {
      var _this$_findAccount4;

      var ret = '';
      (_this$_findAccount4 = this._findAccount('SALESFORCE')) === null || _this$_findAccount4 === void 0 ? void 0 : _this$_findAccount4.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'salesforce_consumer_key') {
          ret = e.node.value;
        }
      });

      if (ret && this.get('isShowSFFullSettings') !== true) {
        ret = ret.substr(0, 10) + '*******' + ret.substr(ret.length - 10);
      }

      return ret;
    }),
    consumerSecret: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', 'isShowSFFullSettings', function () {
      var _this$_findAccount5;

      var ret = '';
      (_this$_findAccount5 = this._findAccount('SALESFORCE')) === null || _this$_findAccount5 === void 0 ? void 0 : _this$_findAccount5.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'salesforce_consumer_secret') {
          ret = e.node.value;
        }
      });

      if (ret && this.get('isShowSFFullSettings') !== true) {
        ret = ret.substr(0, 10) + '*******' + ret.substr(ret.length - 10);
      }

      return ret;
    }),
    importDealsDisabled: Ember.computed('importContacts', 'importCompanies', function () {
      var ret = !(this.get('importContacts') || this.get('importCompanies')); // if (ret) {
      //     this.set('importDeals', false);
      // }

      return ret;
    }),
    _updateMapping: function _updateMapping(crmIsConnected) {
      var _this = this;

      this.set('contactsProcessed', true);
      this.set('companiesProcessed', true);
      this.set('dealsProcessed', true);
      var crm = this.get('selectedIntegration');

      if (crmIsConnected && crm) {
        var hash = {
          propertyId: this.get('selectedProperty.id'),
          crm: crm
        };
        this.ajax.query(_query.default.GET_CRM_MAPPING, 'mapping', hash).then(function (data) {
          _this.set('contactsProcessed', data === null || data === void 0 ? void 0 : data.contactsProcessed);

          _this.set('companiesProcessed', data === null || data === void 0 ? void 0 : data.companiesProcessed);

          _this.set('dealsProcessed', data === null || data === void 0 ? void 0 : data.dealsProcessed);
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._resetSFSettings();
    },
    _findAccount: function _findAccount(application) {
      var _this$get, _this$get$accounts, _this$get$accounts$ed;

      var ret = null;
      (_this$get = this.get('selectedProperty')) === null || _this$get === void 0 ? void 0 : (_this$get$accounts = _this$get.accounts) === null || _this$get$accounts === void 0 ? void 0 : (_this$get$accounts$ed = _this$get$accounts.edges) === null || _this$get$accounts$ed === void 0 ? void 0 : _this$get$accounts$ed.forEach(function (e) {
        if (e.node.application === application) {
          ret = e.node;
        }
      });
      return ret;
    },
    _resetSFSettings: function _resetSFSettings() {
      this.set('SF_clientId', '');
      this.set('SF_clientSecret', '');
    },
    actions: {
      showLoginHubSpot: function showLoginHubSpot() {
        var clientId = '4ac68683-7d65-4d45-9ef7-6c69bae798b3';
        var redirectUrl = 'https://dev.raincheck.co/crm';
        var scope = 'crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read';
        var control = this;
        var propertyId = this.get('selectedProperty.id');

        window.onAuth = function (_ref) {
          var code = _ref.code;

          if (code) {
            control.sendHubSpotCode(propertyId, code);
          }

          popup.close();
        };

        var popup = this.showAuthAccessForm("https://app.hubspot.com/oauth/authorize?client_id=".concat(clientId, "&redirect_uri=").concat(redirectUrl, "&scope=").concat(scope));
      },
      showLoginPipeDrive: function showLoginPipeDrive() {
        if (this.get('pipedriveIsConnected')) {
          this.set('showUninstallDialog', true);
          return;
        }

        var clientId = 'c0bf0905835b084b';
        var redirectUrl = 'https://dev-backend.raincheck.co/crm/pipedrive';
        var initialState = '' + Math.ceil(Math.random() * 1000000);
        var control = this;
        var propertyId = this.get('selectedProperty.id');

        window.onAuth = function (_ref2) {
          var code = _ref2.code,
              error = _ref2.error,
              state = _ref2.state;

          if (code && state === initialState) {
            control.sendPipeDriveCode(propertyId, code);
          }

          popup.close();
        };

        var popup = this.showAuthAccessForm("https://oauth.pipedrive.com/oauth/authorize?client_id=".concat(clientId, "&redirect_uri=").concat(redirectUrl, "&state=").concat(initialState));
      },
      showUninstallInfo: function showUninstallInfo() {
        this.set('showUninstallInfoDialog', true);
      },
      uninstallIntegration: function uninstallIntegration() {
        this.get('paperToaster').show('Integration is unistalled');
        this.set('showMarketDialog', false);
      },
      showLoginSalesForce: function showLoginSalesForce() {
        var clientId = this.get('SF_clientId');
        var clientSecret = this.get('SF_clientSecret');
        var redirectUrl = 'https://dev.raincheck.co/crm';
        var scope = 'id+api+refresh_token';
        var initialState = '' + Math.ceil(Math.random() * 1000000);
        var control = this;
        var propertyId = this.get('selectedProperty.id');

        window.onAuth = function (_ref3) {
          var code = _ref3.code,
              error = _ref3.error,
              state = _ref3.state;

          if (code) {
            control.sendSalesForceCode(propertyId, code, clientId, clientSecret);
          }

          popup.close();
        };

        var popup = this.showAuthAccessForm("https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=".concat(clientId, "&redirect_uri=").concat(redirectUrl, "&scope=").concat(scope, "&nonce=").concat(initialState));
      },
      showSalesForceSettings: function showSalesForceSettings() {
        this.set('isShowSFSettings', true);
      },
      hideSalesForceSettings: function hideSalesForceSettings() {
        this.set('isShowSFSettings', false);
        this.set('isShowSFFullSettings', false);
      },
      getAllContacts: function getAllContacts() {
        var _this2 = this;

        if (this.get('loadingProgress') === 'Loading...') {
          return;
        }

        this.set('loadingProgress', 'Loading...');
        var hash = {
          propertyId: this.get('selectedProperty.id'),
          crm: this.get('selectedIntegration')
        };
        this.ajax.query(_mutation.default.GET_CRM_CONTACTS, 'getAllCrmContacts', hash).then(function (res) {
          if (res.status === 200) {
            _this2.set('loadingProgress', 'Done');

            _this2._updateMapping(true);
          } else {
            _this2.set('loadingProgress', 'Something goes wrong');
          }
        });
      },
      getAllCompanies: function getAllCompanies() {
        var _this3 = this;

        if (this.get('loadingProgress') === 'Loading...') {
          return;
        }

        this.set('loadingProgress', 'Loading...');
        var hash = {
          propertyId: this.get('selectedProperty.id'),
          crm: this.get('selectedIntegration')
        };
        this.ajax.query(_mutation.default.GET_CRM_COMPANIES, 'getAllCrmCompanies', hash).then(function (res) {
          if (res.status === 200) {
            _this3.set('loadingProgress', 'Done');

            _this3._updateMapping(true);
          } else {
            _this3.set('loadingProgress', 'Something goes wrong');
          }
        });
      },
      getAllDeals: function getAllDeals() {
        var _this4 = this;

        if (this.get('loadingProgress') === 'Loading...') {
          return;
        }

        this.set('loadingProgress', 'Loading...');
        var hash = {
          propertyId: this.get('selectedProperty.id'),
          crm: this.get('selectedIntegration')
        };
        this.ajax.query(_mutation.default.GET_CRM_DEALS, 'getAllCrmDeals', hash).then(function (res) {
          if (res.status === 200) {
            _this4.set('loadingProgress', 'Done');

            _this4._updateMapping(true);
          } else {
            _this4.set('loadingProgress', 'Something goes wrong');
          }
        });
      }
    },
    showAuthAccessForm: function showAuthAccessForm(url) {
      var width = 500,
          height = 700,
          left = (window.screen.width - width) / 2,
          top = (window.screen.height - height) / 2;
      return window.open(url, 'auth-popup', "width=".concat(width, ",height=").concat(height, ",left=").concat(left, ",top=").concat(top));
    },
    sendHubSpotCode: function sendHubSpotCode(propertyId, code) {
      var _this5 = this;

      var hash = {
        propertyId: propertyId,
        code: code,
        crm: 'HUBSPOT',
        getAllContacts: this.get('importContacts'),
        getAllCompanies: this.get('importCompanies'),
        getAllDeals: this.get('importDeals')
      };
      this.set('installationInProgress', true);
      this.ajax.mutation(_mutation.default.INTEGRATE_CRM, 'integrateCrm', hash, function (res) {
        if (res.status === 200) {
          _this5.get('selectedProperty.accounts.edges').addObject({
            node: {
              application: 'HUBSPOT',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'hubspot_refresh_token',
                    value: code
                  }
                }]
              }
            }
          });

          if (hash.getAllContacts) {
            _this5._updateMapping(true);
          }

          _this5.get('paperToaster').show('Application connected');
        } else {
          _this5.get('paperToaster').show('Something goes wrong, Try it later.');
        }

        _this5.set('installationInProgress', false);
      }, function () {
        _this5.set('installationInProgress', false);
      });
    },
    sendPipeDriveCode: function sendPipeDriveCode(propertyId, code) {
      var _this6 = this;

      var hash = {
        propertyId: propertyId,
        code: code,
        crm: 'PIPEDRIVE',
        getAllContacts: this.get('importContacts'),
        getAllCompanies: this.get('importCompanies'),
        getAllDeals: this.get('importDeals')
      };
      this.set('installationInProgress', true);
      this.ajax.mutation(_mutation.default.INTEGRATE_CRM, 'integrateCrm', hash, function (res) {
        if (res.status === 200) {
          _this6.get('selectedProperty.accounts.edges').addObject({
            node: {
              application: 'PIPEDRIVE',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'pipedrive_refresh_token',
                    value: code
                  }
                }]
              }
            }
          });

          if (hash.getAllContacts) {
            _this6._updateMapping(true);
          }

          _this6.get('paperToaster').show('Application connected');
        } else {
          _this6.get('paperToaster').show('Something goes wrong, Try it later.');
        }

        _this6.set('installationInProgress', false);
      }, function () {
        _this6.set('installationInProgress', false);
      });
    },
    sendSalesForceCode: function sendSalesForceCode(propertyId, code, consumerKey, consumerSecret) {
      var _this7 = this;

      var hash = {
        propertyId: propertyId,
        code: code,
        crm: 'SALESFORCE',
        consumerKey: consumerKey,
        consumerSecret: consumerSecret,
        getAllContacts: this.get('importContacts'),
        getAllCompanies: this.get('importCompanies'),
        getAllDeals: this.get('importDeals')
      };
      this.set('installationInProgress', true);
      this.ajax.mutation(_mutation.default.INTEGRATE_CRM, 'integrateCrm', hash, function (res) {
        if (res.status === 200) {
          _this7.get('selectedProperty.accounts.edges').addObject({
            node: {
              application: 'SALESFORCE',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'salesforce_refresh_token',
                    value: code
                  }
                }]
              }
            }
          });

          if (hash.getAllContacts) {
            _this7._updateMapping(true);
          }

          _this7.get('paperToaster').show('Application connected');
        } else {
          _this7.get('paperToaster').show('Something goes wrong, Try it later.');
        }

        _this7.set('installationInProgress', false);
      }, function () {
        _this7.set('installationInProgress', false);
      });
    }
  });

  _exports.default = _default;
});