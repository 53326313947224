define("inboxfront/routes/ecommerce", ["exports", "inboxfront/routes/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    model: function model(params) {
      return {
        code: params.code || '',
        context: params.context || '',
        scope: params.scope || ''
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var properties = this.get('sessionAccount.loggeduser.staffprofile.estates');
      controller.set('properties', properties);
      controller.set('selectedProperty', properties[0]);

      if (model.code) {
        var propertyId = window.localStorage.getItem('RC_PropertyId');
        var property = properties.findBy('id', propertyId);

        if (property) {
          controller.set('selectedProperty', property);
          controller.sendCode(model);
        }
      } //TODO set callback domain after register shopify/bigcommerce application, remove manual setting


      controller.set('shopifyCallback', window.localStorage.getItem('RC_ShopifyCallbackDomain') || '');
      controller.set('bigcommerceCallback', window.localStorage.getItem('RC_BigCommerceCallbackDomain') || '');
    }
  });

  _exports.default = _default;
});