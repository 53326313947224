define("inboxfront/controllers/assistants/index", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation", "jquery"], function (_exports, _query, _mutation, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var CHATBOT = 1;
  var WORKFLOW = 2;
  var FLOWSPERPAGE = 15;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    currentStep: 0,
    showPopupDeleteFlow: false,
    showPopupCopyFlow: false,
    awaitCreateFlow: false,
    showPopupActivateFlow: false,
    searchContacts: null,
    observerPage: Ember.observer('pageNum', function () {
      if (this.get('cloneFlows')) {
        this.set('collectionFlows', this.get('sliceData').getSliceData(this.get('cloneFlows'), this.get('pageNum'), FLOWSPERPAGE));
      }
    }),
    filterFlows: function filterFlows(val) {
      var currentFlows;
      this.set('selectedFilter', val);

      if (val === 'workflow') {
        this.set('cloneFlows', this.get('workFlows'));
        currentFlows = this.get('workFlows');
      } else {
        this.set('cloneFlows', this.get('flows').filter(function (f) {
          return !f.isTemplate;
        }).filter(function (f) {
          return f.type === CHATBOT;
        }));
        currentFlows = this.get('cloneFlows');
      }

      this.set('collectionFlows', this.get('sliceData').getSliceData(currentFlows, this.get('pageNum'), FLOWSPERPAGE));
    },
    contentClassName: Ember.computed('isShowTemplateFlow', function () {
      return this.get('isShowTemplateFlow') ? 'template' : '';
    }),
    firstBlock: Ember.computed('blocks.[]', function () {
      return this.get('blocks').findBy('parent', null);
    }),
    componentsFlow: Ember.computed('selectedFlowTemplate.{type,objectType}', 'selectedFlowTemplate', 'selectedComponent', function () {
      return this.get('flowComponents');
    }),
    deserializeFlow: function deserializeFlow(bl) {
      var blocks = [];
      bl.forEach(function (b) {
        if (b.parent) {
          var p = bl.findBy('id', b.parent.id);

          if (p) {
            b.parent = p;
          }
        }

        var children = [];
        b.children.forEach(function (c) {
          c = bl.findBy('id', c.id);

          if (c) {
            children.addObject(c);
          }
        });

        if (!b.parent) {
          console.log('SET CHILDREN', b, children);
        }

        b.children = children.sortBy('id');
        blocks.addObject(b);
      });
      return blocks;
    },
    initFlowData: function initFlowData() {
      var _this = this;

      var ruleOptions = JSON.parse(this.model.ruleOptions);
      var flow = this.get('selectedFlowTemplate');
      this.set('dataCollectors', [{
        id: 1,
        type: 'email',
        label: 'Email',
        text: 'Your email please?',
        icon: 'email'
      }, {
        id: 2,
        type: 'firstName',
        label: 'First Name',
        text: 'Your first name please?',
        icon: 'user-circle'
      }, {
        id: 3,
        type: 'lastName',
        label: 'Last Name',
        text: 'Your last name please?',
        icon: 'user-circle'
      }, {
        id: 4,
        type: 'number',
        label: 'Phone Number',
        text: 'Your number please?',
        icon: 'phone'
      }, {
        id: 5,
        type: 'mobile',
        label: 'Mobile Number',
        text: 'Your mobile please?',
        icon: 'mobile'
      }]);
      this.set('ruleOptions', ruleOptions);
      this.set('flowId', flow.id);
      this.set('blocks', this.deserializeFlow(flow.blocks));
      this.set('rulesConditions', []);

      if (flow.rules) {
        (function () {
          var rules = JSON.parse(flow.rules.rules);

          _this.set('checkCondition', rules.checkCondition);

          rules.conditions.all.forEach(function (item) {
            Object.keys(item).forEach(function (key) {
              if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
            });
          });

          var _loop = function _loop(i) {
            var rulesConditions = {};
            var fieldType = ruleOptions.variables.filter(function (el) {
              return el.name === rules.conditions.all[i].name.replace(' ', '_');
            });

            if (!fieldType[0]) {
              return "continue";
            }

            ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
              if (val.name === rules.conditions.all[i].operator.replace(' ', '_')) {
                rulesConditions.selectedCondition = val;
              }
            });
            rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
            ruleOptions.variables.forEach(function (val) {
              if (val.name === rules.conditions.all[i].name.replace(' ', '_')) {
                rulesConditions.selectedColumn = val;
              }
            });
            rulesConditions.selectedConditionValue = rules.conditions.all[i].value;

            _this.rulesConditions.pushObject(rulesConditions);
          };

          for (var i = 0; i < rules.conditions.all.length; i++) {
            var _ret = _loop(i);

            if (_ret === "continue") continue;
          }
        })();
      }

      this.set('checkConditions', [{
        id: 1,
        title: this.intl.t('matches_all_conditions')
      }, {
        id: 2,
        title: this.intl.t('matches_any_conditions')
      }]);
      this.set('checkCondition', {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      });
      this.set('ruleStruct', {
        conditions: {
          all: []
        },
        actions: []
      });
      this.set('rule', {});
      this.set('flowComponents', [{
        name: 'Message',
        type: 'message',
        icon: 'message-circle',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Single select)',
        type: 'single',
        icon: 'options-single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Slider',
        type: 'slider',
        icon: 'slider',
        data: null,
        blockType: 'SLIDER'
      }, {
        name: 'Data Collector',
        type: 'data',
        icon: 'data-collector',
        data: null,
        blockType: 'FORM'
      }, {
        name: 'Action',
        type: 'action',
        icon: 'play',
        data: null,
        blockType: 'TEXT'
      }]);
      this.set('commentModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      this.set('chatModules', [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }]);
      this.set('tagModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      this.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      });
      this.set('actionsFlow', [{
        type: 'assign',
        label: 'Assign to'
      }, {
        type: 'tag',
        label: 'Tag the visitor'
      }, {
        type: 'contacts',
        label: 'Add visitor to Contacts'
      }, {
        type: 'close',
        label: 'Close the conversation'
      }, {
        type: 'create_ticket',
        label: 'Create ticket'
      }]);
    },
    selectedFlowTab: 'settings',
    openConfigureComponent: function openConfigureComponent(id) {
      var _this2 = this;

      var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var newBlocks = blocks || this.get('blocks');
      var prevActiveBlock = newBlocks.findBy('id', this.get('selectedComponent.id'));
      var activeBlock = newBlocks.findBy('id', id);
      this.set('cursor', {});
      (0, _jquery.default)('.item-flow-wrap').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      prevActiveBlock && Ember.set(prevActiveBlock, 'active', false);
      this.set('selectedFlowTab', 'configure');
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', false);

      if (activeBlock) {
        if (activeBlock.blockType === 'FORM') {
          (function () {
            var extraData = JSON.parse(activeBlock.extraData);
            Ember.set(activeBlock, 'dataCollectorsSelect', extraData.dataCollectorsSelect);

            var dataCollectors = _toConsumableArray(_this2.get('dataCollectors'));

            var parent = activeBlock;

            while (parent && dataCollectors.length) {
              var _parent$parent;

              if (parent.blockType === 'FORM') {
                var _extraData = JSON.parse(parent.extraData);

                _extraData.dataCollectorsSelect.forEach(function (d) {
                  dataCollectors.removeObject(dataCollectors.findBy('id', d.id));
                });
              }

              parent = newBlocks.findBy('id', (_parent$parent = parent.parent) === null || _parent$parent === void 0 ? void 0 : _parent$parent.id);
            } // this.checkChildren(activeBlock, newBlocks, dataCollectors);


            _this2.set('dataCollectorsSelect', dataCollectors);
          })();
        } else if (activeBlock.name === 'Action') {
          var ruleOptions = this.get('ruleOptions');

          if (typeof activeBlock.actions === 'string') {
            (function () {
              var extraData = JSON.parse(activeBlock.actions);
              Ember.set(activeBlock, 'actions', extraData.actions);
              Ember.set(activeBlock, 'alwaysBtn', extraData.alwaysBtn);
              Ember.set(activeBlock, 'checkCondition', extraData.checkCondition);
              Ember.set(activeBlock, 'actionsFlow', extraData.actionsFlow);
              Ember.set(activeBlock, 'rules', []);

              if (extraData.rules.length) {
                extraData.rules.forEach(function (item) {
                  Object.keys(item).forEach(function (key) {
                    if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
                  });
                });

                var _loop2 = function _loop2(i) {
                  var rulesConditions = {};
                  var fieldType = ruleOptions.variables.filter(function (el) {
                    return el.name === extraData.rules[i].name.replace(' ', '_');
                  });
                  fieldType.length && ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
                    if (val.name === extraData.rules[i].operator.replace(' ', '_')) {
                      rulesConditions.selectedCondition = val;
                    }
                  });
                  rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
                  ruleOptions.variables.forEach(function (val) {
                    if (val.name === extraData.rules[i].name.replace(' ', '_')) {
                      rulesConditions.selectedColumn = val;
                    }
                  });
                  rulesConditions.selectedConditionValue = extraData.rules[i].value;
                  activeBlock.rules.pushObject(rulesConditions);
                };

                for (var i = 0; i < extraData.rules.length; i++) {
                  _loop2(i);
                }
              }
            })();
          }
        } else if (activeBlock.blockType === 'BUTTON') {
          var parentFlow = newBlocks.findBy('id', activeBlock.parent.id);

          if (parentFlow) {
            activeBlock = parentFlow;
          }
        } else if (activeBlock.blockType === 'SLIDER') {
          Ember.set(activeBlock, 'children', activeBlock.children.filter(function (child) {
            return child.blockType === 'BUTTON';
          }));
        } else if (activeBlock.blockType === 'COMMENT') {
          this.changeParamModule(activeBlock);
          this.resetCommentModule(activeBlock);
        } else if (activeBlock.blockType === 'TAG') {
          this.changeParamModuleTags(activeBlock);
          this.resetCommentModule(activeBlock);
        }

        Ember.set(activeBlock, 'active', true);
        this.set('selectedComponent', activeBlock);
      } else {
        this.set('selectedComponent', null);
        this.set('isActiveStartBlockFlow', true);
        this.triggerOpenStartConfigure();
      }

      this.set('isLoadingComponent', false);
      setTimeout(function () {
        var activeElement = document.querySelector('.item-flow.active');

        if (document.getElementById('editor')) {
          document.getElementById('editor').innerHTML = activeBlock.mainTitle;
        }

        _this2.set('reRender', true); // activeElement && activeElement.scrollIntoView({ behavior: 'smooth' });

      }, 500);
    },
    triggerOpenStartConfigure: function triggerOpenStartConfigure() {
      (0, _jquery.default)('.item-flow').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      (0, _jquery.default)('.item-flow-wrap').addClass('active');
      this.set('isShowComponent', false);
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', true);
      this.set('selectedFlowTab', 'configure');
    },
    actions: {
      onSelectTemplate: function onSelectTemplate(flow) {
        this.set('selectedFlowTemplate', flow);
        this.initFlowData();
        this.set('isShowTemplateFlow', false);
      },
      onCreateTemplate: function onCreateTemplate() {
        var _this3 = this;

        var hash = {
          title: "Template".concat(String(Math.random()).slice(2)),
          description: '',
          idleTime: 20,
          timeOut: 20,
          warningMessage: 'Hi, we have not heard anything from you for the past 20 seconds, ' + 'please type something to remain active in chat',
          timeOutMessage: 'This chat session has been terminated due to time-out, we are still here with you if you need anything',
          isActive: false,
          auto: true,
          type: 1,
          companyProperty: this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id,
          extraData: JSON.stringify({
            isTemplate: true
          })
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref) {
          var flow = _ref.flow;

          _this3.set('awaitCreateFlow', false);

          _this3.paperToaster.show(_this3.intl.t('flow_created'));

          _this3.transitionToRoute('assistants.tmpl', {
            queryParams: {
              id: flow.id
            }
          });
        });
      },
      showPreview: function showPreview() {
        this.set('isShowPreview', true);
      },
      hidePreview: function hidePreview() {
        this.set('isShowPreview', false); // this.set('selectedFlowTemplate', null);
      },
      hideTemplate: function hideTemplate() {
        this.set('isShowPreview', false);
        this.set('isShowTemplateFlow', false);
        this.set('selectedFlowTemplate', null);
      },
      useTemplate: function useTemplate() {
        this.set('isShowTemplateFlow', true); // this.router.transitionTo('dashboard');
        // this.transitionToRoute('assistants.edit', {queryParams: {id: this.get('selectedFlowTemplate').id}});
        // this.router.transitionTo('assistants.edit', {queryParams: {id: this.get('selectedFlowTemplate').id}});
        // this.set('selectedFlowTemplate', null);
      },
      backToTemplates: function backToTemplates() {
        this.set('isShowTemplateFlow', false);
      },
      createNewFlow: function createNewFlow() {
        var _this4 = this;

        var selectedFlow = this.get('selectedFlowTemplate'); // this.set('isLoader', true);

        this.ajax.query(_mutation.default.DUPLICATE_FLOW, 'duplicateFlow', {
          flowId: selectedFlow.id
        }).then(function (_ref2) {
          var flow = _ref2.flow;

          if (flow) {
            var hash = {
              flowId: flow.id,
              title: "Created From ".concat(selectedFlow.templateTitle),
              description: selectedFlow.templateDescription,
              isDefault: false
            };

            _this4.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref3) {
              var flow = _ref3.flow;

              _this4.set('isShowPreview', false);

              _this4.set('selectedFlowTemplate', null);

              _this4.set('isShowTemplates', false);

              _this4.set('isShowTemplateFlow', false);

              _this4.transitionToRoute('assistants.edit', {
                queryParams: {
                  id: flow.id
                }
              });
            }); // this.transitionToRoute('assistants.edit', {queryParams: {id: flow.id}});
            // this.set('showPopupCopyFlow', false);
            // this.paperToaster.show(this.intl.t('duplicate_flow', {name: selectedFlow.title}));
            // this.queryFlows();

          } else {
            _this4.paperToaster.show('Hmmm');
          }
        });
      },
      openListComponents: function openListComponents() {
        var component = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var event = arguments.length > 1 ? arguments[1] : undefined;
        event.stopPropagation();

        if (component) {
          this.set('selectedComponent', component);
        } else {
          var blocks = this.get('blocks');
          this.set('selectedComponent', {
            children: blocks[0] ? [{
              id: blocks[0].id
            }] : [],
            parent: null
          });
        }

        this.set('selectedFlowTab', 'configure');
        this.set('isShowListComponents', true);
        this.set('isActiveStartBlockFlow', false);
        this.set('isShowComponent', false);
        (0, _jquery.default)('.item-flow, .item-flow-wrap').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        (0, _jquery.default)(event.target).closest('.add-component-flow').addClass('active');
      },
      openStartConfigure: function openStartConfigure() {
        this.triggerOpenStartConfigure();
      },
      openConfigure: function openConfigure(component) {
        this.set('isShowComponent', true);
        this.openConfigureComponent(component.id);

        if (document.querySelector('.txt-input')) {
          setTimeout(function () {
            document.querySelector('.txt-input').innerHTML = component.mainTitle;
          }, 0);
        }
      },
      onSelectField: function onSelectField(field) {// const dataCollectorsSelect = this.get('selectedComponent.dataCollectorsSelect');
        // dataCollectorsSelect.pushObject(field);
        // this.get('dataCollectorsSelect').removeObject(field);
        // const hash = {
        //     flowId: this.get('flowId'),
        //     blockId: this.get('selectedComponent.id'),
        //     extraData: JSON.stringify({dataCollectorsSelect}),
        // };
        //
        // this.ajax.query(Mutation.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(() => {
        //     this.updateFlow(this.get('selectedComponent.id'));
        // });
      },
      removeField: function removeField() {},
      deleteComponent: function deleteComponent() {},
      deleteComponentAll: function deleteComponentAll() {},
      duplicatePath: function duplicatePath() {},
      onBlurInputFlow: function onBlurInputFlow() {},
      showSaveStatus: function showSaveStatus() {},
      onBlurInputMessage: function onBlurInputMessage() {},
      addOption: function addOption() {},
      showTemplates: function showTemplates() {
        this.set('isShowTemplates', true);
        this.set('isShowTemplateFlow', false);
      },
      showFlows: function showFlows() {
        this.set('isShowTemplates', false);
        this.set('isShowTemplateFlow', false);
      },
      onFilterFlows: function onFilterFlows(val) {
        this.set('isShowPreview', false);
        this.set('selectedFlowTemplate', null);
        this.set('isShowTemplates', false);
        this.set('isShowTemplateFlow', false);
        this.filterFlows(val);
      },
      clearFilter: function clearFilter() {
        this.set('searchContacts', null);
        this.set('collectionFlows', this.get('cloneFlows'));
      },
      sortFlow: function sortFlow(fieldName) {
        var sortedFlows;
        var flows = this.get('collectionFlows');
        var isReverse = this.get('isReverse');

        if (fieldName === "Assistant Name") {
          if (isReverse.title) {
            sortedFlows = flows.sortBy("title").reverse();
            this.toggleProperty("isReverse.title");
          } else {
            sortedFlows = flows.sortBy("title");
            this.toggleProperty("isReverse.title");
          }
        }

        if (fieldName === "Sent") {
          if (isReverse.triggered) {
            sortedFlows = flows.sortBy("triggered").reverse();
            this.toggleProperty("isReverse.triggered");
          } else {
            sortedFlows = flows.sortBy("triggered");
            this.toggleProperty("isReverse.triggered");
          }
        }

        if (fieldName === "Engaged") {
          if (isReverse.engaged) {
            sortedFlows = flows.sortBy("engaged").reverse();
            this.toggleProperty("isReverse.engaged");
          } else {
            sortedFlows = flows.sortBy("engaged");
            this.toggleProperty("isReverse.engaged");
          }
        }

        if (fieldName === "Completed") {
          if (isReverse.completed) {
            sortedFlows = flows.sortBy("completed").reverse();
            this.toggleProperty("isReverse.completed");
          } else {
            sortedFlows = flows.sortBy("completed");
            this.toggleProperty("isReverse.completed");
          }
        }

        if (fieldName === "Modified On") {
          if (isReverse.updatedDate) {
            sortedFlows = flows.sortBy("updatedDate").reverse();
            this.toggleProperty("isReverse.updatedDate");
          } else {
            sortedFlows = flows.sortBy("updatedDate");
            this.toggleProperty("isReverse.updatedDate");
          }
        }

        this.set('collectionFlows', sortedFlows);
      },
      createFlow: function createFlow() {
        var _this5 = this;

        var hash = {
          title: "Flow".concat(String(Math.random()).slice(2)),
          description: '',
          idleTime: 20,
          timeOut: 20,
          warningMessage: 'Hi, we have not heard anything from you for the past 20 seconds, ' + 'please type something to remain active in chat',
          timeOutMessage: 'This chat session has been terminated due to time-out, we are still here with you if you need anything',
          isActive: false,
          auto: true,
          type: 1,
          companyProperty: this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref4) {
          var flow = _ref4.flow;

          _this5.set('awaitCreateFlow', false);

          _this5.paperToaster.show(_this5.intl.t('flow_created'));

          _this5.transitionToRoute('assistants.edit', {
            queryParams: {
              id: flow.id
            }
          });
        });
      },
      createWorkflow: function createWorkflow() {
        var _this6 = this;

        var hash = {
          name: "Workflow".concat(String(Math.random()).slice(2)),
          properties: [this.get('companyId')],
          description: '',
          isActive: false,
          trigger: '',
          objectType: 'TICKET'
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', hash, function (_ref5) {
          var workflow = _ref5.workflow;

          _this6.set('awaitCreateFlow', false);

          _this6.paperToaster.show(_this6.intl.t('flow_created'));

          _this6.transitionToRoute('assistants.edit', {
            queryParams: {
              id: workflow.id
            }
          });
        });
      },
      statusChangedItemFlow: function statusChangedItemFlow(flow) {
        var _this7 = this;

        this.set('selectedFlow', flow);

        if (flow.type === CHATBOT) {
          this.set('isDisabledFlow', flow.isDisabled);
          this.set('flowName', flow.title);

          if (!flow.isActive) {
            Ember.set(flow, 'isActive', false);
            this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
          } else {
            var hash = {
              flowId: flow.id,
              isActive: !flow.isActive
            };
            Ember.set(flow, 'isActive', !flow.isActive);
            this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
              var status = flow.isActive ? 'activate_flow' : 'deactivate_flow';

              _this7.paperToaster.show(_this7.intl.t(status, {
                name: flow.title
              }));

              _this7.queryFlows();
            });
          }
        } else {
          this.set('isDisabledFlow', !flow.isValid);
          this.set('flowName', flow.name);

          if (!flow.isActive) {
            Ember.set(flow, 'isActive', false);
            this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
          } else {
            var _hash = {
              workflowId: flow.id,
              isActive: !flow.isActive
            };
            Ember.set(flow, 'isActive', !flow.isActive);
            this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', _hash).then(function () {
              var status = flow.isActive ? 'activate_flow' : 'deactivate_flow';

              _this7.paperToaster.show(_this7.intl.t(status, {
                name: flow.name
              }));

              _this7.queryFlows();
            });
          }
        }
      },
      togglePopupActivateFlow: function togglePopupActivateFlow() {
        this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
      },
      togglePopupDeleteFlow: function togglePopupDeleteFlow(flow) {
        if (flow) this.set('selectedFlow', flow);
        this.set('showPopupDeleteFlow', !this.get('showPopupDeleteFlow'));
      },
      togglePopupCopyFlow: function togglePopupCopyFlow(flow) {
        if (flow) this.set('selectedFlow', flow);
        this.set('showPopupCopyFlow', !this.get('showPopupCopyFlow'));
      },
      deleteFlow: function deleteFlow() {
        var _this8 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);

        if (selectedFlow.type === 1) {
          this.ajax.query(_mutation.default.DELETE_FLOW, 'deleteFlow', {
            flowId: selectedFlow.id
          }).then(function () {
            _this8.set('showPopupDeleteFlow', false);

            _this8.paperToaster.show(_this8.intl.t('delete_flow', {
              name: selectedFlow.title
            }));

            _this8.queryFlows();
          });
        } else {
          this.ajax.query(_mutation.default.DELETE_WORKFLOW, 'deleteWorkflow', {
            workflowId: selectedFlow.id
          }).then(function () {
            _this8.set('showPopupDeleteFlow', false);

            _this8.paperToaster.show(_this8.intl.t('delete_flow', {
              name: selectedFlow.title
            }));

            _this8.queryFlows();
          });
        }
      },
      copyFlow: function copyFlow() {
        var _this9 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);
        this.ajax.query(_mutation.default.DUPLICATE_FLOW, 'duplicateFlow', {
          flowId: selectedFlow.id
        }).then(function () {
          _this9.set('showPopupCopyFlow', false);

          _this9.paperToaster.show(_this9.intl.t('duplicate_flow', {
            name: selectedFlow.title
          }));

          _this9.queryFlows();
        });
      },
      activateFlow: function activateFlow() {
        var _this10 = this;

        var selectedFlow = this.get('selectedFlow');

        if (selectedFlow.type === 1) {
          if (!this.get('isDisabledFlow')) {
            var hash = {
              flowId: selectedFlow.id,
              isActive: !selectedFlow.isActive
            };
            Ember.set(selectedFlow, 'isActive', !selectedFlow.isActive);
            this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
              var status = selectedFlow.isActive ? 'activate_flow' : 'deactivate_flow';

              _this10.paperToaster.show(_this10.intl.t(status, {
                name: selectedFlow.title
              }));

              _this10.queryFlows();
            });
          }
        } else {
          var _hash2 = {
            workflowId: selectedFlow.id,
            isActive: !selectedFlow.isActive
          };
          Ember.set(selectedFlow, 'isActive', !selectedFlow.isActive);
          this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', _hash2).then(function () {
            var status = _hash2.isActive ? 'activate_workflow' : 'deactivate_workflow';

            _this10.paperToaster.show(_this10.intl.t(status, {
              name: selectedFlow.name
            }));

            _this10.queryFlows();
          });
        }
      }
    },
    queryFlows: function queryFlows() {
      var _this11 = this;

      var hash = {
        properties: [this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id]
      };
      var flows = [];
      this.set('isLoader', true);
      var requests = [this.ajax.query(_query.default.FLOWS).then(function (data) {
        !data.errors && data.forEach(function (flow) {
          if (flow.extraData) {
            var extraData = JSON.parse(flow.extraData);
            flow.isDisabled = Boolean(extraData.isDisabled);
            flow.isTemplate = Boolean(extraData.isTemplate);
          }

          flows.addObject(flow);
        });

        _this11.set('flows', flows);
      }), this.ajax.query(_query.default.WORKFLOWS, 'workflows', hash).then(function (data) {
        var workflows = data.map(function (item) {
          return _objectSpread({
            title: item.name,
            updatedDate: item.updatedAt
          }, item);
        });
        !data.errors && workflows.forEach(function (workflow) {
          flows.addObject(workflow);
        });

        _this11.set('workFlows', workflows);
      })];
      return Ember.RSVP.all(requests).then(function () {
        var cloneFlows = flows.filter(function (f) {
          return !f.isTemplate;
        });
        var templateFlows = flows.filter(function (f) {
          return f.isTemplate;
        });

        _this11.set('templateFlows', templateFlows);

        _this11.set('cloneFlows', cloneFlows);

        _this11.set('collectionFlows', _this11.get('sliceData').getSliceData(cloneFlows, 1, FLOWSPERPAGE));

        _this11.set('rangeNum', _this11.get('sliceData').calculateRange(cloneFlows, FLOWSPERPAGE)); //TODO move calculations to pagination component?


        _this11.set('pageNum', _this11.get('sliceData').getPageNum());

        _this11.set('isLoader', false);

        _this11.filterFlows('flow');
      });
    }
  });

  _exports.default = _default;
});