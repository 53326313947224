define("inboxfront/templates/components/dashboard/panels/kb-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fjnnS+YB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dashboard/panel-loader\",null,[[\"isLoading\"],[[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"kb_performance\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"style\",\"display: flex; justify-content: center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"kb_perf\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"kb_views\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panels/kb-performance.hbs"
    }
  });

  _exports.default = _default;
});