define("inboxfront/templates/components/common/form-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0KkAddU6",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"field-phone\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"dropdownClass\",\"placeholder\",\"selected\",\"options\",\"searchEnabled\",\"searchPlaceholder\",\"searchField\",\"onChange\"],[\"select-code-phones\",\" \",[24,[\"selectedCode\"]],[24,[\"selectCodePhones\"]],true,[28,\"t\",[\"search\"],null],\"name\",[28,\"action\",[[23,0,[]],\"selectCodePhone\"],null]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"fib fi-\",[28,\"lowercase\",[[23,1,[\"code\"]]],null]]]],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"code\"],[8],[1,[23,1,[\"dial_code\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"value\",\"onKeyDown\",\"onKeyUp\",\"onChange\",\"onBlur\"],[[24,[\"numberPhone\"]],[28,\"action\",[[23,0,[]],\"onKeyDown\"],null],[28,\"action\",[[23,0,[]],\"onKeyUp\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"numberPhone\"]]],null]],null],[28,\"action\",[[23,0,[]],\"onBlurInputPhone\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/form-phone.hbs"
    }
  });

  _exports.default = _default;
});