define("inboxfront/services/customfields", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('fieldTypes', [{
        id: 'SINGLE-LINE',
        type: 'SINGLE_LINE',
        label: this.intl.t('custom_fields.single_line'),
        description: this.intl.t('custom_fields.single_line_desc'),
        iconColor: '#e8f9f5',
        icon: 'cf-single-line'
      }, {
        id: 'MULTI-LINE',
        type: 'MULTI_LINE',
        label: this.intl.t('custom_fields.multi_line'),
        description: this.intl.t('custom_fields.multi_line_desc'),
        iconColor: '#e8f9f5',
        icon: 'cf-multi-line'
      }, {
        id: 'RICH-TEXT',
        type: 'RICH_TEXT',
        label: this.intl.t('custom_fields.rich_text'),
        description: this.intl.t('custom_fields.rich_text_desc'),
        iconColor: '#e8f9f5',
        icon: 'cf-rich-text'
      }, {
        id: 'NUMBER',
        type: 'NUMBER',
        label: this.intl.t('custom_fields.number'),
        description: this.intl.t('custom_fields.number_desc'),
        iconColor: '#fef9e2',
        icon: 'cf-number'
      }, {
        id: 'DECIMAL',
        type: 'DECIMAL',
        label: this.intl.t('custom_fields.decimals'),
        description: this.intl.t('custom_fields.decimals_desc'),
        iconColor: '#fef9e2',
        icon: 'cf-decimal'
      }, {
        id: 'BOOLEAN',
        type: 'BOOLEAN',
        label: this.intl.t('custom_fields.boolean'),
        description: this.intl.t('custom_fields.boolean_desc'),
        iconColor: '#fdecee',
        icon: 'cf-boolean'
      }, {
        id: 'SELECTION',
        type: 'SELECTION',
        label: this.intl.t('custom_fields.selection'),
        description: this.intl.t('custom_fields.selection_desc'),
        iconColor: '#fdecee',
        icon: 'cf-select'
      }, {
        id: 'DATE',
        type: 'DATE',
        label: this.intl.t('custom_fields.date'),
        description: this.intl.t('custom_fields.date_desc'),
        iconColor: '#e4f2fd',
        icon: 'cf-date',
        iconInnerColor: '#90caf9'
      }]);
    },

    get types() {
      return this.get('fieldTypes');
    },

    prepare: function prepare(systemFields, settings) {
      var orders;

      try {
        orders = JSON.parse(settings.orders);
      } catch (e) {
        orders = {};
      }

      var updated;

      try {
        updated = JSON.parse(settings.updated);
      } catch (e) {
        updated = {};
      }

      var fieldTypes = this.get('fieldTypes');

      var fields = _toConsumableArray(systemFields);

      fields.forEach(function (f) {
        f.isSystem = true;
        f.parentField = null;
        f.parentSection = null;
        f.children = [];
        f.sections = [];
        f.position = orders[f.id] !== undefined ? orders[f.id] : 0;
        f.type = fieldTypes.findBy('type', f.fieldType) || {
          id: 'group'
        };

        if (f.updateable && updated[f.id] !== undefined) {
          f.required = updated[f.id];
        }

        if (f.fieldType === 'GROUP') {
          f.subfields.forEach(function (sf) {
            sf.isSystem = true;
            sf.parentField = null;
            sf.parentSection = null;
            sf.children = [];
            sf.sections = [];
            sf.position = orders[sf.id] !== undefined ? orders[sf.id] : 0;
            sf.type = fieldTypes.findBy('type', sf.fieldType);
          });
        }
      });
      settings.customfieldSet.forEach(function (f) {
        f.type = fieldTypes.findBy('type', f.fieldType);
        var children = [];
        f.children.forEach(function (c) {
          var child = settings.customfieldSet.findBy('id', c.id);

          if (child) {
            child.position = orders[child.id] !== undefined ? orders[child.id] : 0;
            children.push(child);
          }
        });

        if ((f.fieldType === 'BOOLEAN' || f.fieldType === 'SELECTION') && children.length < 2) {
          children.push({
            parentField: f,
            type: fieldTypes[0]
          });
          children.push({
            parentField: f,
            type: fieldTypes[0]
          });
          children = children.slice(0, 2);
        }

        f.children = children.sortBy('position');
        f.sections = [];

        if (f.parentField === null && f.parentSection === null) {
          fields.push(f);
        }

        f.position = orders[f.id] !== undefined ? orders[f.id] : 0;
      });
      settings.sectionSet.forEach(function (s) {
        var parentField;
        var conditions = [];
        s.fields.forEach(function (c) {
          var f = settings.customfieldSet.findBy('id', c.id);

          if (f) {
            conditions.push(f);

            if (!parentField && f.parentField) {
              parentField = fields.findBy('id', f.parentField.id);

              if (parentField) {
                (parentField.sections = parentField.sections || []).addObject(s);
                s.parentField = parentField;
              }
            }
          }
        });
        var subfields = [];
        s.customfieldSet.forEach(function (c) {
          var f = settings.customfieldSet.findBy('id', c.id);

          if (f) {
            f.parentSection = s;
            subfields.push(f);
          }
        });
        subfields = subfields.sortBy('position');
        s.subfields = subfields;
        s.subfields.parentSection = s;
        s.conditions = conditions;
      });
      fields = fields.sortBy('position');
      return fields;
    },
    deleteField: function deleteField(field, callback) {
      return this.ajax.mutation(_mutation.default.DELETE_CUSTOM_FIELD, 'deleteCustomField', {
        id: field.id
      }, callback);
    },
    updateField: function updateField(field, settingsId, callback) {
      var hash = {
        label: field.label,
        description: field.description,
        required: !!field.required,
        mainEntityId: settingsId
      };

      if (field.parentField) {
        hash.parentFieldId = field.parentField.id;
      }

      if (field.parentSection) {
        hash.parentSectionId = field.parentSection.id;
      }

      if (field.id !== undefined) {
        hash.id = field.id;
      } else {
        hash.fieldType = field.type.id;
      }

      return this.ajax.mutation(_mutation.default.CREATE_UPDATE_CUSTOM_FIELD, 'createUpdateCustomField', hash, callback);
    },
    validateField: function validateField(field) {
      var _this = this;

      var isUpdated = field._edited.label !== field.label || field._edited.description !== field.description || field._edited.required !== field.required;
      var isValid = this.get('validation').trimValidation[0].validate(field._edited.label) && field._edited.label.length > 2;
      var choiceCount;

      switch (field.fieldType) {
        case 'SELECTION':
          choiceCount = 0;
          field.children.forEach(function (choice) {
            if (choice.deleted) {
              isUpdated = true;
            } else {
              choiceCount++;
            }
          });
          Ember.set(field, 'choiceCount', choiceCount);
        // eslint-disable-next-line no-fallthrough

        case 'BOOLEAN':
          field.children.forEach(function (choice) {
            if (!isValid) return;

            if (!_this.get('validation').trimValidation[0].validate(choice.editLabel)) {
              isValid = false;
            } else {
              choice.updated = choice.editLabel !== choice.label;

              if (choice.updated && !isUpdated) {
                isUpdated = true;
              }
            }
          });
      }

      Ember.set(field, 'isUpdated', isUpdated);
      Ember.set(field, 'isValid', isUpdated && isValid);
    },
    deleteSection: function deleteSection(section, callback) {
      this.ajax.mutation(_mutation.default.DELETE_FIELD_SECTION, 'deleteSection', {
        id: section.id
      }, callback);
    },
    updateSection: function updateSection(section, settingsId, callback) {
      var hash = {
        title: section.title,
        conditionFields: section.conditions.map(function (c) {
          return c.id;
        }),
        mainEntityId: settingsId
      };

      if (section.id !== undefined) {
        hash.id = section.id;
      }

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FIELD_SECTION, 'createUpdateSection', hash, callback);
    },
    updateOrders: function updateOrders(fields, settingsId) {
      try {
        var orders = {};
        fields.forEach(function (f, index) {
          orders[f.id] = index;
          (f.children || []).forEach(function (c, index) {
            orders[c.id] = index;
          });
          f.sections && f.sections.forEach(function (s) {
            s.subfields.forEach(function (f, index) {
              orders[f.id] = index;
              (f.children || []).forEach(function (c, index) {
                orders[c.id] = index;
              });
            });
          });
        });
        var hash = {
          orders: JSON.stringify(orders)
        };
        return this._updateSettings(hash, settingsId);
      } catch (e) {
        console.error(e);
      }
    },
    updateSystemFields: function updateSystemFields(fields, settingsId) {
      try {
        var updated = {};
        fields.forEach(function (f) {
          if (f.isSystem && f.updateable) {
            updated[f.id] = f.required;
          }
        });
        var hash = {
          updated: JSON.stringify(updated)
        };
        return this._updateSettings(hash, settingsId);
      } catch (e) {
        console.error(e);
      }
    },
    _updateSettings: function _updateSettings(hash, settingsId) {
      Ember.set(hash, 'id', settingsId);
      return this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
    }
  });

  _exports.default = _default;
});