define("inboxfront/controllers/application", ["exports", "inboxfront/config/environment", "inboxfront/gql/mutation"], function (_exports, _environment, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    isCollapsed: false,
    isLoading: true,
    isToastOpenNote: false,
    ws: Ember.observer('userID', function () {
      var _this2 = this;

      //eslint-disable-line
      var account = this.get('sessionAccount.loggeduser.notificationSettings');
      this.callWebSocket(this.get('userID'));

      if (Notification.permission !== 'denied' && !account.active && account.snooze === null) {
        Ember.run.later(function () {
          _this2.set('isToastOpenNote', true);
        }, 5000);
        /*later((() => {
            this.set('isToastOpenNote', false);
        }), 20000);*/
      }
    }),
    callWebSocket: function callWebSocket(userId) {
      var _this3 = this;

      if (userId) {
        var webSocket = new WebSocket("".concat(_environment.default.socketUrlNote).concat(userId, "/"));

        webSocket.onmessage = function (event) {
          var packet;

          try {
            packet = JSON.parse(event.data);
          } catch (e) {
            throw Error('error parse');
          }

          _this3.showNotification(packet);
        };

        webSocket.onclose = function (e) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
          setTimeout(function () {
            _this3.callWebSocket();
          }, 1000);
        };

        webSocket.onerror = function (err) {
          console.error('Socket encountered error: ', err.message, 'Closing socket');
          webSocket.close();
        };
      }
    },
    showNotification: function showNotification(msg) {
      var audio = new Audio('/assets/audio/all-eyes-on-me.mp3');
      audio.play();
      new Notification(msg.message, {
        body: msg.extra_data !== null ? msg.extra_data : '',
        icon: ''
      });
    },
    actions: {
      sendRequestNotification: function sendRequestNotification() {
        var _this = this;

        var hash = {
          active: true,
          messages: true,
          escalations: true,
          tasks: true,
          sound: true
        };
        this.set('isToastOpenNote', false);
        Notification.requestPermission(function (permission) {
          if (permission === 'granted') {
            _this.ajax.query(_mutation.default.UPDATE_NOTIFICATION_SETTINGS, 'updateNotificationSettings', hash).then();
          }
        });
      },
      closeToast: function closeToast() {
        this.set('isToastOpenNote', false);
      }
    }
  });

  _exports.default = _default;
});