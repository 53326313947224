define("inboxfront/routes/users/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    users: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var id = _ref.id;

      if (!id) {
        this.transitionTo('users.index');
      }

      var users = this.ajax.query(_query.default.COMPANY);
      var user = this.ajax.query(_query.default.GET_USER, 'getUser', {
        profileId: id
      });
      var customRoles = this.ajax.query(_query.default.CUSTOM_ROLES);
      return Ember.RSVP.all([users, user, customRoles]).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 3),
            users = _ref3[0],
            user = _ref3[1],
            customRoles = _ref3[2];

        if (user.errors) {
          return _this.transitionTo('users');
        }

        if (!user.systemGroup) {
          user.systemGroup = {};
        }

        users = !users.errors ? users.staffProfiles : [];
        customRoles = !customRoles.errors ? customRoles : [];
        return {
          user: user,
          users: users,
          customRoles: customRoles
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('user', model.user);
      controller.set('customRoles', model.customRoles);
      controller.set('systemRoles', this.users.systemRoles);
    }
  });

  _exports.default = _default;
});