define("inboxfront/controllers/tasks/board", ["exports", "inboxfront/controllers/tasks/main"], function (_exports, _main) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _main.default.extend({
    hideLoading: false,
    actions: {
      deleteTasks: function deleteTasks() {
        var deletedTasks = this.get('deletedTasks');
        var selectedTasks = this.getSelectedTasks();
        this.get('tasks').delete(selectedTasks).then(function () {
          selectedTasks.toArray().forEach(function (t) {
            t._parentList.removeObject(t);

            deletedTasks.pushObject(t);
            selectedTasks.removeObject(t);
            Ember.set(t, 'status', 'DELETED');
            Ember.set(t, 'selected', false);
            Ember.set(t, '_parentList', deletedTasks);
          });
        });
        this.set('selectedTask', null);
        this.set('showDeleteDialog', false);
      },
      archiveTasks: function archiveTasks() {
        var tasksService = this.get('tasks');
        var archivedTasks = this.get('archivedTasks');
        var selectedTasks = this.get('selectedTasks');
        selectedTasks.toArray().forEach(function (t) {
          t._parentList.removeObject(t);

          archivedTasks.pushObject(t);
          selectedTasks.removeObject(t);
          Ember.set(t, 'status', 'ARCHIVED');
          Ember.set(t, 'selected', false);
          Ember.set(t, '_parentList', archivedTasks);
          tasksService.save(t);
        });
      },
      closeTasks: function closeTasks() {
        var tasksService = this.get('tasks');
        var closedTasks = this.get('closedTasks');
        var selectedTasks = this.get('selectedTasks');
        selectedTasks.toArray().forEach(function (t) {
          t._parentList.removeObject(t);

          closedTasks.pushObject(t);
          selectedTasks.removeObject(t);
          Ember.set(t, 'status', 'CLOSED');
          Ember.set(t, 'selected', false);
          Ember.set(t, '_parentList', closedTasks);
          tasksService.save(t);
        });
      },
      showWatcherDialog: function showWatcherDialog(task) {
        if (this.panelShown()) {
          return;
        }

        this.set('selectedTask', task);
        this.set('showWatcherPanel', true);
      }
    },
    updateTasks: function updateTasks(tasks, openTasks) {
      var _this = this;

      this.set('hideLoading', false);
      this.deselectTasks();
      var users = this.get('model.propertyUsers');
      var fields = this.get('model.fields');
      var tasksService = this.get('tasks');
      var options = this.get('selectedFilterOptions');

      if (this.get('filterApplied')) {
        options = this.get('filterData');
      }

      tasksService.filter(options, function (result) {
        tasks = tasks || [];
        openTasks = openTasks || [];
        var inProgressTasks = [];
        var resolvedTasks = [];
        var closedTasks = [];
        var archivedTasks = [];
        var deletedTasks = [];
        result.edges.forEach(function (edge) {
          var task = edge.node;

          if (task) {
            //TODO remove condition, temporarily created for new task settings
            task = tasksService.deserialize(fields, task, users);
            task.selected = false;

            switch (task.status) {
              case 'OPEN':
              case 'RE_OPENED':
                openTasks.push(task);
                task._parentList = openTasks;
                break;

              case 'IN_PROGRESS':
                inProgressTasks.push(task);
                task._parentList = inProgressTasks;
                break;

              case 'RESOLVED':
                resolvedTasks.push(task);
                task._parentList = resolvedTasks;
                break;

              case 'CLOSED':
                closedTasks.push(task);
                task._parentList = closedTasks;
                break;

              case 'ARCHIVED':
                archivedTasks.push(task);
                task._parentList = archivedTasks;
                break;

              case 'DELETED':
                deletedTasks.push(task);
                task._parentList = deletedTasks;
                break;
            }

            tasks.push(task);
          }
        });

        if (_this.get('selectedFilterOptions.status') === 'OPEN') {
          _this.set('selectedFilterOptions.status', "RE_OPENED");

          return _this.updateTasks(tasks, openTasks);
        }

        _this.set('model.startCursor', result.pageInfo.startCursor);

        _this.set('model.endCursor', result.pageInfo.endCursor);

        _this.set('model.isNextDisabled', !result.pageInfo.hasNextPage);

        _this.set('model.isPrevDisabled', !result.pageInfo.hasPreviousPage);

        _this.set('taskList', tasks);

        openTasks.status = 'RE_OPENED';
        inProgressTasks.status = 'IN_PROGRESS';
        resolvedTasks.status = 'RESOLVED';
        closedTasks.status = 'CLOSED';
        archivedTasks.status = 'ARCHIVED';
        deletedTasks.status = 'DELETED';

        _this.set('openTasks', openTasks);

        _this.set('inProgressTasks', inProgressTasks);

        _this.set('resolvedTasks', resolvedTasks);

        _this.set('closedTasks', closedTasks);

        _this.set('archivedTasks', archivedTasks);

        _this.set('deletedTasks', deletedTasks);

        _this.set('selectedTasks', []);

        _this.set('hideLoading', true);
      }, this.get('filterApplied'));
    }
  });

  _exports.default = _default;
});