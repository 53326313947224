define("inboxfront/templates/components/common/dialogs/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QCvJyw2Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[4,\"paper-dialog\",null,[[\"open\",\"clickOutsideToClose\",\"class\",\"onClose\"],[[24,[\"open\"]],[24,[\"clickOutsideToClose\"]],[28,\"concat\",[\"warning-dialog \",[24,[\"class\"]]],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"                \"],[1,[28,\"svg-icon\",[[28,\"or\",[[24,[\"icon\"]],\"alert-triangle\"],null]],null],false],[0,\"\\n                \"],[14,1,[[28,\"hash\",null,[[\"title\"],[[28,\"component\",[\"common/dialogs/title\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"content-title\"],[8],[1,[28,\"t\",[\"things_to_remember\"],null],false],[0,\":\"],[9],[0,\"\\n            \"],[14,1,[[28,\"hash\",null,[[\"body\"],[[28,\"component\",[\"common/dialogs/content-body\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-actions\",null,null,{\"statements\":[[0,\"            \"],[14,1,[[28,\"hash\",null,[[\"btn-cancel\",\"btn-confirm\"],[[28,\"component\",[\"common/buttons/cancel\"],[[\"action\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],[28,\"component\",[\"common/buttons/confirm\"],[[\"action\",\"class\"],[[28,\"action\",[[23,0,[]],\"confirm\"],null],\"pull-right\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/dialogs/warning.hbs"
    }
  });

  _exports.default = _default;
});