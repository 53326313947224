define("inboxfront/components/dashboard/panels/quick-responses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['dashboard-panel'],
    countLimitShow: 5,
    // leftCount: 3,
    isShow: false,
    responses: Ember.computed('pageName', 'listDateMin', 'realData', function () {
      var realData = this.get('realData');

      if (realData) {
        return [];
      }

      return [{
        name: 'Shipping timefrimes',
        sent: 10,
        total: 2,
        percentage: 1
      }, {
        name: 'Shipping timefrimes2',
        sent: 8,
        total: 1,
        percentage: 1
      }, {
        name: 'Shipping timefrimes3',
        sent: 5,
        total: 1,
        percentage: 1
      }, {
        name: 'Shipping timefrimes4',
        sent: 5,
        total: 1,
        percentage: 1
      }, {
        name: 'Shipping timefrimes5',
        sent: 4,
        total: 1,
        percentage: 0
      }, {
        name: 'Shipping timefrimes6',
        sent: 2,
        total: 0,
        percentage: 0
      }, {
        name: 'Shipping timefrimes7',
        sent: 1,
        total: 0,
        percentage: 0
      }, {
        name: 'Shipping timefrimes8',
        sent: 0,
        total: 0,
        percentage: 0
      }, {
        name: 'Shipping timefrimes9',
        sent: 0,
        total: 0,
        percentage: 0
      }];
    }),
    leftCount: Ember.computed('responses', function () {
      var resps = this.get('responses');
      var res = resps.length - this.get('countLimitShow');
      return res > 0 ? res : 0;
    }),
    actions: {
      showFull: function showFull() {
        this.set('countLimitShow', this.get('isShow') ? 5 : this.responses.length);
        this.set('isShow', !this.get('isShow'));
      }
    }
  });

  _exports.default = _default;
});