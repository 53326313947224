define("inboxfront/components/input-chat", ["exports", "autolinker", "inboxfront/utils/regulars"], function (_exports, _autolinker, _regulars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.exec = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var exec = function exec(command) {
    var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return document.execCommand(command, false, value);
  };

  _exports.exec = exec;

  var _default = Ember.Component.extend({
    tagName: '',
    placeholder: '',
    inputClass: '',
    currentUrl: '',
    showEditor: false,
    isShowTextEditor: true,
    currentText: Ember.computed('msgBody', function () {
      var target = document.querySelector('.txt-input');

      if (!this.get('msgBody')) {
        target && (target.innerHTML = '');
      }

      target && (target.innerHTML += this.get('msgBody'));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var input = document.querySelector('.txt-input');

      if (input) {
        input.innerHTML = '';
        input.addEventListener('input', function (event) {
          var _parseHTMLtoURL$, _parseHTMLtoURL$$url, _this$get, _parseHTMLtoURL$2;

          var parseHTMLtoURL = _autolinker.default.parse(input.innerHTML, {
            urls: true
          });

          if (((_parseHTMLtoURL$ = parseHTMLtoURL[0]) === null || _parseHTMLtoURL$ === void 0 ? void 0 : (_parseHTMLtoURL$$url = _parseHTMLtoURL$.url) === null || _parseHTMLtoURL$$url === void 0 ? void 0 : _parseHTMLtoURL$$url.match(_regulars.regexURL)) && ((_this$get = _this.get('currentUrl')) === null || _this$get === void 0 ? void 0 : _this$get.trim()) !== ((_parseHTMLtoURL$2 = parseHTMLtoURL[0]) === null || _parseHTMLtoURL$2 === void 0 ? void 0 : _parseHTMLtoURL$2.url.trim())) {
            _this.parseUrl(parseHTMLtoURL[0].url);
          } else if (!parseHTMLtoURL.length || !parseHTMLtoURL[0].url.match(_regulars.regexURL)) {
            _this.set('previewLink', null);

            _this.set('currentUrl', '');
          }

          if (event.target.firstChild && event.target.firstChild.nodeType === 3) {//exec('formatBlock', '<div>')
          } else if (input.innerHTML === '<br>') {
            input.innerHTML = '';
          }

          if (event.inputType === 'deleteContentBackward' && !event.target.innerText) {
            _this.set('mentioned', []);
          }

          _this.set('isMessageEmpty', Boolean(!event.target.innerText));
        });
      }
    },
    parseUrl: function parseUrl(url) {
      var _this2 = this;

      fetch("https://get-link-preview.herokuapp.com/?url=".concat(url)).then(function (response) {
        return response.status >= 200 && response.status < 208 ? response.json() : '';
      }).then(function (data) {
        if (!data) {
          _this2.set('previewLink', null);

          _this2.set('currentUrl', '');

          return;
        }

        _this2.set('currentUrl', url);

        _this2.set('previewLink', data);
      });
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.actionsEditor = [{
        icon: '<b>B</b>',
        title: 'Bold',
        isActive: false,
        state: function state() {
          return _this3.isSelectionTag('B');
        },
        result: function result() {
          return exec('bold');
        }
      }, {
        icon: '<i>I</i>',
        title: 'Italic',
        isActive: false,
        state: function state() {
          return _this3.isSelectionTag('I');
        },
        result: function result() {
          return exec('italic');
        }
      }, {
        icon: '<u>U</u>',
        title: 'Underline',
        isActive: false,
        state: function state() {
          return _this3.isSelectionTag('U');
        },
        result: function result() {
          return exec('underline');
        }
      }, {
        icon: '<s>S</s>',
        title: 'Strike-through',
        isActive: false,
        state: function state() {
          return _this3.isSelectionTag('STRIKE');
        },
        result: function result() {
          return exec('strikeThrough');
        }
      }, {
        icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
        title: 'List 1',
        isActive: false,
        result: function result() {
          return exec('insertOrderedList', '<ol>');
        }
      }, {
        icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.819 4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0-4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0 9.357h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.137zM1.468 4.155V1.33c-.554.404-.926.606-1.118.606a.338.338 0 0 1-.244-.104A.327.327 0 0 1 0 1.59c0-.107.035-.184.105-.234.07-.05.192-.114.369-.192.264-.118.475-.243.633-.373.158-.13.298-.276.42-.438a3.94 3.94 0 0 1 .238-.298C1.802.019 1.872 0 1.975 0c.115 0 .208.042.277.127.07.085.105.202.105.351v3.556c0 .416-.15.624-.448.624a.421.421 0 0 1-.32-.127c-.08-.085-.121-.21-.121-.376zm-.283 6.664h1.572c.156 0 .275.03.358.091a.294.294 0 0 1 .123.25.323.323 0 0 1-.098.238c-.065.065-.164.097-.296.097H.629a.494.494 0 0 1-.353-.119.372.372 0 0 1-.126-.28c0-.068.027-.16.081-.273a.977.977 0 0 1 .178-.268c.267-.264.507-.49.722-.678.215-.188.368-.312.46-.371.165-.11.302-.222.412-.334.109-.112.192-.226.25-.344a.786.786 0 0 0 .085-.345.6.6 0 0 0-.341-.553.75.75 0 0 0-.345-.08c-.263 0-.47.11-.62.329-.02.029-.054.107-.101.235a.966.966 0 0 1-.16.295c-.059.069-.145.103-.26.103a.348.348 0 0 1-.25-.094.34.34 0 0 1-.099-.258c0-.132.031-.27.093-.413.063-.143.155-.273.279-.39.123-.116.28-.21.47-.282.189-.072.411-.107.666-.107.307 0 .569.045.786.137a1.182 1.182 0 0 1 .618.623 1.18 1.18 0 0 1-.096 1.083 2.03 2.03 0 0 1-.378.457c-.128.11-.344.282-.646.517-.302.235-.509.417-.621.547a1.637 1.637 0 0 0-.148.187z"/></svg>',
        title: 'List 2',
        isActive: false,
        result: function result() {
          return exec('insertUnorderedList', '<ul>');
        }
      }];
    },
    actions: {
      openEditor: function openEditor() {
        this.toggleProperty('isShowTextEditor');
      },
      closePreviewLink: function closePreviewLink() {
        this.set('previewLink', null);
        this.set('currentUrl', '');
      },
      toggleActionBar: function toggleActionBar(action) {
        var input = this.$('.txt-input')[0];
        var selectedAction = this.get('actionsEditor').findBy('title', action.title);

        if (action.state) {
          Ember.set(selectedAction, 'isActive', !action.isActive);
        }

        action.result() && input.focus();
      },
      onBlur: function onBlur() {
        this.set('isFocus', false);
      },
      onKeyUp: function onKeyUp(event) {
        if (event.keyCode === 13 && !event.target.textContent.trim()) {
          event.preventDefault();
          event.target.innerHTML = '';
        }

        if (event.target.textContent.trim()) {
          this.keyUpEventInput(event.keyCode, event.key);
        }

        this.saveSelection();
        this.toggleActions();
      },
      onMouseUp: function onMouseUp() {
        this.set('isFocus', true);
        this.saveSelection();
        this.toggleActions();
      },
      openCalendar: function openCalendar() {
        this.flatpickrRef.toggle();
      },
      updateDateRange: function updateDateRange(date) {
        this.updateDateRange(date);
      },
      openPopupUsers: function openPopupUsers() {
        this.openPopupUsers();
      },
      openKBSearch: function openKBSearch() {
        this.openKBSearch();
      },
      openReply: function openReply() {
        this.openReply();
      },
      sendMessage: function sendMessage() {
        this.sendMessage();
      },
      onSelectEmoji: function onSelectEmoji(emoji) {
        var sel, range;
        var selection = this.get('selection');
        var elementEmoji = document.createElement('div');
        elementEmoji.classList.add('emoji');
        elementEmoji.setAttribute('contenteditable', false);
        elementEmoji.style.backgroundPosition = emoji.x + ' ' + emoji.y;

        if (window.getSelection) {
          sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(selection);

          if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(elementEmoji);
          }
        } else if (sel && sel.createRange) {
          sel.createRange().text = "elementEmoji";
        }
      }
    },
    saveSelection: function saveSelection() {
      if (window.getSelection) {
        var sel = window.getSelection();

        if (sel.getRangeAt && sel.rangeCount) {
          var selection = sel.getRangeAt(0);
          this.set('selection', selection);
        }
      } else if (document.selection && document.selection.createRange) {
        this.set('selection', document.selection.createRange());
      }
    },
    isSelectionTag: function isSelectionTag(tag) {
      var currentNode = window.getSelection().focusNode;

      while (currentNode && currentNode.id !== 'editor') {
        if (currentNode.tagName === tag) return true;
        currentNode = currentNode.parentNode;
      }

      return false;
    },
    toggleActions: function toggleActions() {
      var mappingActions = this.get('actionsEditor').map(function (a) {
        return _objectSpread(_objectSpread({}, a), {}, {
          isActive: a.state ? a.state() : a.isActive
        });
      });
      this.set('actionsEditor', mappingActions);
    }
  });

  _exports.default = _default;
});