define("inboxfront/routes/crm", ["exports", "inboxfront/routes/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    queryParams: {
      error: {},
      state: {},
      code: {
        refreshModel: true,
        replace: false
      }
    },
    model: function model(params) {
      window.opener && window.opener.onAuth && window.opener.onAuth(params);
    }
  });

  _exports.default = _default;
});