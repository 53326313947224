define("inboxfront/components/chat/rooms-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    chats: Ember.inject.service(),
    tagName: '',
    unassignedOpen: true,
    assignedOpen: true,
    otherOpen: false,
    teamsOpen: true,
    directOpen: true,
    SHOW_TEAMS: 5,
    SHOW_DM: 5,
    init: function init() {
      this._super();

      this.userId = this.get('sessionAccount.loggeduser.pk');
    },
    unassignedThreads: Ember.computed('threads', function () {
      return (this.get('threads') || []).filter(function (_ref) {
        var node = _ref.node;
        return !node.agent;
      }).sort(this.sortThreadsByDate);
    }),
    unassignedCount: Ember.computed('unassignedThreads', function () {
      if (this.get('unassignedThreads')) {
        return this.get('unassignedThreads').filter(function (_ref2) {
          var node = _ref2.node;
          return node.unreadCount !== 0;
        }).length;
      }

      return 0;
    }),
    openThreads: Ember.computed('threads', function () {
      var _this = this;

      return (this.get('threads') || []).filter(function (_ref3) {
        var _node$agent$agentProf;

        var node = _ref3.node;
        return node.agent !== null && ((_node$agent$agentProf = node.agent.agentProfile) === null || _node$agent$agentProf === void 0 ? void 0 : _node$agent$agentProf.user.pk) === _this.userId;
      }).sort(this.sortThreadsByDate);
    }),
    openCount: Ember.computed('openThreads', function () {
      if (this.get('openThreads')) {
        return this.get('openThreads').filter(function (_ref4) {
          var node = _ref4.node;
          return node.unreadCount !== 0;
        }).length;
      }

      return 0;
    }),
    otherThreads: Ember.computed('threads', function () {
      var _this2 = this;

      return (this.get('threads') || []).filter(function (_ref5) {
        var _node$agent$agentProf2;

        var node = _ref5.node;
        return node.agent !== null && ((_node$agent$agentProf2 = node.agent.agentProfile) === null || _node$agent$agentProf2 === void 0 ? void 0 : _node$agent$agentProf2.user.pk) !== _this2.userId;
      }).sort(this.sortThreadsByDate);
    }),
    otherCount: Ember.computed('otherThreads', function () {
      if (this.get('otherThreads')) {
        return this.get('otherThreads').filter(function (_ref6) {
          var node = _ref6.node;
          return node.unreadCount !== 0;
        }).length;
      }

      return 0;
    }),
    filterThreads: Ember.computed('panelActive', function () {
      var threads = this.get('threads');
      var panelActive = this.get('panelActive');

      if (this.get('isChat')) {
        if (threads && threads.length > 0) {
          if (panelActive === 'unassigned') {
            return this.get('unassignedThreads');
          } else if (panelActive === 'starred') {
            return this.get('starredThreads');
          } else if (panelActive === 'assigned') {
            return this.get('openThreads');
          } else if (panelActive === 'other') {
            return this.get('otherThreads');
          }
        } else {
          return [];
        }
      }
    }),
    groupRooms: Ember.computed('chats.rooms.[]', function () {
      return (this.get('chats.rooms') || []).filter(function (_ref7) {
        var isGroup = _ref7.isGroup;
        return isGroup;
      });
    }),
    teamRooms: Ember.computed('groupRooms', 'showAllTeams', function () {
      var rooms = this.get('groupRooms');
      this.checkRoomMessagesIsUnread(rooms);
      return this.get('showAllTeams') ? rooms : rooms.slice(0, this.SHOW_TEAMS);
    }),
    unreadTeamRooms: Ember.computed('teamRooms', 'chats.rooms.check', function () {
      return this.get('teamRooms').some(function (_ref8) {
        var isRead = _ref8.isRead;
        return isRead;
      });
    }),
    privateRooms: Ember.computed('chats.rooms.[]', function () {
      return (this.get('chats.rooms') || []).filter(function (_ref9) {
        var isGroup = _ref9.isGroup;
        return !isGroup;
      });
    }),
    directRooms: Ember.computed('privateRooms', 'showAllDirect', function () {
      var rooms = this.get('privateRooms');
      this.checkRoomMessagesIsUnread(rooms);
      return this.get('showAllDirect') ? rooms : rooms.slice(0, this.SHOW_DM);
    }),
    unreadDirectRooms: Ember.computed('directRooms', 'chats.rooms.check', function () {
      return this.get('directRooms').some(function (_ref10) {
        var isRead = _ref10.isRead;
        return isRead;
      });
    }),
    checkRoomMessagesIsUnread: function checkRoomMessagesIsUnread(rooms) {
      var _this3 = this;

      rooms.map(function (room) {
        var _room$messages;

        Ember.set(room, 'isRead', (_room$messages = room.messages) === null || _room$messages === void 0 ? void 0 : _room$messages.edges.some(function (_ref11) {
          var node = _ref11.node;
          return !node.read && node.user.id !== _this3.userId;
        }));
      });
    },
    sortThreadsByDate: function sortThreadsByDate() {
      return function (a, b) {
        return new Date(b.node.lastActive) - new Date(a.node.lastActive);
      };
    },
    actions: {
      menuThreads: function menuThreads(name) {
        if (name === 'teams' || name === 'direct') {
          this.set('isChat', false);
        } else {
          this.set('isChat', true);
        }

        this.set('panelActive', name);
      },
      onToggle: function onToggle(name) {
        console.log('ON TOGGLE', name, this.get(name + 'Open'), arguments);
      }
    }
  });

  _exports.default = _default;
});