define("inboxfront/components/helpdesk/task-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    allCheckTasks: Ember.computed('selectedTasks.length', 'tasks.[]', function () {
      if (this.get('tasks.length')) {
        return this.get('selectedTasks.length') === this.get('tasks.length');
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});