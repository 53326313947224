define("inboxfront/components/dashboard/panels/avg-resolution-time", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var isChats = this.get('pageName') === 'chats';
      var $elem = (0, _jquery.default)(element.querySelector('#avg_resolution_time')).highcharts({
        chart: {
          type: 'spline',
          height: 315
        },
        title: null,
        yAxis: {
          title: null
        },
        xAxis: {
          title: null,
          categories: []
        },
        legend: {
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top'
        },
        series: [{
          name: 'Prior Period',
          color: isChats ? '#7296C2' : '#96AC87',
          dashStyle: 'Dot',
          data: []
        }, {
          name: 'Current Period',
          color: isChats ? '#2B598A' : '#3F524C',
          data: []
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var prevDataGroups = Array.isArray(this.get('prevDataGroups')) ? this.get('prevDataGroups') : [];
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var prior = [];
      var current = [];
      var series = [prior, current];
      var keyName = this.get('pageName') === 'chats' ? 'avgChatResolutionTime' : 'avgTicketResolutionTime';
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function () {
        if (realData) {
          var data = dataGroups[i] || {};
          var prevData = prevDataGroups[i] || {};
          current.pushObject(data[keyName]);
          prior.pushObject(prevData[keyName]);
        } else {
          current.pushObject(Math.ceil(Math.random() * 50 + 20));
          prior.pushObject(Math.ceil(Math.random() * 50 + 20));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});