define("inboxfront/components/dashboard/pages/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cards: Ember.computed('pageName', 'listDateMin', 'realData', 'dataGroups', 'prevDataGroups', function () {
      var dataGroups = this.get('dataGroups');
      var prevDataGroups = Array.isArray(this.get('prevDataGroups')) ? this.get('prevDataGroups') : [];
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime'];
      var data = {};
      var prevData = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
        prevData[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      prevDataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          prevData[k] += d[k];
        });
      });
      var diffTotalChats = prevData.totalChats === 0 ? 100 : (100 * (data.totalChats - prevData.totalChats) / prevData.totalChats).toFixed(2);
      var diffTotalTickets = prevData.totalTickets === 0 ? 100 : (100 * (data.totalTickets - prevData.totalTickets) / prevData.totalTickets).toFixed(2);
      var diffSatisfactionRating = prevData.satisfactionRating === 0 ? 100 : (100 * (data.satisfactionRating - prevData.satisfactionRating) / prevData.satisfactionRating).toFixed(2);
      var diffAvgChatResolutionTime = prevData.avgChatResolutionTime === 0 ? 100 : (100 * (data.avgChatResolutionTime - prevData.avgChatResolutionTime) / prevData.avgChatResolutionTime).toFixed(2);
      var diffAvgTicketResolutionTime = prevData.avgTicketResolutionTime === 0 ? 100 : (100 * (data.avgTicketResolutionTime - prevData.avgTicketResolutionTime) / prevData.avgTicketResolutionTime).toFixed(2);
      var diffTotalEscalation = prevData.totalEscalation === 0 ? 100 : (100 * (data.totalEscalation - prevData.totalEscalation) / prevData.totalEscalation).toFixed(2);
      var realData = this.get('realData');
      return [{
        title: 'total_chats',
        unit: '',
        icon: 'chat-bubble2',
        colorIcon: '#1D3461',
        colorWrapIcon: '#EDF2FB',
        count: realData ? data.totalChats : Math.ceil(Math.random() * 200),
        dir: (realData ? diffTotalChats < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalChats) : 10.25
      }, {
        title: 'total_tickets',
        unit: '',
        icon: 'two-tickets',
        colorIcon: '#1C634A',
        colorWrapIcon: '#EBF9F4',
        count: realData ? data.totalTickets : Math.ceil(Math.random() * 200),
        dir: (realData ? diffTotalTickets < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalTickets) : 10.25
      }, {
        title: 'chat_satisfaction',
        unit: '',
        icon: 'satisfaction',
        colorIcon: '#697FA3',
        colorWrapIcon: '#F9FAFB',
        count: realData ? data.satisfactionRating : Math.ceil(Math.random() * 80),
        dir: (realData ? diffSatisfactionRating < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffSatisfactionRating) : 10.25
      }, {
        title: 'avg_ticket_resolution_time',
        unit: 'min',
        icon: 'task-completed',
        colorIcon: '#5E886C',
        colorWrapIcon: '#F2F6EF',
        count: realData ? data.avgTicketResolutionTime.toFixed(2) : Math.ceil(Math.random() * 6 + 1),
        dir: (realData ? diffAvgTicketResolutionTime < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffAvgTicketResolutionTime) : 2.5
      }, {
        title: 'avg_chat_resolution_time',
        unit: 'min',
        icon: 'clock-checked',
        colorIcon: '#2B598A',
        colorWrapIcon: '#F7FAFD',
        count: realData ? data.avgChatResolutionTime.toFixed(2) : Math.ceil(Math.random() * 20),
        dir: (realData ? diffAvgChatResolutionTime < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffAvgChatResolutionTime) : 2.5
      }, {
        title: 'escalations',
        unit: '',
        icon: 'clock-alert',
        colorIcon: '#f08f84',
        colorWrapIcon: '#FDF2F1',
        count: realData ? data.totalEscalation : Math.ceil(Math.random() * 80),
        dir: (realData ? diffTotalEscalation < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalEscalation) : 2.5,
        isEscalations: true
      }];
    }),
    cards2: Ember.computed('pageName', 'listDateMin', 'realData', 'dataGroups', 'prevDataGroups', function () {
      var dataGroups = this.get('dataGroups');
      var prevDataGroups = Array.isArray(this.get('prevDataGroups')) ? this.get('prevDataGroups') : [];
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime'];
      var data = {};
      var prevData = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
        prevData[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      prevDataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          prevData[k] += d[k];
        });
      });
      var diffTotalAssistantTriggered = prevData.totalAssistantTriggered === 0 ? 100 : (100 * (data.totalAssistantTriggered - prevData.totalAssistantTriggered) / prevData.totalAssistantTriggered).toFixed(2);
      var diffTotalAssistantTerminated = prevData.totalAssistantTerminated === 0 ? 100 : (100 * (data.totalAssistantTerminated - prevData.totalAssistantTerminated) / prevData.totalAssistantTerminated).toFixed(2); // let diffTotalAssistantTriggered = prevData.totalAssistantTriggered === 0 ? 100 : ( 100 * (data.totalAssistantTriggered - prevData.totalAssistantTriggered) / prevData.totalAssistantTriggered).toFixed(2);

      var realData = this.get('realData');
      return [{
        title: 'chat_bot_initiated',
        unit: '',
        icon: 'chat-bot',
        colorIcon: '#AAB794',
        colorWrapIcon: '#F3F5F0',
        count: realData ? data.totalAssistantTriggered : Math.ceil(Math.random() * 150),
        dir: (realData ? diffTotalAssistantTriggered < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalAssistantTriggered) : 2.5
      }, {
        title: 'chat_bot_response_rate',
        unit: '',
        icon: 'blog',
        colorIcon: '#DBBC8E',
        colorWrapIcon: '#F9F4EC',
        count: realData ? data.totalAssistantTerminated : Math.ceil(Math.random() * 80),
        dir: (realData ? diffTotalAssistantTerminated < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalAssistantTerminated) : 10.25
      }, {
        title: 'chat_bot_completed',
        unit: '',
        icon: 'choose',
        colorIcon: '#7C98AB',
        colorWrapIcon: '#EFF3F5',
        count: realData ? data.totalAssistantTerminated : Math.ceil(Math.random() * 80),
        dir: (realData ? diffTotalAssistantTerminated < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diffTotalAssistantTerminated) : 10.25
      }, {
        title: 'chat_bot_resolution_rate',
        unit: '',
        icon: 'message-bot',
        colorIcon: '#AE8A8C',
        colorWrapIcon: '#F5F0F0',
        count: realData ? 0 : Math.ceil(Math.random() * 80),
        dir: realData ? 0 : Math.random() > 0.5 ? 'down' : 'up',
        prevCount: realData ? 0 : 2.5
      }, {
        title: 'chat_bot_handoff_rate',
        unit: '',
        icon: 'exchange',
        colorIcon: '#698396',
        colorWrapIcon: '#F0F3F4',
        count: realData ? 0 : Math.ceil(Math.random() * 80),
        dir: realData ? 0 : Math.random() > 0.5 ? 'down' : 'up',
        prevCount: realData ? 0 : 2.5
      }, {
        title: 'first_contact_resolution_rate',
        unit: '',
        icon: 'sand-timer',
        colorIcon: '#BA94D1',
        colorWrapIcon: '#F3EDF7',
        count: realData ? 0 : Math.ceil(Math.random() * 80),
        dir: realData ? 0 : Math.random() > 0.5 ? 'down' : 'up',
        prevCount: realData ? 0 : 2.5
      }];
    })
  });

  _exports.default = _default;
});