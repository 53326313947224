define("inboxfront/components/dashboard/channels-rating", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['dashboard-panel'],
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _component = this;

      _highcharts.default.chart({
        chart: {
          height: 315,
          renderTo: 'channels-rating',
          type: 'bar'
        },
        title: {
          text: this.intl.t('messages_by_channel'),
          align: 'left'
        },
        xAxis: {
          type: 'category' //categories: ['Facebook', 'Line', 'Telegram', 'Email', 'Whatsapp', 'Twitter', 'Livechat', 'Wechat']

        },
        yAxis: {
          title: 'count'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: _component.intl.t('channels') + '<b>{point.y:.1f} ' + _component.intl.t('count') + '</b>'
        },
        exporting: {
          enabled: false
        },
        series: [
        /*
        {
        	name: 'Facebook',
        	data: [22],
        	color: '#e92c2c',
        },
        {
        	name: 'Line',
        	data: [1],
        	color: '#ffebeb',
        },
        {
        	name: 'Telegram',
        	data: [6],
        	color: '#f98600',
        },
        {
        	name: 'Email',
        	data: [11],
        	color: '#e5f3ff',
        },
        {
        	name: 'Whatsapp',
        	data: [15],
        	color: '#0085ff',
        },
        {
        	name: 'Twitter',
        	data: [37],
        	color: '#e5f8eb',
        },
        {
        	name: 'Livechat',
        	data: [21],
        	color: '#00ba34',
        },
        {
        	name: 'Wechat',
        	data: [10],
        	color: '#fff5e8',
        },
        */
        {
          name: 'Count',
          data: _component.calcChannels(),
          color: '#0085ff' //colors: ['#e92c2c', '#ffebeb', '#f98600', '#e5f3ff', '#0085ff', '#e5f8eb', '#00ba34', '#fff5e8'],

        }]
      });
    },
    calcChannels: function calcChannels() {
      var comp = this.get('pickGraph');
      var newArr = [];
      var arr = [{
        'count': 0,
        'name': 'Facebook'
      }, {
        'count': 0,
        'name': 'Line'
      }, {
        'count': 0,
        'name': 'Telegram'
      }, {
        'count': 0,
        'name': 'Email'
      }, {
        'count': 0,
        'name': 'Whatsapp'
      }, {
        'count': 0,
        'name': 'Twitter'
      }, {
        'count': 0,
        'name': 'Livechat'
      }, {
        'count': 0,
        'name': 'Wechat'
      }];

      for (var i = 0; i < comp.length; i++) {
        arr[0].count += comp[i].node.totalMsgsFacebook;
        arr[1].count += comp[i].node.totalMsgsLine;
        arr[2].count += comp[i].node.totalMsgsTelegram;
        arr[3].count += comp[i].node.totalMsgsEmail;
        arr[4].count += comp[i].node.totalMsgsWhatsapp;
        arr[5].count += comp[i].node.totalMsgsTwitter;
        arr[6].count += comp[i].node.totalMsgsLivechat;
        arr[7].count += comp[i].node.totalMsgsWechat;
      }

      for (var _i = 0; _i < 8; _i++) {
        var s = ['', 0];
        s[0] = arr[_i].name;
        s[1] = arr[_i].count;
        newArr.push(s);
      }

      return newArr;
    }
  });

  _exports.default = _default;
});