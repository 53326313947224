define("inboxfront/services/users", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    getPropertyUsers: function getPropertyUsers() {
      var _this = this;

      return new Promise(function (resolve) {
        _this.ajax.query(_query.default.PROPERTY_USERS).then(function (res) {
          _this.set('propertyUsers', !res.errors ? res : []);

          resolve(_this.get('propertyUsers'));
        });
      });
    },
    getQuotas: function getQuotas() {
      var _this2 = this;

      return new Promise(function (resolve) {
        _this2.ajax.query(_query.default.QUOTAS).then(function (res) {
          if (res) {
            resolve(res);
          } else {
            resolve({
              tariffPlan: {
                userCountLimit: 25
              },
              userCountUsage: 0
            });
          }
        });
      });
    },

    get systemRoles() {
      return [{
        id: 'admin',
        name: 'user_role_admin'
      }, {
        id: 'owner',
        name: 'user_role_owner'
      }, {
        id: 'member',
        name: 'user_role_member'
      }];
    },

    create: function create() {
      return {
        firstName: '',
        lastName: '',
        withEmail: true,
        email: '',
        userDep: {},
        userProp: {},
        isAgent: true,
        systemRole: this.systemRoles.findBy('id', 'member'),
        selectedRoles: []
      };
    },
    save: function save(user) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        var hash = {
          firstName: user.firstName,
          lastName: user.lastName,
          department: user.userDep.id,
          propertiesId: user.userProp.id,
          isAgent: user.isAgent,
          systemGroup: user.systemRole.id
        };

        if (user.withEmail) {
          hash.email = user.email;
        } else {
          hash.defaultPassword = _this3.generatePassword();
        }

        if (user.systemRole.id === 'member') {
          if (user.selectedRoles.length) {
            hash.customRoles = user.selectedRoles.map(function (role) {
              return role.id;
            });
          }
        }

        _this3.ajax.mutation(_mutation.default.CREATE_USER, 'createUser', hash, function (res) {
          resolve(res.profile.user);
        }, function () {
          reject();
        });
      });
    },
    validateUser: function validateUser(user) {
      var valid = user && user.userProp.id !== undefined;

      if (valid && !user.userDep.id) {
        valid = false;
      }

      user && Ember.set(user, 'isValid', valid);
    },
    generatePassword: function generatePassword() {
      var chars = 'abcdefghijklmnopqrstuvwxyz'.split('');
      var res = [];
      res.push(chars[Math.floor(Math.random() * chars.length)].toUpperCase());

      for (var i = 0; i < 6; i++) {
        res.push(chars[Math.floor(Math.random() * chars.length)]);
      }

      res.push(Math.floor(Math.random() * 10));
      return res.join('');
    }
  });

  _exports.default = _default;
});