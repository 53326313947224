define("inboxfront/templates/components/chat/assign-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xqBgL7QT",
    "block": "{\"symbols\":[\"agent\"],\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],\"onclickOutside\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"apps-popup assign\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"assign_agent\"],null],false],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[1,[28,\"paper-input\",null,[[\"placeholder\",\"value\",\"onChange\"],[[28,\"t\",[\"search\"],null],[24,[\"searchAgents\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"searchAgents\"]]],null]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"popup-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredAgents\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"popup-list-item alignCenter\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"assignUserThread\",[23,1,[]]],null]],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"avatar-block\"],[8],[0,\"\\n                        \"],[1,[28,\"user-avatar\",null,[[\"size\",\"user\"],[30,[23,1,[]]]]],false],[0,\"\\n                        \"],[7,\"span\",true],[11,\"class\",[29,[\"user-status \",[23,1,[\"status\"]]]]],[8],[9],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[1,[28,\"user-fullname\",[[23,1,[]]],null],false],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/chat/assign-agent.hbs"
    }
  });

  _exports.default = _default;
});