define("inboxfront/components/channels/widget-settings", ["exports", "inboxfront/config/environment", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _environment, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    hoursChanged: false,
    isShowPopupApps: false,
    showChannelDialog: false,
    orderType: 'track',
    kbCollection: [],
    uploadSuccessLogo: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);

        if (controller.get('attachmentLogo.id')) {
          controller.deleteAttachment(controller.get('attachmentLogo.id'));
        }

        controller.set('attachmentLogo.id', result.data.file.attachment.id);
        controller.set('attachmentLogo.url', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
      };
    }),
    uploadSuccessLauncher: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);

        if (controller.get('attachmentLauncher.id')) {
          controller.deleteAttachment(controller.get('attachmentLauncher.id'));
        }

        controller.set('attachmentLauncher.id', result.data.file.attachment.id);
        controller.set('attachmentLauncher.url', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
      };
    }),
    uploadSuccessBackground: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);

        if (controller.get('attachmentBackground.id')) {
          controller.deleteAttachment(controller.get('attachmentBackground.id'));
        }

        controller.set('attachmentBackground.id', result.data.file.attachment.id);
        controller.set('attachmentBackground.url', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
      };
    }),
    optionsBrand: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token');
      var propertyId = this.get('widget.companyProperty.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '#logo-upload',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"isWidgetLogo": "true", "appLabel": "companies", "model": "property", "objectId": "' + propertyId + '"}'
        }
      };
    }),
    optionsLauncher: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token');
      var propertyId = this.get('widget.companyProperty.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '#launcher-upload',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"isWidgetLauncher": "true", "appLabel": "companies", "model": "property", "objectId": "' + propertyId + '"}'
        }
      };
    }),
    optionsBackground: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token');
      var propertyId = this.get('widget.companyProperty.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '#back-upload',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"isWidgetBackground": true, "appLabel": "companies", "model": "property", "property": ' + propertyId + ', "objectId": ' + propertyId + '}'
        }
      };
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.responseTimes = {
        AUTOMATIC: 'We will respond as soon as possible',
        MINUTES: 'Typically replies in a few minutes',
        HOURS: 'Typically replies in a few hours',
        DAY: 'Typically replies in a day'
      };
      this.widget = this.get('widget') || {};
      this.orders = [{
        id: 3722426187982,
        name: '#1013',
        createdAt: '2021-09-20T12:33:42+01:00',
        fulfillmentStatus: true
      }, {
        id: 3723216945358,
        name: '#1014',
        createdAt: '2021-10-21T12:32:48+03:00',
        fulfillmentStatus: false
      }, {
        id: 3723218288846,
        name: '#1015',
        createdAt: '2021-11-20T12:33:44+02:00',
        fulfillmentStatus: true
      }, {
        id: 3723482202318,
        name: '#1016',
        createdAt: '2021-12-23T12:33:43+06:00',
        fulfillmentStatus: false
      }, {
        id: 3723491803342,
        name: '#1017',
        createdAt: '2020-10-27T12:32:48+05:00',
        fulfillmentStatus: false
      }];
      this.hours = [{
        on: true,
        from: '08:00',
        to: '17:30'
      }, {
        on: true,
        from: '08:00',
        to: '17:30'
      }, {
        on: true,
        from: '08:00',
        to: '17:30'
      }, {
        on: true,
        from: '08:00',
        to: '17:30'
      }, {
        on: true,
        from: '08:00',
        to: '17:30'
      }, {
        on: false,
        from: '08:00',
        to: '17:30'
      }, {
        on: false,
        from: '08:00',
        to: '17:30'
      }];
      this.apps = [{
        name: 'Shopify',
        icon: 'EC-shopify-logo',
        info: this.get('intl').t('shopify_description')
      }, {
        name: 'BigCommerce',
        icon: 'EC-bigcommerce-logo',
        info: this.get('intl').t('big_commerce_description')
      }];
      this.moreApp = {
        name: this.get('intl').t('explore_more_app'),
        icon: 'icon-home',
        info: this.get('intl').t('explore_more_app_description')
      };
      this.workingHoursCopy = this.get('workingHours') ? JSON.parse(this.get('workingHours')) : this.get('hours');
      var hash = {
        page: 'WIDGET',
        q: 'fff',
        properties: [14],
        // this.get('widget.companyProperty.id')
        platform: 'shopify',
        domain: 'python-books.myshopify.com',
        email: 'olimpia@gmail.com'
      };
      this.ajax.query(_query.default.SEARCH_ORDERS, 'search', hash).then(function (data) {
        _this.set('orders', data);

        if (!data.length) {
          _this.set('orderType', 'orderForm');
        }
      });
      this.ajax.query(_query.default.KB).then(function (data) {
        var filterKb = data.filter(function (kb) {
          return kb.visible;
        });

        _this.set('kbCollection', filterKb);

        _this.set('selectedKb', filterKb.findBy('id', _this.get('widget').defaultKb ? _this.get('widget').defaultKb.id : {}));
      });
    },
    filterFlows: Ember.computed('widget.id', function () {
      return this.get('flows').filter(function (flow) {
        return flow.isActive;
      });
    }),
    attachmentLogo: Ember.computed('widget.id', function () {
      var attachmentLogo = this.get('widget.logo');
      return {
        url: attachmentLogo && "".concat(_environment.default.mediaUrl).concat(attachmentLogo.datafile),
        id: attachmentLogo && attachmentLogo.id
      };
    }),
    attachmentLauncher: Ember.computed('widget.id', function () {
      var attachmentLauncher = this.get('widget.launcher');
      return {
        url: attachmentLauncher && "".concat(_environment.default.mediaUrl).concat(attachmentLauncher.datafile),
        id: attachmentLauncher && attachmentLauncher.id
      };
    }),
    attachmentBackground: Ember.computed('widget.id', function () {
      var attachmentBackground = this.get('widget.background');
      return {
        url: attachmentBackground && "".concat(_environment.default.mediaUrl).concat(attachmentBackground.datafile),
        id: attachmentBackground && attachmentBackground.id
      };
    }),
    selectedApp: Ember.computed('widget.id', function () {
      return [];
    }),
    defaultFlow: Ember.computed('widget.id', function () {
      return this.get('widget.defaultFlow') || {
        id: null,
        title: 'Choose flow...'
      };
    }),
    responseTime: Ember.computed('widget.id', 'widget.responseTime', function () {
      return this.get('responseTimes')[this.get('widget.responseTime')];
    }),
    filterApps: Ember.computed('widget.id', function () {
      var _this2 = this;

      var accounts = this.get('apps').filter(function (item) {
        return _this2.get('accounts').some(function (account) {
          return account.node.application === item.name.toUpperCase();
        });
      });
      accounts.pushObject(this.get('moreApp'));
      return accounts;
    }),
    availableTrustedDomains: Ember.computed('widget.trustedDomains', function () {
      var domains = [];
      this.get('widget.trustedDomains.edges').forEach(function (domain) {
        domains.push(domain.node.domain);
      });
      return domains.join();
    }),
    channelsEnabled: Ember.computed('channels.{@each.type}', function () {
      return this.get('channels').filter(function (channel) {
        return channel.type;
      });
    }),
    codeScript: Ember.computed('widget.id', function () {
      var environment = {
        staging: 'dev/',
        production: 'prod/',
        demo: 'demo/',
        development: 'development/'
      };

      var staticPath = _environment.default.apollo.apiURL.replace('graphql', 'static/');

      return "<!-- Start of Async Raincheck Code -->\n\t\t\t<script>\n\t\t\t\t!function() {\n\t\t\t\t\tvar t = document.createElement('script');\n\t\t\t\t\tt.type = 'text/javascript';\n\t\t\t\t\tt.async = true;\n\t\t\t\t\tt.setAttribute('id', 'RC-widget-script');\n\t\t\t\t\tt.setAttribute('data-widget-id', '".concat(this.get('widget.id'), "');\n\t\t\t\t\tt.src = '").concat(staticPath).concat(environment[_environment.default.environment], "sdk.js';\n\t\t\t\t\tdocument.body.appendChild(t);\n\t\t\t\t}();\n\t\t\t</script>\n\t\t<!-- End of Async Raincheck Code -->");
    }),
    actions: {
      chooseApp: function chooseApp(app, e) {
        e.stopPropagation();

        if (app.icon === 'icon-home') {
          this.get('router').transitionTo('ecommerce');
        } else {
          this.get('selectedApp').pushObject(app);
          this.get('filterApps').removeObject(app);
        }

        this.set('isShowPopupApps', false);
      },
      nextBlockOrder: function nextBlockOrder(type) {
        this.set('orderType', type);
      },
      checkOrder: function checkOrder() {
        var _this3 = this;

        var hash = {
          page: 'WIDGET',
          q: '636',
          // 1460
          properties: [this.get('widget.companyProperty.id')],
          platform: 'shopify',
          domain: 'http://kasper-umka.myshopify.com/',
          email: this.get('emailCustomer'),
          orderId: this.get('orderId')
        };
        this.ajax.query(_query.default.SEARCH_ORDERS, 'search', hash).then(function (data) {
          _this3.set('orders', data);

          _this3.set('orderType', 'orders');
        });
      },
      onSaveColorTheme: function onSaveColorTheme(themeColor) {
        document.querySelectorAll('.pcr-app').forEach(function (el) {
          return el.classList.remove('visible');
        });
        this.updateWidgetSettings({
          themeColor: themeColor
        });
      },
      onSaveColorButton: function onSaveColorButton(actionButtonColor) {
        document.querySelectorAll('.pcr-app').forEach(function (el) {
          return el.classList.remove('visible');
        });
        this.updateWidgetSettings({
          actionButtonColor: actionButtonColor
        });
      },
      saveWorkingHours: function saveWorkingHours() {
        var _this4 = this;

        var workingHours = JSON.stringify(this.get('workingHoursCopy'));
        var hash = {
          propertyId: this.get('widget.companyProperty.id'),
          workingHours: workingHours
        };
        this.ajax.mutation(_mutation.default.UPDATE_PROPERTY, 'updateCompanyProperty', hash, function () {
          _this4.checkHours();

          _this4.get('paperToaster').show("".concat(_this4.intl.t('business_hours'), " ").concat(_this4.intl.t('status.updated')));
        });
      },
      updateWorkingDays: function updateWorkingDays(day) {
        var key = "workingHoursCopy.".concat(day, ".on");
        this.set(key, !this.get(key));
        this.checkHours();
      },
      updateWorkingHours: function updateWorkingHours(day, field, value) {
        var key = "workingHoursCopy.".concat(day, ".").concat(field);
        this.set(key, value);
        this.checkHours();
      },
      cancelWorkingHours: function cancelWorkingHours() {
        this.set('workingHoursCopy', JSON.parse(this.get('workingHours')));
        this.checkHours();
      },
      addDomains: function addDomains() {
        var param = {
          whitelistedDomains: this.get('availableTrustedDomains').split(',').map(function (domain) {
            return domain.trim();
          }),
          whitelistedDomainEnabled: !!this.get('availableTrustedDomains')
        };
        this.updateWidgetSettings(param);
      },
      copyScript: function copyScript(codeScript) {
        var _this5 = this;

        navigator.clipboard.writeText(codeScript).then(function () {
          _this5.paperToaster.show(_this5.intl.t('copying_success'));
        }, function (err) {
          _this5.paperToaster.show(_this5.intl.t('copying_failed'), err);
        });
      },
      toggleChannel: function toggleChannel(key, val) {
        var param = _defineProperty({}, key, val);

        var channel = this.get('channels').findBy('key', key);

        if (channel.disabled) {
          this.set('showChannelDialog', true);
          this.set('channel', channel);
          return;
        }

        Ember.set(channel, 'type', val);
        this.updateWidgetSettings(param);
      },
      toggleKbDisplay: function toggleKbDisplay(kbDisplay) {
        this.set('widget.kbDisplay', kbDisplay);
        this.updateWidgetSettings({
          kbDisplay: kbDisplay
        });
      },
      onChangeSound: function onChangeSound(sound) {
        this.set('widget.sound', sound);

        if (sound) {
          var audio = new Audio('assets/audio/all-eyes-on-me.mp3');
          audio.play().then();
        }

        this.updateWidgetSettings({
          sound: sound
        });
      },
      switchShowArticle: function switchShowArticle(val) {
        this.set('widget.isShowArticle', val);
      },
      onBlurInput: function onBlurInput(key, val) {
        var widget = this.get('widget');

        var param = _defineProperty({}, key, val);

        Ember.set(widget, key, val);
        this.updateWidgetSettings(param);
      },
      togglePosition: function togglePosition(position) {
        this.set('widget.position', position);
        this.updateWidgetSettings({
          position: position
        });
      },
      toggleResponseTime: function toggleResponseTime(responseTime) {
        this.set('widget.responseTime', responseTime);
        this.updateWidgetSettings({
          responseTime: responseTime
        });
      },
      onSelectKb: function onSelectKb(kb) {
        this.set('selectedKb', kb);
        this.updateWidgetSettings({
          kbId: kb.id
        });
      },
      onChangeFlow: function onChangeFlow(flow) {
        this.set('defaultFlow', flow);
        this.updateWidgetSettings({
          flowId: flow.id
        });
      },
      deleteAttachment: function deleteAttachment(key, id, event) {
        event.stopPropagation();
        this.deleteAttachment(id);
        this.set("".concat(key, ".id"), null);
        this.set("".concat(key, ".url"), null);
      },
      openPopupApps: function openPopupApps() {
        if (this.get('isShowPopupApps')) return;
        this.set('isShowPopupApps', true);
      },
      onClickOutside: function onClickOutside() {
        this.set('isShowPopupApps', false);
      },
      redirectCreateChannel: function redirectCreateChannel() {
        var _this6 = this;

        this.get('router').transitionTo({
          queryParams: {
            page: 'channel'
          }
        }).then(function () {
          _this6.showDialogAction(_this6.get('channel'));
        });
      }
    },
    deleteAttachment: function deleteAttachment(attachmentId) {
      this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
        attachmentId: attachmentId
      });
    },
    checkHours: function checkHours() {
      this.set('hoursChanged', this.get('workingHours') !== JSON.stringify(this.get('workingHoursCopy')));
    },
    updateWidgetSettings: function updateWidgetSettings() {
      var _this7 = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var widget = _objectSpread(_objectSpread({}, params), {}, {
        propertyId: this.get('widget.companyProperty.id'),
        language: navigator.language || 'en-US'
      });

      this.ajax.query(_mutation.default.UPDATE_WIDGET_SETTINGS, 'updateWidgetSettings', widget).then(function () {
        return _this7.get('paperToaster').show(_this7.intl.t('settings_updated'));
      }).catch(function () {
        return _this7.get('paperToaster').show(_this7.intl.t('network_error'));
      });
    }
  });

  _exports.default = _default;
});