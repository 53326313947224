define("inboxfront/templates/components/channels/name-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xvtxAGBF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"channel_name\"],null],false],[0,\"\\n        \"],[1,[28,\"svg-icon\",[\"info2\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"paper-input\",null,[[\"required\",\"placeholder\",\"value\",\"onChange\"],[true,[28,\"t\",[\"channel_name\"],null],[24,[\"channel\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"channel\",\"name\"]]],null]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"title-channel\"],[8],[0,\"\\n    \"],[1,[28,\"svg-icon\",[[28,\"concat\",[\"source-\",[24,[\"selectedChannelType\",\"type\"]]],null]],[[\"class\"],[[24,[\"selectedChannelType\",\"type\"]]]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"upper-text\"],[8],[1,[24,[\"selectedChannelType\",\"title\"]],false],[0,\" \"],[1,[28,\"t\",[\"settings\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/channels/name-field.hbs"
    }
  });

  _exports.default = _default;
});