define("inboxfront/templates/components/contacts/tasks-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tXM8VsWA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"task-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[1,[24,[\"item\",\"title\"]],true],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"priority\"],null],false],[0,\": \"],[1,[24,[\"item\",\"priority\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"Status\"],null],false],[0,\": \"],[1,[24,[\"item\",\"status\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"agent\"],null],false],[0,\": \"],[1,[24,[\"item\",\"assignedBy\",\"firstName\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"status.created\"],null],false],[0,\": \"],[1,[28,\"moment-format\",[[24,[\"item\",\"created\"]],\"do MMM, YYYY\"],null],false],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"time.due_date\"],null],false],[0,\": \"],[1,[28,\"moment-format\",[[24,[\"item\",\"dueDate\"]],\"do MMM, YYYY h:mm A\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/contacts/tasks-item.hbs"
    }
  });

  _exports.default = _default;
});