define("inboxfront/templates/components/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0KHsgIzH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"style\",\"position: absolute; top: 20px; z-index: 11;\"],[10,\"class\",\"header-menu\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"empty\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"page-wrapper \",[28,\"if\",[[24,[\"class\"]],[24,[\"class\"]]],null]]]],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"extra\"],[[28,\"component\",[\"empty\"],null]]]]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"page-inner-wrapper\"],[8],[0,\"\\n        \"],[14,1,[[28,\"hash\",null,[[\"title\"],[[28,\"component\",[\"page-title\"],[[\"showButton\"],[false]]]]]]]],[0,\"\\n\\n        \"],[14,1,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"page-content\"],null]]]]]],[0,\"\\n\\n        \"],[14,1,[[28,\"hash\",null,[[\"footer\"],[[28,\"component\",[\"page-footer\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/page.hbs"
    }
  });

  _exports.default = _default;
});