define("inboxfront/helpers/filter-grid-contacts", ["exports", "moment", "inboxfront/helpers/countries-list"], function (_exports, _moment, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterGridContacts = filterGridContacts;
  _exports.default = void 0;

  function filterGridContacts(params) {
    var fieldId = params[0];
    var profile = params[1];
    var fieldType = params[2];
    var fields = params[3];

    if (fieldId === 'phone') {
      return profile.personalPhone;
    } else if (fieldId === 'country') {
      var _profile$homeAddress, _profile$officeAddres;

      var filterCountry = _countriesList.COUNTRIES_LIST.findBy('iso2', profile.citizenship);

      return ((_profile$homeAddress = profile.homeAddress) === null || _profile$homeAddress === void 0 ? void 0 : _profile$homeAddress.country) || ((_profile$officeAddres = profile.officeAddress) === null || _profile$officeAddres === void 0 ? void 0 : _profile$officeAddres.country) || filterCountry;
    } else if (fieldId === 'company') {
      return profile.companyProperty.title;
    } else if (fieldId === 'organization') {
      if (!profile.organization) return '';
      return profile.organization.name;
    } else if (fieldId === 'contactOwner') {
      var _profile$guestcontact, _profile$guestcontact2;

      var listCRM = ['hubspot', 'pipedrive', 'salesforce']; //TODO check this data duplication

      var guestContactType = (_profile$guestcontact = profile.guestcontactSet) === null || _profile$guestcontact === void 0 ? void 0 : (_profile$guestcontact2 = _profile$guestcontact.find(function (c) {
        return listCRM.indexOf(c.contactType) !== -1;
      })) === null || _profile$guestcontact2 === void 0 ? void 0 : _profile$guestcontact2.contactType;

      if (guestContactType) {
        return profile.crmContactOwner || 'Unassigned';
      } else {
        if (!profile.contactOwner) return 'Unassigned';
        return "".concat(profile.contactOwner.firstName, " ").concat(profile.contactOwner.lastName);
      }
    } else if (fieldId === 'created') {
      return (0, _moment.default)(profile.created).format('L');
    } else if (fieldId === 'mobile') {
      return profile.mobilePhone;
    } else {
      var _profile$customFields, _profile$customFields2;

      var value = (_profile$customFields = profile.customFields) === null || _profile$customFields === void 0 ? void 0 : (_profile$customFields2 = _profile$customFields.findBy('field.id', fieldId)) === null || _profile$customFields2 === void 0 ? void 0 : _profile$customFields2.value;

      if (value) {
        if (fieldType === 'DATE') {
          return (0, _moment.default)(value).format('L');
        } else if (fieldType === 'BOOLEAN' || fieldType === 'SELECTION') {
          var _fields$findBy, _fields$findBy$childr;

          value = (_fields$findBy = fields.findBy('id', fieldId)) === null || _fields$findBy === void 0 ? void 0 : (_fields$findBy$childr = _fields$findBy.children.findBy('id', value)) === null || _fields$findBy$childr === void 0 ? void 0 : _fields$findBy$childr.label; //TODO remove duplication for BOOLEAN and SELECTION custom fields
        }
      }

      return value || '';
    }
  }

  var _default = Ember.Helper.helper(filterGridContacts);

  _exports.default = _default;
});