define("inboxfront/components/dashboard/panels/chats-performance", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['dashboard-panel'],
    renderChart: function renderChart() {
      (0, _jquery.default)(this.element.querySelector('#chats-perf')).highcharts({
        chart: {
          type: 'pie',
          height: 315,
          width: 315
        },
        title: null,
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: 'INBOUND CHATS',
            y: 0,
            color: '#676978'
          }, {
            name: 'ABANDONMENT',
            y: 0,
            color: '#CCD4D4'
          }]
        }]
      });
      (0, _jquery.default)(this.element.querySelector('#chats-views')).highcharts({
        chart: {
          type: 'pie',
          renderTo: 'chats-views',
          height: 315,
          width: 315
        },
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: '1st CONTACT RESOLUTION',
            y: 0,
            color: '#676978'
          }, {
            name: 'TICKET CONVERSION',
            y: 0,
            color: '#CCD4D4'
          }]
        }]
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart1 = (0, _jquery.default)(this.element.querySelector('#chats-perf')).highcharts();
      var chart2 = (0, _jquery.default)(this.element.querySelector('#chats-views')).highcharts();
      var dataGroups = this.get('dataGroups');
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime'];
      var data = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      var realData = this.get('realData');
      var series1 = [{
        name: '',
        colorByPoint: true,
        data: [{
          name: 'INBOUND CHATS',
          y: realData ? data.totalChats : Math.ceil(Math.random() * 100),
          color: '#4A73B1'
        }, {
          name: 'ABANDONMENT',
          y: realData ? data.totalQueueAbandonment : Math.ceil(Math.random() * 100),
          color: '#C7D6E0'
        }]
      }];
      var series2 = [{
        name: '',
        colorByPoint: true,
        data: [{
          name: '1st CONTACT RESOLUTION',
          y: realData ? data.nonFirstContactResolutionTime : Math.ceil(Math.random() * 100),
          color: '#4A73B1'
        }, {
          name: 'TICKET CONVERSION',
          y: realData ? 0 : Math.ceil(Math.random() * 100),
          color: '#C7D6E0'
        }]
      }];
      chart1.series[0].setData(series1[0].data);
      chart2.series[0].setData(series2[0].data);
      chart1.redraw();
      chart2.redraw();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.renderChart();
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element.querySelector('#chats-perf')).highcharts().destroy();
      (0, _jquery.default)(this.element.querySelector('#chats-views')).highcharts().destroy();
    }
  });

  _exports.default = _default;
});