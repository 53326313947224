define("inboxfront/controllers/departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    departments: Ember.inject.service(),
    showEditDepartmentDialog: false,
    showDeleteDepartmentDialog: false,
    actions: {
      showCreateDialog: function showCreateDialog() {
        this.clearForm();
        this.set('selectedDepartment', this.get('departments').createDepartment());
        this.set('showEditDepartmentDialog', true);
      },
      showEditDialog: function showEditDialog(department) {
        this.set('editDisplayName', department.displayName);

        if (department.isUserDefined) {
          this.set('editDepartmentName', department.name);
          this.set('editDescription', department.description);
        }

        this.set('selectedDepartment', department);
        this.set('showEditDepartmentDialog', true);
      },
      showDeleteDialog: function showDeleteDialog(department) {
        this.set('selectedDepartment', department);
        this.set('showDeleteDepartmentDialog', true);
      },
      closeDepartmentDialog: function closeDepartmentDialog() {
        this.clearForm();
        this.set('showEditDepartmentDialog', false);
      },
      deleteDepartment: function deleteDepartment() {
        var _this = this;

        if (this.get('selectedDepartment').staffProfiles.length) {
          this.set('newDepartment', null);

          var deps = _toConsumableArray(this.get('model.departments'));

          deps.removeObject(this.get('selectedDepartment'));
          this.set('departmentsToMove', deps);
          this.set('showDeleteTransferUsersDialog', true);
          return;
        }

        var toaster = this.get('paperToaster');
        this.get('departments').deleteDepartment(this.get('selectedDepartment')).then(function () {
          toaster.show(_this.intl.t('department_removed'));

          _this.set('selectedDepartment', null);
        }).catch(function (error) {
          return toaster.show(error);
        });
      },
      continueDeletionDepartment: function continueDeletionDepartment() {
        this.set('showDeleteDepartmentDialog', true);
      },
      saveDepartment: function saveDepartment() {
        var _this2 = this;

        var toaster = this.get('paperToaster');
        var isNew = !this.get('selectedDepartment.id');
        var hash = {
          name: this.get('editDepartmentName'),
          displayName: this.get('editDisplayName') || this.get('editDepartmentName'),
          description: this.get('editDescription')
        };
        this.get('departments').saveDepartment(this.get('selectedDepartment'), hash).then(function (department) {
          if (isNew) {
            toaster.show(_this2.intl.t('department_created', {
              name: department.displayName
            }));
          } else {
            toaster.show(_this2.intl.t('department_updated'));
          }

          _this2.set('showEditDepartmentDialog', false);
        }).catch(function (error) {
          return toaster.show(error);
        });
      },
      updateStatus: function updateStatus(department, active) {
        var _this3 = this;

        if (!active && department.staffProfiles.length) {
          this.set('selectedDepartment', department);
          this.set('newDepartment', null);

          var deps = _toConsumableArray(this.get('model.departments'));

          deps.removeObject(department);
          this.set('departmentsToMove', deps);
          this.set('showDeactivateDepartmentDialog', true);
          return;
        }

        var toaster = this.get('paperToaster');
        this.get('departments').updateStatus(department, active).then(function (department) {
          return toaster.show(_this3.intl.t(department.active ? 'department_activated' : 'department_deactivated'));
        }).catch(function (error) {
          return toaster.show(error);
        });
      },
      deactivateDepartment: function deactivateDepartment() {},
      activateTeamChat: function activateTeamChat(department, active) {
        if (!active) {
          this.set('selectedDepartment', department);
          this.set('showDisableTeamChatDialog', true);
          return;
        }

        var toaster = this.get('paperToaster');
        this.get('departments').activateTeamChat(department, active).then(function (department) {
          return toaster.show('Team Chat Enabled');
        }).catch(function (error) {
          return toaster.show(error);
        });
      },
      disableTeamChat: function disableTeamChat() {
        var _this4 = this;

        var toaster = this.get('paperToaster');
        this.get('departments').activateTeamChat(this.get('selectedDepartment'), false).then(function (department) {
          toaster.show('Team Chat Disabled');

          _this4.set('selectedDepartment', null);
        }).catch(function (error) {
          return toaster.show(error);
        });
      }
    },
    clearForm: function clearForm() {
      this.set('editDepartmentName', '');
      this.set('editDisplayName', '');
      this.set('editDescription', '');
    }
  });

  _exports.default = _default;
});