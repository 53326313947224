define("inboxfront/mixins/child-mixin", ["exports", "inboxfront/mixins/parent-mixin"], function (_exports, _parentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    parentClass: _parentMixin.default,
    parentComponent: Ember.computed({
      get: function get() {
        if (this._parentComponent !== undefined) {
          return this._parentComponent;
        }

        return this.nearestOfType(this.get('parentClass'));
      },
      set: function set(key, value) {
        return this._parentComponent = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('parentComponent')) {
        this.get('parentComponent').register(this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('parentComponent')) {
        this.get('parentComponent').unregister(this);
      }
    }
  });

  _exports.default = _default;
});