define("inboxfront/components/chat/reply-in-thread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    chats: Ember.inject.service(),
    tagName: '',
    currentText: Ember.computed('msgBody', function () {
      var target = document.querySelector('.reply-in-thread-input');

      if (!this.get('msgBody')) {
        target && (target.innerHTML = '');
      }

      target && (target.innerHTML += this.get('msgBody'));
    }),
    scrollTo: Ember.computed('message.replies.[]', function () {
      var replies = this.get('message.replies');

      if (replies.length > 0) {
        setTimeout(function () {
          document.getElementById('msg' + replies[replies.length - 1].id) && document.getElementById('msg' + replies[replies.length - 1].id).scrollIntoView({
            behavior: 'smooth'
          });
        }, 500);
      }

      return '';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('msgBody', '');
    },
    actions: {
      sendMessage: function sendMessage() {
        var _room$messages;

        var input = document.querySelector('.reply-in-thread-input');
        var innerText = input.innerHTML.trim();

        if (innerText.length === 0) {
          return;
        }

        var msg = window.linkify(innerText, {
          callback: function callback(text, href) {
            if (href) {
              return "<a target=\"_blank\" class=\"link\" href=\"".concat(href, "\" title=\"").concat(href, "\">").concat(text, "</a>");
            } else {
              return text;
            }
          }
        });
        var data = {
          id: Math.ceil(Math.random() * 100000 + 10000),
          text: msg,
          created: new Date(),
          modified: new Date(),
          user: this.get('sessionAccount.loggeduser'),
          read: false,
          extraText: '',
          status: '',
          pinned: false,
          inReplyTo: {
            id: this.get('message.id')
          }
        };
        var room = this.get('chats.rooms').findBy('id', this.get('message.room.id'));
        (_room$messages = room.messages) === null || _room$messages === void 0 ? void 0 : _room$messages.edges.pushObject({
          node: data
        });
        var message = this.get('message');

        if (!Array.isArray(message.replies)) {
          Ember.set(message, 'replies', []);
        }

        message.replies.addObject(data);

        if (room) {
          Ember.set(room, 'lastMessage', data);
          this.set('chats.rooms', this.get('chats.rooms').sortBy('lastMessage.modified').reverse());
        }
        /*
        let newMessagePacket = {
            type: 'message',
            message: msg,
            in_reply_to: this.get('message.node.id')
        };
         if (room.webSocket.readyState === 1) {
            room.webSocket.send(JSON.stringify(newMessagePacket));
        }
        /**/
        //this.set('msgBody', '');


        input.innerHTML = '';
        this.set('isLoadingMessage', false);
        this.set('isMessageEmpty', true);
      },
      openReply: function openReply() {},
      openKBSearch: function openKBSearch() {},
      updateDateRange: function updateDateRange() {},
      openPopupUsers: function openPopupUsers() {},
      keyUpEventInput: function keyUpEventInput() {},
      scrollMessages: function scrollMessages() {
        var _this = this;

        var elements = document.querySelectorAll('.unread.message-sent'); //TODO rework scroll check

        if (elements.length) {
          elements.forEach(function (item) {
            if (_this.isElementInViewport(item)) {
              var id = item.id.substr(3);

              _this.readMessage(id, item);
            }
          });
        }
      },
      editMessage: function editMessage(msg) {},
      deleteMessage: function deleteMessage(msg) {}
    },
    isElementInViewport: function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },
    readMessage: function readMessage(id, item) {
      this.get('chats').readMessage(this.get('room'), id, item);
    }
  });

  _exports.default = _default;
});