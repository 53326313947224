define("inboxfront/components/common/column-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    visibleColumns: Ember.computed('columns.[]', function () {
      return (this.get('columns') || []).filter(function (c) {
        return c.visible;
      });
    }),
    hiddenColumns: Ember.computed('columns.[]', function () {
      return (this.get('columns') || []).filter(function (c) {
        return !c.visible;
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      hideColumn: function hideColumn(column) {
        Ember.set(column, 'visible', false);
        this.get('visibleColumns').removeObject(column);
        this.get('hiddenColumns').unshiftObject(column);
        this.updatePositions(this.get('hiddenColumns'));
      },
      showColumn: function showColumn(column) {
        Ember.set(column, 'visible', true);
        this.get('hiddenColumns').removeObject(column);
        this.get('visibleColumns').pushObject(column);
        this.updatePositions(this.get('visibleColumns'));
      },
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;
        var column = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, column);
        this.updatePositions(targetList);
      }
    },
    updatePositions: function updatePositions(list) {
      list.forEach(function (c, i) {
        if (c.position !== i) {
          Ember.set(c, 'position', i);
        }
      });
      this.set('columns', this.get('columns').sortBy('position'));
      var columnSettings = [];
      this.get('columns').forEach(function (c, i) {
        columnSettings.pushObject({
          id: c.id,
          pos: i,
          vis: c.visible
        });
      });
      this.onUpdateColumns(columnSettings);
    }
  });

  _exports.default = _default;
});