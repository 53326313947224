define("inboxfront/mockData/ordersBigCommerce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mockOrders = void 0;
  var mockOrders = [{
    id: 1000000,
    financialStatus: 'Completed',
    createdAt: new Date() - 1,
    closedAt: new Date(),
    totalPrice: 234,
    products: [{
      name: 'item 1',
      image: 'https://gravatar.com/avatar/c094cc986cc6030736e2c4323239dc3f?s=400&d=robohash&r=x',
      quantity: 12,
      totalPrice: 11,
      price: 110
    }]
  }, {
    id: 2000000,
    financialStatus: 'Cancelled',
    createdAt: new Date() - 2,
    cancelledAt: new Date() - 1,
    totalPrice: 456,
    products: [{
      name: 'item 1',
      image: null,
      quantity: 15,
      totalPrice: 30,
      price: 250
    }]
  }];
  _exports.mockOrders = mockOrders;
});