define("inboxfront/controllers/tasks/index", ["exports", "inboxfront/gql/mutation", "inboxfront/controllers/tasks/main"], function (_exports, _mutation, _main) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _main.default.extend({
    sliceData: Ember.inject.service(),
    hideLoading: false,
    observerPage: Ember.observer('pageNum', function () {
      if (this.get('cloneTaskList')) {
        this.set('taskList', this.get('sliceData').getSliceData(this.get('cloneTaskList'), this.get('pageNum')));
        this.set('selectedTasks', []);
      }
    }),
    actions: {
      updateColumns: function updateColumns(columnSettings) {
        try {
          var hash = {
            id: this.get('model.ticketSettings.id'),
            fields: JSON.stringify(columnSettings)
          };
          this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
        } catch (e) {
          console.error('ON UPDATE TASKS COLUMNS', e);
        }
      },
      deleteTasks: function deleteTasks() {
        var tasksService = this.get('tasks');
        var tasks = this.getSelectedTasks();
        tasksService.delete(tasks).then(function () {
          tasks.toArray().forEach(function (t) {
            Ember.set(t, 'status', 'DELETED');
            Ember.set(t, 'checked', false);
            tasksService.save(t);
          });
        });
      },
      archiveTasks: function archiveTasks(task) {
        var tasks = this.get('selectedTasks');

        if (task !== tasks) {
          tasks = [task];
        }

        var tasksService = this.get('tasks');
        tasks.forEach(function (t) {
          Ember.set(t, 'status', 'ARCHIVED');
          Ember.set(t, 'checked', false);
          tasksService.save(t);
        });
      },
      closeTasks: function closeTasks() {
        var tasksService = this.get('tasks');
        this.get('selectedTasks').forEach(function (t) {
          Ember.set(t, 'status', 'CLOSED');
          Ember.set(t, 'checked', false);
          tasksService.save(t);
        });
      },
      deselectAllTasks: function deselectAllTasks() {
        this.deselectTasks();
      }
    },
    updateTasks: function updateTasks(tasks) {
      var _this = this;

      this.set('hideLoading', false);
      this.deselectTasks();
      var users = this.get('model.propertyUsers');
      var fields = this.get('model.fields');
      var tasksService = this.get('tasks');
      var options = this.get('selectedFilterOptions');

      if (this.get('filterApplied')) {
        options = this.get('filterData');
      }

      tasksService.filter(options, function (result) {
        tasks = tasks || [];
        result.edges.forEach(function (edge) {
          var task = edge.node;

          if (task) {
            //TODO remove condition, temporarily created for new task settings
            task = tasksService.deserialize(fields, task, users);
            task.checked = false;
            tasks.push(task);
          }
        });

        if (_this.get('selectedFilterOptions.status') === 'OPEN') {
          _this.set('selectedFilterOptions.status', "RE_OPENED");

          return _this.updateTasks(tasks);
        }

        _this.set('model.startCursor', result.pageInfo.startCursor);

        _this.set('model.endCursor', result.pageInfo.endCursor);

        _this.set('model.isNextDisabled', !result.pageInfo.hasNextPage);

        _this.set('model.isPrevDisabled', !result.pageInfo.hasPreviousPage);

        _this.set('cloneTaskList', tasks);

        _this.set('taskList', _this.get('sliceData').getSliceData(_this.get('cloneTaskList'), 1));

        _this.set('rangeNum', _this.get('sliceData').calculateRange(_this.get('cloneTaskList'))); //TODO move calculations to pagination component?


        _this.set('pageNum', _this.get('sliceData').getPageNum());

        _this.set('selectedTasks', []);

        _this.set('hideLoading', true);
      }, this.get('filterApplied'));
    }
  });

  _exports.default = _default;
});