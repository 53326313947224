define("inboxfront/routes/orgfields", ["exports", "inboxfront/routes/customfields", "inboxfront/gql/query"], function (_exports, _customfields, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customfields.default.extend({
    ajax: Ember.inject.service(),
    contacts: Ember.inject.service(),
    customfields: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var settings = this.ajax.query(_query.default.ORGANIZATION_SETTINGS);
      return Ember.RSVP.all([settings]).then(function (res) {
        var settings = res[0];
        return {
          settings: settings,
          fields: _this.get('customfields').prepare(_this.get('contacts').organizationSystemFields, settings),
          types: _this.get('customfields').types
        };
      });
    }
  });

  _exports.default = _default;
});