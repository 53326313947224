define("inboxfront/services/profile", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    userStatus: 'offline',
    init: function init() {
      this._super();

      this.notificationSettings = this.get('sessionAccount.loggeduser.notificationSettings');
      this.set('timeList', [{
        value: 30,
        title: '30 minutes'
      }, {
        value: 60,
        title: '1 hour'
      }, {
        value: 120,
        title: '2 hours'
      }, {
        value: 240,
        title: '4 hours'
      }, {
        value: 480,
        title: '8 hours'
      }, {
        value: 'custom',
        title: 'Custom...'
      }]);
    },
    changeStatusUser: function changeStatusUser(status) {
      var _this = this;

      this.ajax.query(_mutation.default.UPDATE_AGENT_STATUS, 'updateAgentStatus', {
        status: status.toUpperCase()
      }).then(function () {
        _this.set('userStatus', status);
      });
    },
    getStatusUser: function getStatusUser() {
      var _this2 = this;

      this.ajax.query(_query.default.AGENT_PROFILE, 'agentProfile').then(function (user) {
        _this2.set('userStatus', (user.status || '').toLowerCase());
      });
    },
    updateProfile: function updateProfile(hash) {
      var _this3 = this;

      var noteText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'profile_updated';
      this.ajax.query(_mutation.default.UPDATE_NOTIFICATION_SETTINGS, 'updateNotificationSettings', hash).then(function () {
        _this3.set('notificationSettings', _objectSpread({}, hash));

        _this3.get('paperToaster').show(_this3.intl.t(noteText));
      });
    },
    chooseTimeSnooze: function chooseTimeSnooze(time, noteSettings) {
      var today = new Date();
      var snoozeEndDate = today.setMinutes(today.getMinutes() + time);

      var hash = _objectSpread(_objectSpread({}, noteSettings), {}, {
        snooze: new Date(snoozeEndDate).toISOString()
      });

      this.updateProfile(hash);
    }
  });

  _exports.default = _default;
});