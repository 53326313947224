define("inboxfront/components/field-emoji", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isFocus: false,
    id: 'editor',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('message')) {
        var editor = document.getElementById(this.get('id'));

        if (editor) {
          editor.innerHTML = this.get('message');
        }
      }
    },
    actions: {
      onMouseUp: function onMouseUp(e) {
        e.stopPropagation();
        this.set('isFocus', true);
        this.saveSelection();
      },
      onKeyUp: function onKeyUp(event) {
        var elm = event.target;

        if (event.keyCode === 13 && !elm.textContent.trim()) {
          event.preventDefault();
          elm.innerHTML = '';
        }

        if (elm.textContent.trim()) {//this.keyUpEventInput(event.keyCode, event.key);
        }

        this.onChange && this.onChange(elm.textContent, this.get('extraData'));
        this.saveSelection();
      },
      onSelectEmoji: function onSelectEmoji(emoji) {
        var _this = this;

        var sel, range;
        var selection = this.get('selection');
        var elementEmoji = document.createElement('div'); //const txtNode = document.createTextNode(emoji.unicode);

        elementEmoji.classList.add('emoji');
        elementEmoji.setAttribute('contenteditable', false);
        elementEmoji.style.backgroundPosition = emoji.x + ' ' + emoji.y; //elementEmoji.appendChild(txtNode);

        if (window.getSelection) {
          sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(selection);

          if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(elementEmoji);
            sel.collapseToEnd();
            this.saveSelection();
            setTimeout(function () {
              _this.set('isFocus', true);
            }, 0);
          }
        } else if (sel && sel.createRange) {
          sel.createRange().text = "elementEmoji";
        }
      },
      onClickOutsidePopup: function onClickOutsidePopup(e) {
        var editor = document.getElementById(this.get('id'));

        if (!editor.contains(e.target)) {
          this.set('isFocus', false);
        }
      },
      onBlurInputMessage: function onBlurInputMessage(extraData, mainTitle) {
        var editor = document.getElementById(this.get('id'));
        mainTitle = editor.innerHTML.trim().replaceAll('<!---->', '');
        this.set('message', mainTitle);
        this.onBlurInputMessage && this.onBlurInputMessage(extraData, mainTitle);
      }
    },
    saveSelection: function saveSelection() {
      if (window.getSelection) {
        var sel = window.getSelection();

        if (sel.getRangeAt && sel.rangeCount) {
          var selection = sel.getRangeAt(0);
          this.set('selection', selection);
        }
      } else if (document.selection && document.selection.createRange) {
        this.set('selection', document.selection.createRange());
      }
    }
  });

  _exports.default = _default;
});