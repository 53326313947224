define("inboxfront/components/dashboard/panels/messages-per-channel", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#messages_per_channel')).highcharts({
        chart: {
          height: 315,
          type: 'column'
        },
        title: null,
        xAxis: {
          categories: []
        },
        yAxis: {
          title: null
        },
        plotOptions: {
          series: {// stacking: 'normal'
          }
        },
        series: [{
          name: 'FB Messenger',
          color: '#043E6D',
          data: []
        }, {
          name: 'LINE',
          color: '#2B598A',
          data: []
        }, {
          name: 'Live Chat',
          color: '#4A73B1',
          data: []
        }, {
          name: 'WeChat',
          color: '#5887BC',
          data: []
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var fb = [];
      var line = [];
      var live = [];
      var wechat = [];
      var series = [fb, line, live, wechat];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function () {
        if (realData) {
          var data = dataGroups[i];
          line.pushObject(data === null || data === void 0 ? void 0 : data.totalMsgsLine);
          fb.pushObject(data === null || data === void 0 ? void 0 : data.totalMsgsFacebook);
          live.pushObject(data === null || data === void 0 ? void 0 : data.totalMsgsLivechat);
          wechat.pushObject(data === null || data === void 0 ? void 0 : data.totalMsgsWechat);
        } else {
          fb.pushObject(Math.ceil(Math.random() * 10));
          line.pushObject(Math.ceil(Math.random() * 10));
          live.pushObject(Math.ceil(Math.random() * 10));
          wechat.pushObject(Math.ceil(Math.random() * 10));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.series[2].setData(series[2]);
      chart.series[3].setData(series[3]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});