define("inboxfront/templates/components/helpdesk/department-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HAwa7Oqy",
    "block": "{\"symbols\":[\"option\",\"department\"],\"statements\":[[4,\"if\",[[24,[\"isEditMode\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"multiple\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",[29,[\"department-selector \",[22,\"class\"]]]],[3,\"action\",[[23,0,[]],\"selectDepartment\",\"onMouseUp\"]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"activeDepartments\"]]],null,{\"statements\":[[4,\"paper-checkbox\",null,[[\"value\",\"onChange\"],[[23,2,[\"selected\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"selected\"]]],null]],null]]],{\"statements\":[[0,\"                    \"],[1,[23,2,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"power-select\",null,[[\"placeholder\",\"class\",\"options\",\"selected\",\"disabled\",\"required\",\"searchEnabled\",\"searchPlaceholder\",\"searchField\",\"onChange\"],[[24,[\"placeholder\"]],[28,\"concat\",[\"department-selector \",[24,[\"class\"]]],null],[24,[\"activeDepartments\"]],[24,[\"value\"]],[24,[\"disabled\"]],[24,[\"required\"]],true,[28,\"t\",[\"search\"],null],\"displayName\",[28,\"action\",[[23,0,[]],\"onSelectChange\"],null]]],{\"statements\":[[0,\"            \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,[\"value\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/helpdesk/department-selector.hbs"
    }
  });

  _exports.default = _default;
});