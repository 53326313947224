define("inboxfront/controllers/tasks/main", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tasks: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    isCreateDialogInserted: false,
    showCreateDialog: false,
    showDeleteDialog: false,
    showFilterPanel: false,
    showAssignPanel: false,
    showMergePanel: false,
    isBulkUpdatePanelInserted: false,
    showBulkUpdatePanel: false,
    showWatcherPanel: false,
    filterData: null,
    allCheckTasks: Ember.computed('taskList.{@each.checked}', function () {
      return this.get('taskList').every(function (task) {
        return task.checked;
      });
    }),
    isVisibleActions: Ember.computed('taskList.{@each.checked}', function () {
      return this.get('taskList').some(function (task) {
        return task.checked;
      });
    }),
    isVisibleMergeButton: Ember.computed('taskList.{@each.checked}', function () {
      var _this$get$filter;

      return ((_this$get$filter = this.get('taskList').filter(function (task) {
        return task.checked;
      })) === null || _this$get$filter === void 0 ? void 0 : _this$get$filter.length) > 1;
    }),
    userDateTimeFormat: Ember.computed('session.data.userSettings', function () {
      var session = this.get('session');

      if (session.get('data.userSettings') && !session.get('data.userSettings.errors')) {
        var dateFormat = session.get('data.userSettings').dateformat;
        var timeFormat = session.get('data.userSettings').timeformat;

        if (timeFormat === '24 hours') {
          return dateFormat.toUpperCase() + " HH:mm A";
        } else {
          return dateFormat.toUpperCase() + " hh:mm A";
        }
      }

      return 'DD-MM-YYYY';
    }),
    actions: {
      onQuickFilter: function onQuickFilter(filter) {
        this.set('selectedFilter', filter);
        this.set('selectedFilterOptions.status', "");
        this.set('selectedFilterOptions.assignee', "");
        this.set('selectedFilterOptions.active', true);

        switch (filter.id) {
          case 'personal':
            this.set('selectedFilterOptions.status', "");
            this.set('selectedFilterOptions.assignee', this.get('sessionAccount.loggeduser.id'));
            break;

          case 'opened':
            this.set('selectedFilterOptions.status', "OPEN");
            break;

          case 'closed':
            this.set('selectedFilterOptions.status', "CLOSED");
            break;

          case 'resolved':
            this.set('selectedFilterOptions.status', "RESOLVED");
            break;

          case 'archived':
            this.set('selectedFilterOptions.status', "ARCHIVED");
            break;

          case 'deleted':
            this.set('selectedFilterOptions.status', "DELETED");
            this.set('selectedFilterOptions.active', false);
            break;

          default:
            this.set('selectedFilterOptions.status', "");
            this.set('selectedFilterOptions.assignee', "");
            break;
        }

        this.set('selectedFilterOptions.after', "");
        this.set('selectedFilterOptions.before', "");
        this.updateTasks();
      },
      showCreateTaskDialog: function showCreateTaskDialog(status) {
        var _this = this;

        this.set('additionalData', status ? {
          status: status
        } : {});
        this.set('isCreateDialogInserted', true);
        setTimeout(function () {
          return _this.set('showCreateDialog', true);
        }, 300);
      },
      showDeleteTasksDialog: function showDeleteTasksDialog(task) {
        this.hideAllPanels();

        if (task !== this.get('selectedTasks')) {
          this.set('selectedTask', task);
        } else {
          this.set('selectedTask', null);
        }

        this.set('showDeleteDialog', true);
      },
      showFilterTasksPanel: function showFilterTasksPanel() {
        var _this2 = this;

        setTimeout(function () {
          _this2.hideAllPanels();

          _this2.set('showFilterPanel', true);
        }, 300);
      },
      applyFilter: function applyFilter(data) {
        this.set('filterData', data);
        this.set('filterApplied', true);
        this.updateTasks();
      },
      clearFilter: function clearFilter() {
        this.set('filterData', {});
        this.set('filterApplied', false);
        this.updateTasks();
      },
      showAssignTasksPanel: function showAssignTasksPanel(task) {
        var _this3 = this;

        this.hideAllPanels();
        var hash = {};

        if (task !== this.get('selectedTasks')) {
          this.set('selectedTask', task);
          this.set('selectedUser', task.assignedTo);
          hash.propertyIds = [task.propertyId];
        } else {
          this.set('selectedTask', null);
          this.set('selectedUser', null);
          hash.propertyIds = [task[0].propertyId];
        }

        this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
          result.unshift({
            id: 0,
            firstName: 'Unassigned',
            lastName: ''
          });

          _this3.set('assigneeUsers', result);
        });
        this.set('showAssignPanel', true);
      },
      assignTasks: function assignTasks(user) {
        var _this4 = this;

        var mutations = [];
        var selectedTasks = this.getSelectedTasks();
        selectedTasks.forEach(function (t) {
          if (t.assignedTo.id !== user.id) {
            Ember.set(t, 'assignedTo', user);
            mutations.push(_this4.get('tasks').save(t));
          }
        });
        return Ember.RSVP.all(mutations).then(function () {
          if (_this4.get('selectedTask')) {
            _this4.set('selectedTask', null);
          } else {
            _this4.deselectTasks();
          }

          _this4.hideAllPanels();

          _this4.get('paperToaster').show(_this4.intl.t('assignee_changed'));
        });
      },
      showBulkUpdateTasksPanel: function showBulkUpdateTasksPanel() {
        var _this5 = this;

        this.set('isBulkUpdatePanelInserted', true);
        setTimeout(function () {
          _this5.hideAllPanels();

          _this5.set('showBulkUpdatePanel', true);
        }, 300);
      },
      bulkUpdateTasks: function bulkUpdateTasks(data) {
        var _this6 = this;

        var mutations = [];
        this.get('selectedTasks').forEach(function (t) {
          t.updateData(data);
          mutations.push(_this6.get('tasks').save(t));
        });
        return Ember.RSVP.all(mutations).then(function () {
          _this6.deselectTasks();

          _this6.hideAllPanels();

          _this6.get('paperToaster').show(_this6.intl.t('status.updated'));
        });
      },
      showMergeTasksPanel: function showMergeTasksPanel() {
        this.hideAllPanels();
        this.set('showMergePanel', true);
      },
      mergeTasks: function mergeTasks(primaryTask, secondaryTasks) {
        var _this7 = this;

        this.get('tasks').merge(primaryTask, secondaryTasks, function () {
          _this7.deselectTasks();

          _this7.hideAllPanels();

          _this7.get('paperToaster').show(_this7.intl.t('tasks_merged'));

          var route = Ember.getOwner(_this7).lookup("route:".concat(_this7.router.currentRoute.name));
          route.refresh();
        });
      },
      showWatcherTasksPanel: function showWatcherTasksPanel() {
        this.hideAllPanels();
        this.set('showWatcherPanel', true);
      },
      hideWatcherPanel: function hideWatcherPanel() {
        this.hideAllPanels();
      },
      updateWatchers: function updateWatchers(users) {
        var _this8 = this;

        var mutations = [];
        this.getSelectedTasks().forEach(function (t) {
          t.setWatchers(users);
          mutations.push(_this8.get('tasks').save(t));
        });
        return Ember.RSVP.all(mutations).then(function () {
          _this8.deselectTasks();

          _this8.hideAllPanels();

          _this8.get('paperToaster').show(_this8.intl.t('status.updated'));
        });
      },
      updatePriority: function updatePriority(task) {
        var _this9 = this;

        this.get('tasks').save(task, function () {
          _this9.get('paperToaster').show(_this9.intl.t('priority_updated'));
        });
      },
      updateStatus: function updateStatus(task) {
        var _this10 = this;

        this.get('tasks').save(task, function () {
          _this10.get('paperToaster').show(_this10.intl.t('status_updated'));
        });
      },
      updateDepartment: function updateDepartment(task) {
        var _this11 = this;

        this.get('tasks').save(task, function () {
          _this11.get('paperToaster').show(_this11.intl.t('department_updated'));
        });
      },
      onTaskCreate: function onTaskCreate(task) {
        this.get('paperToaster').show(this.intl.t('task_created'));
        this.set('showCreateDialog', false);
        this.transitionToRoute('tasks.edit', {
          queryParams: {
            id: task.id
          }
        });
      },
      selectTask: function selectTask(task) {
        if (this.panelShown()) {
          return;
        }

        var checked = task.checked;
        Ember.set(task, 'checked', !checked);

        if (checked) {
          this.get('selectedTasks').removeObject(task);
        } else {
          this.get('selectedTasks').pushObject(task);
        }
      },
      selectAllTasks: function selectAllTasks(isAllChecked) {
        if (this.panelShown()) {
          return;
        }

        var selectedTasks = [];
        this.get('taskList').forEach(function (t) {
          Ember.set(t, 'checked', isAllChecked);

          if (isAllChecked) {
            selectedTasks.pushObject(t);
          }
        });
        this.set('selectedTasks', selectedTasks);
      }
    },
    getSelectedTasks: function getSelectedTasks() {
      var task = this.get('selectedTask');

      if (task) {
        return [task];
      }

      return this.get('selectedTasks');
    },
    deselectTasks: function deselectTasks() {
      this.set('selectedTask', null);
      this.get('selectedTasks').forEach(function (t) {
        Ember.set(t, 'checked', false);
      });
      this.set('selectedTasks', []);
    },
    hideAllPanels: function hideAllPanels() {
      this.set('selectedTask', null);
      this.set('showCreateDialog', false);
      this.set('showFilterPanel', false);
      this.set('showAssignPanel', false);
      this.set('showBulkUpdatePanel', false);
      this.set('showMergePanel', false);
      this.set('showWatcherPanel', false);
    },
    panelShown: function panelShown() {
      return this.get('showFilterPanel') || this.get('showAssignPanel') || this.get('showBulkUpdatePanel') || this.get('showMergePanel') || this.get('showWatcherPanel');
    }
  });

  _exports.default = _default;
});