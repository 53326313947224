define("inboxfront/components/assistants/workflows/block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component.id', function () {
      return this.get('blocks').findBy('id', this.get('component.id'));
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      },
      deleteComponent: function deleteComponent(component, event) {
        this.deleteComponent(component, event);
      },
      selectBranch: function selectBranch(component, index) {
        this.selectBranch(component, index, true);
      },
      deselectBranch: function deselectBranch(component, index) {
        this.deselectBranch(component, index, true);
      },
      updateBranchName: function updateBranchName(component, index, value) {
        this.updateBranchName(component, index, value);
      }
    }
  });

  _exports.default = _default;
});