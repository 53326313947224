define("inboxfront/components/dashboard/panels/tasks-performance", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem1 = (0, _jquery.default)(element.querySelector('#tasks_perf')).highcharts({
        chart: {
          type: 'pie',
          height: 315,
          width: 315
        },
        title: null,
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: 'Reopened',
            y: 0,
            color: '#CFC59F'
          }, {
            name: 'Open',
            y: 0,
            color: '#3F524C'
          }]
        }]
      });
      var $elem2 = (0, _jquery.default)(element.querySelector('#tasks_views')).highcharts({
        chart: {
          type: 'pie',
          height: 315,
          width: 315
        },
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: 'Task',
            y: 0,
            color: '#E1E6C6'
          }, {
            name: 'Ticket',
            y: 0,
            color: '#5A7B5E'
          }]
        }]
      });
      this.set('$highcharts', [$elem1.highcharts(), $elem2.highcharts()]);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart1 = this.get('$highcharts')[0];
      var chart2 = this.get('$highcharts')[1];
      var dataGroups = this.get('dataGroups');
      var dataKeys = ['reopenedTickets', 'openTickets', 'totalTasks', 'totalTickets'];
      var data = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      var realData = this.get('realData');
      var series1 = [{
        name: 'Reopened',
        y: realData ? data.reopenedTickets : Math.ceil(Math.random() * 100),
        color: '#CFC59F'
      }, {
        name: 'Open',
        y: realData ? data.openTickets : Math.ceil(Math.random() * 100),
        color: '#3F524C'
      }];
      var series2 = [{
        name: 'Task',
        y: realData ? data.totalTasks : Math.ceil(Math.random() * 100),
        color: '#E1E6C6'
      }, {
        name: 'Ticket',
        y: realData ? data.totalTickets : Math.ceil(Math.random() * 100),
        color: '#5A7B5E'
      }];
      chart1.series[0].setData(series1);
      chart2.series[0].setData(series2);
      chart1.redraw();
      chart2.redraw();
    }
  });

  _exports.default = _default;
});