define("inboxfront/components/dashboard/pages/kb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cards: Ember.computed('pageName', 'listDateMin', 'realData', 'kbStats', 'prevDataGroups', function () {
      var dataGroups = this.get('kbStats');
      var prevDataGroups = Array.isArray(this.get('prevDataGroups')) ? this.get('prevDataGroups') : [];
      var dataKeys = ['totalVisitors', 'totalViews', 'totalLikes', 'totalDislikes', 'totalEnquiries', 'sucessfullSearch'];
      var data = {};
      var prevData = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
        prevData[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      prevDataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          prevData[k] += d[k];
        });
      });
      var diff = prevData.totalVisitors === 0 ? 100 : (100 * (data.totalVisitors - prevData.totalVisitors) / prevData.totalVisitors).toFixed(2);
      var diff2 = prevData.totalViews === 0 ? 100 : (100 * (data.totalViews - prevData.totalViews) / prevData.totalViews).toFixed(2);
      var diff3 = prevData.totalLikes === 0 ? 100 : (100 * (data.totalLikes - prevData.totalLikes) / prevData.totalLikes).toFixed(2);
      var diff4 = prevData.totalEnquiries === 0 ? 100 : (100 * (data.totalEnquiries - prevData.totalEnquiries) / prevData.totalEnquiries).toFixed(2);
      var diff5 = prevData.sucessfullSearch === 0 ? 100 : (100 * (data.sucessfullSearch - prevData.sucessfullSearch) / prevData.sucessfullSearch).toFixed(2);
      var realData = this.get('realData');
      return [{
        title: 'total_visitors',
        unit: '',
        icon: 'users2',
        colorIcon: '#301862',
        colorWrapIcon: '#F2EFF5',
        count: realData ? data.totalVisitors : Math.ceil(Math.random() * 200),
        dir: (realData ? diff < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff) : 5.25
      }, {
        title: 'total_views',
        unit: '',
        icon: 'mark-as',
        colorIcon: '#301862',
        colorWrapIcon: '#EBEAF5',
        count: realData ? data.totalViews : Math.ceil(Math.random() * 40),
        dir: (realData ? diff2 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff2) : 5.25
      }, {
        title: 'total_likes',
        unit: '',
        icon: 'fb-like',
        colorIcon: '#301862',
        colorWrapIcon: '#E4E5F5',
        count: realData ? data.totalLikes : Math.ceil(Math.random() * 30),
        dir: (realData ? diff3 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff3) : 5.25
      }, {
        title: 'total_enquiries',
        unit: '',
        icon: 'inquiry',
        colorIcon: '#301862',
        colorWrapIcon: '#E2E2F8',
        count: realData ? data.totalEnquiries : Math.ceil(Math.random() * 60 + 60),
        dir: (realData ? diff4 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff4) : 5.25
      }, {
        title: 'successful_search',
        unit: '',
        icon: 'search-for-love',
        colorIcon: '#301862',
        colorWrapIcon: '#E0DEFB',
        count: realData ? data.sucessfullSearch : Math.ceil(Math.random() * 40 + 40),
        dir: (realData ? diff5 < 0 : Math.random() > 0.5) ? 'down' : 'up',
        prevCount: realData ? Math.abs(diff5) : 5.25
      }];
    })
  });

  _exports.default = _default;
});