define("inboxfront/templates/components/dashboard/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gohukZk/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[28,\"concat\",[\"dashboard/pages/\",[24,[\"pageName\"]]],null]],[[\"pageName\",\"dataGroups\",\"listDateMin\",\"listDate\",\"isAllProperty\",\"props\",\"realData\",\"isLoading\",\"kbStats\",\"prevDataGroups\",\"prevStartDate\",\"prevEndDate\"],[[24,[\"pageName\"]],[24,[\"dataGroups\"]],[24,[\"listDateMin\"]],[24,[\"listDate\"]],[24,[\"isAllProperty\"]],[24,[\"props\"]],[24,[\"realData\"]],[24,[\"isLoading\"]],[24,[\"kbStats\"]],[24,[\"prevDataGroups\"]],[24,[\"prevStartDate\"]],[24,[\"prevEndDate\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/page.hbs"
    }
  });

  _exports.default = _default;
});