define("inboxfront/components/dashboard/panels/kb-performance", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem1 = (0, _jquery.default)(element.querySelector('#kb_perf')).highcharts({
        chart: {
          type: 'pie',
          height: 315,
          width: 315
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: 'Failed',
            y: 0,
            color: '#DCD6F7'
          }, {
            name: 'Successful',
            y: 0,
            color: '#424874'
          }]
        }]
      });
      var $elem2 = (0, _jquery.default)(element.querySelector('#kb_views')).highcharts({
        chart: {
          type: 'pie',
          height: 315,
          width: 315
        },
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: 'Views',
            y: 0,
            color: '#CBCDE1'
          }, {
            name: 'Visits',
            y: 0,
            color: '#424874'
          }]
        }]
      });
      this.set('$highcharts', [$elem1.highcharts(), $elem2.highcharts()]);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart1 = this.get('$highcharts')[0];
      var chart2 = this.get('$highcharts')[1];
      var dataGroups = this.get('kbStats') || [];
      var dataKeys = ['totalVisitors', 'totalViews', 'totalEnquiries', 'sucessfullSearch'];
      var data = {};
      dataKeys.forEach(function (k) {
        data[k] = 0;
      });
      dataGroups.forEach(function (d) {
        dataKeys.forEach(function (k) {
          data[k] += d[k];
        });
      });
      var realData = this.get('realData');
      var series1 = [{
        name: 'Failed',
        y: realData ? Math.abs(data.totalEnquiries - data.sucessfullSearch) : Math.ceil(Math.random() * 100),
        color: '#DCD6F7'
      }, {
        name: 'Successful',
        y: realData ? data.sucessfullSearch : Math.ceil(Math.random() * 80),
        color: '#424874'
      }];
      var series2 = [{
        name: 'Views',
        y: realData ? data.totalViews : Math.ceil(Math.random() * 100),
        color: '#CBCDE1'
      }, {
        name: 'Visits',
        y: realData ? data.totalVisitors : Math.ceil(Math.random() * 100),
        color: '#424874'
      }]; // console.log('CHART1', chart1);

      chart1.series[0].setData(series1);
      chart2.series[0].setData(series2); // chart1.redraw();
      // chart2.redraw();
    }
  });

  _exports.default = _default;
});