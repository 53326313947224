define("inboxfront/templates/components/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BSwV825X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pagination\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"number-page\"],[8],[1,[22,\"pageNum\"],false],[0,\" of \"],[1,[22,\"rangeNum\"],false],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"navigate-button\",[28,\"action\",[[23,0,[]],\"firstPage\",[24,[\"pageNum\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[\"arrow\"],[[\"class\"],[\"arrow-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"navigate-button\",[28,\"action\",[[23,0,[]],\"prevPage\",[24,[\"pageNum\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[\"next\"],[[\"class\"],[\"arrow-circle-icon prev\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"navigate-button\",[28,\"action\",[[23,0,[]],\"nextPage\",[24,[\"pageNum\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[\"next\"],[[\"class\"],[\"arrow-circle-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"navigate-button\",[28,\"action\",[[23,0,[]],\"lastPage\",[24,[\"pageNum\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[\"arrow\"],[[\"class\"],[\"arrow-icon _right\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/pagination.hbs"
    }
  });

  _exports.default = _default;
});