define("inboxfront/controllers/companies/edit", ["exports", "inboxfront/helpers/countries-list", "inboxfront/helpers/timezones-list", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, _countriesList, _timezonesList, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    queryParams: ['id'],
    COUNTRIES_LIST: _countriesList.COUNTRIES_LIST,
    TIMEZONES_LIST: _timezonesList.TIMEZONES_LIST,
    showDeactivateDialog: false,
    showDeleteDialog: false,
    hoursChanged: false,
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        controller.set('attachmentId', result.data.file.attachment.id);
        controller.set('urlAttachment', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
      };
    }),
    options: Ember.computed('model.company', function () {
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      var companyId = this.get('model.company.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        dictDefaultMessage: this.intl.t('upload_image'),
        clickable: '.avatar-upload',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "companies", "model": "property", "objectId": "' + companyId + '"}'
        }
      };
    }),
    availableParentCompanies: Ember.computed('model.company.{parent,children.length}', function () {
      var companyId = this.get('model.company.id');
      return this.get('model.companies').filter(function (company) {
        var parent = company.parent;
        var companyInParentChain = false;

        while (parent && !companyInParentChain) {
          companyInParentChain = parent.id === companyId;
          parent = parent.parent;
        }

        return !companyInParentChain;
      });
    }),
    availableChildCompanies: Ember.computed('model.company.{parent,children.length}', function () {
      var _this = this;

      var parentId = this.get('model.company.parent.id');
      return this.get('model.companies').filter(function (company) {
        if (company.parent !== null) {
          return false;
        }

        if (parentId !== undefined) {
          if (company.id === parentId) {
            return false;
          }

          return !_this.companyInChain(company, parentId);
        }

        return true;
      });
    }),
    companyInChain: function companyInChain(company, id) {
      var ret = false;

      for (var i = 0, children = company.children, l = children.length; i < l && !ret; i++) {
        ret = children[i].id === id;

        if (!ret && children[i].children) {
          ret = this.companyInChain(children[i], id);
        }
      }

      return ret;
    },
    restCompanyGroups: Ember.computed('model.company.companygroupSet.length', function () {
      var groups = this.get('model.company.companygroupSet');
      return this.get('model.groups').filter(function (group) {
        return !groups.find(function (g) {
          return g.id === group.id;
        });
      });
    }),
    actions: {
      updateField: function updateField(field, name) {
        var _this2 = this;

        var value = this.get("edit.".concat(field));

        if (value !== this.get("model.company.".concat(field))) {
          if (!this.get('validation').trimValidation[0].validate(value)) {
            return;
          }

          if (field === 'website' && !this.get('validation').websiteValidation[0].validate(value)) {
            return;
          }

          var hash = {
            propertyId: this.get('model.company.id')
          };
          hash[field] = value;
          this.ajax.mutation(_mutation.default.UPDATE_PROPERTY, 'updateCompanyProperty', hash, function () {
            _this2.set("model.company.".concat(field), value);

            _this2.get("paperToaster").show("".concat(name, " ").concat(_this2.intl.t('status.updated')));
          });
        }
      },
      updateCountry: function updateCountry(value) {
        var _this3 = this;

        if (value.iso2 !== this.get('model.company.country')) {
          var hash = {
            propertyId: this.get('model.company.id'),
            country: value.iso2
          };
          this.ajax.mutation(_mutation.default.UPDATE_PROPERTY, 'updateCompanyProperty', hash, function () {
            _this3.set('edit.country', value);

            _this3.set('model.company.country', value.iso2);

            _this3.get("paperToaster").show("".concat(_this3.intl.t('country'), " ").concat(_this3.intl.t('status.updated')));
          });
        }
      },
      updateTimezone: function updateTimezone(value) {
        var _this4 = this;

        if (value.tzCode !== this.get('model.company.timezone')) {
          var hash = {
            propertyId: this.get('model.company.id'),
            timezone: value.tzCode
          };
          this.ajax.mutation(_mutation.default.UPDATE_PROPERTY, 'updateCompanyProperty', hash, function () {
            _this4.set('edit.timezone', value);

            _this4.set('model.company.timezone', value.tzCode);

            _this4.get("paperToaster").show("".concat(_this4.intl.t('timezone'), " ").concat(_this4.intl.t('status.updated')));
          });
        }
      },
      deactivateCompany: function deactivateCompany() {
        var _this5 = this;

        var hash = {
          isActive: false,
          propertyId: this.get('model.company.id')
        };
        this.ajax.mutation(_mutation.default.ACTIVE_DEACTIVE_COMPANY, 'activeDeactiveProperty', hash, function () {
          _this5.set('model.company.isActive', false);

          _this5.set('showDeactivateDialog', false);

          _this5.get("paperToaster").show(_this5.intl.t('company_deactivated'));
        });
      },
      activateCompany: function activateCompany() {
        var _this6 = this;

        var hash = {
          isActive: true,
          propertyId: this.get('model.company.id')
        };
        this.ajax.mutation(_mutation.default.ACTIVE_DEACTIVE_COMPANY, 'activeDeactiveProperty', hash, function () {
          _this6.set('model.company.isActive', true);

          _this6.get("paperToaster").show(_this6.intl.t('company_activated'));
        });
      },
      deleteCompany: function deleteCompany() {
        var _this7 = this;

        this.ajax.mutation(_mutation.default.DELETE_COMPANY, 'deleteProperty', {
          propertyId: this.get('model.company.id')
        }, function () {
          _this7.set('showDeleteDialog', false);

          _this7.get("paperToaster").show(_this7.intl.t('company_deleted'));

          _this7.transitionToRoute('companies.index');
        });
      },
      deleteAttachment: function deleteAttachment() {
        var _this8 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: this.get('attachmentId')
        }).then(function () {
          _this8.set('model.company.logo', null);

          _this8.set('urlAttachment', '/assets/images/upload_avatar.png');

          _this8.set('attachmentId', null);
        });
      },
      addParent: function addParent() {
        var _this9 = this;

        if (!this.get('showParentSelect')) {
          this.set('showParentSelect', true);
          this.set('showChildSelect', false);
          return;
        }

        if (!this.get('selectedParent')) {
          return;
        }

        var parent = this.get('selectedParent');
        var hash = {
          childId: this.get('model.company.id'),
          parentId: parent.id
        };
        this.set('selectedChild', null);
        this.ajax.mutation(_mutation.default.ADD_PARENT_CHILD_COMPANY, 'addParentChild', hash, function () {
          parent.children.addObject(_this9.get('model.company'));

          _this9.set('model.company.parent', parent);

          _this9.set('showParentSelect', false);

          _this9.set('selectedParent', null);

          _this9.get('paperToaster').show("".concat(_this9.intl.t('parent_company'), " ").concat(_this9.intl.t('status.added')));
        });
      },
      removeParent: function removeParent() {
        var _this10 = this;

        this.ajax.mutation(_mutation.default.ADD_PARENT_CHILD_COMPANY, 'addParentChild', {
          childId: this.get('model.company.id')
        }, function () {
          _this10.get('model.company.parent.children').removeObject(_this10.get('model.company'));

          _this10.set('model.company.parent', null);

          _this10.get('paperToaster').show("".concat(_this10.intl.t('parent_company'), " ").concat(_this10.intl.t('status.removed')));
        });
      },
      addChild: function addChild() {
        var _this11 = this;

        if (!this.get('showChildSelect')) {
          this.set('showChildSelect', true);
          this.set('showParentSelect', false);
          return;
        }

        if (!this.get('selectedChild')) {
          return;
        }

        var child = this.get('selectedChild');
        var hash = {
          childId: child.id,
          parentId: this.get('model.company.id')
        };
        this.set('selectedParent', null);
        this.ajax.mutation(_mutation.default.ADD_PARENT_CHILD_COMPANY, 'addParentChild', hash, function () {
          child.parent = _this11.get('model.company');

          _this11.get('model.company.children').addObject(child);

          _this11.set('selectedChild', null);

          _this11.get('paperToaster').show("".concat(_this11.intl.t('child_company'), " ").concat(_this11.intl.t('status.added')));
        });
      },
      removeChild: function removeChild(child) {
        var _this12 = this;

        this.ajax.mutation(_mutation.default.ADD_PARENT_CHILD_COMPANY, 'addParentChild', {
          childId: child.id
        }, function () {
          child.parent = null;

          _this12.get('model.company.children').removeObject(child);

          _this12.get('paperToaster').show("".concat(_this12.intl.t('child_company'), " ").concat(_this12.intl.t('status.removed')));
        });
      },
      addGroup: function addGroup() {
        var _this13 = this;

        if (!this.get('showGroupSelect')) {
          this.set('showGroupSelect', true);
          return;
        }

        if (!this.get('selectedGroup')) {
          return;
        }

        var group = this.get('selectedGroup');

        var companies = _toConsumableArray(group.properties);

        companies.addObject(this.get('model.company'));
        var ids = [];
        companies.forEach(function (company) {
          return ids.push(company.id);
        });
        var hash = {
          companyGroupId: this.get('selectedGroup.id'),
          properties: ids
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_COMPANY_GROUP, 'createUpdateCompanyGroup', hash, function () {
          _this13.get('paperToaster').show(_this13.intl.t('company_added_to_group'));

          Ember.set(group, 'properties', companies);

          _this13.get('model.company.companygroupSet').addObject(group);

          _this13.set('selectedGroup', null);
        });
      },
      removeGroup: function removeGroup(group) {
        var _this14 = this;

        var companyId = this.get('model.company.id');
        var companies = group.properties.filter(function (item) {
          return item.id !== companyId;
        });
        var ids = [];
        companies.forEach(function (company) {
          return ids.push(company.id);
        });
        var hash = {
          companyGroupId: group.id,
          properties: ids
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_COMPANY_GROUP, 'createUpdateCompanyGroup', hash, function () {
          _this14.get('paperToaster').show(_this14.intl.t('company_removed_from_group'));

          Ember.set(group, 'properties', companies);

          _this14.get('model.company.companygroupSet').removeObject(group);
        });
      },
      updateWorkingDays: function updateWorkingDays(day) {
        var key = "model.company.hours.".concat(day, ".on");
        this.set(key, !this.get(key));
        this.checkHours();
      },
      updateWorkingHours: function updateWorkingHours(day, field, value) {
        var key = "model.company.hours.".concat(day, ".").concat(field);
        this.set(key, value);
        this.checkHours();
      },
      cancelWorkingHours: function cancelWorkingHours() {
        this.set('model.company.hours', JSON.parse(this.get('model.company.workingHours')));
        this.checkHours();
      },
      saveWorkingHours: function saveWorkingHours() {
        var _this15 = this;

        var hours = JSON.stringify(this.get('model.company.hours'));
        var hash = {
          propertyId: this.get('model.company.id'),
          workingHours: hours
        };
        this.ajax.mutation(_mutation.default.UPDATE_PROPERTY, 'updateCompanyProperty', hash, function () {
          _this15.set('model.company.workingHours', hours);

          _this15.checkHours();

          _this15.get("paperToaster").show("".concat(_this15.intl.t('business_hours'), " ").concat(_this15.intl.t('status.updated')));
        });
      }
    },
    checkHours: function checkHours() {
      this.set('hoursChanged', this.get('model.company.workingHours') !== JSON.stringify(this.get('model.company.hours')));
    }
  });

  _exports.default = _default;
});