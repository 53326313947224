define("inboxfront/components/dashboard/panels/escalations-priority", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#escalations_rating')).highcharts({
        chart: {
          height: 315
        },
        title: null,
        xAxis: {
          title: null,
          categories: []
        },
        yAxis: {
          title: null
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'spline',
          name: this.intl.t('urgent'),
          color: '#CDB184',
          data: []
        }, {
          type: 'spline',
          name: this.intl.t('high'),
          color: '#EFE4CF',
          data: []
        }, {
          type: 'spline',
          name: this.intl.t('medium'),
          color: '#CFC59F',
          data: []
        }, {
          type: 'spline',
          name: this.intl.t('low'),
          color: '#96AC87',
          data: []
        }, {
          type: 'spline',
          name: this.intl.t('na'),
          color: '#5A7B5E',
          data: []
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var urgent = [];
      var high = [];
      var medium = [];
      var low = [];
      var na = [];
      var series = [urgent, high, medium, low, na];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function () {
        if (realData) {
          var data = dataGroups[i];
          urgent.pushObject(data === null || data === void 0 ? void 0 : data.totalEscalationUrgent);
          high.pushObject(data === null || data === void 0 ? void 0 : data.totalEscalationHigh);
          medium.pushObject(data === null || data === void 0 ? void 0 : data.totalEscalationMedium);
          low.pushObject(data === null || data === void 0 ? void 0 : data.totalEscalationLow); //normalize NA escalations, make sure sum of all is less then total count

          var c = (data === null || data === void 0 ? void 0 : data.totalEscalation) - (data === null || data === void 0 ? void 0 : data.totalEscalationUrgent) - (data === null || data === void 0 ? void 0 : data.totalEscalationHigh) - (data === null || data === void 0 ? void 0 : data.totalEscalationMedium) - (data === null || data === void 0 ? void 0 : data.totalEscalationLow);
          na.pushObject(isNaN(c) ? 0 : c > 0 ? c : 0);
        } else {
          urgent.pushObject(Math.ceil(Math.random() * 50 + 20));
          high.pushObject(Math.ceil(Math.random() * 50 + 20));
          medium.pushObject(Math.ceil(Math.random() * 50 + 20));
          low.pushObject(Math.ceil(Math.random() * 50 + 20));
          na.pushObject(Math.ceil(Math.random() * 50 + 20));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.series[2].setData(series[2]);
      chart.series[3].setData(series[3]);
      chart.series[4].setData(series[4]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});