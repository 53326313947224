define("inboxfront/routes/common", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "inboxfront/mixins/permissions"], function (_exports, _authenticatedRouteMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _permissions.default, {
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.adminPages = ['settings', 'companies.index', 'group.index', 'departments', 'contactfields', 'orgfields', 'issuetypes', 'ticketfields', 'users.index', 'roles.index', 'permissions', 'channels', 'chatslimit', 'response', 'ecommerce'];
      this.controller = Ember.getOwner(this).lookup('controller:application');
    },
    checkIsAdminPage: function checkIsAdminPage(routeName) {
      var applicationController = this.controller;
      Ember.set(applicationController, 'isAdminPage', this.adminPages.indexOf(routeName) >= 0);
      Ember.set(applicationController, 'isCollapsed', !Ember.get(applicationController, 'isAdminPage'));
    },
    actions: {
      loading: function loading(transition) {
        var applicationController = this.controller;
        Ember.set(applicationController, 'isLoading', false);
        Ember.set(applicationController, 'textLoading', this.get('textLoading') || this.get('intl').t('status.loading'));
        this.checkIsAdminPage(this.routeName);
        transition.promise.finally(function () {
          Ember.set(applicationController, 'isLoading', true);
        });
      },
      willTransition: function willTransition(transition) {
        this.checkIsAdminPage(transition.to.name);
      }
    }
  });

  _exports.default = _default;
});