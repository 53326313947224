define("inboxfront/components/chat/room-messages-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    raincheckbot: {
      firstName: '',
      lastName: '',
      username: 'raincheck.bot'
    },
    isToday: Ember.computed('message', function () {
      var currentDate = new Date(this.get('message.node.created')).getDate();
      var now = new Date().getDate();
      return currentDate === now;
    }),
    usernameSession: Ember.computed('sessionAccount', function () {
      var _this$sessionAccount$;

      return ((_this$sessionAccount$ = this.sessionAccount.loggeduser) === null || _this$sessionAccount$ === void 0 ? void 0 : _this$sessionAccount$.username) || '';
    }),
    currentDate: Ember.computed('message', function () {
      var date = new Date();
      var currentDate = new Date(this.get('message.node.created')).getDate();
      var today = date.getDate();
      var yesterday = date.setDate(date.getDate() - 1);

      if (currentDate === today) {
        return this.intl.t('time.today');
      } else if (currentDate === yesterday) {
        return this.intl.t('time.yesterday');
      } else {
        return (0, _moment.default)(this.get('message.node.created')).format('LL');
      }
    }),
    messages: Ember.computed('room.messagesNew.[]', 'numberOfMsgs', function () {
      var messages = this.get('room.messagesNew');

      if (this.get('numberOfMsgs') >= messages.length - 1) {
        return messages;
      }

      return messages.slice(messages.length - this.get('numberOfMsgs') - 1);
    }),
    actions: {}
  });

  _exports.default = _default;
});