define("inboxfront/components/contacts/org-contact-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    contactTypeLogo: Ember.computed('organization.source', function () {
      var source = (this.get('organization.source') || '').toLowerCase();
      return source ? "CRM-".concat(source, "-logo") : '';
    })
  });

  _exports.default = _default;
});