define("inboxfront/templates/components/contacts/accociate-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UJjlV697",
    "block": "{\"symbols\":[\"contact\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-guest\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filterContacts\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"guest-item\"],[8],[0,\"\\n            \"],[7,\"div\",true],[8],[7,\"span\",true],[10,\"class\",\"guest-title\"],[8],[1,[28,\"t\",[\"firstname\"],null],false],[9],[1,[23,1,[\"firstName\"]],false],[9],[0,\"\\n            \"],[7,\"div\",true],[8],[7,\"span\",true],[10,\"class\",\"guest-title\"],[8],[1,[28,\"t\",[\"lastname\"],null],false],[9],[1,[23,1,[\"lastName\"]],false],[9],[0,\"\\n            \"],[7,\"div\",true],[8],[7,\"span\",true],[10,\"class\",\"guest-title\"],[8],[1,[28,\"t\",[\"email\"],null],false],[9],[1,[23,1,[\"email\"]],false],[9],[0,\"\\n            \"],[7,\"div\",true],[8],[7,\"span\",true],[10,\"class\",\"guest-title\"],[8],[1,[28,\"t\",[\"phone\"],null],false],[9],[1,[23,1,[\"phone\"]],false],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"associate action-button\",[28,\"action\",[[23,0,[]],[23,0,[\"selectContact\"]],[23,1,[]]],null]]],{\"statements\":[[0,\"                \"],[1,[28,\"t\",[\"actions.select\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"target\",\"query\",\"route\"],[\"guest-link action-button\",\"_blank\",[28,\"hash\",null,[[\"id\"],[[23,1,[\"id\"]]]]],\"contacts.edit\"]],{\"statements\":[[0,\"                \"],[1,[28,\"t\",[\"actions.go_to\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/contacts/accociate-contacts.hbs"
    }
  });

  _exports.default = _default;
});