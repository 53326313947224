define("inboxfront/components/contacts/guest-contact-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    contactTypeLogo: Ember.computed('guest', function () {
      var _this$get$find;

      var listCRM = ['hubspot', 'pipedrive', 'salesforce']; //TODO check this data duplication

      var guestContactType = (_this$get$find = this.get('guest.guestcontactSet').find(function (c) {
        return listCRM.indexOf(c.contactType) !== -1;
      })) === null || _this$get$find === void 0 ? void 0 : _this$get$find.contactType;
      return guestContactType ? "CRM-".concat(guestContactType, "-logo") : '';
    })
  });

  _exports.default = _default;
});