define("inboxfront/services/departments", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('departments', []);
    },
    load: function load() {
      var _this = this;

      var activeOnly = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      return new Promise(function (resolve) {
        _this.ajax.query(_query.default.DEPARTMENT).then(function (res) {
          var deps = _this.get('departments');

          if (Array.isArray(res)) {
            res = activeOnly ? res.filter(function (dep) {
              return dep.active === true;
            }) : res;
            res.forEach(function (dep) {
              var _dep$teamChat;

              dep.isUserDefined = dep.category === 'A_2';
              dep.teamChatEnabled = !!((_dep$teamChat = dep.teamChat) === null || _dep$teamChat === void 0 ? void 0 : _dep$teamChat.isActive);
              dep.displayName || (dep.displayName = dep.name);
            });
            deps.setObjects(res.sort(function (a, b) {
              var x = a.name.toLowerCase();
              var y = b.name.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }));
          }

          resolve(deps);
        });
      });
    },
    createDepartment: function createDepartment() {
      return {
        category: 'A_2',
        isUserDefined: true,
        name: '',
        displayName: '',
        description: '',
        active: true,
        activateTeamChat: true,
        staffProfiles: []
      };
    },
    saveDepartment: function saveDepartment(department, data) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        if (!department.id) {
          data.active = department.active;
          data.activateTeamChat = department.activateTeamChat;

          _this2.ajax.query(_mutation.default.CREATE_DEPARTMENT, 'createUserDefinedDepartment', data).then(function (res) {
            if (res.department) {
              var _dep$teamChat2;

              var dep = res.department;
              dep.isUserDefined = dep.category === 'A_2';
              dep.teamChatEnabled = !!((_dep$teamChat2 = dep.teamChat) === null || _dep$teamChat2 === void 0 ? void 0 : _dep$teamChat2.isActive);
              dep.displayName || (dep.displayName = dep.name);

              var deps = _this2.get('departments');

              deps.pushObject(dep);
              deps.setObjects(deps.sort(function (a, b) {
                var x = a.name.toLowerCase();
                var y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
              }));
              resolve(dep);
            } else {
              reject(_this2.intl.t('internal_error'));
            }
          }).catch(function () {
            return reject(_this2.intl.t('network_error'));
          });
        } else {
          var hash = {
            departmentId: department.id,
            displayName: data.displayName
          };

          if (department.isUserDefined) {
            hash.name = data.name;
            hash.description = data.description;
          }

          _this2.ajax.query(_mutation.default.UPDATE_DEPARTMENT, 'updateDepartment', hash).then(function (res) {
            if (res.department) {
              var _dep$teamChat3;

              var dep = res.department;
              Ember.set(department, 'name', dep.name);
              Ember.set(department, 'displayName', dep.displayName);
              Ember.set(department, 'description', dep.description);
              Ember.set(department, 'teamChatEnabled', !!((_dep$teamChat3 = dep.teamChat) === null || _dep$teamChat3 === void 0 ? void 0 : _dep$teamChat3.isActive));
              resolve(department);
            } else {
              reject(_this2.intl.t('internal_error'));
            }
          }).catch(function () {
            return reject(_this2.intl.t('network_error'));
          });
        }
      });
    },
    updateStatus: function updateStatus(department, active) {
      var _this3 = this;

      var hash = {
        departmentId: department.id,
        active: active
      };
      return new Promise(function (resolve, reject) {
        _this3.ajax.query(_mutation.default.UPDATE_DEPARTMENT, 'updateDepartment', hash).then(function (res) {
          if (res.department) {
            var _res$department$teamC;

            Ember.set(department, 'active', res.department.active);
            Ember.set(department, 'teamChatEnabled', !!((_res$department$teamC = res.department.teamChat) === null || _res$department$teamC === void 0 ? void 0 : _res$department$teamC.isActive));
            resolve(department);
          } else {
            var error = JSON.parse(res.formErrors);
            reject(error.error);
          }
        }).catch(function () {
          return reject(_this3.intl.t('network_error'));
        });
      });
    },
    activateTeamChat: function activateTeamChat(department, active) {
      var _this4 = this;

      var hash = {
        departmentId: department.id,
        activateTeamChat: active
      };
      return new Promise(function (resolve, reject) {
        _this4.ajax.query(_mutation.default.UPDATE_DEPARTMENT, 'updateDepartment', hash).then(function (res) {
          if (res.department) {
            var _res$department$teamC2;

            Ember.set(department, 'teamChatEnabled', !!((_res$department$teamC2 = res.department.teamChat) === null || _res$department$teamC2 === void 0 ? void 0 : _res$department$teamC2.isActive));
            resolve(department);
          } else {
            var error = JSON.parse(res.formErrors);
            reject(error.error);
          }
        }).catch(function () {
          return reject(_this4.intl.t('network_error'));
        });
      });
    },
    deleteDepartment: function deleteDepartment(department) {
      var _this5 = this;

      var hash = {
        departmentId: department.id
      };
      return new Promise(function (resolve, reject) {
        _this5.ajax.mutation(_mutation.default.DELETE_USER_DEFINED_DEPARTMENT, 'deleteUserDefinedDepartment', hash, function (res) {
          if (!res.formErrors) {
            _this5.get('departments').removeObject(department);

            resolve();
          } else {
            var error = JSON.parse(res.formErrors);
            reject(error.error);
          }
        }).catch(function () {
          return reject(_this5.intl.t('network_error'));
        });
      });
    }
  });

  _exports.default = _default;
});