define("inboxfront/components/helpdesk/task-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    leftCountWatchers: Ember.computed('task.watchers', function () {
      var watchers = this.get('task.watchers');

      if (watchers) {
        return watchers.length > 5 ? watchers.length - 5 : '';
      }

      return '';
    }),
    actions: {
      chooseCard: function chooseCard(task, e) {
        if (e.ctrlKey) {
          Ember.set(task, 'selected', task.selected ? '' : 'selected');
          this.onSelect();
        }
      },
      onSelectChange: function onSelectChange(value) {
        this.set('task.department', value);
        this.onUpdateDepartment();
      },
      selectWatcher: function selectWatcher(task) {
        this.onSelectWatcher(task);
      }
    }
  });

  _exports.default = _default;
});