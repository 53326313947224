define("inboxfront/components/chat/visitor-info", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: '',
    trackings: Ember.computed('chatSession.{}', function () {
      var trackings = this.get('chatSession').trackingSet || [];
      var tracks = [];

      var _loop = function _loop(i) {
        if (tracks.length === 0) {
          tracks.push(trackings[i]);
        } else {
          var targetLength = tracks.filter(function (obj) {
            return obj.target === trackings[i].target;
          });

          if (targetLength === 0) {
            tracks.push(trackings[i]);
          } else {
            var targetObj = tracks.filter(function (track) {
              return track.target === trackings[i].target;
            })[0];
            if (targetObj) Ember.set(targetObj, 'created', trackings[i].created); //eslint-disable-line
          }
        }
      };

      for (var i = trackings.length - 1; i >= 0; i--) {
        _loop(i);
      }

      return tracks;
    }),
    firstLink: Ember.computed('chatSession.{}', function () {
      var trackings = this.get('chatSession').trackingSet;

      if (trackings.length) {
        return trackings[0].target;
      }
    }),
    chatSession: Ember.computed('selectedThread.{}', function () {
      if (this.get('selectedThread.id')) {
        if (this.get('selectedThread')) {
          return this.get('selectedThread').chatsession;
        }
      }

      return {};
    }),
    countGuestForm: Ember.computed('guestInfo', function () {
      var _this$get = this.get('guestInfo'),
          firstName = _this$get.firstName,
          lastName = _this$get.lastName,
          primaryEmail = _this$get.primaryEmail,
          personalPhone = _this$get.personalPhone;

      var progress = document.querySelector('.progress-form .line');
      var count = [firstName, lastName, primaryEmail, personalPhone].filter(function (a) {
        return a;
      }).length;

      if (progress) {
        progress.style.width = "".concat(25 * count, "%");
      }

      return count;
    }),
    openOpportunities: null,
    closedOpportunities: null,
    guestIsFromCrm: Ember.computed('guestInfo', function () {
      var _this$get2, _this$get2$find;

      var listCRM = ['hubspot', 'pipedrive', 'salesforce']; //TODO check this data duplication

      var guestContactType = (_this$get2 = this.get('guestInfo.guestcontactSet')) === null || _this$get2 === void 0 ? void 0 : (_this$get2$find = _this$get2.find(function (c) {
        return listCRM.indexOf(c.contactType) !== -1;
      })) === null || _this$get2$find === void 0 ? void 0 : _this$get2$find.contactType;
      this.loadOpportunities(!!guestContactType);
      return !!guestContactType;
    }),
    totalWon: Ember.computed('guestInfo', 'closedOpportunities', function () {
      var ret = 0;

      if (this.get('guestIsFromCrm')) {
        this.get('closedOpportunities').forEach(function (o) {
          return ret += o.stage === 'closedwon' ? o.amount : 0;
        });
      }

      return ret;
    }),
    loadOpportunities: function loadOpportunities(guestIsFromCrm) {
      var _this = this;

      this.set('openOpportunities', []);
      this.set('closedOpportunities', []);

      if (!guestIsFromCrm) {
        return;
      }

      var data = {
        crm: 'HUBSPOT',
        objectId: this.get('guestInfo.id'),
        systemObject: 'contact'
      };
      this.ajax.query(_query.default.GET_CRM_OPPORTUNITIES, 'opportunities', data).then(function (res) {
        if (!Array.isArray(res)) {
          res = [];
        }

        _this.set('openOpportunities', res.filter(function (opp) {
          return !_this._opportunityIsClosed(opp);
        }));

        _this.set('closedOpportunities', res.filter(function (opp) {
          return _this._opportunityIsClosed(opp);
        }));
      });
    },
    _opportunityIsClosed: function _opportunityIsClosed(opp) {
      if (opp.stage === 'closedwon' || opp.stage === 'closedlost') {
        opp.isClosed = true;
        return true;
      }

      if (opp.closeDate && !opp.targetCloseDate) {
        //check if opened deal is expired
        opp.isExpired = this._isExpired(opp.closeDate);
        opp.targetCloseDate = opp.closeDate;
        opp.closeDate = null;
      }

      return false;
    },
    _isExpired: function _isExpired(date) {
      var today = new Date();
      var closeDate = new Date(date);
      return closeDate < today;
    },
    actions: {}
  });

  _exports.default = _default;
});