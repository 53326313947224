define("inboxfront/components/channels/channel-list", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    channels: Ember.computed('selectedProperty.accounts.edges.[]', function () {
      var accounts = this.get('selectedProperty.accounts.edges');
      var hidden = ['LIVECHAT', 'SHOPIFY', 'BIGCOMMERCE', 'PIPEDRIVE'];
      return accounts.filter(function (account) {
        return hidden.indexOf(account.node.application) < 0;
      });
    }),
    actions: {
      showEditDialog: function showEditDialog(channel) {
        this.showDialogAction(channel, false);
      },
      showDelete: function showDelete(channel) {
        this.set('selectedChannel', channel);
        this.set('showDeleteDialog', true);
      },
      deleteChannel: function deleteChannel() {
        var _this = this;

        var channel = this.get('selectedChannel');
        var hash = {
          accountId: channel.id
        };
        this.ajax.mutation(_mutation.default.DELETE_ACCOUNT, 'deleteAccount', hash, function () {
          _this.get('paperToaster').show('Channel deleted');

          _this.set('selectedChannel', null);

          _this.onDelete(channel);
        });
      },
      updateStatus: function updateStatus(channel) {
        var _this2 = this;

        var hash = {
          accountId: channel.id,
          active: !channel.active,
          propertyId: Number(channel.companyProperty.id)
        };
        this.ajax.query(_mutation.default.ACTIVE_DEACTIVE_ACCOUNT, 'activeDeactiveAccount', hash).then(function (result) {
          Ember.set(channel, 'active', result.account.active);

          if (channel.active) {
            _this2.get('paperToaster').show(_this2.intl.t('channel_activated'));
          } else {
            _this2.get('paperToaster').show(_this2.intl.t('channel_deactivated'));
          }
        }).catch(function () {
          _this2.get('paperToaster').show(_this2.intl.t('network_error'));
        });
      }
    }
  });

  _exports.default = _default;
});