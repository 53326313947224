define("inboxfront/controllers/ecommerce", ["exports", "inboxfront/controllers/ecommerce/crm-integrations"], function (_exports, _crmIntegrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_crmIntegrations.default, {
    paperToaster: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    selectedIntegration: null,
    showLoginForm: false,
    showMarketDialog: false,
    queryParams: ['scope', 'code', 'context'],
    shopData: Ember.computed('selectedIntegration', 'selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount;

      var ret = false;
      (_this$_findAccount = this._findAccount(this.get('selectedIntegration'))) === null || _this$_findAccount === void 0 ? void 0 : _this$_findAccount.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'shop_name') {
          ret = {
            shop_name: e.node.value.split('.')[0]
          };
        } else if (e.node.key === 'bc_store_domain') {
          ret = {
            shop_name: e.node.value
          };
        }
      });
      return ret;
    }),
    shopifyIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount2;

      var ret = false;
      (_this$_findAccount2 = this._findAccount('SHOPIFY')) === null || _this$_findAccount2 === void 0 ? void 0 : _this$_findAccount2.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'shop_name') {
          ret = true;
        }
      });
      return ret;
    }),
    bigcommerceIsConnected: Ember.computed('selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var _this$_findAccount3;

      var ret = false;
      (_this$_findAccount3 = this._findAccount('BIGCOMMERCE')) === null || _this$_findAccount3 === void 0 ? void 0 : _this$_findAccount3.accountsettingSet.edges.forEach(function (e) {
        if (e.node.key === 'bc_store_domain') {
          ret = true;
        }
      });
      return ret;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onSelectProperty: function onSelectProperty(property) {
        this.set('selectedProperty', property);
        this.set('loadingProgress', '');
        this.set('isShowSFSettings', false); //TODO remove dependency
      },
      onSelectIntegration: function onSelectIntegration(name) {
        this.set('loadingProgress', '');
        this.set('selectedIntegration', name);
        this.set('showMarketDialog', true);
      },
      showLogin: function showLogin() {
        this.set('showLoginForm', true);
        this.set('shopifyDomain', '');
        this.set('bcDomain', '');
      },
      saveShopifyForm: function saveShopifyForm() {
        var shopName = this.get('shopifyDomain') + '.myshopify.com';

        try {
          var clientId = '0335e023bd1c093f9582b0e50fcde52c';
          var scope = ['read_products', 'write_script_tags', 'write_customers', 'write_orders', 'read_fulfillments', 'write_fulfillments', 'read_assigned_fulfillment_orders', 'read_merchant_managed_fulfillment_orders', 'read_third_party_fulfillment_orders', 'read_shipping'].join(',');
          var redirectURL = 'https://dev.raincheck.co/ecommerce';
          var nonce = 'qweasd';
          var accessMode = '';
          var authURL = "https://".concat(shopName, "/admin/oauth/authorize?client_id=").concat(clientId, "&scope=").concat(scope, "&redirect_uri=").concat(redirectURL, "&state=").concat(nonce, "&grant_options[]=").concat(accessMode);
          this.updateLocalStorage(shopName);
          window.location = authURL;
        } catch (e) {
          console.error(e);
        }
      },
      saveBCForm: function saveBCForm() {
        var shopName = 'store-' + this.get('bcDomain') + '.mybigcommerce.com';

        try {
          this.updateLocalStorage(shopName);
          window.location = "https://".concat(shopName, "/manage/marketplace/apps/35931");
        } catch (e) {
          console.error(e);
        }
      }
    },
    sendCode: function sendCode(_ref) {
      var code = _ref.code,
          context = _ref.context,
          scope = _ref.scope;

      if (context) {
        this.set('selectedIntegration', 'BIGCOMMERCE');
        var hash = {
          code: code,
          context: context,
          scope: scope
        };
        this.sendBigCommerceCode(this.prepareShopData(hash));
      } else {
        this.set('selectedIntegration', 'SHOPIFY');
        var _hash = {
          code: code
        };
        this.sendShopifyCode(this.prepareShopData(_hash));
      }
    },
    sendShopifyCode: function sendShopifyCode(hash) {
      var _this = this;

      var url = 'https://' + window.localStorage.getItem('RC_ShopifyCallbackDomain');
      this.set('installationInProgress', true);
      this.ajax.raw("".concat(url, "/auth/callback"), {
        method: 'POST',
        data: hash,
        headers: {
          'authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
        },
        crossDomain: true
      }).then(function (response) {}).catch(function (_ref2) {
        var response = _ref2.response,
            jqXHR = _ref2.jqXHR,
            payload = _ref2.payload;

        if (jqXHR.status === 200) {
          _this.get('selectedProperty.accounts.edges').addObject({
            node: {
              name: 'shopify',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'shop_name',
                    value: hash.shop_name
                  }
                }]
              }
            }
          });

          _this.get('paperToaster').show('Shopify store connected');

          _this.clearLocalStorage();
        }
      }).catch(function (e) {
        console.error(e);
      }).finally(function () {
        _this.set('installationInProgress', false);
      });
    },
    sendBigCommerceCode: function sendBigCommerceCode(hash) {
      var _this2 = this;

      var url = 'https://' + window.localStorage.getItem('RC_BigCommerceCallbackDomain');
      this.set('installationInProgress', true);
      this.ajax.raw("".concat(url, "/bigcommerce/callback"), {
        method: 'POST',
        data: hash,
        headers: {
          'authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
        },
        crossDomain: true
      }).then(function (response) {}).catch(function (_ref3) {
        var response = _ref3.response,
            jqXHR = _ref3.jqXHR,
            payload = _ref3.payload;

        if (jqXHR.status === 200) {
          _this2.get('selectedProperty.accounts.edges').addObject({
            node: {
              name: 'bigcommerce',
              accountsettingSet: {
                edges: [{
                  node: {
                    key: 'bc_store_domain',
                    value: hash.shop_name
                  }
                }]
              }
            }
          });

          _this2.get('paperToaster').show('BigCommerce store connected');

          _this2.clearLocalStorage();
        }
      }).finally(function () {
        _this2.set('installationInProgress', false);
      });
    },
    prepareShopData: function prepareShopData(hash) {
      hash.shop_name = window.localStorage.getItem('RC_ShopName');
      hash.widgetID = window.localStorage.getItem('RC_WidgetId');
      return hash;
    },
    updateLocalStorage: function updateLocalStorage(shopName) {
      window.localStorage.setItem('RC_PropertyId', this.get('selectedProperty.id'));
      window.localStorage.setItem('RC_WidgetId', this.get('selectedProperty.widgetSettings.id'));
      window.localStorage.setItem('RC_ShopName', shopName);
    },
    clearLocalStorage: function clearLocalStorage() {
      window.localStorage.removeItem('RC_PropertyId');
      window.localStorage.removeItem('RC_WidgetId');
      window.localStorage.removeItem('RC_ShopName');
    }
  });

  _exports.default = _default;
});