define("inboxfront/components/settings-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    currentLink: Ember.computed('router.currentRoute.queryParams', function () {
      var page = this.router.currentRouteName.split('.')[0];
      return page !== 'channels' ? page : this.router.currentRoute.queryParams.page;
    })
  });

  _exports.default = _default;
});