define("inboxfront/templates/components/dashboard/panels/support-trends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/icRD1xa",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dashboard/panel-loader\",null,[[\"isLoading\"],[[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"support_trends\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"support_trends\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panels/support-trends.hbs"
    }
  });

  _exports.default = _default;
});