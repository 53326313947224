define("inboxfront/controllers/dashboard", ["exports", "inboxfront/helpers/highchart-mock", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _highchartMock, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    kb: Ember.inject.service(),
    isAllProperty: Ember.computed('selectedProperty', function () {
      return this.get('selectedProperty').id === '*';
    }),
    mergeStatistics: function mergeStatistics() {
      var res = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var listDate = this.get('listDate');
      var list = {};
      var likes = [];
      var dislikes = [];
      var satisfactionRating = [];

      for (var i = 0; i < res.length; i++) {
        Object.keys(res[i].node).forEach(function (key) {
          list[key] = 0;
        });
      }

      var _loop = function _loop(_i) {
        Object.keys(res[_i].node).forEach(function (key) {
          list[key] += res[_i].node[key];
        });
      };

      for (var _i = 0; _i < res.length; _i++) {
        _loop(_i);
      }

      listDate.forEach(function (item) {
        var isDate = res.filter(function (el) {
          return item === el.node.date;
        });

        if (isDate.length) {
          likes.push(isDate[0].node.likes);
          dislikes.push(isDate[0].node.dislikes);
          satisfactionRating.push(isDate[0].node.satisfactionRating);
        } else {
          likes.push(0);
          dislikes.push(0);
          satisfactionRating.push(0);
        }
      });
      this.set('likes', likes);
      this.set('dislikes', dislikes);
      this.set('satisfactionRating', satisfactionRating);
      this.get('isAllProperty') ? this.set('filterComp', list) : this.set('filterProp', list);
    },
    daysBetween: function daysBetween(from, to) {
      return Math.ceil(Math.abs(to.getTime() - from.getTime()) / (1000 * 3600 * 24));
    },
    formatDate: function formatDate(date1, date2) {
      var d1 = date1.toISOString().slice(0, 10);
      var d2 = date2.toISOString().slice(0, 10);
      return d1.slice(5).replace('-', '/') + '-' + d2.slice(5).replace('-', '/');
    },
    nextDay: function nextDay(date) {
      date.setDate(date.getDate() + 1);
      return date;
    },
    prevDay: function prevDay(date) {
      var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      date.setDate(date.getDate() - count);
      return date;
    },
    selectDefaultDates: function selectDefaultDates() {
      var startDate = this.prevDay(new Date(), 7);
      var endDate = this.prevDay(new Date());
      this.set('startDate', startDate.toLocaleDateString().split('.').reverse().join('-'));
      this.set('endDate', endDate.toLocaleDateString().split('.').reverse().join('-'));
      var prevStartDate = this.prevDay(new Date(startDate), 7);
      var prevEndDate = this.prevDay(new Date(startDate));
      this.set('prevStartDate', prevStartDate.toLocaleDateString().split('.').reverse().join('-'));
      this.set('prevEndDate', prevEndDate.toLocaleDateString().split('.').reverse().join('-'));
      this.addListDates(startDate, endDate);
    },
    addListDates: function addListDates(start, end) {
      var startDate = new Date(start);
      var endDate = new Date(end);
      var now = new Date();
      var daysLag = this.daysBetween(startDate, endDate);
      var list = [];
      var chartsBarTitles = [];
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var datesGroups = [];

      if (daysLag > 180) {
        while (startDate < endDate) {
          var d = startDate.toISOString().slice(0, 10);
          list.push(d);
          var endOfMonth = new Date(startDate);
          endOfMonth.setDate(startDate.getDate() + 32);

          while (endOfMonth.getMonth() > startDate.getMonth()) {
            endOfMonth.setDate(endOfMonth.getDate() - 1);
          }

          if (startDate < endDate) {
            chartsBarTitles.pushObject(months[startDate.getMonth()]);
          } else {
            var d2 = endDate.toISOString().slice(0, 10);
            chartsBarTitles.pushObject(months[startDate.getMonth()]);
          }

          startDate = this.nextDay(endOfMonth);
        }
      } else if (daysLag > 21) {
        while (startDate < endDate) {
          var _d = startDate.toISOString().slice(0, 10);

          list.push(_d);
          var endOfWeek = new Date(startDate);
          endOfWeek.setDate(startDate.getDate() + 6);

          if (endOfWeek > endDate) {
            endOfWeek = endDate;
          }

          var day = new Date(startDate);
          var dayGroup = [];
          dayGroup.pushObject({
            date: day.toISOString().slice(0, 10)
          });

          while (day < endOfWeek) {
            day = this.nextDay(day);
            dayGroup.pushObject({
              date: day.toISOString().slice(0, 10)
            });
          }

          var dateMin = void 0;

          if (startDate < endDate) {
            var _d2 = endOfWeek.toISOString().slice(0, 10);

            if (startDate.getMonth() === endOfWeek.getMonth()) {
              dateMin = startDate.getDate() + '-' + endOfWeek.getDate() + ' ' + months[startDate.getMonth()];
              chartsBarTitles.pushObject(dateMin);
            } else {
              dateMin = startDate.getDate() + '-' + months[startDate.getMonth()] + '-' + endOfWeek.getDate() + ' ' + months[endOfWeek.getMonth()];
              chartsBarTitles.pushObject(dateMin);
            }
          } else {
            var _d3 = endDate.toISOString().slice(0, 10);

            dateMin = _d.slice(5).replace('-', '/') + '-' + _d3.slice(5).replace('-', '/');
            chartsBarTitles.pushObject(dateMin);
          }

          dayGroup.dateMin = dateMin;
          datesGroups.pushObject(dayGroup);
          startDate = this.nextDay(endOfWeek);
        }
      } else {
        if (endDate > now) {
          endDate = now;
        }

        for (var i = 0; i <= daysLag && startDate <= endDate; i++) {
          var _d4 = startDate.toISOString().slice(0, 10);

          list.pushObject(_d4);

          if (daysLag < 6) {
            chartsBarTitles.pushObject(days[startDate.getDay()]);
            datesGroups.pushObject({
              date: _d4,
              dateMin: days[startDate.getDay()]
            });
          } else {
            chartsBarTitles.pushObject(startDate.getDate() + ' ' + months[startDate.getMonth()]);
            datesGroups.pushObject({
              date: _d4,
              dateMin: startDate.getDate() + ' ' + months[startDate.getMonth()]
            });
          }

          startDate = this.nextDay(startDate);
        }
      }

      this.set('listDate', list);
      this.set('listDateMin', chartsBarTitles);
      this.set('datesGroups', datesGroups);
    },
    updateCompanyQuery: function updateCompanyQuery(startDate, endDate) {
      this.updatePropertyQuery(startDate, endDate, {
        id: 32
      });
      /*        this.ajax.query(Query.COMPANY_STATS, 'companyStats', {date: `[${startDate}, ${endDate}]`})
                  .then(({edges}) => {
                      const data = edges.map(e => e.node);
                       if (!edges.length && !this.get('isJoinedUser')) {
                          this.set('isShowStartScreen', true);
                          edges = [{node: HIGH_CHART_MOCK}];
                      }
                      this.mergeData(data);
                       // this.calcCompanies(edges);
                      // this.mergeStatistics(edges);
                  });/**/
    },
    updateAgentQuery: function updateAgentQuery(startDate, endDate) {
      var _this = this;

      this.ajax.query(_query.default.AGENT_STATS, 'agentStats', {
        date: "[".concat(startDate, ", ").concat(endDate, "]")
      }).then(function (res) {
        var arr = [];
        res === null || res === void 0 ? void 0 : res.edges.forEach(function (i) {
          var f = arr.some(function (el) {
            return el.id === i.node.agent.id;
          });

          if (!f) {
            arr.push({
              'id': i.node.agent.id,
              'name': i.node.agent.user.firstName + ' ' + i.node.agent.user.lastName,
              'likes': i.node.likes,
              'dislikes': i.node.dislikes,
              'chatHandled': i.node.chatHandled,
              'avgResponseTime': parseInt(i.node.avgResponseTime),
              'satisfactionRating': parseInt(i.node.satisfactionRating),
              'avgResolutionTime': 0,
              'overdueTickets': i.node.overdueTickets,
              'closedTickets': i.node.closedTickets,
              'openTickets': i.node.openTickets,
              'totalTickets': i.node.totalTickets
            });
          } else {
            var filter = arr.filter(function (el) {
              return el.id === i.node.agent.id;
            })[0];
            filter.likes += i.node.likes;
            filter.dislikes += i.node.dislikes;
            filter.chatHandled += i.node.chatHandled;
            filter.avgResponseTime += parseInt(i.node.avgResponseTime);
            filter.satisfactionRating += parseInt(i.node.satisfactionRating);
            filter.avgResolutionTime += 0;
            filter.overdueTickets += i.node.overdueTickets;
            filter.closedTickets += i.node.closedTickets;
            filter.openTickets += i.node.openTickets;
            filter.totalTickets += i.node.totalTickets;
          }
        });
        arr.map(function (el) {
          var filter = res.edges.filter(function (i) {
            return i.node.agent.id === el.id && i.node.satisfactionRating !== 0;
          });
          el.satisfactionRating = filter.length ? parseInt(el.satisfactionRating / filter.length) : el.satisfactionRating;
        });

        _this.set('props', arr);
      });
    },
    updatePropertyQuery: function updatePropertyQuery(startDate, endDate, estate) {
      var _this2 = this;

      if (this.get('isShowStartScreen')) {
        setTimeout(function () {
          var data = _this2.mergeData([]);

          _this2.set('dataGroups', data);

          setTimeout(function () {
            var data = _this2.mergeData([]);

            _this2.set('kbStats', data);
          }, 200);
        }, 200);
        return;
      }

      var hash = {
        date: "[".concat(startDate, ", ").concat(endDate, "]")
      };
      hash.estateId = estate ? estate.id : this.get('selectedProperty').id;
      this.set('isLoading', true);
      this.set('dataGroups', this.mergeData([]));
      this.ajax.query(_query.default.PROPERTY_STATS1, 'propertyStats', hash).then(function (_ref) {
        var edges = _ref.edges;

        if (!edges && !_this2.get('isJoinedUser')) {
          _this2.set('isShowStartScreen', true);
        }

        var data = (edges || []).map(function (e) {
          return e.node;
        });

        if (_this2.get('selectedProperty.id') === '*') {
          _this2.calcCompanies(edges || []);
        }

        _this2.set('dataGroups', _this2.mergeData(data));

        _this2.getPriorPeriodData(estate);
      }).finally(function () {
        _this2.set('isLoading', false);
      });
      var hash2 = {
        date: "[".concat(startDate, ", ").concat(endDate, "]")
      };
      this.set('kbStats', this.mergeData([]));
      this.ajax.query(_query.default.KB_STATS1, 'kbStatsistics', hash2).then(function (_ref2) {
        var edges = _ref2.edges;

        if (!edges && !_this2.get('isJoinedUser')) {
          _this2.set('isShowStartScreen', true);
        }

        var data = (edges || []).map(function (e) {
          return e.node;
        });

        _this2.set('kbStats', _this2.mergeData(data));
      });
    },
    getPriorPeriodData: function getPriorPeriodData(estate) {
      var _this3 = this;

      var prevStartDate = this.get('prevStartDate');
      var prevEndDate = this.get('prevEndDate');
      var startDate = new Date(prevStartDate);
      var endDate = new Date(prevEndDate);
      var now = new Date();
      var daysLag = this.daysBetween(startDate, endDate);
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var datesGroups = [];

      if (daysLag > 180) {
        while (startDate < endDate) {
          var endOfMonth = new Date(startDate);
          endOfMonth.setDate(startDate.getDate() + 32);

          while (endOfMonth.getMonth() > startDate.getMonth()) {
            endOfMonth.setDate(endOfMonth.getDate() - 1);
          }

          startDate = this.nextDay(endOfMonth);
        }
      } else if (daysLag > 21) {
        while (startDate < endDate) {
          var startDateShortISO = startDate.toISOString().slice(0, 10);
          var endOfWeek = new Date(startDate);
          endOfWeek.setDate(startDate.getDate() + 6);

          if (endOfWeek > endDate) {
            endOfWeek = endDate;
          }

          var day = new Date(startDate);
          var dayGroup = [];
          dayGroup.pushObject({
            date: day.toISOString().slice(0, 10)
          });

          while (day < endOfWeek) {
            day = this.nextDay(day);
            dayGroup.pushObject({
              date: day.toISOString().slice(0, 10)
            });
          }

          var dateMin = void 0;

          if (startDate < endDate) {
            if (startDate.getMonth() === endOfWeek.getMonth()) {
              dateMin = startDate.getDate() + '-' + endOfWeek.getDate() + ' ' + months[startDate.getMonth()];
            } else {
              dateMin = startDate.getDate() + '-' + months[startDate.getMonth()] + '-' + endOfWeek.getDate() + ' ' + months[endOfWeek.getMonth()];
            }
          } else {
            var d2 = endDate.toISOString().slice(0, 10);
            dateMin = startDateShortISO.slice(5).replace('-', '/') + '-' + d2.slice(5).replace('-', '/');
          }

          dayGroup.dateMin = dateMin;
          datesGroups.pushObject(dayGroup);
          startDate = this.nextDay(endOfWeek);
        }
      } else {
        if (endDate > now) {
          endDate = now;
        }

        for (var i = 0; i <= daysLag && startDate <= endDate; i++) {
          var d = startDate.toISOString().slice(0, 10);

          if (daysLag < 6) {
            datesGroups.pushObject({
              date: d,
              dateMin: days[startDate.getDay()]
            });
          } else {
            datesGroups.pushObject({
              date: d,
              dateMin: startDate.getDate() + ' ' + months[startDate.getMonth()]
            });
          }

          startDate = this.nextDay(startDate);
        }
      }

      this.set('prevDatesGroups', datesGroups);
      var hash = {
        date: "[".concat(prevStartDate, ", ").concat(prevEndDate, "]")
      };
      hash.estateId = estate ? estate.id : this.get('selectedProperty').id;
      this.set('prevDataGroups', this.mergeData([]));
      this.ajax.query(_query.default.PROPERTY_STATS1, 'propertyStats', hash).then(function (_ref3) {
        var edges = _ref3.edges;

        if (!edges && !_this3.get('isJoinedUser')) {
          _this3.set('isShowStartScreen', true);
        } else {
          var data = (edges || []).map(function (e) {
            return e.node;
          });

          _this3.set('prevDataGroups', _this3.mergeData(data, _this3.get('prevDatesGroups')));
        }
      });
    },
    mergeData: function mergeData(rawData, datesGroup) {
      var _this4 = this;

      var datesGroups = datesGroup || this.get('datesGroups');
      var dataGroups = [];
      datesGroups.forEach(function (data) {
        if (!Array.isArray(data)) {
          var stats = _this4.normalizeData(rawData.findBy('date', data.date) || {
            'id': 'NO DATA',
            'date': data.date,
            'dateMin': data.dateMin
          });

          stats.dateMin = data.dateMin;
          dataGroups.pushObject(stats);
        } else {
          var group = [];
          data.forEach(function (d) {
            var stats = rawData.findBy('date', d.date) || {};
            stats.dateMin = d.dateMin;
            group.pushObject(stats);
          });
          group = _this4.mergeGroup(group);
          group.dateMin = data.dateMin;
          dataGroups.pushObject(group);
        }
      });
      return dataGroups;
    },
    mergeGroup: function mergeGroup(group) {
      var data = {};
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime', 'totalVisitors', 'totalViews', 'totalLikes', 'totalDislikes', 'totalEnquiries', 'sucessfullSearch'];
      dataKeys.forEach(function (k) {
        data[k] = 0;
      });
      group.forEach(function (d) {
        dataKeys.forEach(function (k) {
          var value = +d[k];

          if (isNaN(value)) {
            value = 0;
          }

          data[k] += value;
        });
      });
      return data;
    },
    normalizeData: function normalizeData(data) {
      var dataKeys = ["totalMsgs", "totalMsgsFacebook", "totalMsgsLine", "totalMsgsTelegram", "totalMsgsEmail", "totalMsgsWhatsapp", "totalMsgsTwitter", "totalMsgsLivechat", "totalMsgsWechat", "totalChats", "visitorsByHours", "satisfactionRating", "avgResponseTime", "visitorsByLang", "visitorsByCountry", "likes", "dislikes", "overdueTickets", "closedTickets", "openTickets", "totalTickets", 'totalAssistantTriggered', 'totalAssistantTerminated', 'totalOpenChats', 'totalClosedChat', 'inboundMessagesPeak', 'agentResponsiveness', 'avgChatResolutionTime', 'totalQueueAbandonment', 'totalTasks', 'reopenedTickets', 'avgTicketResolutionTime', 'totalTicketsFacebook', 'totalTicketsLine', 'totalTicketsTelegram', 'totalTicketsEmail', 'totalTicketsWhatsapp', 'totalTicketsTwitter', 'totalTicketsLivechat', 'totalTicketsWechat', 'nonFirstContactResolutionTime', 'enquiryTickets', 'incidentTickets', 'cancellationTickets', 'complaintTickets', 'totalQuickResponseUsage', 'totalQuickResponseChanged', 'totalEscalation', 'totalEscalationUrgent', 'totalEscalationHigh', 'totalEscalationMedium', 'totalEscalationLow', 'avgResolutionTime', 'totalVisitors', 'totalViews', 'totalLikes', 'totalDislikes', 'totalEnquiries', 'sucessfullSearch'];
      dataKeys.forEach(function (k) {
        if (k !== 'date' && k !== 'dateMin' && isNaN(data[k])) {
          data[k] = 0;
        }
      });
      return data;
    },
    calcCompanies: function calcCompanies(data) {
      var prop = this.get('model.properties');
      var arr = [];

      for (var i = 0; i < prop.length; i++) {
        var obj = {};
        obj['dislikes'] = 0;
        obj['likes'] = 0;
        obj['avgResponseTime'] = 0;
        obj['satisfactionRating'] = 0;
        obj['chatHandled'] = 0;
        obj['totalTickets'] = 0;
        obj['openTickets'] = 0;
        obj['closedTickets'] = 0;
        obj['overdueTickets'] = 0;
        obj['avgResolutionTime'] = 0;
        obj['name'] = prop[i].title;
        arr.push(obj);
      }

      var _loop2 = function _loop2(_i2) {
        arr[_i2].name = prop[_i2].title;
        data.forEach(function (item) {
          if (item.node.estate.id === prop[_i2].id) {
            arr[_i2].dislikes += item.node.dislikes;
            arr[_i2].likes += item.node.likes;
            arr[_i2].chatHandled += item.node.chatHandled;
            arr[_i2].totalTickets += item.node.totalTickets;
            arr[_i2].openTickets += item.node.openTickets;
            arr[_i2].closedTickets += item.node.closedTickets;
            arr[_i2].overdueTickets += item.node.overdueTickets; //arr[i].avgResolutionTime += item.node.avgResolutionTime || 0;

            arr[_i2].avgResponseTime += item.node.avgResponseTime;
            arr[_i2].satisfactionRating += item.node.satisfactionRating;
          }
        });
      };

      for (var _i2 = 0; _i2 < prop.length; _i2++) {
        _loop2(_i2);
      }

      this.set('props', arr);
    },
    knowledgeBases: Ember.computed('kb.bases.[]', function () {
      var bases = [{
        id: '*',
        name: 'All Knowledge Bases'
      }];
      bases.addObjects(this.get('kb.bases'));
      return bases;
    }),
    actions: {
      onHeadAction: function onHeadAction(pageName) {
        var _this5 = this;

        this.set('pageName', pageName);
        setTimeout(function () {
          _this5.set('dataGroups', _toConsumableArray(_this5.get('dataGroups')));

          _this5.set('kbStats', _toConsumableArray(_this5.get('kbStats')));
        }, 500);
      },
      onSelectChange: function onSelectChange(value) {
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        this.set('selectedProperty', value);

        if (value.id === '*') {
          this.updateCompanyQuery(startDate, endDate);
        } else {
          this.updatePropertyQuery(startDate, endDate);
          this.updateAgentQuery(startDate, endDate);
        }
      },
      onSelectChangeBase: function onSelectChangeBase(value) {
        this.set('selectedBase', value);
      },
      dismissData: function dismissData() {
        this.set('isShowStartScreen', false);
        this.updatePropertyQuery(this.get('startDate'), this.get('endDate'), this.get('customGroups')[1]);
      },
      changeDate: function changeDate(dates) {
        var prevStartDate = dates.prevStartDate.toLocaleDateString().split('.').reverse().join('-');
        var prevEndDate = dates.prevEndDate.toLocaleDateString().split('.').reverse().join('-');
        this.set('prevStartDate', prevStartDate);
        this.set('prevEndDate', prevEndDate);
        var startDate = dates.startDate.toLocaleDateString().split('.').reverse().join('-');
        var endDate = dates.endDate.toLocaleDateString().split('.').reverse().join('-');
        this.set('startDate', startDate);
        this.set('endDate', endDate);
        this.addListDates(startDate, endDate);

        if (this.get('isAllProperty')) {
          this.updateCompanyQuery(startDate, endDate);
        } else {
          this.updatePropertyQuery(startDate, endDate);
          this.updateAgentQuery(startDate, endDate);
        }
      }
    }
  });

  _exports.default = _default;
});