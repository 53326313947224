define("inboxfront/templates/components/page-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ny4/5LSG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"page-inner-content \",[28,\"if\",[[24,[\"class\"]],[24,[\"class\"]]],null]]]],[8],[0,\"\\n\"],[4,\"ember-scrollable\",null,null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/page-content.hbs"
    }
  });

  _exports.default = _default;
});