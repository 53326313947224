define("inboxfront/controllers/group/edit", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    showDeleteGroupDialog: false,
    queryParams: ['id'],
    restCompanies: Ember.computed('groupProperties.length', function () {
      var _this = this;

      return this.get('properties').filter(function (company) {
        return !_this.get('groupProperties').find(function (c) {
          return c.id === company.id;
        });
      });
    }),
    restUsers: Ember.computed('users.length', function () {
      var _this2 = this;

      return this.get('modelUsers').filter(function (user) {
        return !_this2.get('users').find(function (u) {
          return Number(u.id) === user.id;
        });
      });
    }),
    actions: {
      updateCompanyName: function updateCompanyName() {
        var name = this.get('group.name');

        if (name) {
          this.saveCompanyGroup({
            name: name
          });
        }
      },
      updateCompanyDescription: function updateCompanyDescription() {
        this.saveCompanyGroup({
          description: this.get('group.description')
        });
      },
      addCompany: function addCompany() {
        var _this3 = this;

        if (!this.get('showCompanySelect')) {
          this.set('showCompanySelect', true);
          return;
        }

        if (!this.get('selectedCompany')) {
          return;
        }

        this.get('groupProperties').pushObject(this.get('selectedCompany'));
        var properties = this.get('groupProperties').map(function (property) {
          return Number(property.id);
        });
        this.saveCompanyGroup({
          properties: properties
        }, function () {
          _this3.get('paperToaster').show(_this3.intl.t('company_added_to_group'));

          _this3.set('selectedCompany', null);
        });
      },
      removeCompany: function removeCompany(company) {
        var _this4 = this;

        this.get('groupProperties').removeObject(company);
        var properties = this.get('groupProperties').map(function (property) {
          return Number(property.id);
        });
        this.saveCompanyGroup({
          properties: properties
        }, function () {
          _this4.get('paperToaster').show(_this4.intl.t('company_removed_from_group'));
        });
      },
      addUser: function addUser() {
        var _this5 = this;

        if (!this.get('showUserSelect')) {
          this.set('showUserSelect', true);
          return;
        }

        if (!this.get('selectedUser')) {
          return;
        }

        this.get('users').pushObject(this.get('selectedUser'));
        var users = this.get('users').map(function (user) {
          return Number(user.id);
        });
        this.saveCompanyGroup({
          users: users
        }, function () {
          _this5.get('paperToaster').show(_this5.intl.t('user_added_to_group'));

          _this5.set('selectedUser', null);
        });
      },
      removeUser: function removeUser(user) {
        var _this6 = this;

        this.get('users').removeObject(user);
        var users = this.get('users').map(function (u) {
          return Number(u.id);
        });
        this.saveCompanyGroup({
          users: users
        }, function () {
          _this6.get('paperToaster').show(_this6.intl.t('user_removed_from_group'));
        });
      },
      deleteGroup: function deleteGroup() {
        var _this7 = this;

        this.ajax.mutation(_mutation.default.DELETE_COMPANY_GROUP, 'deleteCompanyGroup', {
          companyGroupId: this.get('groupId')
        }, function () {
          _this7.get('paperToaster').show(_this7.intl.t('company_group_deleted'));

          _this7.set('showDeleteGroupDialog', false);

          _this7.transitionToRoute('group.index');
        });
      }
    },
    saveCompanyGroup: function saveCompanyGroup(hash, onSuccess) {
      hash.companyGroupId = this.get('groupId');
      this.ajax.mutation(_mutation.default.CREATE_UPDATE_COMPANY_GROUP, 'createUpdateCompanyGroup', hash, onSuccess);
    }
  });

  _exports.default = _default;
});