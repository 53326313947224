define("inboxfront/components/common/two-way-bind-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    isEditMode: true,
    visible: true,
    selected: Ember.computed('value', 'field.showProp', function () {
      if (this.get('field.showProp')) {
        return this.get('field.options').findBy('iso2', this.get('value.iso2'));
      }

      return this.get('value');
    }),
    fieldType: Ember.computed('params.[]', 'field.fieldType', function () {
      var ret;

      if (this.params && this.params[0]) {
        ret = this.params[0];
      } else {
        ret = this.get('field.fieldType');
      }

      ret = ret || 'INPUT';
      return ret;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(value) {
          var valid = true;

          if (_this.get('field.required') === true) {
            valid = typeof value === 'string' ? value.trim() !== '' : value;
          }

          return valid;
        }
      }];
      this.websiteValidation = [this.trimValidation[0], {
        message: this.intl.t('forms.provide_website'),
        validate: function validate(value) {
          var valid = true;

          if (value && value.length) {
            var websitePatter = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; //eslint-disable-line

            valid = websitePatter.test(value);
          }

          return valid;
        }
      }];
    },
    actions: {
      onChange: function onChange(value) {
        this.set('value', value);
        this.onChange && this.onChange(value, this.get('field'));
      },
      onSelect: function onSelect(value) {
        this.set('value', value);
        this.onChange && this.onChange(value, this.get('field'));
      }
    }
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});