define("inboxfront/components/dashboard/panels/satisfaction-rating-combo", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#satisfaction_rating')).highcharts({
        chart: {
          height: 315
        },
        title: null,
        xAxis: {
          categories: [],
          title: null
        },
        yAxis: [{
          title: null
        }, {
          title: null,
          labels: {
            format: '{value} %'
          },
          opposite: true
        }],
        legend: {
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top'
        },
        series: [{
          type: 'column',
          name: 'Like',
          color: '#697FA3',
          data: []
        }, {
          type: 'column',
          name: 'Dislike',
          color: '#C7D6E0',
          data: []
        }, {
          type: 'spline',
          name: 'Satisfaction Rating',
          yAxis: 1,
          tooltip: {
            valueSuffix: '%'
          },
          color: '#043E6D',
          data: []
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var likes = [];
      var dislikes = [];
      var rating = [];
      var series = [likes, dislikes, rating];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function () {
        if (realData) {
          var data = dataGroups[i];
          likes.pushObject(data === null || data === void 0 ? void 0 : data.likes);
          dislikes.pushObject(data === null || data === void 0 ? void 0 : data.dislikes);
          rating.pushObject(data === null || data === void 0 ? void 0 : data.satisfactionRating);
        } else {
          likes.pushObject(Math.ceil(Math.random() * 100));
          dislikes.pushObject(Math.ceil(Math.random() * 100));
          rating.pushObject(Math.ceil(Math.random() * 100));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.series[2].setData(series[2]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});