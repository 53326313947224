define("inboxfront/components/dashboard/panels/tickets-source", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#tickets_source')).highcharts({
        chart: {
          type: 'bar',
          height: 315
        },
        title: null,
        xAxis: {
          title: null,
          categories: []
        },
        yAxis: {
          title: null,
          categories: []
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            pointWidth: 15
          }
        },
        legend: {
          align: 'left'
        },
        series: [{
          name: this.intl.t('email'),
          color: '#3F524C',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('phone'),
          color: '#51685C',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('line'),
          color: '#698079',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('messenger'),
          color: '#627E6C',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('livechat'),
          color: '#4D6755',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('wechat'),
          color: '#96AC87',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('twitter'),
          color: '#E1E6C6',
          data: [],
          pointWidth: 14
        }, {
          name: this.intl.t('telegram'),
          color: '#DCD9B6',
          data: [],
          pointWidth: 14
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      });
      var email = [];
      var phone = [];
      var line = [];
      var messenger = [];
      var livechat = [];
      var wechat = [];
      var twitter = [];
      var telegram = [];
      var series = [email, phone, line, messenger, livechat, wechat, twitter, telegram];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function () {
        if (realData) {
          var data = dataGroups[i];
          email.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsEmail);
          phone.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsPhone);
          line.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsLine);
          messenger.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsFacebook);
          livechat.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsLivechat);
          wechat.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsWechat);
          twitter.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsTwitter);
          telegram.pushObject(data === null || data === void 0 ? void 0 : data.totalTicketsTelegram);
        } else {
          email.pushObject(Math.ceil(Math.random() * 50 + 20));
          phone.pushObject(Math.ceil(Math.random() * 50 + 20));
          line.pushObject(Math.ceil(Math.random() * 50 + 20));
          messenger.pushObject(Math.ceil(Math.random() * 50 + 20));
          livechat.pushObject(Math.ceil(Math.random() * 50 + 20));
          wechat.pushObject(Math.ceil(Math.random() * 50 + 20));
          twitter.pushObject(Math.ceil(Math.random() * 50 + 20));
          telegram.pushObject(Math.ceil(Math.random() * 50 + 20));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates);
      chart.series[0].setData(series[0]);
      chart.series[1].setData(series[1]);
      chart.series[2].setData(series[2]);
      chart.series[3].setData(series[3]);
      chart.series[4].setData(series[4]);
      chart.series[5].setData(series[5]);
      chart.series[6].setData(series[6]);
      chart.series[7].setData(series[7]);
      chart.redraw();
    }
  });

  _exports.default = _default;
});