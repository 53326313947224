define("inboxfront/templates/components/helpdesk/priority-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KIbYaDh2",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"isEditMode\"]]],null,{\"statements\":[[4,\"power-select\",null,[[\"class\",\"options\",\"placeholder\",\"selected\",\"disabled\",\"onChange\"],[[28,\"concat\",[\"priority-selector \",[24,[\"class\"]]],null],[24,[\"options\"]],[24,[\"placeholder\"]],[24,[\"selected\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"changeValue\"],null]]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[[23,1,[\"id\"]]]]],[8],[0,\"\\n            \"],[1,[28,\"svg-icon\",[[28,\"concat\",[\"priority-\",[23,1,[\"id\"]]],null]],null],false],[0,\"\\n            \"],[1,[28,\"t\",[[23,1,[\"title\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"priority-selector \",[24,[\"selected\",\"id\"]]]]],[8],[0,\"\\n        \"],[1,[28,\"svg-icon\",[[28,\"concat\",[\"priority-\",[24,[\"selected\",\"id\"]]],null]],null],false],[0,\"\\n        \"],[1,[28,\"t\",[[24,[\"selected\",\"title\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/helpdesk/priority-selector.hbs"
    }
  });

  _exports.default = _default;
});