define("inboxfront/templates/components/assistants/flows/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "59pGmFra",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"single-option action\"],[8],[0,\"\\n    \"],[1,[24,[\"nextComponent\",\"name\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/flows/action.hbs"
    }
  });

  _exports.default = _default;
});