define("inboxfront/components/assistants/zoom-slider", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['zoom-slider'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)('.canvas-block').on('mousewheel', function (event) {
        if (event.ctrlKey) {
          event.preventDefault();

          var canvasZoom = _this.get('canvasZoom');

          if (event.originalEvent.deltaY < 0) {
            canvasZoom = canvasZoom + 5;

            if (canvasZoom > 200) {
              canvasZoom = 200;
            }
          } else {
            canvasZoom = canvasZoom - 5;

            if (canvasZoom < 20) {
              canvasZoom = 20;
            }
          }

          _this.set('canvasZoom', canvasZoom);

          var scale = canvasZoom / 100;
          (0, _jquery.default)('.canvas-inner').css('zoom', scale);
        }
      }).on('dblclick', function () {
        _this.set('canvasZoom', 100);

        (0, _jquery.default)('.canvas-inner').css('zoom', 1);
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)('.canvas-inner').off();
      (0, _jquery.default)('.canvas-block').off();
    },
    actions: {
      onChange: function onChange(value) {
        var canvasZoom = this.get('canvasZoom');
        var scale = canvasZoom / 100;
        (0, _jquery.default)('.canvas-inner').css('zoom', scale);
      }
    }
  });

  _exports.default = _default;
});