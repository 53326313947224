define("inboxfront/templates/components/assistants/flows/templates-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zU6XIqqn",
    "block": "{\"symbols\":[\"flow\"],\"statements\":[[7,\"div\",true],[10,\"style\",\"display: flex; flex-direction: row;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"style\",\"flex:1\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"container-markets\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"ecommerce-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"flows\"]]],null,{\"statements\":[[0,\"                    \"],[1,[28,\"assistants/flows/templates-list-item\",null,[[\"flow\",\"onSelectTemplate\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onSelectTemplate\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"ecommerce-tab\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onCreateTemplate\"]]],null]],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"flex-space-between\"],[8],[0,\"\\n                        \"],[1,[28,\"svg-icon\",[\"plus\"],null],false],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Create Template\"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/assistants/flows/templates-list.hbs"
    }
  });

  _exports.default = _default;
});