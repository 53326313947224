define("inboxfront/components/assistants/flows/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component.id', function () {
      var _component$children;

      var component = this.get('blocks').findBy('id', this.get('component.id'));
      return (_component$children = component.children) === null || _component$children === void 0 ? void 0 : _component$children.filter(function (child) {
        return child.blockType !== 'BUTTON';
      })[0];
    }),
    sliders: Ember.computed('component', function () {
      var _component$children2;

      var component = this.get('blocks').findBy('id', this.get('component.id'));
      return (_component$children2 = component.children) === null || _component$children2 === void 0 ? void 0 : _component$children2.filter(function (child) {
        return child.blockType === 'BUTTON';
      });
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      },
      deleteComponent: function deleteComponent(component, event) {
        this.deleteComponent(component, event);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        this.deleteComponentAll(component, event);
      },
      duplicatePath: function duplicatePath(component, event) {
        this.duplicatePath(component, event);
      }
    }
  });

  _exports.default = _default;
});