define("inboxfront/templates/components/kb/dialogs/delete-category-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8rXjSLnn",
    "block": "{\"symbols\":[\"dialog\"],\"statements\":[[4,\"common/dialogs/warning\",null,[[\"open\",\"onConfirm\"],[[24,[\"open\"]],[24,[\"onConfirm\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `dialog.title` to be a contextual component but found a string. Did you mean `(component dialog.title)`? ('inboxfront/templates/components/kb/dialogs/delete-category-confirm.hbs' @ L6:C7) \"],null]],null,{\"statements\":[[0,\"        Delete Category \"],[1,[24,[\"category\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `dialog.body` to be a contextual component but found a string. Did you mean `(component dialog.body)`? ('inboxfront/templates/components/kb/dialogs/delete-category-confirm.hbs' @ L10:C7) \"],null]],[[\"icon\"],[\"trash2\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[0,\"This category will be deleted permanently. This action cannot be undone.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"btn-cancel\"]],false],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"btn-confirm\"]],\"expected `dialog.btn-confirm` to be a contextual component but found a string. Did you mean `(component dialog.btn-confirm)`? ('inboxfront/templates/components/kb/dialogs/delete-category-confirm.hbs' @ L15:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"actions.delete\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/kb/dialogs/delete-category-confirm.hbs"
    }
  });

  _exports.default = _default;
});