define("inboxfront/components/dashboard/panels/kb-article-enquries", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['dashboard-panel'],
    popular: Ember.computed('pageName', 'listDateMin', 'realData', function () {
      var realData = this.get('realData');

      if (realData) {
        return [];
      }

      return [{
        name: 'Refunds',
        num: 100
      }, {
        name: 'Returns',
        num: 95
      }, {
        name: 'Billing',
        num: 70
      }, {
        name: 'Exchange',
        num: 65
      }];
    }),
    failed: Ember.computed('pageName', 'listDateMin', 'realData', function () {
      var realData = this.get('realData');

      if (realData) {
        return [];
      }

      return [{
        name: 'Track',
        num: 5
      }];
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var realData = this.get('realData');

      _highcharts.default.chart({
        chart: {
          type: 'pie',
          renderTo: 'kb-art',
          height: 315,
          width: 315
        },
        title: null,
        legend: {
          enabled: true
        },
        tooltip: {
          valueDecimals: 0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true,
            animation: false
          },
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px'
              },
              connectorWidth: 0
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: [{
            name: 'Failed',
            y: realData ? 0 : Math.ceil(Math.random() * 20),
            color: '#A486D5'
          }, {
            name: 'Successful',
            y: realData ? 0 : Math.ceil(Math.random() * 30 + 30),
            color: '#301862'
          }]
        }]
      });
    }
  });

  _exports.default = _default;
});