define("inboxfront/mixins/events-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseMove', this.handleMouseMove.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseMove', this.handleMouseMove.bind(this));
      this.element.removeEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    touchStart: function touchStart(e) {
      return this.down(e);
    },
    mouseDown: function mouseDown(e) {
      this.down(e);
    },
    touchEnd: function touchEnd(e) {
      return this.up(e);
    },
    mouseUp: function mouseUp(e) {
      return this.up(e);
    },
    touchCancel: function touchCancel(e) {
      return this.up(e);
    },
    handleMouseLeave: function handleMouseLeave(e) {
      return this.up(e);
    },
    up: function up() {},
    down: function down() {},
    contextMenu: function contextMenu() {},
    handleMouseMove: function handleMouseMove(e) {
      return this.move(e);
    },
    touchMove: function touchMove(e) {
      return this.move(e);
    },
    pointerMove: function pointerMove(e) {
      return this.move(e);
    },
    move: function move() {}
  });

  _exports.default = _default;
});