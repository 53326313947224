define("inboxfront/controllers/companies/index", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    showCreateDialog: false,
    showDeactivateDialog: false,
    showDeleteDialog: false,
    actions: {
      onCompanyCreate: function onCompanyCreate(company, createAnotherCompany) {
        var _this = this;

        try {
          var hash = {
            industry: company.industry,
            code: company.code,
            title: company.title,
            address: company.address,
            postalCode: company.postalCode,
            country: company.propertyCountry.iso2,
            phoneNumber: company.phoneNumber,
            website: company.website
          };
          this.ajax.mutation(_mutation.default.CREATE_PROPERTY, 'createCompanyProperty', hash, function (res) {
            _this.get("paperToaster").show(_this.intl.t('company_created'));

            if (!createAnotherCompany) {
              _this.refreshRoute();

              _this.set("showCreateDialog", false);
            }
          });
        } catch (e) {
          console.error(e);
        }
      },
      onCreateClose: function onCreateClose(companyCreated) {
        companyCreated && this.refreshRoute();
      },
      deactivateDialog: function deactivateDialog(company) {
        this._showDeactivateDialog(company);
      },
      deactivateCompany: function deactivateCompany() {
        this._deactivateCompany();
      },
      activateCompany: function activateCompany(company) {
        this._activateCompany(company);
      },
      deleteDialog: function deleteDialog(company) {
        this.set('selectedCompany', company);
        this.set('showDeleteDialog', true);
      },
      deleteCompany: function deleteCompany() {
        var _this2 = this;

        this.ajax.mutation(_mutation.default.DELETE_COMPANY, 'deleteProperty', {
          propertyId: this.get('selectedCompany.id')
        }, function () {
          _this2.set('showDeleteDialog', false);

          _this2.refreshRoute();

          _this2.get("paperToaster").show(_this2.intl.t('company_deleted'));
        });
        this.get('model.companies').removeObject(this.get('selectedCompany'));
      },
      changeCompanyStatus: function changeCompanyStatus(company) {
        if (company.isActive) {
          this._showDeactivateDialog(company);
        } else {
          this._activateCompany(company);
        }
      }
    },
    _showDeactivateDialog: function _showDeactivateDialog(company) {
      this.set('selectedCompany', company);
      this.set('showDeactivateDialog', true);
    },
    _activateCompany: function _activateCompany(company) {
      var _this3 = this;

      var hash = {
        isActive: true,
        propertyId: company.id
      };
      this.ajax.mutation(_mutation.default.ACTIVE_DEACTIVE_COMPANY, 'activeDeactiveProperty', hash, function () {
        Ember.set(company, 'isActive', true);

        _this3.get("paperToaster").show(_this3.intl.t('company_activated'));
      });
    },
    _deactivateCompany: function _deactivateCompany() {
      var _this4 = this;

      var company = this.get('selectedCompany');
      var hash = {
        isActive: false,
        propertyId: company.id
      };
      this.ajax.mutation(_mutation.default.ACTIVE_DEACTIVE_COMPANY, 'activeDeactiveProperty', hash, function () {
        Ember.set(company, 'isActive', false);

        _this4.set('showDeactivateDialog', false);

        _this4.get("paperToaster").show(_this4.intl.t('company_deactivated'));
      });
    },
    refreshRoute: function refreshRoute() {
      var route = Ember.getOwner(this).lookup("route:".concat(this.router.currentRoute.name));
      route.refresh();
    }
  });

  _exports.default = _default;
});