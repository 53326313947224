define("inboxfront/components/page-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    showButton: true,
    isCollapsed: false,
    currentIcon: Ember.computed('router.currentRoute.queryParams', function () {
      var _this = this;

      var currentRoute = this.router.currentRouteName;
      var icon = 'admin-company-settings';

      if (this.get('adminMenuIcons')) {
        this.get('adminMenuIcons').forEach(function (menuItem) {
          if (menuItem.link === currentRoute) {
            if (!_this.get('router.currentRoute.queryParams.page')) {
              icon = menuItem.icon;
            } else {
              icon = _this.get('router.currentRoute.queryParams.page') === 'widget' ? 'configure' : 'channels2';
            }
          } else if ('ecommerce' === currentRoute) {
            icon = 'admin-marketplace-settings';
          } else if ('settings' === currentRoute) {
            icon = 'settings';
          }
        });
      }

      return icon;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.adminMenuIcons = [{
        icon: 'company',
        link: 'companies.index'
      }, {
        icon: 'group',
        link: 'group.index'
      }, {
        icon: 'departments',
        link: 'departments'
      }, {
        icon: 'contact-fields',
        link: 'contactfields'
      }, {
        icon: 'organization-fields',
        link: 'orgfields'
      }, {
        icon: 'issue-types',
        link: 'issuetypes'
      }, {
        icon: 'ticket-fields',
        link: 'ticketfields'
      }, {
        icon: 'users',
        link: 'users.index'
      }, {
        icon: 'roles',
        link: 'roles.index'
      }, {
        icon: 'lock3',
        link: 'permissions'
      }, {
        icon: 'configure',
        link: 'channels',
        type: 'widget',
        query: 'widget'
      }, {
        icon: 'channels2',
        link: 'channels',
        query: 'channels'
      }, {
        icon: 'configure',
        link: 'chatslimit'
      }, {
        icon: 'quick-response',
        link: 'response'
      }];
    }
  });

  _exports.default = _default;
});