define("inboxfront/routes/permissions", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: function model() {
      var grid = this.ajax.query(_query.default.GRID);
      var roles = this.ajax.query(_query.default.COMPANY_CUSTOM_ROLES);
      return Ember.RSVP.all([grid, roles]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            grid = _ref2[0],
            roles = _ref2[1];

        return {
          grid: !grid.errors ? grid : 'null',
          roles: !roles.errors ? roles : []
        };
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var parse = JSON.parse(model.grid);
      controller.set('grid', parse ? this.sortGrid(parse) : []);
      model.roles.forEach(function (el) {
        el.readyGrid = el.readyGrid !== null ? JSON.parse(el.readyGrid) : _this.sortGrid(parse);
      });
      controller.set('currentGrid', model.roles.length ? model.roles[0].readyGrid[0].arr : {});
      controller.set('selectedRole', model.roles.length ? model.roles[0] : {});
      controller.set('selectedTab', parse ? Object.keys(parse)[0] : {});
      controller.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete' // 'Permissions.delegate_assign',
      // 'Permissions.respond_reply'
      ]);

      try {
        var currentGrid = controller.get('selectedRole').readyGrid.filter(function (el) {
          return el.name === controller.get('selectedTab');
        })[0];
        var arr = [];
        currentGrid.arr.forEach(function (item) {
          var a = _objectSpread({}, item);

          a.subArr = _toConsumableArray(item.subArr);
          arr.pushObject(a);
        });

        if (currentGrid.name === 'Conversation') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.delegate_assign', 'Permissions.respond_reply']);
        } else if (currentGrid.name === 'Administration') {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete', 'Permissions.publish']);
          arr.forEach(function (i) {
            var a = i.subArr.slice(0, 4);
            i.subArr = a.concat(i.subArr.slice(i.subArr.length - 1));
          });
        } else {
          this.set('titleTabs', ['Permissions.item', 'Permissions.full_control', 'Permissions.view_read', 'Permissions.create_add', 'Permissions.edit', 'Permissions.delete']);
          arr.forEach(function (i) {
            i.subArr = i.subArr.slice(0, -2);
          });
        }

        controller.set('currentGrid', arr);
      } catch (e) {
        console.error(e);
      }
    },
    sortGrid: function sortGrid(parse) {
      var _this2 = this;

      return Object.keys(parse).map(function (key) {
        return {
          name: key,
          arr: _this2.mappingGrid(parse[key])
        };
      });
    },
    mappingGrid: function mappingGrid(val) {
      return Object.keys(val).map(function (key) {
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          enabled: false,
          subArr: val[key].map(function (item) {
            return {
              name: item[0],
              id: item[1],
              enabled: false,
              type: key.charAt(0).toUpperCase() + key.slice(1)
            };
          })
        };
      });
    }
  });

  _exports.default = _default;
});