define("inboxfront/services/slice-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROWSPERPAGE = 10;

  var _default = Ember.Service.extend({
    pageNum: 1,
    getPageNum: function getPageNum() {
      return this.pageNum;
    },
    calculateRange: function calculateRange(data) {
      var rowsPerPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ROWSPERPAGE;
      return Math.ceil((data.length + 1) / rowsPerPage);
    },
    getSliceData: function getSliceData(data, pageNum) {
      var rowsPerPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ROWSPERPAGE;

      var sliceData = this._slideData(data, pageNum, rowsPerPage);

      if (!sliceData.length && pageNum > 1) {
        pageNum = pageNum - 1;
        this.set('pageNum', pageNum);
        sliceData = this._slideData(data, pageNum, rowsPerPage);
      }

      sliceData.forEach(function (obj) {
        //TODO rework resetting checkboxes, remove from pagination to table?
        Ember.set(obj, 'checked', false);
      });
      return sliceData;
    },
    _slideData: function _slideData(data, pageNum, rowsPerPage) {
      return data.slice((pageNum - 1) * rowsPerPage, pageNum * rowsPerPage);
    }
  });

  _exports.default = _default;
});