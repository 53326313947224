define("inboxfront/components/chat/room-info-exchanges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedTab: "Media",
    messages: Ember.computed('room', 'room.messages.edges.[]', function () {
      return this.get('room.messages.edges') || [];
    }),
    images: Ember.computed('messages', function () {
      var ret = [];
      this.get('messages').forEach(function (msg) {
        if (msg.node.attachments && msg.node.attachments.length) {
          var extension = msg.node.attachments[0].extension;

          if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
            ret.push(msg.node.attachments[0]);
          }
        }
      });
      return ret;
    }),
    documents: Ember.computed('messages', function () {
      var ret = [];
      this.get('messages').forEach(function (msg) {
        if (msg.node.attachments && msg.node.attachments.length) {
          var extension = msg.node.attachments[0].extension;

          if (extension === 'pdf' || extension === 'text/csv') {
            ret.unshift(msg.node.attachments[0]);
          }
        }
      });
      return ret;
    }),
    links: Ember.computed('messages', function () {
      var ret = [];
      var regExp = /(<a target="_blank" [^>]+>.*?<\/a>)/g;
      this.get('messages').forEach(function (msg) {
        var matches = msg.node.text && msg.node.text.match(regExp) || null;

        if (matches !== null) {
          ret = ret.concat(matches);
        }
      });
      return ret;
    })
  });

  _exports.default = _default;
});