define("inboxfront/components/assistants/flows/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component', function () {
      var component = this.get('component');
      var block = this.get('blocks').findBy('id', component.id);

      if (block.name === 'Action') {
        if (typeof block.actions === 'string') {
          var extraData = JSON.parse(block.actions);
          Ember.set(block, 'actions', extraData.actions);
          Ember.set(block, 'alwaysBtn', extraData.alwaysBtn);
          Ember.set(block, 'checkCondition', extraData.checkCondition);
          Ember.set(block, 'actionsFlow', extraData.actionsFlow);
          Ember.set(block, 'rules', []);
        }
      }

      return block;
    }),
    countComponents: Ember.computed('nextComponent', function () {
      var components = this.get('nextComponent.children').filter(function (child) {
        return child.blockType === 'BUTTON';
      });
      return components.length;
    }),
    countFormFields: Ember.computed('nextComponent', function () {
      if (this.get('nextComponent.blockType') === 'FORM') {
        var extraData = JSON.parse(this.get('nextComponent.extraData'));

        if (!Array.isArray(extraData.dataCollectorsSelect)) {
          extraData.dataCollectorsSelect = [];
        }

        return extraData.dataCollectorsSelect.length;
      }

      return 0;
    }),
    msgIsLong: Ember.computed('nextComponent.mainTitle', function () {
      return this.get('nextComponent.mainTitle.length') > 20;
    }),
    isValidBlock: Ember.computed('nextComponent.mainTitle', function () {
      return !!this.get('nextComponent.mainTitle');
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      },
      deleteComponent: function deleteComponent(component, event) {
        this.deleteComponent(component, event);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        this.deleteComponentAll(component, event);
      },
      duplicatePath: function duplicatePath(component, event) {
        this.duplicatePath(component, event);
      }
    }
  });

  _exports.default = _default;
});