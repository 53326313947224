define("inboxfront/services/chats", ["exports", "inboxfront/gql/query", "inboxfront/gql/chats", "inboxfront/config/environment", "jquery"], function (_exports, _query, _chats, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedRoom = null;
      this.bookmarks = [];
      this.rooms = [];
      this.reminders = [];
    },
    load: function load() {
      var _this = this;

      this.ajax.querySessionKey().then(function () {
        _this.updateRooms();

        _this.loadBookmarks();
      });
    },
    loadReminders: function loadReminders() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        return _this2.ajax.query(_query.default.GET_MESSAGE_REMINDERS).then(function (res) {
          var remindersByRoom = {};
          var reminders = Array.isArray(res) ? res : [];
          reminders.forEach(function (rem) {
            if (!remindersByRoom[rem.message.room.id]) {
              remindersByRoom[rem.message.room.id] = [];
            }

            remindersByRoom[rem.message.room.id].addObject(rem);
            var created = new Date(rem.createdAt);
            var now = new Date();
            var delta = Math.round(now.getTime() / 1000) - Math.round(created.getTime() / 1000);
            rem.expired = Math.abs(delta) > 3 * 24 * 3600;
          });

          _this2.get('reminders').setObjects(reminders);

          resolve({
            remindersByRoom: remindersByRoom,
            remindersById: _this2.get('reminders')
          });
        });
      });
    },
    loadRoom: function loadRoom(id) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.ajax.query(_chats.default.GET_ROOM, 'room', {
          roomId: id
        }).then(function (roomData) {
          var room = _this3.get('rooms').findBy('id', id);

          if (!room) {
            room = _this3.createRoom(roomData);

            _this3.get('rooms').addObject(room);
          }

          if (_this3.get('reminders')[roomData.id]) {
            Ember.set(room, 'reminders', _this3.get('reminders')[roomData.id]);
          }

          if (!room.isGroup) {
            if (!room.messages._parsed) {
              //parse data="{task_id:'...'}"
              var reminders = _this3.get('reminders');

              room.messages.edges.forEach(function (edge) {
                if (edge.node.data) {
                  try {
                    edge.node.data = JSON.parse(edge.node.data);

                    if (edge.node.data.reminder_id) {
                      edge.node.data.reminder = reminders.findBy('id', '' + edge.node.data.reminder_id);
                    }
                  } catch (e) {
                    edge.node.data = undefined;
                    console.error(e);
                  }
                }
              });
              room.messages._parsed = true;
            }
          }

          _this3.checkMentioned(room, roomData);

          _this3.setMessages(room, Array.isArray(roomData.messages && roomData.messages.edges) ? roomData.messages.edges.map(function (edge) {
            return edge.node;
          }) : []);

          _this3.set('selectedRoom', room);

          resolve(room);
        });
      });
    },
    checkMentioned: function checkMentioned(room, roomData) {
      var userPK = this.get('sessionAccount.loggeduser.pk');
      var hash = {
        roomId: room.id,
        userPK: userPK
      };

      if (roomData.added.length && roomData.added.findBy('id', String(userPK))) {
        hash.action = 'added';
        this.ajax.query(_chats.default.DELETE_ICON_INTERNAL_CHAT, 'deleteIcon', hash).then(function () {
          Ember.set(room, 'isAddedChat', false);
        });
      } else if (roomData.mentioned.length && roomData.mentioned.findBy('id', String(userPK))) {
        hash.action = 'mentioned';
        this.ajax.query(_chats.default.DELETE_ICON_INTERNAL_CHAT, 'deleteIcon', hash).then(function () {
          Ember.set(room, 'isMentionedChat', false);
        });
      }
    },
    updateRooms: function updateRooms() {
      var _this4 = this;

      this.ajax.query(_chats.default.GET_ROOMS).then(function (roomsData) {
        var rooms = _this4.get('rooms');

        var roomsTmp = [];
        roomsData.map(function (roomData) {
          try {
            var room = false; //rooms.findBy('id', roomData.id);

            if (!room) {
              room = _this4.createRoom(roomData);
            }

            if (roomData.added && roomData.added.length && roomData.added.findBy('id', String(_this4.get('sessionAccount.loggeduser.pk')))) {
              Ember.set(room, 'isAddedChat', true);
            } else if (roomData.mentioned && roomData.mentioned.length && roomData.mentioned.findBy('id', String(_this4.get('sessionAccount.loggeduser.pk')))) {
              Ember.set(room, 'isMentionedChat', true);
            }

            if (room.isBot) {
              _this4.set('braincheckRoom', room);
            } else {
              var participant = room.participants.find(function (user) {
                return user.id !== String(_this4.get('sessionAccount.loggeduser.pk'));
              });

              if (participant) {
                var _participant$staffpro, _participant$staffpro2;

                participant.status = (_participant$staffpro = participant.staffprofile) === null || _participant$staffpro === void 0 ? void 0 : (_participant$staffpro2 = _participant$staffpro.chatAgent[0]) === null || _participant$staffpro2 === void 0 ? void 0 : _participant$staffpro2.status.toLowerCase();
                Ember.set(room, 'participant', participant);
              }

              roomsTmp.addObject(room);
            }

            _this4.setMessages(room, Array.isArray(roomData.messages && roomData.messages.edges) ? roomData.messages.edges.map(function (edge) {
              return edge.node;
            }) : []);
          } catch (e) {
            console.error(e, 'ROOM', roomData);
          }
        });
        rooms.setObjects(roomsTmp.sortBy('lastMessage.modified').reverse());

        if (_this4.get('braincheckRoom')) {
          rooms.unshiftObject(_this4.get('braincheckRoom'));
        }

        _this4.initSockets();
      });
    },
    createRoom: function createRoom(roomData) {
      Ember.set(roomData, 'status', 'offline');
      var currentUser = this.get('sessionAccount.loggeduser.username');
      var filterParticipants = roomData.participants.filter(function (el) {
        return el.username !== currentUser;
      })[0];

      if (!roomData.isGroup && filterParticipants) {
        Ember.set(roomData, 'firstName', filterParticipants.firstName);
        Ember.set(roomData, 'lastName', filterParticipants.lastName);
        Ember.set(roomData, 'username', filterParticipants.username);
        Ember.set(roomData, 'isBot', roomData.username === 'raincheck.bot');

        if (roomData.isBot) {
          Ember.set(roomData, 'firstName', 'Braincheck');
          Ember.set(roomData, 'user', {
            username: 'raincheck.bot'
          });
        } else {
          var _filterParticipants$s;

          Ember.set(roomData, 'user', (_filterParticipants$s = filterParticipants.staffprofile) === null || _filterParticipants$s === void 0 ? void 0 : _filterParticipants$s.user);
        }
      }

      return roomData;
    },
    setMessages: function setMessages(room, messages) {
      var bookmarkedMsgs = {};
      (this.get('bookmarks') || []).forEach(function (b) {
        return bookmarkedMsgs[b.message.id] = true;
      });
      var now = new Date().getTime() / 1000;
      messages.forEach(function (msgData) {
        var created = new Date(msgData.created);
        var delta = Math.round(now - Math.round(created.getTime() / 1000));
        msgData.expired = Math.abs(delta) > 900;
        msgData.bookmarked = bookmarkedMsgs[msgData.id];

        if (Array.isArray(msgData.replies) && msgData.replies.length) {
          msgData.replies = msgData.replies.sortBy('created');
          msgData.lastReply = msgData.replies[msgData.replies.length - 1];
          msgData.created = new Date(msgData.lastReply.created);
          msgData.replies.forEach(function (msgData) {
            var created = new Date(msgData.created);
            var delta = Math.round(now - Math.round(created.getTime() / 1000));
            msgData.expired = Math.abs(delta) > 900;
            msgData.bookmarked = bookmarkedMsgs[msgData.id];
          });
        }

        msgData.reaction = Array.isArray(msgData.reaction) ? msgData.reaction : [];
      });
      Ember.set(room, 'allMessages', messages.sortBy('created'));
      Ember.set(room, 'messagesNew', messages.filter(function (msg) {
        return !msg.inReplyTo;
      }).sortBy('created'));
      Ember.set(room, 'lastMessage', room.messagesNew[room.messagesNew.length - 1]);
      var firstUnreadMessage = false;

      for (var i = 0, msgs = room.messagesNew; i < msgs.length && !firstUnreadMessage; i++) {
        if (!msgs[i].read && !msgs[i].inReplyTo) {
          firstUnreadMessage = msgs[i];
        }
      }

      Ember.set(room, 'firstUnreadMessage', firstUnreadMessage);
    },
    initSockets: function initSockets() {
      var _this5 = this;

      this.get('rooms').forEach(function (room) {
        if (room.webSocket) {
          return;
        }

        var ws = new WebSocket("".concat(_environment.default.socketUrl).concat(room.owner.id, "/").concat(room.id, "/"));
        Ember.set(room, 'webSocket', ws);

        _this5.callSocket(ws, room);
      });
    },
    addMessage: function addMessage(packet) {
      var _this6 = this;

      this.ajax.query(_query.default.ROOM_MESSAGE, 'chatMessage', {
        messageId: packet.message_id
      }).then(function (data) {
        var messages = _this6.get('selectedRoom.messages.edges');

        messages.pushObject({
          node: data
        });

        if (data.inReplyTo) {
          var message = messages.findBy('node.id', data.inReplyTo.id);

          if (message) {
            if (!Array.isArray(message.node.replies)) {
              Ember.set(message.node, 'replies', []);
            }

            message.node.replies.addObject(data);
          }
        }

        _this6.scrollToMessage(packet.message_id);
      });
    },
    deleteRoomMessage: function deleteRoomMessage(room, msgId) {
      var packet = JSON.stringify({
        type: 'delete',
        message_id: msgId
      });

      if (room.webSocket.readyState === 1) {
        room.webSocket.send(packet);
      }
    },
    addRoom: function addRoom(roomData) {
      this.get('rooms').addObject(this.createRoom(roomData));
      this.initSockets();
    },
    deleteRoom: function deleteRoom(room) {
      var _this7 = this;

      return new Promise(function (resolve) {
        _this7.ajax.mutation(_chats.default.DELETE_ROOM, 'deleteChatRoom', {
          roomId: room.id
        }, function () {
          room.webSocket.close();
          Ember.set(room, 'webSocket', false);

          _this7.get('rooms').removeObject(room);

          resolve(room);
        });
      });
    },
    changeStatusUser: function changeStatusUser(packet) {
      var privateDialogs = this.get('rooms').filterBy('isGroup', false);
      privateDialogs.forEach(function (user) {
        user.participants.map(function (el) {
          if (Number(el.id) === packet.userId) {
            Ember.set(user, 'status', packet.type);
          }
        });
      });
    },
    deleteEditMessage: function deleteEditMessage(packet) {
      this.get('selectedRoom.messages.edges').map(function (mes) {
        if (mes.node.id === packet.message_id) {
          Ember.set(mes, 'node.status', packet.status.toUpperCase());
          if (packet.type === 'edit') Ember.set(mes, 'node.text', packet.message);
        }
      });
    },
    addEmoji: function addEmoji(room, emoji, msgId) {
      var packet = JSON.stringify({
        type: 'add_emoji',
        emoji: "emoji-".concat(emoji.category, " _").concat(emoji.unicode),
        message_id: parseInt(msgId)
      });

      if (room.webSocket.readyState === 1) {
        room.webSocket.send(packet);
      }
    },
    onAddEmoji: function onAddEmoji(packet, room) {
      this.ajax.query(_query.default.ROOM_MESSAGE, 'chatMessage', {
        messageId: packet.message_id
      }).then(function (data) {
        room.messages.edges.map(function (mes) {
          if (mes.node.id === String(packet.message_id)) {
            Ember.set(mes, 'node', data);
          }
        });
      });
    },
    deleteEmoji: function deleteEmoji(room, data, msgId) {
      var packet = {
        type: 'delete_emoji',
        emoji_id: null,
        message_id: parseInt(msgId)
      };
      data.ids.forEach(function (id) {
        packet.emoji_id = parseInt(id);
        packet = JSON.stringify(packet);
        room.webSocket.send(packet);
      });
    },
    onDeleteEmoji: function onDeleteEmoji(packet, room) {
      room.messages.edges.map(function (mes) {
        if (Number(mes.node.id) === packet.message_id) {
          var filterEmoji = mes.node.reaction.findBy('id', String(packet.emoji_id));
          mes.node.reaction.removeObject(filterEmoji);
        }
      });
    },
    readMessage: function readMessage(room, msgId, item) {
      var packet = JSON.stringify({
        type: 'read_message',
        message_id: msgId
      });

      if (room) {
        var msg = room.messages.edges.findBy('node.id', msgId);
        msg && Ember.set(msg, 'node.read', true);
        Ember.set(room, 'isRead', false);
        Ember.set(this.get('rooms'), 'check', !this.get('rooms.check'));
      }

      if (room.webSocket.readyState === 1) {
        room.webSocket.send(packet);
        (0, _jquery.default)(item).removeClass('unread').addClass('read'); //TODO remove dependence from View
      }
    },
    onReadMessage: function onReadMessage(id) {
      var msg = document.getElementById('mes' + id);

      if (msg && msg.classList.contains('unread')) {
        (0, _jquery.default)(msg).removeClass('unread').addClass('read'); //TODO remove dependence from View
      }
    },
    scrollToMessage: function scrollToMessage(id) {
      setTimeout(function () {
        document.getElementById('mes' + id).scrollIntoView({
          behavior: 'smooth'
        }); //TODO remove dependence from View
      }, 500);
    },
    typing: function typing(room) {
      var packet = JSON.stringify({
        type: 'typing'
      });

      if (room.webSocket.readyState === 1) {
        room.webSocket.send(packet);
      }
    },
    callSocket: function callSocket(ws, room) {
      var _this8 = this;

      var user = this.get('sessionAccount.loggeduser.username');

      ws.onmessage = function (event) {
        var packet;

        try {
          packet = JSON.parse(event.data);
        } catch (e) {
          throw Error('error parse');
        }

        switch (packet.type) {
          case 'online':
          case 'offline':
            if (packet.username !== user) {
              _this8.changeStatusUser(packet);
            }

            break;

          case 'message':
            _this8.addMessage(packet);

            break;

          case 'read_message':
            _this8.onReadMessage(packet.message_id);

            break;

          case 'typing':
            if (packet.username !== user) {
              var _document$querySelect;

              _this8.set('typingUser', packet.username);

              (_document$querySelect = document.querySelector('.typing-text')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('blink');
              setTimeout(function () {
                var _document$querySelect2;

                (_document$querySelect2 = document.querySelector('.typing-text')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.remove('blink');
              }, 2000);
            }

            break;

          case 'edit':
          case 'delete':
            if (packet.status === 'edited' || packet.status === 'deleted') {
              _this8.deleteEditMessage(packet);
            } else if (packet.status === 'expired') {
              _this8.get('paperToaster').show("You cannot ".concat(packet.type, " this message now, it's too late"));
            }

            break;

          case 'add_emoji':
            _this8.onAddEmoji(packet, room);

            break;

          case 'delete_emoji':
            _this8.onDeleteEmoji(packet, room);

            break;

          default:
        }
      };

      ws.onclose = function (e) {
        console.log('Socket is closed. Room:', room, e);
        Ember.set(room, 'webSocket', false);
        setTimeout(function () {
          _this8.load();
        }, 5000);
      };

      ws.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        ws.close();
      };
    },
    loadBookmarks: function loadBookmarks() {
      var _this9 = this;

      this.ajax.query(_chats.default.GET_BOOKMARKS).then(function (res) {
        var bookmarks = Array.isArray(res) ? res : [];
        bookmarks.forEach(function (bookmark) {
          var created = new Date(bookmark.message.created);
          var now = new Date();
          var delta = Math.round(now.getTime() / 1000) - Math.round(created.getTime() / 1000);
          bookmark.message.expired = Math.abs(delta) > 900;
          bookmark.message.bookmarked = true;
        });

        _this9.set('bookmarks', bookmarks);
      });
    }
  });

  _exports.default = _default;
});