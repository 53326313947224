define("inboxfront/components/chat/chats-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    unassignedThreads: Ember.computed('threads', function () {
      if (this.get('threads')) {
        return this.get('threads').filter(function (obj) {
          return obj.node.agent === null;
        }).sort(function (a, b) {
          return new Date(b.node.lastActive) - new Date(a.node.lastActive);
        });
      }

      return [];
    }),
    unassignedCount: Ember.computed('unassignedThreads', function () {
      if (this.get('unassignedThreads')) {
        return this.get('unassignedThreads').filter(function (obj) {
          return obj.node.unreadCount !== 0;
        }).length;
      }

      return 0;
    }),
    openThreads: Ember.computed('threads', function () {
      var userID = this.get('sessionAccount.loggeduser.pk');

      if (this.get('threads')) {
        return this.get('threads').filter(function (obj) {
          return obj.node.agent !== null && obj.node.agent.agentProfile.user.pk === userID;
        }).sort(function (a, b) {
          return new Date(b.node.lastActive) - new Date(a.node.lastActive);
        });
      }

      return [];
    }),
    openCount: Ember.computed('openThreads', function () {
      if (this.get('openThreads')) {
        return this.get('openThreads').filter(function (obj) {
          return obj.node.unreadCount !== 0;
        }).length;
      }

      return 0;
    }),
    otherThreads: Ember.computed('threads', function () {
      var userID = this.get('sessionAccount.loggeduser.pk');

      if (this.get('threads')) {
        return this.get('threads').filter(function (obj) {
          return obj.node.agent !== null && obj.node.agent.agentProfile.user.pk !== userID;
        }).sort(function (a, b) {
          return new Date(b.node.lastActive) - new Date(a.node.lastActive);
        });
      }

      return [];
    }),
    otherCount: Ember.computed('otherThreads', function () {
      if (this.get('otherThreads')) {
        return this.get('otherThreads').filter(function (obj) {
          return obj.node.unreadCount !== 0;
        }).length;
      }

      return 0;
    }),
    filterThreads: Ember.computed('panelActive', function () {
      var threads = this.get('threads');
      var panelActive = this.get('panelActive');

      if (this.get('isChat')) {
        if (threads && threads.length > 0) {
          if (panelActive === 'unassigned') {
            return this.get('unassignedThreads');
          } else if (panelActive === 'starred') {
            return this.get('starredThreads');
          } else if (panelActive === 'assigned') {
            return this.get('openThreads');
          } else if (panelActive === 'other') {
            return this.get('otherThreads');
          }
        } else {
          return [];
        }
      }
    }),
    teamRooms: Ember.computed('rooms.[]', 'showAllTeams', function () {
      var rooms = (this.get('rooms') || []).filter(function (room) {
        return room.isGroup;
      });
      var userID = '' + this.get('sessionAccount.loggeduser.pk');
      rooms.map(function (room) {
        var _room$messages;

        Ember.set(room, 'isRead', (_room$messages = room.messages) === null || _room$messages === void 0 ? void 0 : _room$messages.edges.some(function (item) {
          return !item.node.read && item.node.user.id !== userID;
        }));
      });

      if (this.get('showAllTeams')) {
        return rooms;
      } else {
        return rooms.slice(0, 5);
      }
    }),
    unreadTeamRooms: Ember.computed('teamRooms', 'rooms.check', function () {
      return this.get('teamRooms').some(function (room) {
        return room.isRead;
      });
    }),
    directRooms: Ember.computed('rooms.[]', 'showAllDirect', function () {
      var rooms = (this.get('rooms') || []).filter(function (room) {
        return !room.isGroup;
      });
      var userID = '' + this.get('sessionAccount.loggeduser.pk');
      rooms.map(function (room) {
        var _room$messages2;

        Ember.set(room, 'isRead', (_room$messages2 = room.messages) === null || _room$messages2 === void 0 ? void 0 : _room$messages2.edges.some(function (item) {
          return !item.node.read && item.node.user.id !== userID;
        }));
      });

      if (this.get('showAllDirect')) {
        return rooms;
      } else {
        return rooms.slice(0, 3);
      }
    }),
    unreadDirectRooms: Ember.computed('directRooms', 'rooms.check', function () {
      return this.get('directRooms').some(function (room) {
        return room.isRead;
      });
    }),
    actions: {
      menuThreads: function menuThreads(name) {
        if (name === 'teams' || name === 'direct') {
          this.set('isChat', false);
        } else {
          this.set('isChat', true);
        }

        this.set('panelActive', name);
      }
    }
  });

  _exports.default = _default;
});