define("inboxfront/templates/components/settings-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jYVGRjkf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"alignCenter menu-link \",[28,\"if\",[[24,[\"menuItem\",\"class\"]],[24,[\"menuItem\",\"class\"]]],null],\" \",[28,\"if\",[[28,\"eq\",[[24,[\"currentLink\"]],[28,\"or\",[[24,[\"menuItem\",\"type\"]],[24,[\"menuItem\",\"link\"]]],null]],null],\"active\",\"\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"menuItem\",\"query\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"page\"],[[24,[\"menuItem\",\"query\"]]]]],[24,[\"menuItem\",\"link\"]]]],{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[[24,[\"menuItem\",\"icon\"]]],null],false],[0,\"\\n            \"],[7,\"span\",true],[8],[1,[28,\"t\",[[24,[\"menuItem\",\"title\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"route\"],[[24,[\"menuItem\",\"link\"]]]],{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[[24,[\"menuItem\",\"icon\"]]],null],false],[0,\"\\n            \"],[7,\"span\",true],[8],[1,[28,\"t\",[[24,[\"menuItem\",\"title\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/settings-menu-item.hbs"
    }
  });

  _exports.default = _default;
});