define("inboxfront/components/common/avatar", ["exports", "inboxfront/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    size: 35,
    init: function init() {
      var _this$user$firstName, _this$user$lastName;

      this._super.apply(this, arguments);

      this.colors = [{
        color: '#afb882',
        backgroundColor: '#dfe4b6'
      }, {
        color: '#b56366',
        backgroundColor: '#ff9ca0'
      }, {
        color: '#86b07b',
        backgroundColor: '#b3dba7'
      }, {
        color: '#7f81a9',
        backgroundColor: '#d0d2fb'
      }, {
        color: '#8f7c56',
        backgroundColor: '#ecd098'
      }, {
        color: '#64a5af',
        backgroundColor: '#a3e4ec'
      }, {
        color: '#6aa6c2',
        backgroundColor: '#9dddf9'
      }];
      this.url = this.user.attachment && "".concat(_environment.default.mediaUrl).concat(this.user.attachment.datafile);
      this.fontSize = parseInt(this.size / 2.5);
      this.lineHeight = this.size - 6;
      this.initial = ((_this$user$firstName = this.user.firstName) === null || _this$user$firstName === void 0 ? void 0 : _this$user$firstName.slice(0, 1).toUpperCase()) || ((_this$user$lastName = this.user.lastName) === null || _this$user$lastName === void 0 ? void 0 : _this$user$lastName.slice(0, 1).toUpperCase()) || 'A';
      this.code = this.hashCode(this.initial);
      this.color = this.get('colors')[Math.abs(Math.floor(this.code % this.colors.length))];
    },
    hashCode: function hashCode(string) {
      var hash = 0;

      if ((string === null || string === void 0 ? void 0 : string.length) > 0) {
        for (var i = 0; i < string.length; i++) {
          var char = string.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash |= 0;
        }
      }

      return hash & hash;
    }
  });

  _exports.default = _default;
});