define("inboxfront/templates/components/dashboard/panels/peak-time-heatmap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AF+GQ3ra",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dashboard/panel-loader\",null,[[\"isLoading\"],[[24,[\"isLoading\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"inbound_traffic\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"peak-time-heatmap\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/panels/peak-time-heatmap.hbs"
    }
  });

  _exports.default = _default;
});