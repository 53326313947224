define("inboxfront/components/dashboard/panels/support-trends", ["exports", "inboxfront/components/dashboard/panel", "jquery"], function (_exports, _panel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panel.default.extend({
    renderChart: function renderChart(element) {
      var $elem = (0, _jquery.default)(element.querySelector('#support_trends')).highcharts({
        chart: {
          type: 'column',
          height: 315
        },
        title: null,
        xAxis: {
          categories: [],
          title: null
        },
        yAxis: {
          title: null
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        series: [{
          name: 'Conversations',
          data: [],
          color: '#B0C5AE',
          pointWidth: 34
        }, {
          name: 'Tickets',
          data: [],
          color: '#9293B5',
          pointWidth: 34
        }, {
          name: 'Knowledge base',
          data: [],
          color: '#F7D79B',
          pointWidth: 34
        }]
      });
      this.set('$highcharts', $elem.highcharts());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('$highcharts');
      var dataGroups = this.get('dataGroups');
      var kbStats = this.get('kbStats');
      var dates = dataGroups.map(function (d) {
        return d.dateMin;
      }); // console.log('RENDER TRENDS', dates, dataGroups, kbStats);

      var chats = [];
      var tickets = [];
      var kb = [];
      var series = [chats, tickets, kb];
      var realData = this.get('realData');
      var i = 0;
      dates.forEach(function (d) {
        if (realData) {
          var data = dataGroups[i];
          var kbData = kbStats[i];
          chats.pushObject(data === null || data === void 0 ? void 0 : data.totalChats);
          tickets.pushObject(data === null || data === void 0 ? void 0 : data.totalTickets);
          kb.pushObject(kbData === null || kbData === void 0 ? void 0 : kbData.totalViews);
        } else {
          chats.pushObject(Math.ceil(Math.random() * 100));
          tickets.pushObject(Math.ceil(Math.random() * 100));
          kb.pushObject(Math.ceil(Math.random() * 100));
        }

        i++;
      });
      chart.xAxis[0].setCategories(dates, false);
      chart.series[0].setData(series[0], false);
      chart.series[1].setData(series[1], false);
      chart.series[2].setData(series[2]); // chart.redraw();
    }
  });

  _exports.default = _default;
});