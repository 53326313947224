define("inboxfront/controllers/contactfields", ["exports", "inboxfront/controllers/customfields"], function (_exports, _customfields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customfields.default.extend({});

  _exports.default = _default;
});