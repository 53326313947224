define("inboxfront/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    validation: Ember.inject.service(),
    email: '',
    password: '',
    inputType: 'password',
    isDisabledBtn: Ember.computed('email', 'password', function () {
      var validationEmail = this.validation.emailValidation[0].validate(this.get('email'));
      var validationPassword = this.validation.passwordValidation[0].validate(this.get('password'));
      return !validationEmail || !validationPassword;
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _this$getProperties = this.getProperties('email', 'password'),
            email = _this$getProperties.email,
            password = _this$getProperties.password;

        this.get('session').authenticate('authenticator:oauth2', email, password).catch(function (_ref) {
          var responseJSON = _ref.responseJSON;

          if (responseJSON.error_description) {
            _this.get('paperToaster').show(_this.intl.t('forms.login_failed'));
          }
        });
      },
      togglePassword: function togglePassword() {
        this.set('inputType', this.inputType === 'password' ? 'text' : 'password');
      }
    }
  });

  _exports.default = _default;
});