define("inboxfront/templates/components/common/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tmyD0eKi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"wrapAvatar\"],[11,\"style\",[29,[\"background-color: \",[24,[\"color\",\"backgroundColor\"]],\"; color: \",[24,[\"color\",\"color\"]],\"; font-size: \",[22,\"fontSize\"],\"px;\\n             height: \",[22,\"size\"],\"px; width: \",[22,\"size\"],\"px; line-height: \",[22,\"lineHeight\"],\"px;\"]]],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"url\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"initial\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"img\",true],[11,\"src\",[22,\"url\"]],[10,\"alt\",\"avatar\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"status\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"status\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/avatar.hbs"
    }
  });

  _exports.default = _default;
});