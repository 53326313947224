define("inboxfront/components/page-header", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: '',
    progress: 100,
    currentLink: Ember.computed('router.currentRoute.queryParams', function () {
      var page = this.router.currentRouteName.split('.')[0];

      switch (page) {
        case 'channels':
          return this.router.currentRoute.queryParams.page;

        case 'import':
          return this.router.currentRoute.queryParams.tab;

        default:
          return page;
      }
    }),
    isContacts: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'contacts';
    }),
    isOrganizations: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'organizations';
    }),
    isContactsImport: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'Contacts';
    }),
    isOrganizationsImport: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'Organizations';
    }),
    isOnboarding: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'onboarding';
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.query(_query.default.ONBOARDING_PROGRESS).then(function (progress) {
        _this.set('progress', progress[0]);
      });
    }
  });

  _exports.default = _default;
});