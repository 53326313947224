define("inboxfront/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    authGoogle: Ember.inject.service(),
    routeIfAlreadyAuthenticated: '/',
    model: function model() {
      this.authGoogle.init();
    }
  });

  _exports.default = _default;
});