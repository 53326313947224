define("inboxfront/components/paper-input", ["exports", "inboxfront/mixins/focusable-mixin", "inboxfront/mixins/color-mixin", "inboxfront/mixins/child-mixin", "inboxfront/mixins/validation-mixin"], function (_exports, _focusableMixin, _colorMixin, _childMixin, _validationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_focusableMixin.default, _colorMixin.default, _childMixin.default, _validationMixin.default, {
    tagName: 'md-input-container',
    classNameBindings: ['hasValue:md-input-has-value', 'isInvalidAndTouched:md-input-invalid', 'hasLeftIcon:md-icon-left', 'hasRightIcon:md-icon-right', 'focused:md-input-focused', 'block:md-block', 'placeholder:md-input-has-placeholder'],
    type: 'text',
    autofocus: false,
    autocomplete: true,
    maxlengthInput: 128,
    maxlengthTextArea: 256,
    isTouched: false,
    //iconComponent: 'paper-icon',
    isInvalid: Ember.computed.or('hasErrorMessages', 'isNativeInvalid'),
    hasValue: Ember.computed('value', 'isNativeInvalid', function () {
      var value = this.get('value');
      var isNativeInvalid = this.get('isNativeInvalid');
      return !Ember.isEmpty(value) || isNativeInvalid;
    }),
    inputElementId: Ember.computed('elementId', {
      get: function get() {
        return "input-".concat(this.get('elementId'));
      },
      set: function set(key, value) {
        return Ember.set(this, 'elementId', value);
      }
    }),
    renderCharCount: Ember.computed('value', function () {
      var currentLength = this.get('value') ? this.get('value').length : 0;
      return "".concat(currentLength, "/").concat(this.get('maxlength'));
    }),
    //hasLeftIcon: bool('icon'),
    //hasRightIcon: bool('iconRight'),
    isInvalidAndTouched: Ember.computed.and('isInvalid', 'isTouched'),
    validationProperty: 'value',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _this$getProperties = this.getProperties('value', 'errors'),
          value = _this$getProperties.value,
          errors = _this$getProperties.errors;

      var _this$getProperties2 = this.getProperties('_prevValue', '_prevErrors'),
          _prevValue = _this$getProperties2._prevValue,
          _prevErrors = _this$getProperties2._prevErrors;

      if (value !== _prevValue || errors !== _prevErrors) {
        this.notifyValidityChange();
      }

      this._prevValue = value;
      this._prevErrors = errors;
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.setValue(this.get('value'));
    },
    setValue: function setValue(value) {
      value = !value ? '' : value;

      if (this.element.querySelector('input, textarea').value !== value) {
        this.element.querySelector('input, textarea').value = value;
      }
    },
    actions: {
      handleInput: function handleInput(e) {
        var _this = this,
            _inputElement$validit;

        this.onChange && this.onChange(e.target.value);
        Ember.run.next(function () {
          if (_this.isDestroyed) {
            return;
          }

          _this.setValue(_this.get('value'));
        });
        var inputElement = this.element.querySelector('input');
        var isNativeInvalid = inputElement === null || inputElement === void 0 ? void 0 : (_inputElement$validit = inputElement.validity) === null || _inputElement$validit === void 0 ? void 0 : _inputElement$validit.badInput;

        if (this.type === 'date' && e.target.value === '') {
          isNativeInvalid = false;
        }

        this.set('isNativeInvalid', isNativeInvalid);
        this.notifyValidityChange();
      },
      handleBlur: function handleBlur(e) {
        this.onBlur && this.onBlur(e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }
    }
  });

  _exports.default = _default;
});